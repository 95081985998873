import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import host from "../../global";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import {Download} from "lucide-react";

const PDFGenerator = ({ name, role, scores, stages, interview_id }) => {
  const [MCQTestResultData, setMCQTestResultData] = useState({});
  const [codingProblems, setProblems] = useState([]);
  const [codeSubmissions, setCodeSubmissions] = useState({});
  const [evaluationData, setEvaluationData] = useState({});
  const [behavioralEvaluationData, setBehavioralEvaluationData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedProblemId, setSelectedProblemId] = useState(null);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioIndex, setAudioIndex] = useState(null);
  const audioRefs = useRef([]);
  const averageScore = scores && Object.keys(scores).length > 0
  ? (
      Object.values(scores).reduce((acc, curr) => acc + parseFloat(curr || 0), 0) /
      Object.values(scores).length
    ).toFixed(2)
  : "0.00";
  const accessToken = Cookies.get("accessToken");

  const handleToggleDetails = (problemId) => {
    setSelectedProblemId(selectedProblemId === problemId ? null : problemId);
  };

  const toggleAudio = (index) => {
    if (audioIndex === index && audioPlaying) {
      audioRefs.current[index].pause();
      setAudioPlaying(false);
      setAudioIndex(null);
    } else {
      audioRefs.current[index].play();
      setAudioPlaying(true);
      setAudioIndex(index);
      audioRefs.current.forEach((audio, i) => {
        if (i !== index) {
          audio.pause();
        }
      });
    }
  };

  const generatePDF = useCallback(async () => {

    if (loading) {
      toast.warn("Data is still loading, cannot generate PDF yet.");
      return;
    }

    try {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true,
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 10;
      const contentWidth = pageWidth - 2 * margin;

      let currentY = margin;
      let pageCount = 1;

      const addNewPage = () => {
        pdf.addPage();
        pageCount++;
        currentY = margin;
        pdf.setFontSize(10);
        pdf.text(`Candidate Report - ${name || "Unknown"} (Page ${pageCount})`, margin, currentY);
        currentY += 10;
      };

      // Header
      pdf.setFontSize(16);
      pdf.setTextColor(0, 0, 0);
      pdf.text(`Candidate Report - ${name || "Unknown"}`, margin, currentY);
      currentY += 10;

      pdf.setFontSize(10);
      pdf.text(`Job Role: ${role || "NA"}`, margin, currentY);
      currentY += 5;
      pdf.text(`Total Average Percent: ${averageScore}%`, margin, currentY);
      currentY += 5;
      pdf.text(
        `Date: ${MCQTestResultData.startDateTime || evaluationData.startDateTime}`,
        margin,
        currentY
      );
      currentY += 10;

      pdf.setFont("Helvetica", "normal");

      // MCQ Section (Render if data exists)
      if (Object.keys(MCQTestResultData).length > 0) {
        if (currentY > pageHeight - 80) addNewPage();
        console.log("Rendering MCQ Section");
        pdf.setFontSize(14);
        pdf.text(`MCQ Test - ${scores.mcq_percent || 0}%`, margin, currentY);
        currentY += 10;

        pdf.setDrawColor(200, 200, 200);
        pdf.line(margin, currentY, pageWidth - margin, currentY);
        currentY += 5;

        if (MCQTestResultData.mcqs && Object.keys(MCQTestResultData.mcqs).length > 0) {
          Object.values(MCQTestResultData.mcqs).forEach((question, index) => {
            if (currentY > pageHeight - 70) addNewPage();
            pdf.setFontSize(12);
            pdf.setTextColor(0, 0, 100);
            const splitQuestion = pdf.splitTextToSize(`Question ${index + 1}: ${question.question || "N/A"}`, contentWidth);
            if (currentY + splitQuestion.length * 5 > pageHeight - 30) addNewPage();
            pdf.text(splitQuestion, margin, currentY);
            currentY += splitQuestion.length * 5 + 2;

            pdf.setFontSize(10);
            pdf.setTextColor(0, 0, 0);
            const options = [question.option1, question.option2, question.option3, question.option4].filter(Boolean);
            if (options.length > 0) {
              options.forEach((option, optIndex) => {
                const splitOption = pdf.splitTextToSize(`${String.fromCharCode(65 + optIndex)}. ${option}`, contentWidth - 10);
                if (currentY + splitOption.length * 5 > pageHeight - 30) addNewPage();
                pdf.text(splitOption, margin + 5, currentY);
                currentY += splitOption.length * 5 + 2;
              });
            } else {
              pdf.text("No options available", margin + 5, currentY);
              currentY += 5;
            }

            if (question.answer) {
              const splitCorrect = pdf.splitTextToSize(`Correct Answer: ${question.answer}`, contentWidth - 10);
              if (currentY + splitCorrect.length * 5 > pageHeight - 30) addNewPage();
              pdf.text(splitCorrect, margin + 5, currentY);
              currentY += splitCorrect.length * 5 + 2;
            }

            if (question.given_answer) {
              if (currentY > pageHeight - 30) addNewPage();
              const splitUser = pdf.splitTextToSize(`Your Answer: ${question.given_answer}`, contentWidth - 10);
              pdf.setFont("Helvetica", "bold");
              pdf.setTextColor(question.score === 1 ? 0 : 255, question.score === 1 ? 128 : 0, 0);
              if (currentY + splitUser.length * 5 > pageHeight - 30) addNewPage();
              pdf.text(splitUser, margin + 5, currentY);
              pdf.setTextColor(0, 0, 0);
              pdf.setFont("Helvetica", "normal");
              currentY += splitUser.length * 5 + 2;
            }

            if (question.score !== undefined && question.score !== null) {
              pdf.text(`Score: ${question.score || 0}`, margin + 5, currentY);
              currentY += 5;
            } else if (!question.given_answer) {
              if (currentY > pageHeight - 30) addNewPage();
              pdf.setTextColor(255, 0, 0);
              pdf.setFont("Helvetica", "bold");
              pdf.text("Not Attempted", margin + 5, currentY);
              pdf.setTextColor(0, 0, 0);
              pdf.setFont("Helvetica", "normal");
              currentY += 10;
            }

            currentY += 10;
            if (index < Object.values(MCQTestResultData.mcqs).length - 1) {
              pdf.setDrawColor(200, 200, 200);
              pdf.line(margin, currentY - 5, pageWidth - margin, currentY - 5);
            }
          });
        } else {
          pdf.setFontSize(10);
          pdf.text("No MCQ data available", margin, currentY);
          currentY += 10;
        }
      }

      // Coding Section (Render if data exists)
      if (codingProblems.length > 0) {
        if (currentY > pageHeight - 80) addNewPage();
        console.log("Rendering Coding Section");
        pdf.setFontSize(14);
        pdf.text(`Coding Test - ${scores.coding_percent || 0}%`, margin, currentY);
        currentY += 10;

        pdf.setDrawColor(200, 200, 200);
        pdf.line(margin, currentY, pageWidth - margin, currentY);
        currentY += 5;

        if (codingProblems.length > 0) {
          codingProblems.forEach((problem, index) => {
            const problemId = problem.id;
            const submission = codeSubmissions[problemId] || {};
            const testCases = submission?.test_cases_passed || [];

            if (currentY > pageHeight - 70) addNewPage();
            pdf.setFontSize(12);
            pdf.setTextColor(0, 0, 100);
            pdf.text(`Problem ${index + 1}: ${problem.title || "N/A"}`, margin, currentY);
            currentY += 7;

            if (problem.description) {
              const splitDescription = pdf.splitTextToSize(`Description: ${problem.description}`, contentWidth);
              if (currentY + splitDescription.length * 5 > pageHeight - 30) addNewPage();
              pdf.setFontSize(10);
              pdf.setTextColor(0, 0, 0);
              pdf.text(splitDescription, margin, currentY);
              currentY += splitDescription.length * 5 + 5;
            }

            if (problem.input_format) {
              const splitInputFormat = pdf.splitTextToSize(`Input Format: ${problem.input_format}`, contentWidth);
              if (currentY + splitInputFormat.length * 5 > pageHeight - 30) addNewPage();
              pdf.text(splitInputFormat, margin, currentY);
              currentY += splitInputFormat.length * 5 + 5;
            }

            if (problem.output_format) {
              const splitOutputFormat = pdf.splitTextToSize(`Output Format: ${problem.output_format}`, contentWidth);
              if (currentY + splitOutputFormat.length * 5 > pageHeight - 30) addNewPage();
              pdf.text(splitOutputFormat, margin, currentY);
              currentY += splitOutputFormat.length * 5 + 5;
            }

            if (problem.constraints) {
              const splitConstraints = pdf.splitTextToSize(`Constraints: ${problem.constraints}`, contentWidth);
              if (currentY + splitConstraints.length * 5 > pageHeight - 30) addNewPage();
              pdf.text(splitConstraints, margin, currentY);
              currentY += splitConstraints.length * 5 + 5;
            }

            if (problem.examples?.length > 0) {
              pdf.text("Examples:", margin, currentY);
              currentY += 5;
              problem.examples.forEach((example, exIndex) => {
                if (currentY > pageHeight - 50) addNewPage();
                const splitInput = pdf.splitTextToSize(`Input ${exIndex + 1}: ${example.input || "N/A"}`, contentWidth - 10);
                const splitOutput = pdf.splitTextToSize(`Output ${exIndex + 1}: ${example.output || "N/A"}`, contentWidth - 10);
                const splitExplanation = example.explanation
                  ? pdf.splitTextToSize(`Explanation ${exIndex + 1}: ${example.explanation}`, contentWidth - 10)
                  : [];
                if (currentY + (splitInput.length + splitOutput.length + splitExplanation.length) * 5 > pageHeight - 30) addNewPage();
                pdf.text(splitInput, margin + 5, currentY);
                currentY += splitInput.length * 5 + 2;
                pdf.text(splitOutput, margin + 5, currentY);
                currentY += splitOutput.length * 5 + 2;
                if (example.explanation) {
                  pdf.text(splitExplanation, margin + 5, currentY);
                  currentY += splitExplanation.length * 5 + 2;
                }
              });
              currentY += 5;
            }

            if (submission.language && submission.code) {
              if (currentY > pageHeight - 70) addNewPage();
              pdf.text("Submission Details:", margin, currentY);
              currentY += 5;
              pdf.text(`Language: ${submission.language}`, margin + 5, currentY);
              currentY += 5;
              const splitCode = pdf.splitTextToSize(`Code: ${submission.code}`, contentWidth - 10);
              if (currentY + splitCode.length * 5 > pageHeight - 30) addNewPage();
              pdf.text(splitCode, margin + 5, currentY);
              currentY += splitCode.length * 5 + 5;
              const acceptedTestCasesCount = Array.isArray(testCases) ? testCases.filter(tc => tc.status_message === "Accepted").length : 0;
              const totalTestCasesCount = Array.isArray(testCases) ? testCases.length : 0;
              pdf.text(`Test Cases Passed: ${acceptedTestCasesCount} / ${totalTestCasesCount}`, margin + 5, currentY);
              currentY += 5;

              if (selectedProblemId === problemId && Array.isArray(testCases)) {
                if (currentY > pageHeight - 70) addNewPage();
                pdf.text("Test Case Details:", margin, currentY);
                currentY += 5;
                testCases.forEach((testCase, tcIndex) => {
                  if (currentY > pageHeight - 50) addNewPage();
                  pdf.text(`Test Case ${tcIndex + 1}:`, margin + 5, currentY);
                  currentY += 5;
                  pdf.text(`Status: ${testCase.status_message || "N/A"}`, margin + 10, currentY);
                  currentY += 5;
                  const splitYourOutput = pdf.splitTextToSize(`Your Output: ${testCase.yourOutput || "N/A"}`, contentWidth - 20);
                  if (currentY + splitYourOutput.length * 5 > pageHeight - 30) addNewPage();
                  pdf.text(splitYourOutput, margin + 10, currentY);
                  currentY += splitYourOutput.length * 5 + 2;
                  const splitExpectedOutput = pdf.splitTextToSize(`Expected Output: ${testCase.expectedOutput || "N/A"}`, contentWidth - 20);
                  if (currentY + splitExpectedOutput.length * 5 > pageHeight - 30) addNewPage();
                  pdf.text(splitExpectedOutput, margin + 10, currentY);
                  currentY += splitExpectedOutput.length * 5 + 2;
                  if (testCase.stderr) {
                    const splitStderr = pdf.splitTextToSize(`Stderr: ${testCase.stderr}`, contentWidth - 20);
                    if (currentY + splitStderr.length * 5 > pageHeight - 30) addNewPage();
                    pdf.text(splitStderr, margin + 10, currentY);
                    currentY += splitStderr.length * 5 + 2;
                  }
                  currentY += 5;
                });
              }
            } else {
              if (currentY > pageHeight - 30) addNewPage();
              pdf.setTextColor(255, 0, 0);
              pdf.setFont("Helvetica", "bold");
              pdf.text("Not Attempted", margin + 5, currentY);
              pdf.setTextColor(0, 0, 0);
              pdf.setFont("Helvetica", "normal");
              currentY += 10;
            }

            currentY += 10;
            if (index < codingProblems.length - 1) {
              pdf.setDrawColor(200, 200, 200);
              pdf.line(margin, currentY - 5, pageWidth - margin, currentY - 5);
            }
          });
        } else {
          pdf.setFontSize(10);
          pdf.text("No coding problems available", margin, currentY);
          currentY += 10;
        }
      }

      // AI Interview Section (Render if data exists)
      if (Object.keys(evaluationData).length > 0) {
        if (currentY > pageHeight - 80) addNewPage();
        console.log("Rendering AI Interview Section");
        pdf.setFontSize(14);
        pdf.text(`AI Interview - ${scores.interview_percent || 0}%`, margin, currentY);
        currentY += 10;

        pdf.setDrawColor(200, 200, 200);
        pdf.line(margin, currentY, pageWidth - margin, currentY);
        currentY += 5;

        if (evaluationData.questions && Array.isArray(evaluationData.questions) && evaluationData.questions.length > 0) {
          evaluationData.questions.forEach((question, index) => {
            if (currentY > pageHeight - 70) addNewPage();
            pdf.setFontSize(12);
            pdf.setTextColor(0, 0, 100);
            const splitQuestion = pdf.splitTextToSize(`Question ${index + 1}: ${question || "N/A"}`, contentWidth);
            if (currentY + splitQuestion.length * 5 > pageHeight - 30) addNewPage();
            pdf.text(splitQuestion, margin, currentY);
            currentY += splitQuestion.length * 5 + 2;

            pdf.setFontSize(10);
            pdf.setTextColor(0, 0, 0);
            const answer = evaluationData.answers[index] || "NA";
            const splitAnswer = pdf.splitTextToSize(`Answer: ${answer}`, contentWidth - 10);
            if (currentY + splitAnswer.length * 5 > pageHeight - 30) addNewPage();
            pdf.text(splitAnswer, margin + 5, currentY);
            currentY += splitAnswer.length * 5 + 2;

            const score = evaluationData.scores[index] || "NA";
            pdf.text(`Score: ${score}`, margin + 5, currentY);
            currentY += 5;

            const remark = evaluationData.remarks[index] || "NA";
            const splitRemark = pdf.splitTextToSize(`Remark: ${remark}`, contentWidth - 10);
            if (currentY + splitRemark.length * 5 > pageHeight - 30) addNewPage();
            pdf.text(splitRemark, margin + 5, currentY);
            currentY += splitRemark.length * 5 + 2;

            if (evaluationData.video_urls && evaluationData.video_urls[index]) {
              if (currentY > pageHeight - 30) addNewPage();
              const videoLinkText = "Video Response";
              const videoLinkX = margin + 5;
              const videoLinkY = currentY;
              const videoLinkWidth = pdf.getTextWidth(videoLinkText);
              const videoLinkHeight = 5;

              pdf.setTextColor(0, 0, 255);
              pdf.text(videoLinkText, videoLinkX, videoLinkY);

              pdf.link(videoLinkX, videoLinkY - 4, videoLinkWidth, videoLinkHeight, {
                url: evaluationData.video_urls[index],
              });

              pdf.setTextColor(0, 0, 0);
              currentY += 7;
            }

            currentY += 10;
            if (index < evaluationData.questions.length - 1) {
              pdf.setDrawColor(200, 200, 200);
              pdf.line(margin, currentY - 5, pageWidth - margin, currentY - 5);
            }
          });
        } else {
          pdf.setFontSize(10);
          pdf.text("No AI Interview data available", margin, currentY);
          currentY += 10;
        }
        if (evaluationData.Interview_final_evaluation) {
          if (currentY > pageHeight - 70) addNewPage(); // Only add new page if insufficient space
          pdf.setFontSize(14);
          pdf.text("Smart Evaluation", margin, currentY);
          currentY += 10;
  
          pdf.setDrawColor(200, 200, 200);
          pdf.line(margin, currentY, pageWidth - margin, currentY);
          currentY += 10;
  
          // Overall Score
          pdf.setFontSize(12);
          pdf.text("Overall Score:", margin, currentY);
          currentY += 7;
          if (
            Object.keys(evaluationData.Interview_final_evaluation.overall_score || {})
              .length > 0
          ) {
            Object.entries(evaluationData.Interview_final_evaluation.overall_score).forEach(
              ([category, score]) => {
                pdf.setFontSize(10);
                pdf.text(
                  `${category.charAt(0).toUpperCase() + category.slice(1)}: ${score}%`,
                  margin + 5,
                  currentY
                );
                currentY += 5;
              }
            );
            currentY += 5;
          } else {
            pdf.setFontSize(10);
            pdf.text("No overall scores available.", margin + 5, currentY);
            currentY += 10;
          }
  
          // Skill Performance
          if (currentY > pageHeight - 60) addNewPage();
          pdf.setFontSize(12);
          pdf.text("Skill Performance:", margin, currentY);
          currentY += 7;
          if (
            Object.keys(evaluationData.Interview_skill_performance || {}).length > 0
          ) {
            Object.entries(evaluationData.Interview_skill_performance).forEach(
              ([skill, score]) => {
                const skillName = skill.replace(/([A-Z])/g, " $1").trim();
                pdf.setFontSize(10);
                pdf.text(
                  `${skillName}: ${Number(score).toFixed(1)}%`,
                  margin + 5,
                  currentY
                );
                currentY += 5;
              }
            );
            currentY += 5;
          } else {
            pdf.setFontSize(10);
            pdf.text(
              "No skill performance data available.",
              margin + 5,
              currentY
            );
            currentY += 10;
          }
  
          // Top Skills
          if (currentY > pageHeight - 60) addNewPage();
          pdf.setFontSize(12);
          pdf.text("Top Skills:", margin, currentY);
          currentY += 7;
          if (
            evaluationData.Interview_final_evaluation.top_skills &&
            evaluationData.Interview_final_evaluation.top_skills.length > 0
          ) {
            pdf.setFontSize(10);
            evaluationData.Interview_final_evaluation.top_skills.forEach((skill) => {
              pdf.text(`• ${skill}`, margin + 5, currentY);
              currentY += 5;
            });
            currentY += 5;
          } else {
            pdf.setFontSize(10);
            pdf.text("No top skills identified.", margin + 5, currentY);
            currentY += 10;
          }
  
          // Improvement Areas
          if (currentY > pageHeight - 60) addNewPage();
          pdf.setFontSize(12);
          pdf.text("Improvement Areas:", margin, currentY);
          currentY += 7;
          if (
            evaluationData.Interview_final_evaluation.improvement_areas &&
            evaluationData.Interview_final_evaluation.improvement_areas.length > 0
          ) {
            pdf.setFontSize(10);
            evaluationData.Interview_final_evaluation.improvement_areas.forEach((area) => {
              pdf.text(`• ${area}`, margin + 5, currentY);
              currentY += 5;
            });
            currentY += 5;
          } else {
            pdf.setFontSize(10);
            pdf.text("No improvement areas identified.", margin + 5, currentY);
            currentY += 10;
          }
  
          // Future Potential
          if (currentY > pageHeight - 70) addNewPage();
          pdf.setFontSize(12);
          pdf.text("Future Potential:", margin, currentY);
          currentY += 7;
          pdf.setFontSize(10);
          if (evaluationData.Interview_final_evaluation.future_potential) {
            const potentialText = evaluationData.Interview_final_evaluation.future_potential;
            const splitPotential = pdf.splitTextToSize(
              potentialText,
              contentWidth - 10
            );
            if (currentY + splitPotential.length * 5 > pageHeight - 30)
              addNewPage();
            pdf.text(splitPotential, margin + 5, currentY);
            currentY += splitPotential.length * 5 + 5;
          } else {
            pdf.text(
              "No future potential assessment available.",
              margin + 5,
              currentY
            );
            currentY += 10;
          }
  
          // Recommendation
          if (currentY > pageHeight - 70) addNewPage();
          pdf.setFontSize(12);
          pdf.text("Recommendation:", margin, currentY);
          currentY += 7;
          pdf.setFontSize(10);
          if (evaluationData.Interview_final_evaluation.recommendation) {
            pdf.text(
              `Decision: ${evaluationData.Interview_final_evaluation.recommendation}`,
              margin + 5,
              currentY
            );
            currentY += 5;
            if (evaluationData.Interview_final_evaluation.recommendation_reason) {
              const reasonText =
                evaluationData.Interview_final_evaluation.recommendation_reason;
              const splitReason = pdf.splitTextToSize(
                `Reason: ${reasonText}`,
                contentWidth - 10
              );
              if (currentY + splitReason.length * 5 > pageHeight - 20)
                addNewPage();
              pdf.text(splitReason, margin + 5, currentY);
              currentY += splitReason.length * 5 + 5;
            }
          } else {
            pdf.text("No recommendation provided.", margin + 5, currentY);
            currentY += 10;
          }
  
          // Hidden Insights
          if (evaluationData.Interview_final_evaluation.hidden_insights) {
            if (currentY > pageHeight - 70) addNewPage();
            pdf.setFontSize(12);
            pdf.text("Hidden Insights:", margin, currentY);
            currentY += 5;
            const insightsText = evaluationData.Interview_final_evaluation.hidden_insights;
            const splitInsights = pdf.splitTextToSize(
              insightsText,
              contentWidth - 10
            );
            if (currentY + splitInsights.length * 5 > pageHeight - 30)
              addNewPage();
            pdf.setFontSize(10);
            pdf.text(splitInsights, margin + 5, currentY);
            currentY += splitInsights.length * 5 + 5;
          }
  
          // Authenticity Report
          if (evaluationData.Interview_final_evaluation.authenticity_confidence_score > 0) {
            if (currentY > pageHeight - 70) addNewPage();
            pdf.setFontSize(12);
            pdf.text("Authenticity Report:", margin, currentY);
            currentY += 5;
            pdf.setFontSize(10);
            pdf.text(
              `Authenticity Confidence Score: ${evaluationData.Interview_final_evaluation.authenticity_confidence_score.toFixed(
                1
              )}%`,
              margin + 5,
              currentY
            );
            currentY += 5;
            if (evaluationData.Interview_final_evaluation.plagiarism_score > 0) {
              if (currentY > pageHeight - 30) addNewPage();
              pdf.text(
                `Plagiarism Detection Score: ${evaluationData.Interview_final_evaluation.plagiarism_score.toFixed(
                  1
                )}%`,
                margin + 5,
                currentY
              );
              currentY += 5;
            }
            currentY += 10;
          }
        }
      }

      // Behavioral Evaluation Section (Render if data exists)
      if (behavioralEvaluationData.questions && Array.isArray(behavioralEvaluationData.questions) && behavioralEvaluationData.questions.length > 0) {
        if (currentY > pageHeight - 80) addNewPage();
        console.log("Rendering Behavioral Evaluation Section");
        pdf.setFontSize(14);
        pdf.text(`Behavioral Evaluation - ${scores.behavioral_percent || 0}%`, margin, currentY);
        currentY += 10;

        pdf.setDrawColor(200, 200, 200);
        pdf.line(margin, currentY, pageWidth - margin, currentY);
        currentY += 5;

        // Group questions by trait
        const groupedQuestions = behavioralEvaluationData.questions
          ? behavioralEvaluationData.questions.reduce((acc, q, i) => {
              const trait = behavioralEvaluationData.traits[i] || "Unknown";
              acc[trait] = acc[trait] || [];
              acc[trait].push({
                question: q,
                answer: behavioralEvaluationData.answers[i],
                insight: behavioralEvaluationData.behavioral_insights[i],
                originalIndex: i,
              });
              return acc;
            }, {})
          : {};

        // Process each trait group
        for (const [trait, questions] of Object.entries(groupedQuestions)) {
          if (currentY > pageHeight - 30) addNewPage();

          // Add trait header
          pdf.setFontSize(12);
          pdf.setTextColor(0, 0, 100);
          pdf.text(trait, margin, currentY);
          currentY += 7;

          // Process each question in the trait
          questions.forEach((q, i) => {
            if (currentY > pageHeight - 30) addNewPage();

            // Add question number and text
            pdf.setFontSize(12);
            pdf.setTextColor(0, 0, 100);
            const questionText = `Question ${i + 1}: ${q.question}`;
            const splitQuestion = pdf.splitTextToSize(questionText, contentWidth);
            if (currentY + splitQuestion.length * 5 > pageHeight - 30) addNewPage();
            pdf.text(splitQuestion, margin, currentY);
            currentY += splitQuestion.length * 5 + 3;

            // Add answer
            pdf.setFontSize(10);
            pdf.setTextColor(0, 0, 0);
            const answer = q.answer || "No answer provided";
            const splitAnswer = pdf.splitTextToSize(`Answer: ${answer}`, contentWidth - 10);
            if (currentY + splitAnswer.length * 5 > pageHeight - 30) addNewPage();
            pdf.text(splitAnswer, margin + 5, currentY);
            currentY += splitAnswer.length * 5 + 5;

            // Add insight
            if (q.insight) {
              const insightText = `Insight: ${q.insight}`;
              const splitInsight = pdf.splitTextToSize(insightText, contentWidth - 10);
              if (currentY + splitInsight.length * 5 > pageHeight - 20) addNewPage();
              pdf.text(splitInsight, margin + 5, currentY);
              currentY += splitInsight.length * 5 + 5;
            }

            if (behavioralEvaluationData.video_urls && behavioralEvaluationData.video_urls[q.originalIndex]) {
              if (currentY > pageHeight - 30) addNewPage();
              const videoLinkText = "Video Response";
              const videoLinkX = margin + 5;
              const videoLinkY = currentY;
              const videoLinkWidth = pdf.getTextWidth(videoLinkText);
              const videoLinkHeight = 5;

              pdf.setTextColor(0, 0, 255);
              pdf.text(videoLinkText, videoLinkX, videoLinkY);

              pdf.link(videoLinkX, videoLinkY - 4, videoLinkWidth, videoLinkHeight, {
                url: behavioralEvaluationData.video_urls[q.originalIndex],
              });

              pdf.setTextColor(0, 0, 0);
              currentY += 7;
            }

            currentY += 10;
            if (i < questions.length - 1) {
              pdf.setDrawColor(200, 200, 200);
              pdf.line(margin, currentY - 5, pageWidth - margin, currentY - 5);
            }
          });

          currentY += 10;
        }

        // Add Behavioral Insights Section
        if (behavioralEvaluationData.final_evaluation) {
          if (currentY > pageHeight - 70) addNewPage();
          pdf.setFontSize(14);
          pdf.text("Behavioral Insights", margin, currentY);
          currentY += 10;

          pdf.setDrawColor(200, 200, 200);
          pdf.line(margin, currentY, pageWidth - margin, currentY);
          currentY += 10;

          // Overall Trait Scores
          if (Object.keys(behavioralEvaluationData.final_evaluation.overall_trait_scores || {}).length > 0) {
            pdf.setFontSize(12);
            pdf.text("Trait Performance Overview:", margin, currentY);
            currentY += 7;

            Object.entries(behavioralEvaluationData.final_evaluation.overall_trait_scores).forEach(([trait, score]) => {
              pdf.setFontSize(10);
              pdf.text(`${trait.charAt(0).toUpperCase() + trait.slice(1)}: ${score}%`, margin + 5, currentY);
              currentY += 5;
            });
            currentY += 5;
          }

          // Overall Insight
          if (behavioralEvaluationData.final_evaluation.overall_insight) {
            if (currentY > pageHeight - 70) addNewPage();
            pdf.setFontSize(12);
            pdf.text("Overall Insight:", margin, currentY);
            currentY += 7;

            const insightText = behavioralEvaluationData.final_evaluation.overall_insight;
            const splitInsight = pdf.splitTextToSize(insightText, contentWidth - 10);
            pdf.setFontSize(10);
            pdf.text(splitInsight, margin + 5, currentY);
            currentY += splitInsight.length * 5 + 5;
          }

          // Strengths
          if (behavioralEvaluationData.final_evaluation.strongest_traits?.length > 0) {
            if (currentY > pageHeight - 60) addNewPage();
            pdf.setFontSize(12);
            pdf.text("Strengths:", margin, currentY);
            currentY += 7;

            pdf.setFontSize(10);
            behavioralEvaluationData.final_evaluation.strongest_traits.forEach((trait) => {
              pdf.text(`• ${trait}`, margin + 5, currentY);
              currentY += 5;
            });
            currentY += 5;
          }

          // Areas to Improve
          if (behavioralEvaluationData.final_evaluation.weakest_traits?.length > 0) {
            if (currentY > pageHeight - 60) addNewPage();
            pdf.setFontSize(12);
            pdf.text("Areas to Improve:", margin, currentY);
            currentY += 7;

            pdf.setFontSize(10);
            behavioralEvaluationData.final_evaluation.weakest_traits.forEach((trait) => {
              pdf.text(`• ${trait}`, margin + 5, currentY);
              currentY += 5;
            });
            currentY += 5;
          }

          // Response Consistency
          if (behavioralEvaluationData.final_evaluation.consistency_analysis) {
            if (currentY > pageHeight - 50) addNewPage();
            pdf.setFontSize(12);
            pdf.text("Response Consistency:", margin, currentY);
            currentY += 7;

            pdf.setFontSize(10);
            pdf.text(`Consistency Score: ${scores.behavioral_percent || 0}%`, margin + 5, currentY);
            currentY += 5;

            const consistencyText = behavioralEvaluationData.final_evaluation.consistency_analysis;
            const splitConsistency = pdf.splitTextToSize(consistencyText, contentWidth - 10);
            pdf.text(splitConsistency, margin + 5, currentY);
            currentY += splitConsistency.length * 5 + 5;
          }

          // Hiring Recommendation
          if (behavioralEvaluationData.final_evaluation.hiring_recommendation) {
            if (currentY > pageHeight - 70) addNewPage();
            pdf.setFontSize(12);
            pdf.text("Hiring Recommendation:", margin, currentY);
            currentY += 7;

            pdf.setFontSize(10);
            pdf.text(`Recommendation: ${behavioralEvaluationData.final_evaluation.hiring_recommendation}`, margin + 5, currentY);
            currentY += 5;
          }
        }
      }

      // Smart Evaluation Section (if exists)
      if (Object.keys(evaluationData).length > 0 && evaluationData.final_evaluation) {
        addNewPage();
        pdf.setFontSize(14);
        pdf.setTextColor(0, 0, 0);
        pdf.text("Smart Evaluation", margin, currentY);
        currentY += 10;

        pdf.setDrawColor(200, 200, 200);
        pdf.line(margin, currentY, pageWidth - margin, currentY);
        currentY += 10;

        // Overall Score
        pdf.setFontSize(12);
        pdf.text("Overall Score:", margin, currentY);
        currentY += 7;

        if (Object.keys(evaluationData.final_evaluation.overall_score || {}).length > 0) {
          Object.entries(evaluationData.final_evaluation.overall_score).forEach(([category, score]) => {
            pdf.setFontSize(10);
            pdf.text(`${category.charAt(0).toUpperCase() + category.slice(1)}: ${score}%`, margin + 5, currentY);
            currentY += 5;
          });
          currentY += 5;
        } else {
          pdf.setFontSize(10);
          pdf.text("No overall scores available.", margin + 5, currentY);
          currentY += 10;
        }

        // Skill Performance
        if (currentY > pageHeight - 60) addNewPage();
        pdf.setFontSize(12);
        pdf.text("Skill Performance:", margin, currentY);
        currentY += 7;

        if (Object.keys(evaluationData.skill_performance || {}).length > 0) {
          Object.entries(evaluationData.skill_performance).forEach(([skill, score]) => {
            const skillName = skill.replace(/([A-Z])/g, " $1").trim();
            pdf.setFontSize(10);
            pdf.text(`${skillName}: ${Number(score).toFixed(1)}%`, margin + 5, currentY);
            currentY += 5;
          });
          currentY += 5;
        } else {
          pdf.setFontSize(10);
          pdf.text("No skill performance data available.", margin + 5, currentY);
          currentY += 10;
        }

        // Top Skills
        if (currentY > pageHeight - 60) addNewPage();
        pdf.setFontSize(12);
        pdf.text("Top Skills:", margin, currentY);
        currentY += 7;

        if (evaluationData.final_evaluation.top_skills && evaluationData.final_evaluation.top_skills.length > 0) {
          pdf.setFontSize(10);
          evaluationData.final_evaluation.top_skills.forEach((skill) => {
            pdf.text(`• ${skill}`, margin + 5, currentY);
            currentY += 5;
          });
          currentY += 5;
        } else {
          pdf.setFontSize(10);
          pdf.text("No top skills identified.", margin + 5, currentY);
          currentY += 10;
        }

        // Improvement Areas
        if (currentY > pageHeight - 60) addNewPage();
        pdf.setFontSize(12);
        pdf.text("Improvement Areas:", margin, currentY);
        currentY += 7;

        if (evaluationData.final_evaluation.improvement_areas && evaluationData.final_evaluation.improvement_areas.length > 0) {
          pdf.setFontSize(10);
          evaluationData.final_evaluation.improvement_areas.forEach((area) => {
            pdf.text(`• ${area}`, margin + 5, currentY);
            currentY += 5;
          });
          currentY += 5;
        } else {
          pdf.setFontSize(10);
          pdf.text("No improvement areas identified.", margin + 5, currentY);
          currentY += 10;
        }

        // Future Potential
        if (currentY > pageHeight - 70) addNewPage();
        pdf.setFontSize(12);
        pdf.text("Future Potential:", margin, currentY);
        currentY += 7;

        pdf.setFontSize(10);
        if (evaluationData.final_evaluation.future_potential) {
          const potentialText = evaluationData.final_evaluation.future_potential;
          const splitPotential = pdf.splitTextToSize(potentialText, contentWidth - 10);
          if (currentY + splitPotential.length * 5 > pageHeight - 30) addNewPage();
          pdf.text(splitPotential, margin + 5, currentY);
          currentY += splitPotential.length * 5 + 5;
        } else {
          pdf.text("No future potential assessment available.", margin + 5, currentY);
          currentY += 10;
        }

        // Recommendation
        if (currentY > pageHeight - 70) addNewPage();
        pdf.setFontSize(12);
        pdf.text("Recommendation:", margin, currentY);
        currentY += 7;

        pdf.setFontSize(10);
        if (evaluationData.final_evaluation.recommendation) {
          pdf.text(`Decision: ${evaluationData.final_evaluation.recommendation}`, margin + 5, currentY);
          currentY += 5;
          if (evaluationData.final_evaluation.recommendation_reason) {
            const reasonText = evaluationData.final_evaluation.recommendation_reason;
            const splitReason = pdf.splitTextToSize(`Reason: ${reasonText}`, contentWidth - 10);
            if (currentY + splitReason.length * 5 > pageHeight - 20) addNewPage();
            pdf.text(splitReason, margin + 5, currentY);
            currentY += splitReason.length * 5 + 5;
          }
        } else {
          pdf.text("No recommendation provided.", margin + 5, currentY);
          currentY += 10;
        }

        // Hidden Insights
        if (evaluationData.final_evaluation.hidden_insights) {
          if (currentY > pageHeight - 70) addNewPage();
          pdf.setFontSize(12);
          pdf.text("Hidden Insights:", margin, currentY);
          currentY += 5;
          const insightsText = evaluationData.final_evaluation.hidden_insights;
          const splitInsights = pdf.splitTextToSize(insightsText, contentWidth - 10);
          if (currentY + splitInsights.length * 5 > pageHeight - 30) addNewPage();
          pdf.setFontSize(10);
          pdf.text(splitInsights, margin + 5, currentY);
          currentY += splitInsights.length * 5 + 5;
        }

        // Authenticity Report
        if (evaluationData.final_evaluation.authenticity_confidence_score > 0) {
          if (currentY > pageHeight - 50) addNewPage();
          pdf.setFontSize(12);
          pdf.text("Authenticity Report:", margin, currentY);
          currentY += 5;
          pdf.setFontSize(10);
          pdf.text(`Authenticity Confidence Score: ${evaluationData.final_evaluation.authenticity_confidence_score.toFixed(1)}%`, margin + 5, currentY);
          currentY += 5;
          if (evaluationData.final_evaluation.plagiarism_score > 0) {
            if (currentY > pageHeight - 30) addNewPage();
            pdf.text(`Plagiarism Detection Score: ${evaluationData.final_evaluation.plagiarism_score.toFixed(1)}%`, margin + 5, currentY);
            currentY += 5;
          }
          currentY += 10;
        }
      }

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(100, 100, 100);
        pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 25, pageHeight - 10);
      }

      pdf.save(`${name || "Candidate"}_Candidate_Report.pdf`);
    } catch (error) {
      console.error("PDF generation error:", error);
    }
  }, [
    name,
    role,
    averageScore,
    scores,
    stages,
    MCQTestResultData,
    codingProblems,
    codeSubmissions,
    evaluationData,
    behavioralEvaluationData,
    selectedProblemId,
    accessToken,
    interview_id,
    loading,
  ]);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        console.log("Fetching MCQ data for interview_id:", interview_id);
        const mcqResponse = await axios.get(`${host}/get_mcqs_test_result`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { interview_id },
        });
        setMCQTestResultData(mcqResponse.data || {});
        console.log("MCQ Data fetched:", mcqResponse.data);
      } catch (error) {
        console.warn("No MCQ data available for interview_id:", interview_id, error);
        setMCQTestResultData({}); // Set empty object if no data
      }

      // Fetch Coding data
      try {
        console.log("Fetching Coding data for interview_id:", interview_id);
        const problemsResponse = await axios.get(
          `${host}/api/problems_from_codesubmissions`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { interview_id },
          }
        );
        setProblems(problemsResponse.data || []);
        console.log("Coding Problems fetched:", problemsResponse.data);

        const submissionsResponse = await axios.get(
          `${host}/api/get_codesubmissions`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { interview_id },
          }
        );
        setCodeSubmissions(submissionsResponse.data.submissions || {});
        console.log(
          "Code Submissions fetched:",
          submissionsResponse.data.submissions
        );
      } catch (error) {
        console.warn("No Coding data available for interview_id:", interview_id, error);
        setProblems([]);
        setCodeSubmissions({});
      }

      // Fetch AI Interview data
      try {
        console.log("Fetching AI Interview data for interview_id:", interview_id);
        const evalResponse = await axios.get(`${host}/evaluation`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { interview_id },
        });
        setEvaluationData(evalResponse.data || {});
        console.log("Evaluation Data fetched:", evalResponse.data);
      } catch (error) {
        console.warn("No AI Interview data available for interview_id:", interview_id, error);
        setEvaluationData({});
      }

      // Fetch Behavioral Interview data
      try {
        console.log(
          "Fetching Behavioral Interview data for interview_id:",
          interview_id
        );
        const behavioralResponse = await axios.get(
          `${host}/behavioral_evaluation`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { interview_id },
          }
        );
        setBehavioralEvaluationData(behavioralResponse.data || {});
        console.log(
          "Behavioral Evaluation Data fetched:",
          behavioralResponse.data
        );
      } catch (error) {
        console.warn(
          "No Behavioral Interview data available for interview_id:",
          interview_id,
          error
        );
        setBehavioralEvaluationData({});
      }

      setLoading(false);
    };
    

    fetchAllData();
  }, [interview_id, accessToken]);

  return (
    <button className="p-2 text-white" onClick={generatePDF} disabled={loading}>
      <Download />
    </button>
  );
};

export default PDFGenerator;