import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import RecordRTC from "recordrtc";
import host from "../../global";
import { toast } from "react-toastify";
import { Timer } from "lucide-react";
import { ArrowRight } from "lucide-react";
import { CheckCircle, Sparkles, Star, Brain, Mic } from "lucide-react";
import Loader from "../../components/Loader";
import { AnimatePresence, motion } from "framer-motion";

const InterviewPortal = () => {
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
  const AWS_REGION_NAME = process.env.REACT_APP_AWS_REGION_NAME;
  const { interview_id } = useParams();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const INTRODUCTION_TEXT =
    "The AI-Driven Interview Process Assistant leverages the power of AI to automate and optimize the traditional recruitment process.";
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [isQuestionVisible, setIsQuestionVisible] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showResumeButton, setShowResumeButton] = useState(false);
  const [isInterviewComplete, setIsInterviewComplete] = useState(false);
  const [interviewNotFound, setInterviewNotFound] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [isNextQuestionLoading, setIsNextQuestionLoading] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(240);
  const [timerActive, setTimerActive] = useState(false);
  const timerRef = useRef(null);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const style = document.createElement("style");
  const [candidateName, setCandidateName] = useState("Candidate");
  const [interviewStartTime, setInterviewStartTime] = useState(null);
  const [currentGif, setCurrentGif] = useState("stable");
  const [videoRecorder, setVideoRecorder] = useState(null);
  const videoRef = useRef(null);
  const [videoIncluded, setVideoIncluded] = useState(false);

  const fetchConfigurations = async () => {
    try {
      const response = await axios.get(
        `${host}/get_configurations_wo_auth/${interview_id}`
      );
      setVideoIncluded(response.data.video_included);
    } catch (error) {
      console.error("Error fetching configurations:", error);
      toast.error("Failed to load configurations. Defaulting to audio-only.");
      setVideoIncluded(false); // Default to false if fetch fails
    }
  };

  useEffect(() => {
    fetchConfigurations();
  }, []);

  useEffect(() => {
    if (isRecording) {
      setCurrentGif("listening");
    } else if (isPlaying) {
      setCurrentGif("speaking");
    } else {
      setCurrentGif("stable");
    }
  }, [isRecording, isPlaying]);

  const gifVariants = {
    stable: "/Stable.gif",
    speaking: "/Speaking.gif",
    listening: "/Listening.gif",
  };

  const resumeInterview = async () => {
    try {
      setShowResumeButton(false);
      const response = await axios.post(
        `${host}/resumeinterview/${interview_id}`,
        {}
      );
      if (response.data.message === "Interview Complete") {
        handleSubmit();
      } else {
        setCurrentQuestion(response.data.question);
        playQuestionAudio(response.data.currentQuestionIndex - 1);
      }
    } catch (error) {
      console.error("Error resuming interview:", error.message);
    }
  };

  useEffect(() => {
    const playGreetingAudio = async () => {
      try {
        const audioGreeting = new Audio(
          `https://${BUCKET_NAME}.s3.${AWS_REGION_NAME}.amazonaws.com/${interview_id}_audio_questions/greeting.mp3`
        );
        setIsPlaying(true);
        await audioGreeting.play();
        audioGreeting.onended = () => {
          setIsPlaying(false);
          startRecording();
        };
      } catch (error) {
        console.error("Error playing greeting audio:", error);
        toast.error("Something went wrong");
        setTimeout(() => {
          navigate(`/candidate/${interview_id}/interviewinstructions`);
        }, 2000);
        setIsPlaying(false);
      }
    };

    const checkInterviewState = async () => {
      try {
        const response = await fetch(
          `${host}/check_interview_state/${interview_id}`
        );
        const data = await response.json();
        if (data.message) {
          setInterviewNotFound(true);
          setErrormsg(data.message);
        } else {
          setCurrentQuestionIndex(data.currentQuestionIndex);
          setCandidateName(data.candidate_name);
          if (data.interview_status === "Complete") {
            setIsInterviewComplete(true);
          } else {
            if (data.currentQuestionIndex === -1) {
              playGreetingAudio();
            } else {
              setShowResumeButton(true);
            }
          }
        }
      } catch (error) {
        console.error("Error checking interview state:", error);
      }
    };

    checkInterviewState();
  }, [interview_id, AWS_REGION_NAME, BUCKET_NAME, navigate]);

  const stopRecordingAndProceed = async () => {
    setShowTimer(false);
    setTimerActive(false);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setIsNextButtonEnabled(false);
    setIsQuestionVisible(false);
    setIsNextQuestionLoading(true);
    setIsRecording(false);
    try {
      if (recorder) {
        recorder.stopRecording(async () => {
          const audioBlob = recorder.getBlob();
          let videoBlob = null;

          if (videoIncluded && videoRecorder) {
            videoRecorder.stopRecording(async () => {
              const videoBlob = videoRecorder.getBlob();
              const stream = videoRef.current?.srcObject;
              stream?.getTracks().forEach((track) => track.stop());
              if (videoRef.current) videoRef.current.srcObject = null;

              const index = await getQuestion(audioBlob, videoBlob);
              setRecorder(null);
              setVideoRecorder(null);
              setIsPlaying(false);

              if (index !== null) {
                setIsNextQuestionLoading(true);
                playQuestionAudio(index);
                setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                setIsNextQuestionLoading(false);
              }
            });
          } else {
            const stream = videoRef.current?.srcObject;
            stream?.getTracks().forEach((track) => track.stop());
            if (videoRef.current) videoRef.current.srcObject = null;

            const index = await getQuestion(audioBlob, videoBlob);
            setRecorder(null);
            setVideoRecorder(null);
            setIsPlaying(false);

            if (index !== null) {
              setIsNextQuestionLoading(true);
              playQuestionAudio(index);
              setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
              setIsNextQuestionLoading(false);
            }
          }
        });
      }
    } catch (error) {
      console.error("Error stopping recording:", error);
      toast.error("Something went wrong while stopping the recording.");
    }
  };

  const TimerDisplay = () => {
    return (
      <div
        className={`
        fixed top-1 right-10 
        flex items-center gap-2 
        ${timeLeft <= 10 ? "animate-pulse" : ""}
        ${timeLeft <= 20 ? "bg-red-500 text-white" : "bg-yellow-500 text-white"}
        px-4 py-2 rounded-full
        shadow-lg
        transition-all duration-300
        transform
        ${showTimer ? "translate-y-0 opacity-100" : "-translate-y-4 opacity-0"}
      `}
      >
        <Timer className="w-5 h-5" />
        <span className="font-semibold">{timeLeft}s remaining</span>
      </div>
    );
  };

  useEffect(() => {
    if (timerActive && timeLeft > 0) {
      console.log(timeLeft);
      timerRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 30) {
            setShowTimer(true);
          }

          if (prev <= 1) {
            stopRecordingAndProceed();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }; // eslint-disable-next-line
  }, [timerActive, timeLeft]);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: videoIncluded ? true : false })
      .then((stream) => {
        const recorder = RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/mp3",
        });
        recorder.startRecording();
        setRecorder(recorder);
        if (videoIncluded) {
          const videoRecorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/mp4;codecs=avc1,mp4a",
          });
          videoRecorder.startRecording();
          setVideoRecorder(videoRecorder);

          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
          }
        }
        setIsRecording(true);
        setTimeLeft(240);
        setTimerActive(true);
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
        toast.error(
          "Couldn't access camera or microphone. Please check permissions."
        );
      });
    setTimeout(() => {
      setIsNextButtonEnabled(true);
    }, 5000);
  };

  const getQuestion = async (audioBlob, videoBlob = null) => {
    const formData = new FormData();
    formData.append("file", audioBlob, "recording.mp3");
    if (videoBlob && videoIncluded) {
      formData.append("video_file", videoBlob, "recording.mp4");
    }
    try {
      const response = await axios.post(
        `${host}/getquestion/${interview_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        response.data.message &&
        response.data.message === "Interview Complete"
      ) {
        handleSubmit();
        return null;
      } else {
        setCurrentQuestion(response.data.question);
        console.log("Current question:", response.data.question);
        return response.data.currentQuestionIndex;
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      alert("Attention: Internet issue detected. Please refresh the page.");
    }
  };

  const playQuestionAudio = (index) => {
    let retryCount = 0;
    const maxRetries = 4;
    console.log(currentQuestion);

    const tryPlayAudio = async () => {
      try {
        const audio = `https://${BUCKET_NAME}.s3.${AWS_REGION_NAME}.amazonaws.com/${interview_id}_audio_questions/${
          index + 1
        }.mp3`;
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current.src = audio;

        try {
          await audioRef.current.load();
          await new Promise((resolve) => setTimeout(resolve, 100));
          await audioRef.current.play();
          setIsPlaying(true);
          try {
          } catch (error) {
            console.log("Error: ", error);
          }

          audioRef.current.onended = () => {
            setIsPlaying(false);
            setIsQuestionVisible(true);
            startRecording();
          };
        } catch (error) {
          console.error("Audio failed to play:", error);
          if (retryCount < maxRetries) {
            retryCount++;
            setTimeout(tryPlayAudio, 4000);
          } else {
            console.error(`Failed to play audio after ${maxRetries} attempts`);
            alert(
              "Interview Disconnected,Please check your internet connection and try again."
            );
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    tryPlayAudio();
  };

  style.textContent = `
  @keyframes float {
    0% { transform: translateY(0) rotate(0deg); }
    100% { transform: translateY(-10px) rotate(5deg); }
  }
`;
  document.head.appendChild(style);

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    try {
      const endTime = new Date();
      const payload = {
        delete_video_files: videoIncluded,
      };
      axios.delete(`${host}/delete-interview-files/${interview_id}`, {
        params: {
          end_time: endTime.toISOString(),
        },
        data: payload,
      });
      navigate(`/thankyou/${interview_id}`);
    } catch (error) {
      console.error("Error deleting audio files:", error);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const createConfetti = () => {
    const colors = [
      "bg-blue-500",
      "bg-purple-500",
      "bg-pink-500",
      "bg-yellow-400",
      "bg-green-400",
    ];
    return Array.from({ length: 50 }).map((_, i) => (
      <div
        key={i}
        className={`absolute w-2 h-2 rounded-full ${
          colors[i % colors.length]
        } animate-confetti`}
        style={{
          left: `${Math.random() * 100}%`,
          animationDelay: `${Math.random() * 2}s`,
          transform: `rotate(${Math.random() * 360}deg)`,
        }}
      />
    ));
  };

  style.textContent = `
  @keyframes confetti {
    0% { transform: translateY(0) rotate(0deg); opacity: 1; }
    100% { transform: translateY(-100vh) rotate(720deg); opacity: 0; }
  }
  
  .animate-confetti {
    animation: confetti 4s ease-out infinite;
  }
  
  .animate-fade-in {
    animation: fadeIn 1s ease-out forwards;
  }
  
  .animate-fade-in-delay {
    animation: fadeIn 1s ease-out 0.5s forwards;
    opacity: 0;
  }
  
  .animate-fade-in-delay-2 {
    animation: fadeIn 1s ease-out 1s forwards;
    opacity: 0;
  }
  
  .animate-slide-up {
    animation: slideUp 0.8s ease-out forwards;
  }
  
  .animate-spin-slow {
    animation: spin 6s linear infinite;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
`;
  document.head.appendChild(style);

  return (
    <div className="h-full flex items-center justify-center p-8">
      {isRecording && showTimer && <TimerDisplay />}
      <div className="w-full rounded-3xl p-4 flex flex-col justify-between gap-5 h-full mt-2 mb-2">
        {interviewNotFound ? (
          <div className="flex flex-col items-center p-6 bg-gradient-to-r from-red-100 to-pink-200 border-l-4 border-red-500 rounded-xl shadow-xl max-w-lg mx-auto transform hover:scale-105 transition-transform duration-300 ease-in-out">
            <p className="text-red-800 text-center font-semibold tracking-wide">
              {errormsg}
            </p>
          </div>
        ) : isInterviewComplete ? (
          <div className=" relative w-full flex items-center justify-center rounded-lg overflow-hidden">
            {/* Confetti particles */}
            {createConfetti()}

            {/* Main content container */}
            <div className="text-center space-y-6 p-8 animate-fade-in">
              {/* Success icon with pulse effect */}
              <div className="inline-flex items-center justify-center">
                <div className="relative">
                  <CheckCircle className="w-20 h-20 text-green-500 animate-bounce" />
                  <div className="absolute inset-0 bg-green-500 rounded-full animate-ping opacity-20" />
                </div>
              </div>

              {/* Decorative elements */}
              <Sparkles className="absolute top-10 left-10 w-6 h-6 text-yellow-400 animate-spin-slow" />
              <Star className="absolute bottom-10 right-10 w-6 h-6 text-purple-500 animate-pulse" />

              {/* Text content */}
              <div className="relative z-10 space-y-4">
                <h2 className="text-3xl font-bold text-slate-400 animate-slide-up">
                  Interview Complete!
                </h2>
                <p className="text-lg text-slate-500 max-w-md mx-auto animate-fade-in-delay">
                  Thank you for participating in the interview process.
                </p>

                {/* Button with hover effect */}
                <button
                  className="mt-6 px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-full font-semibold 
                shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl
                animate-fade-in-delay-2 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
                >
                  Return Home
                </button>
              </div>
            </div>
          </div>
        ) : showResumeButton ? (
          <div className="relative min-h-[400px] w-full max-w-lg mx-auto p-1">
            {/* Main card */}
            <div className="relative h-full glass-effect backdrop-blur-xl rounded-xl p-8 shadow-xl transition-all duration-500 hover:shadow-2xl">
              {/* Content container */}
              <div className="relative space-y-8">
                {/* Header */}
                <div className="space-y-2 text-center">
                  <h2 className="text-2xl font-bold bg-gradient-to-r from-[#2F64FF] to-[#FB54AE] bg-clip-text text-transparent">
                    Welcome Back!
                  </h2>
                  <div className="w-16 h-1 mx-auto rounded-full bg-gradient-to-r from-[#2F64FF] to-[#FB54AE]" />
                </div>

                {/* Message */}
                <div className="space-y-4">
                  <p className="text-gray-300 text-lg text-center leading-relaxed">
                    We've saved your progress from your previous session. Ready
                    to continue your journey?
                  </p>

                  {/* Progress indicator */}
                  <div className="flex items-center justify-center space-x-2 text-sm text-gray-300">
                    <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
                    <span>Progress Saved</span>
                  </div>
                </div>

                {/* Button */}
                <div className="pt-4 flex items-center justify-center w-full">
                  <button
                    onClick={resumeInterview}
                    onMouseEnter={() => setIsButtonHovered(true)}
                    onMouseLeave={() => setIsButtonHovered(false)}
                    className="button group relative w-1/2 "
                  >
                    {/* Button content */}
                    <div className="relative flex items-center justify-center space-x-2 ">
                      <span>Resume Interview</span>
                      <ArrowRight className="w-5 h-5 transform transition-transform duration-300 group-hover:translate-x-1" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex gap-8 min-h-[470px] relative">
              <div className="w-1/2 flex flex-col items-center pt-8 px-8 rounded-2xl glass-effect backdrop-blur-md shadow-lg relative overflow-hidden">
                <div className="flex flex-col items-center h-full">
                  <div className="relative w-[320px] h-[320px] rounded-full border border-[#992AD2] overflow-hidden">
                    {!isPlaying && !isRecording && (
                      <>
                        {/* Gentle breathing effect */}
                        <motion.div
                          className="absolute inset-0 z-0"
                          animate={{
                            scale: [1, 1.02, 1],
                            opacity: [0.3, 0.5, 0.3],
                          }}
                          transition={{
                            duration: 4,
                            repeat: Infinity,
                            ease: "easeInOut",
                          }}
                        >
                          <div className="w-full h-full rounded-full border-1.5 border-[#992AD2]" />
                        </motion.div>

                        {/* Floating particles */}
                        {[...Array(5)].map((_, i) => (
                          <motion.div
                            key={`particle-${i}`}
                            className="absolute w-2 h-2 rounded-full bg-blue-400/20"
                            initial={{
                              x: Math.random() * 400 - 200,
                              y: Math.random() * 400 - 200,
                            }}
                            animate={{
                              x: Math.random() * 400 - 200,
                              y: Math.random() * 400 - 200,
                              opacity: [0.2, 0.5, 0.2],
                            }}
                            transition={{
                              duration: 8 + Math.random() * 4,
                              repeat: Infinity,
                              ease: "easeInOut",
                              delay: i * 0.8,
                            }}
                          />
                        ))}

                        {/* Subtle gradient pulse */}
                        {/* <motion.div
                className="absolute inset-0 z-0"
                animate={{
                  opacity: [0.3, 0.5, 0.3],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              /> */}

                        {/* Gentle rotate animation */}
                        {/* <motion.div
                className="absolute inset-0 border-2 border-[#992AD2] rounded-full"
                animate={{
                  rotate: 360,
                }}
                transition={{
                  duration: 20,
                  repeat: Infinity,
                  ease: "linear",
                }}
              /> */}
                      </>
                    )}
                    {/* Audio wave animations */}
                    {/* {isPlaying && (
                    <div className="absolute inset-0 z-0">
                      {[...Array(3)].map((_, i) => (
                        <motion.div
                          key={`wave-${i}`}
                          className="absolute inset-0 rounded-full border-2 border-[#992AD2]"
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{
                            scale: 1 + i * 0.1,
                            opacity: [0, 0.5, 0],
                          }}
                          transition={{
                            duration: 2,
                            repeat: Infinity,
                            delay: i * 0.4,
                            ease: "easeInOut",
                          }}
                        />
                      ))}
                    </div>
                  )} */}

                    {/* Listening state animations */}
                    {isRecording && (
                      <>
                        {/* Pulsing ring */}
                        {/* <motion.div
                        className="absolute inset-0 z-0"
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{
                          scale: [1, 1.05, 1],
                          opacity: [0.3, 0.5, 0.3],
                        }}
                        transition={{
                          duration: 1.5,
                          repeat: Infinity,
                          ease: "easeInOut",
                        }}
                      >
                        <div className="w-full h-full rounded-full border-2 border-[#992AD2]" />
                      </motion.div> */}

                        {/* Ripple effect */}
                        {/* {[...Array(3)].map((_, i) => (
                        <motion.div
                          key={`ripple-${i}`}
                          className="absolute inset-0 z-0"
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{
                            scale: 1 + i * 0.1,
                            opacity: [0, 0.2, 0],
                          }}
                          transition={{
                            duration: 2,
                            repeat: Infinity,
                            delay: i * 0.3,
                            ease: "easeInOut",
                          }}
                        >
                          <div className="w-full h-full rounded-full border-2 border-red-500/20" />
                        </motion.div>
                      ))} */}
                      </>
                    )}

                    {/* Subtle color overlay */}
                    {/* {isPlaying && (
                    <motion.div
                      className="absolute inset-0  z-0"
                      animate={{
                        opacity: 1,
                        scale: isPlaying ? [1, 1.02, 1] : 1,
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut",
                      }}
                    />
                  )} */}
                    {/* ##test## */}
                    {/* Avatar */}
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={currentGif}
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{
                          opacity: 1,
                          scale: 1,
                          y: isPlaying ? 0 : 0,
                        }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{
                          duration: 0.4,
                          y: {
                            duration: 2,
                            repeat: Infinity,
                            ease: "easeInOut",
                          },
                        }}
                        className="relative z-10 w-full h-full pointer-events-none select-none"
                      >
                        <img
                          src={gifVariants[currentGif]}
                          alt={`AI Assistant ${currentGif}`}
                          className="w-full h-full object-cover pointer-events-none"
                          style={{
                            objectPosition: "center top",
                            transform: "scale(1.2)",
                            transformOrigin: "center top",
                          }}
                          draggable="false"
                        />
                      </motion.div>
                    </AnimatePresence>
                  </div>
                  <div className="mt-6 flex items-center gap-8">
                    {isRecording && (
                      <div className="flex items-center gap-3 rounded-xl shadow-sm p-4">
                        <Mic className="w-5 h-5 text-red-500 animate-pulse" />
                        <p className="text-[#D9D9D9] font-light">Listening</p>
                        <div className="flex gap-1">
                          {[...Array(3)].map((_, i) => (
                            <div
                              key={i}
                              className="w-1.5 h-1.5 bg-red-500 rounded-full animate-bounce"
                              style={{ animationDelay: `${i * 0.15}s` }}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                    {isNextQuestionLoading && (
                      <div className="flex items-center gap-3 p-4 rounded-xl shadow-sm">
                        <Brain className="w-5 h-5 text-red-500 animate-pulse" />
                        <p className="text-[#D9D9D9] font-light">
                          Analyzing response
                        </p>
                        <div className="flex gap-1">
                          {[...Array(3)].map((_, i) => (
                            <div
                              key={i}
                              className="w-1.5 h-1.5 bg-red-500 rounded-full animate-bounce"
                              style={{ animationDelay: `${i * 0.15}s` }}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                    {isNextButtonEnabled && (
                      <div className="flex justify-end">
                        <button
                          className="button text-white py-3 px-8 rounded-[50px] 
                        font-medium shadow-lg hover:shadow-xl hover:shadow-blue-500/20
                        transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 
                        focus:ring-blue-400 focus:ring-offset-2 relative group"
                          onClick={stopRecordingAndProceed}
                        >
                          <span className="relative z-10">Next Question</span>
                          <div className="absolute inset-0 rounded-[50px] bg-gradient-to-br from-[#3ECFDA] to-[#0952DA] opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-1/2 flex flex-col justify-between relative p-8 rounded-2xl glass-effect shadow-lg">
                <div className="text-left text-2xl font-semibold leading-tight select-none min-h-[60px]">
                  <p className="text-xl font-light text-[#D9D9D9]">
                    {currentQuestionIndex === -1 ? (
                      INTRODUCTION_TEXT
                    ) : isQuestionVisible ? (
                      currentQuestion
                    ) : (
                      <>
                        <p></p>
                        <p></p>
                      </>
                    )}
                  </p>
                </div>

                {videoIncluded && (
                  <video
                    ref={videoRef}
                    autoPlay
                    muted
                    className="absolute bottom-4 right-4 w-56 h-44 rounded-lg border-2 border-gray-300"
                  />
                )}
                <div className="flex flex-col h-full">
                  <div className="mt-auto flex justify-between items-center p-4"></div>
                </div>
              </div>
            </div>
            <audio ref={audioRef} />
            {isSubmitLoading && <Loader />}
          </>
        )}
      </div>
    </div>
  );
};

export default InterviewPortal;
