import React, { useEffect, useState } from "react";
import axios from "axios";
import host from "../../global.jsx";
import { Edit, Block, CheckCircle, Refresh } from "@mui/icons-material";
import Swal from "sweetalert2";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Cookies from "js-cookie";
import Tooltip from "@mui/material/Tooltip";

const Dashboard = () => {
  const accessToken = Cookies.get("accessToken");
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminContact, setAdminContact] = useState("");
  const [data, setData] = useState([]);
  const [TotalHRCount, setTotalHRCount] = useState(null);
  const [interviewCount, setInterviewCount] = useState(0);
  const [formVisible, setFormVisible] = useState(false);
  const [viewOrgDetails, setViewOrgDetails] = useState(false);
  const [editOrgId, setEditOrgId] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [editOrgData, setEditOrgData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [suspensionReason, setSuspensionReason] = useState("");
  // const [ticketRemark, setTicketRemark] = useState("");
  const [acceptedDemos, setAcceptedDemos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [isRefreshingDemos, setIsRefreshingDemos] = useState(false);
  const [isCreatingAdmin, setIsCreatingAdmin] = useState(new Set());
  const [emailSentDemos, setEmailSentDemos] = useState(new Set());
  const [isOpen, setIsOpen] = useState(false);
  const [adminsPerPage] = useState(10);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [selectedOrgAdminId, setSelectedOrgAdminId] = useState(null);
  const [isSuspending, setIsSuspending] = useState(false);
  const [currentAdminPage, setCurrentAdminPage] = useState(1);
  const indexOfLastAdmin = currentAdminPage * adminsPerPage;
  const indexOfFirstAdmin = indexOfLastAdmin - adminsPerPage;
  const [isReactivating, setIsReactivating] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const currentAdmins = data.slice(indexOfFirstAdmin, indexOfLastAdmin);
  const totalAdminPages = Math.ceil(data.length / adminsPerPage);
  const paginateAdmins = (pageNumber) => setCurrentAdminPage(pageNumber);
  const [formData, setFormData] = useState({
    organization_name: "",
    org_admin_name: "",
    org_admin_email: "",
    org_admin_contact: "",
    total_hr_allocated: "5",
  });
  const [validationErrors, setValidationErrors] = useState({
    organization_name: "",
    org_admin_name: "",
    org_admin_email: "",
    org_admin_contact: "",
    total_hr_allocated: "5",
  });

  const refreshAcceptedDemosData = async () => {
    setIsRefreshingDemos(true);
    try {
      const response = await axios.get(`${host}/get-accepted-demos`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!isMounted) return;
      setAcceptedDemos(response.data);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error refreshing accepted demos:", error);
    } finally {
      if (isMounted) setIsRefreshingDemos(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const updateDashboardData = async () => {
      await displayConvAIAdminData();
      await getDashCounts();
      await getOrgAdminsData();
      if (isMounted) {
        await getAcceptedDemosData();
      }
    };
    updateDashboardData();

    return () => {
      isMounted = false;
    };
  }, []);

  const getAcceptedDemosData = async () => {
    try {
      const response = await axios.get(`${host}/get-accepted-demos`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setAcceptedDemos(response.data);
    } catch (error) {
      console.error("Error fetching accepted demos:", error);
    }
  };

  const extractCompanyNameFromEmail = (email) => {
    if (!email || typeof email !== "string") return "N/A";
    const domain = email.split("@")[1];
    if (!domain) return "N/A";
    const companyName = domain.split(".")[0];
    return companyName
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const filteredAndSortedDemos = acceptedDemos
    .map((demo) => ({
      ...demo,
      companyName: extractCompanyNameFromEmail(demo.workEmail),
    }))
    .filter((demo) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        demo.name?.toLowerCase().includes(searchLower) ||
        demo.companyName.toLowerCase().includes(searchLower) ||
        demo.workEmail?.toLowerCase().includes(searchLower)
      );
    })
    .sort((a, b) => {
      switch (sortOption) {
        case "date":
          return new Date(b.created_at) - new Date(a.created_at);
        case "company":
          return a.companyName.localeCompare(b.companyName);
        default:
          return 0;
      }
    });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAndSortedDemos.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredAndSortedDemos.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSuspendOrgAdmin = async () => {
    if (!suspensionReason.trim()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please provide a reason for suspension",
      });
      return;
    }

    setIsSuspending(true);

    try {
      const response = await axios.post(
        `${host}/suspend_org_admin/${selectedOrgAdminId}`,
        { reason: suspensionReason },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      await getOrgAdminsData();
      setSuspensionReason("");
      setSelectedOrgAdminId(null);
      setShowSuspendModal(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Admin suspended successfully!",
      });
    } catch (error) {
      const errorDetail = error.response?.data?.detail || error.message;
      console.error("Error suspending admin:", error.response || error);

      let userFriendlyMessage = "Failed to suspend the admin.";
      if (errorDetail.includes("UserNotFoundException")) {
        userFriendlyMessage =
          "The admin cannot be suspended because their account was not found. They may need to verify their email.";
      } else if (errorDetail.includes("email")) {
        userFriendlyMessage =
          "There was an issue with the admin’s email. Please check and try again.";
      } else {
        userFriendlyMessage = `Failed to suspend the admin: ${errorDetail}`;
      }

      Swal.fire({
        icon: "error",
        title: "Error",
        text: userFriendlyMessage,
      });
    } finally {
      setIsSuspending(false);
    }
  };

  const handleReactivateOrgAdmin = async (org_admin_id) => {
    setIsReactivating(true);
    try {
      const response = await axios.post(
        `${host}/reactivate_org_admin/${org_admin_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      await getOrgAdminsData();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Admin reactivated successfully!",
      });
    } catch (error) {
      console.error("Error reactivating admin:", error.response?.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.detail || "Failed to reactivate user",
      });
    } finally {
      setIsReactivating(false);
    }
  };

  const getDashCounts = async () => {
    try {
      const response = await axios.get(`${host}/get-dashboard-counts`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response) {
        setTotalHRCount(response.data.total_hr);
        setInterviewCount(response.data.total_interviews_count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const displayConvAIAdminData = async () => {
    try {
      const response = await axios.get(`${host}/get_convai_admin_details`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response) {
        setAdminName(response.data.admin_name);
        setAdminEmail(response.data.admin_email);
        setAdminContact(response.data.admin_contact);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getOrgAdminsData = async () => {
    try {
      const response = await axios.get(`${host}/getAllOrgAdmins`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response) {
        console.log("Org Admins Data:", response.data);
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const validateOrganizationName = (name) => {
    if (!name || !name.trim()) {
      return "Organization name cannot be empty";
    }
    if (name !== name.trim()) {
      return "Organization name cannot have leading or trailing spaces";
    }
    if (/^\d+$/.test(name)) {
      return "Organization name cannot be only numbers";
    }
    if (!/^[A-Za-z0-9\s\-&.]+$/.test(name)) {
      return "Organization name can only contain letters, numbers, spaces, hyphens, ampersands, and periods";
    }
    return "";
  };

  const validateAdminName = (name) => {
    if (!name || !name.trim()) {
      return "Admin name cannot be empty";
    }
    if (!/^[A-Za-z\s\-']+$/.test(name)) {
      return "Admin name can only contain letters, spaces, hyphens, and apostrophes";
    }
    if (name.trim().length < 2 || name.trim().length > 50) {
      return "Admin name must be between 2 and 50 characters";
    }
    return "";
  };

  const validateEmail = (email) => {
    if (!email || !email.trim()) {
      return "Email cannot be empty";
    }
    const emailRegex =
      /^[A-Za-z0-9._-]+@[A-Za-z0-9]+\.(com|org|net|edu|gov|mil|int|info|io|co|biz|in|us|uk|)$/;
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address";
    }
    const atSymbolCount = (email.match(/@/g) || []).length;
    if (atSymbolCount !== 1) {
      return "Email must contain exactly one @ symbol";
    }
    const domainPart = email.split("@")[1];
    if (!domainPart.includes(".")) {
      return "Email domain must contain at least one dot (e.g. .com)";
    }
    return "";
  };

  const validateContact = (contact) => {
    if (!contact || !contact.trim()) return "";
    if (!/^[0-9+\-\s()]*$/.test(contact)) {
      return "Contact can only contain numbers, +, -, spaces, and parentheses";
    }
    const digitsOnly = contact.replace(/\D/g, "");
    if (digitsOnly.length === 0) {
      return "Contact must contain at least one number";
    }
    if (digitsOnly.length < 7 || digitsOnly.length > 15) {
      return "Contact number must contain 7-15 digits";
    }
    const specialCharCount = (contact.match(/[+\-\s()]/g) || []).length;
    if (specialCharCount > digitsOnly.length / 2) {
      return "Too many special characters in contact number";
    }
    if (!/^\+?[0-9]/.test(contact)) {
      return "Contact must start with a number or +";
    }
    return "";
  };

  const validateHrAllocated = (count) => {
    if (!count || count.trim() === "") {
      return "Number of HRs cannot be empty";
    }
    const numValue = Number(count);
    if (isNaN(numValue)) {
      return "Please enter a valid number";
    }
    if (!Number.isInteger(numValue)) {
      return "Number of HRs must be a whole number";
    }
    if (numValue <= 0) {
      return "Number of HRs must be greater than 0";
    }
    if (numValue > 100) {
      return "Number of HRs cannot be greater than 100";
    }
    return "";
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "org_admin_contact") {
      if (!/^[0-9+() ]*$/.test(value)) {
        return;
      }
    }
    if (name === "total_hr_allocated") {
      if (!/^\d*$/.test(value)) {
        return;
      }
      if (value > 100) {
        return;
      }
    }

    let errorMessage = "";
    switch (name) {
      case "organization_name":
        errorMessage = validateOrganizationName(value);
        break;
      case "org_admin_email":
        errorMessage = validateEmail(value);
        break;
      case "org_admin_name":
        errorMessage = validateAdminName(value);
        break;
      case "org_admin_contact":
        errorMessage = validateContact(value);
        break;
      case "total_hr_allocated":
        errorMessage = validateHrAllocated(value);
        break;
      default:
        break;
    }
    setValidationErrors({
      ...validationErrors,
      [name]: errorMessage,
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleEditForm = async (org_id) => {
    setEditOrgId(org_id);
    if (editForm) {
      setEditForm(false);
    } else {
      try {
        const response = await axios.get(`${host}/getCompanyData/${org_id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response && response.data) {
          setEditOrgData(response.data);
          setEditForm(true);
          setFormData({
            organization_name: response.data.organization_name || "",
            org_admin_name: response.data.org_admin_name || "",
            org_admin_email: response.data.org_admin_email || "",
            org_admin_contact: response.data.org_admin_contact || "",
            total_hr_allocated: response.data.total_hr_allocated || "5",
          });
          setValidationErrors({
            organization_name: "",
            org_admin_name: "",
            org_admin_email: "",
            org_admin_contact: "",
            total_hr_allocated: "",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No data returned for this organization.",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch organization data.",
        });
      }
    }
  };

  const handleDeleteOrg = async (e) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Confirm Delete Operation",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.get(
          `${host}/deleteCompanyData/${editOrgId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response) {
          console.log("Data Deleted Successfully!");
          await Promise.all([getDashCounts(), getOrgAdminsData()]);
          setEditForm(false);
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Organization has been deleted.",
          });
        }
      } catch (error) {
        console.error("Error deleting data:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to delete organization.",
        });
      }
    }
  };

  const handleAddCompany = () => {
    setFormData({
      organization_name: "",
      org_admin_name: "",
      org_admin_email: "",
      org_admin_contact: "",
      total_hr_allocated: "5",
    });
    setValidationErrors({
      organization_name: "",
      org_admin_name: "",
      org_admin_email: "",
      org_admin_contact: "",
      total_hr_allocated: "5",
    });
    setFormVisible(true);
  };

  const validateForm = () => {
    const errors = {
      organization_name: validateOrganizationName(formData.organization_name),
      org_admin_name: validateAdminName(formData.org_admin_name),
      org_admin_email: validateEmail(formData.org_admin_email),
      org_admin_contact: formData.org_admin_contact
        ? validateContact(formData.org_admin_contact)
        : "",
      total_hr_allocated: validateHrAllocated(formData.total_hr_allocated),
    };
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const createAdminFromDemo = async (demo) => {
    if (isCreatingAdmin.has(demo._id)) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Admin creation already in progress for this demo",
      });
      return;
    }

    setIsCreatingAdmin((prev) => new Set(prev).add(demo._id));
    try {
      console.log("Creating admin from demo:", demo);

      const adminData = {
        organization_name:
          extractCompanyNameFromEmail(demo.workEmail) || "Unknown Company",
        org_admin_name: demo.name || "Unknown Admin",
        org_admin_email: demo.workEmail,
        org_admin_contact: demo.phoneNumber || "",
        total_hr_allocated: "5",
      };

      console.log("Admin data being sent:", adminData);

      const response = await axios.post(
        `${host}/create_new_org_admin`,
        adminData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Admin creation response:", response.data);

      setEmailSentDemos((prev) => new Set(prev).add(demo._id));
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Admin account created successfully! Email sent.",
      });

      await getOrgAdminsData();
      await getAcceptedDemosData();
    } catch (error) {
      console.error("Error creating admin:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          "Error creating admin: " +
          (error.response?.data?.detail || error.message),
      });
    } finally {
      setIsCreatingAdmin((prev) => {
        const newSet = new Set(prev);
        newSet.delete(demo._id);
        return newSet;
      });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Valid Data",
      });
      return;
    }

    try {
      await axios.post(`${host}/create_new_org_admin`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "New Organization Added Successfully!",
      });
      setFormData({
        organization_name: "",
        org_admin_name: "",
        org_admin_email: "",
        org_admin_contact: "",
        total_hr_allocated: "5",
      });
      setFormVisible(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error: " + (error.response?.data?.detail || error.message),
      });
    }
  };

  const hideForm = () => {
    setFormVisible(false);
  };

  const handleFormUpdate = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please correct the errors in the form",
      });
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("organization_name", formData.organization_name);
      formDataToSend.append("org_admin_name", formData.org_admin_name);
      formDataToSend.append("org_admin_email", formData.org_admin_email);
      formDataToSend.append("org_admin_contact", formData.org_admin_contact);
      formDataToSend.append("total_hr_allocated", formData.total_hr_allocated);

      const response = await axios.post(
        `${host}/update_org_data/${editOrgId}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Organization Data Successfully Updated!",
        });
        setEditForm(false);
        setFormData({
          organization_name: "",
          org_admin_name: "",
          org_admin_email: "",
          org_admin_contact: "",
          total_hr_allocated: "5",
        });
        await getOrgAdminsData();
      }
    } catch (error) {
      console.error("Error details:", error.response?.data);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.detail || "Update failed",
      });
    }
  };

  const showOrgData = async (org_id) => {
    try {
      const response = await axios.get(`${host}/getCompanyData/${org_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response) {
        setOrgData(response.data);
        setViewOrgDetails(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const hideOrgDetails = () => {
    setViewOrgDetails(false);
  };

  return (
    <>
      {/* Removed ToastContainer */}
      {formVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-800">
                Fill The Details
              </p>
              <CloseRounded
                className="text-red-500 cursor-pointer"
                onClick={hideForm}
              />
            </div>
            <form className="space-y-4 max-h-80 overflow-y-auto">
              {[
                {
                  label: "Organization Name*",
                  name: "organization_name",
                  type: "text",
                  placeholder: "Enter organization name (e.g. NSquare Xperts)",
                },
                {
                  label: "Admin Name*",
                  name: "org_admin_name",
                  type: "text",
                  placeholder: "Enter admin name",
                },
                {
                  label: "Admin Email*",
                  name: "org_admin_email",
                  type: "text",
                  placeholder: "admin@company.com",
                },
                {
                  label: "Admin Contact",
                  name: "org_admin_contact",
                  type: "text",
                  placeholder: "Enter 10-digit number",
                },
                {
                  label: "Set Number of HRs*",
                  name: "total_hr_allocated",
                  type: "text",
                  min: "5",
                  placeholder: "Enter a positive number",
                },
              ].map(({ label, name, type, placeholder, min }) => (
                <div key={name} className="mb-4">
                  <label className="block text-gray-600">{label}</label>
                  <input
                    name={name}
                    type={type}
                    min={min}
                    placeholder={placeholder}
                    value={formData[name]}
                    onChange={handleFormChange}
                    className={`w-full border ${
                      validationErrors[name]
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg p-2 focus:ring-2 focus:ring-blue-400`}
                  />
                  {validationErrors[name] && (
                    <p className="text-red-500 text-sm mt-1">
                      {validationErrors[name]}
                    </p>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="w-full md:w-auto py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
      {editForm && editOrgData && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-800">
                Edit Details
              </p>
              <CloseRounded
                className="text-red-500 cursor-pointer"
                onClick={toggleEditForm}
              />
            </div>
            <form className="space-y-4 max-h-80 overflow-y-auto">
              {[
                {
                  label: "Organization Name",
                  name: "organization_name",
                  type: "text",
                  placeholder: "Enter organization name",
                },
                {
                  label: "Organization Admin Name",
                  name: "org_admin_name",
                  type: "text",
                  placeholder: "Enter admin name",
                },
                {
                  label: "Organization Admin Email",
                  name: "org_admin_email",
                  type: "text",
                  placeholder: "admin@company.com",
                },
                {
                  label: "Organization Admin Contact",
                  name: "org_admin_contact",
                  type: "text",
                  placeholder: "Enter 10-digit number",
                },
                {
                  label: "Set Number Of HRs",
                  name: "total_hr_allocated",
                  type: "number",
                  min: "5",
                  placeholder: "Enter a positive number",
                },
              ].map(({ label, name, type = "text", placeholder, min }) => (
                <div key={name} className="mb-4">
                  <label className="block text-gray-600">{label}</label>
                  <input
                    name={name}
                    type={type}
                    min={min}
                    placeholder={placeholder || editOrgData[name] || ""}
                    value={formData[name]}
                    onChange={handleFormChange}
                    className={`w-full border ${
                      validationErrors[name]
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg p-2 focus:ring-2 focus:ring-blue-400`}
                  />
                  {validationErrors[name] && (
                    <p className="text-red-500 text-sm mt-1">
                      {validationErrors[name]}
                    </p>
                  )}
                </div>
              ))}
              <div className="flex space-x-4">
                <button
                  type="button"
                  className="w-full md:w-auto py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  onClick={handleFormUpdate}
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={handleDeleteOrg}
                  className="w-full md:w-auto py-2 px-4 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
                >
                  Delete Organization
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {viewOrgDetails && orgData && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-800">
                Organization Details
              </p>
              <CloseRounded
                className="text-red-500 cursor-pointer"
                onClick={hideOrgDetails}
              />
            </div>
            <hr className="border-gray-300 mb-4" />
            <div className="space-y-2 text-gray-700">
              {[
                {
                  label: "Organization Name",
                  value: orgData.organization_name,
                },
                { label: "Admin Name", value: orgData.org_admin_name },
                { label: "Admin Email", value: orgData.org_admin_email },
                { label: "Admin Contact", value: orgData.org_admin_contact },
                { label: "HR Allocated", value: orgData.total_hr_allocated },
              ].map(({ label, value }) => (
                <p key={label}>
                  <strong>{label}:</strong> {value}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {showSuspendModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-800">
                Suspend Organization Admin
              </p>
              <CloseRounded
                className="text-red-500 cursor-pointer"
                onClick={() => {
                  setShowSuspendModal(false);
                  setSuspensionReason("");
                  setSelectedOrgAdminId(null);
                }}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">
                Reason for Suspension <span className="text-red-500">*</span>
              </label>
              <textarea
                value={suspensionReason}
                onChange={(e) => setSuspensionReason(e.target.value)}
                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-400 resize-none"
                rows="4"
                placeholder="Enter the reason for suspension..."
                required
                disabled={isSuspending}
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                className="py-2 px-4 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 disabled:bg-gray-200"
                onClick={() => {
                  setShowSuspendModal(false);
                  setSuspensionReason("");
                  setSelectedOrgAdminId(null);
                }}
                disabled={isSuspending}
              >
                Cancel
              </button>
              <button
                className="py-2 px-4 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:bg-red-300 flex items-center"
                onClick={handleSuspendOrgAdmin}
                disabled={!suspensionReason.trim() || isSuspending}
              >
                {isSuspending ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8h-8z"
                      />
                    </svg>
                    Suspending...
                  </>
                ) : (
                  "Suspend"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6">
        <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
          <h2 className="text-xl font-semibold">{adminName}</h2>
          <p className="text-sm text-gray-600">{adminEmail}</p>
          <p className="text-sm text-gray-600">{adminContact}</p>
          <p className="font-bold text-gray-500">Admin</p>
          <button
            onClick={handleAddCompany}
            className="mt-6 bg-green-600 text-white py-2 px-6 rounded-lg w-full hover:bg-green-700 transition"
          >
            Add Organization
          </button>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
          <div className="text-4xl font-bold text-blue-600">
            {data?.length || 0}
          </div>
          <p className="mt-2">Active Organizations</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
          <div className="text-4xl font-bold text-indigo-600">
            {TotalHRCount || 0}
          </div>
          <p className="mt-2">Active HRs</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
          <div className="text-4xl font-bold text-purple-600">
            {interviewCount}
          </div>
          <p className="mt-2">Total Interviews Conducted</p>
        </div>
      </div>
      <div className="overflow-x-auto mt-6 bg-white shadow-lg rounded-lg">
        <table className="min-w-full table-auto">
          <thead className="bg-blue-600 text-white">
            <tr>
              <th className="py-2 px-4 text-left">Sr</th>
              <th className="py-2 px-4 text-left">Admin Name</th>
              <th className="py-2 px-4 text-left">Admin Contact</th>
              <th className="py-2 px-4 text-left">HRs Allocated</th>
              <th className="py-2 px-4 text-left">Status</th>
              <th className="py-2 px-4 text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentAdmins.map((item, index) => (
              <tr
                key={item.organization_id}
                className="border-b border-gray-200 hover:bg-gray-50"
              >
                <td className="py-2 px-4">{indexOfFirstAdmin + index + 1}</td>
                <td
                  className="py-2 px-4 text-blue-600 cursor-pointer hover:text-blue-800"
                  onClick={() => showOrgData(item.organization_id)}
                >
                  {item.org_admin_name}
                </td>
                <td className="py-2 px-4">{item.org_admin_contact}</td>
                <td className="py-2 px-4">{item.total_hr_allocated}</td>
                <td className="py-2 px-4">
                  {item.is_suspended.toLowerCase() === "true"
                    ? "Suspended"
                    : "Active"}
                </td>
                <td className="py-2 px-4 flex space-x-2 items-center">
                  <Tooltip title="Edit Organization Details">
                    <button
                      className="text-blue-500 hover:text-blue-700 transition duration-200"
                      onClick={() => toggleEditForm(item.organization_id)}
                    >
                      <Edit />
                    </button>
                  </Tooltip>
                  {item.is_suspended.toLowerCase() !== "true" && (
                    <Tooltip title="Suspend this admin">
                      <button
                        className="flex items-center space-x-1 bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition duration-200 disabled:bg-red-300"
                        onClick={() => {
                          setSelectedOrgAdminId(item.org_admin_id);
                          setShowSuspendModal(true);
                        }}
                        disabled={isSuspending}
                      >
                        {isSuspending &&
                        selectedOrgAdminId === item.org_admin_id ? (
                          <>
                            <svg
                              className="animate-spin h-4 w-4 mr-1 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              />
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8v8h-8z"
                              />
                            </svg>
                            <span className="text-sm">Suspending...</span>
                          </>
                        ) : (
                          <>
                            <Block fontSize="small" />
                            <span className="text-sm">Suspend</span>
                          </>
                        )}
                      </button>
                    </Tooltip>
                  )}
                  {item.is_suspended.toLowerCase() === "true" && (
                    <Tooltip title="Reactivate this admin">
                      <button
                        className="flex items-center space-x-1 bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 transition duration-200 disabled:bg-green-300"
                        onClick={() =>
                          handleReactivateOrgAdmin(item.org_admin_id)
                        }
                        disabled={isReactivating}
                      >
                        {isReactivating &&
                        selectedOrgAdminId === item.org_admin_id ? (
                          <>
                            <svg
                              className="animate-spin h-4 w-4 mr-1 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              />
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8v8h-8z"
                              />
                            </svg>
                            <span className="text-sm">Reactivating...</span>
                          </>
                        ) : (
                          <>
                            <CheckCircle fontSize="small" />
                            <span className="text-sm">Reactivate</span>
                          </>
                        )}
                      </button>
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {data.length > adminsPerPage && (
          <div className="flex justify-between items-center p-4">
            <div className="text-sm text-gray-600">
              Showing {indexOfFirstAdmin + 1}-
              {Math.min(indexOfLastAdmin, data.length)} of {data.length} admins
            </div>
            <div className="flex space-x-2">
              <button
                className={`px-3 py-1 border rounded ${
                  currentAdminPage === 1
                    ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                    : "bg-white hover:bg-gray-100 text-gray-700"
                }`}
                onClick={() =>
                  currentAdminPage > 1 && paginateAdmins(currentAdminPage - 1)
                }
                disabled={currentAdminPage === 1}
              >
                Previous
              </button>
              <button
                className={`px-3 py-1 border rounded ${
                  currentAdminPage === totalAdminPages
                    ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                    : "bg-white hover:bg-gray-100 text-gray-700"
                }`}
                onClick={() =>
                  currentAdminPage < totalAdminPages &&
                  paginateAdmins(currentAdminPage + 1)
                }
                disabled={currentAdminPage === totalAdminPages}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      {acceptedDemos.length > 0 && (
        <div className="bg-white p-6 rounded-lg shadow-md mt-6">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-4">
              <h2 className="font-bold text-xl">Accepted Demo Requests</h2>
              <Tooltip title="Refresh Demos">
                <button
                  onClick={refreshAcceptedDemosData}
                  disabled={isRefreshingDemos}
                  className={`text-blue-500 hover:text-blue-700 transition duration-200 ${
                    isRefreshingDemos ? "animate-spin" : ""
                  }`}
                >
                  <Refresh />
                </button>
              </Tooltip>
            </div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
            >
              {isOpen ? "Close" : "Open"}
            </button>
          </div>

          {isOpen && (
            <>
              <div className="flex flex-wrap gap-4 mb-4">
                <input
                  type="text"
                  placeholder="Search by name, company or email"
                  className="px-4 py-2 border rounded-lg flex-grow"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1);
                  }}
                />
                <select
                  className="px-4 py-2 border rounded-lg"
                  value={sortOption}
                  onChange={(e) => {
                    setSortOption(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Sort by</option>
                  <option value="date">Date (newest first)</option>
                  <option value="company">Company name</option>
                </select>
              </div>

              <div className="overflow-x-auto rounded-lg border border-gray-200">
                <table className="min-w-full bg-white">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="py-3 px-4 text-left font-semibold text-gray-700">
                        Name
                      </th>
                      <th className="py-3 px-4 text-left font-semibold text-gray-700">
                        Company
                      </th>
                      <th className="py-3 px-4 text-left font-semibold text-gray-700">
                        Email
                      </th>
                      <th className="py-3 px-4 text-left font-semibold text-gray-700">
                        Phone
                      </th>
                      <th className="py-3 px-4 text-left font-semibold text-gray-700">
                        Accepted Date
                      </th>
                      <th className="py-3 px-4 text-left font-semibold text-gray-700">
                        Actions
                      </th>
                      <th className="py-3 px-4 text-left font-semibold text-gray-700">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((item) => (
                        <tr
                          key={item._id}
                          className={`border-b hover:bg-gray-50 ${
                            emailSentDemos.has(item._id) ? "bg-green-50" : ""
                          }`}
                        >
                          <td className="py-3 px-4">{item.name}</td>
                          <td className="py-3 px-4">
                            {extractCompanyNameFromEmail(item.workEmail)}
                          </td>
                          <td className="py-3 px-4">{item.workEmail}</td>
                          <td className="py-3 px-4">
                            {item.phoneNumber || "N/A"}
                          </td>
                          <td className="py-3 px-4">
                            {new Date(item.created_at).toLocaleString()}
                          </td>
                          <td className="py-3 px-4">
                            <Tooltip title="Create Admin Account">
                              <button
                                onClick={() => createAdminFromDemo(item)}
                                className={`px-3 py-1 rounded text-white transition duration-200 ${
                                  emailSentDemos.has(item._id) ||
                                  isCreatingAdmin.has(item._id)
                                    ? "bg-gray-400 cursor-not-allowed"
                                    : "bg-green-500 hover:bg-green-600"
                                }`}
                                disabled={
                                  emailSentDemos.has(item._id) ||
                                  isCreatingAdmin.has(item._id)
                                }
                              >
                                {isCreatingAdmin.has(item._id)
                                  ? "Creating..."
                                  : "Create Admin"}
                              </button>
                            </Tooltip>
                          </td>
                          <td className="py-3 px-4">
                            {emailSentDemos.has(item._id) && (
                              <span className="text-green-600 flex items-center">
                                <CheckCircle
                                  fontSize="small"
                                  className="mr-1"
                                />
                                Email Sent
                              </span>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="7"
                          className="py-4 px-4 text-center text-gray-500"
                        >
                          No results found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {filteredAndSortedDemos.length > 0 && (
                <div className="flex flex-wrap justify-between items-center mt-4">
                  <div className="text-sm text-gray-600 mb-2 sm:mb-0">
                    Showing {indexOfFirstItem + 1}-
                    {Math.min(indexOfLastItem, filteredAndSortedDemos.length)}{" "}
                    of {filteredAndSortedDemos.length} demos
                  </div>
                  <div className="flex flex-wrap gap-2">
                    <button
                      className={`px-3 py-1 border rounded ${
                        currentPage === 1
                          ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                          : "bg-white hover:bg-gray-100 text-gray-700"
                      }`}
                      onClick={() =>
                        currentPage > 1 && paginate(currentPage - 1)
                      }
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {Array.from(
                      { length: totalPages },
                      (_, idx) => idx + 1
                    ).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={`px-3 py-1 border rounded ${
                          currentPage === pageNumber
                            ? "bg-blue-500 text-white"
                            : "bg-white hover:bg-gray-100 text-gray-700"
                        }`}
                        onClick={() => paginate(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    ))}
                    <button
                      className={`px-3 py-1 border rounded ${
                        currentPage === totalPages
                          ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                          : "bg-white hover:bg-gray-100 text-gray-700"
                      }`}
                      onClick={() =>
                        currentPage < totalPages && paginate(currentPage + 1)
                      }
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;
