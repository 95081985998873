import React from 'react';
import { 
  Home, 
  Users, 
  Clipboard, 
  LogOut, 
  BarChart2, 
  Briefcase 
} from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';

const SidebarIcon = ({ icon: Icon, label, expanded, path, onClick }) => (
  <Link 
    to={path} 
    className="flex w-full text-white cursor-pointer hover:bg-white/5 rounded-md transition-all"
    onClick={onClick}
  >
    <div className={`flex ${expanded ? 'flex-row items-center pl-1' : 'flex-col items-center'} w-full gap-1 p-2`}>
      <Icon className="w-6 h-6" />
      {/* {expanded ? (
        <span className="text-sm ml-3">{label}</span>
      ) : ( */}
        <span className="text-[10px] font-light text-center w-full leading-tight opacity-80">
          {label}
        </span>
      {/* )} */}
    </div>
  </Link>
);

const Sidebar = ({ 
  // isSidebarExpanded, 
  // setIsSidebarExpanded, 
  handleSignOut 
}) => {
  const sidebarItems = [
    { icon: Home, label: 'Dashboard', path: 'dashboard' },
    { icon: BarChart2, label: 'Reports', path: 'candidatereports' },
    { icon: Briefcase, label: 'Job Library', path: 'created-jds' },
    { icon: Clipboard, label: 'Test Library', path: 'testlibrary' }
  ];

  return (
    <div 
      className="
        bg-app-background shadow-[4px_0_10px_-3px_rgba(0,0,0,0.7)] 
        transition-all duration-300 ease-in-out w-16 overflow-hidden flex flex-col items-center py-4"
      // onMouseEnter={() => setIsSidebarExpanded(true)}
      // onMouseLeave={() => setIsSidebarExpanded(false)}
    >
      <div className='flex flex-col gap-4 items-center justify-center py-2'>
        <Link to="/org-admin/dashboard">
        {/* {isSidebarExpanded ? ( */}
          {/* <img src="/GenieHire-logo-white.png" alt="Logo" style={{ height: "34px"}} />
        ):( */}
          <img src="/Homepagelogo.png" alt="Logo" style={{ height: "34px", width: "24px" }} />
        {/* )} */}
        </Link>
        <div className="w-full px-2 space-y-2">
          {sidebarItems.map((item, index) => (
            <SidebarIcon 
              key={index} 
              icon={item.icon} 
              label={item.label} 
              // expanded={isSidebarExpanded} 
              path={item.path} 
            />
          ))}
        </div>
        <div className="mt-auto px-2 w-full">
          <SidebarIcon 
            icon={LogOut} 
            label="Sign Out" 
            // expanded={isSidebarExpanded} 
            path="#" 
            onClick={handleSignOut}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
