import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  CheckCircleIcon,
  LightbulbIcon,
  SparklesIcon,
  ArrowRightIcon,
  PlusIcon,
  ClockIcon,
  Minus,
  SettingsIcon,
  X,
  Users,
  MapPin,
  AlertCircle,
} from "lucide-react";
import host from "../../global";
import { toast } from "react-toastify";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import AIProcessingLoader from "../AIProcessingLoader";
import Loader from "../Loader";
import JobDescriptionLoader from "../JobDescriptionLoader";
import { ChevronDown, ChevronUp } from "lucide-react";

const ItemType = "ITEM";

const InterviewTimeCalculator = ({ skills, customQuestions = [], onTimeCalculated }) => {
  const [recommendedTime, setRecommendedTime] = useState(0);

  useEffect(() => {
    const calculateRecommendedTime = () => {
      const easySkills = skills?.Required_Skills?.Easy?.length || 0;
      const mediumSkills = skills?.Required_Skills?.Medium?.length || 0;
      const hardSkills = skills?.Required_Skills?.Hard?.length || 0;

      const customQuestionsCount = customQuestions.length;
      const totalSkills = easySkills + mediumSkills + hardSkills;

      if (totalSkills === 0 && customQuestionsCount === 0) {
        setRecommendedTime(0);
        return;
      }

      const hardQuestions = hardSkills > 0 ? Math.ceil(totalSkills * 0.7) : 0;
      const mediumQuestions = hardSkills > 0 ? 
        Math.ceil(totalSkills * 0.3) : 
        Math.ceil(totalSkills * 0.7);
      const easyQuestions = hardSkills > 0 ? 
        0 : 
        Math.ceil(totalSkills * 0.3);
  
      const timePerHard = 5;  
      const timePerMedium = 3; 
      const timePerEasy = 2;   
      const timePerCustom = 3; 
      
      const totalTime = 
        (hardQuestions * timePerHard) +
        (mediumQuestions * timePerMedium) +
        (easyQuestions * timePerEasy) +
        (customQuestionsCount * timePerCustom);
    
      const recommendedTimeWithBuffer = Math.ceil(totalTime * 1.2);
      setRecommendedTime(recommendedTimeWithBuffer);
      if (onTimeCalculated) {
        onTimeCalculated(recommendedTimeWithBuffer);
      }
    };

    calculateRecommendedTime();
  }, [skills, customQuestions, onTimeCalculated]);

  if (recommendedTime === 0) {
    return null;
  }

  return (
    <div className="p-4 rounded-xl text-sm text-gray-300 mb-2 relative overflow-hidden backdrop-blur-sm bg-yellow-400/20 border border-yellow-400/50">
      <div className="relative z-10">
        Recommended time: <span className="font-semibold text-yellow-400">{recommendedTime} minutes</span>
        <p className="text-xs mt-1 text-gray-400">
          (based on skill complexity and number of questions)
        </p>
      </div>
    </div>
  );
};


const DraggableAssessmentItem = ({ id, index, moveItem, children }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "ASSESSMENT_ITEM",
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "ASSESSMENT_ITEM",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={`transition-all duration-200 ${
        isDragging ? "opacity-50 scale-102" : "opacity-100 scale-100"
      }`}
    >
      {children}
    </div>
  );
};



const steps = [
  { id: 1, name: 'Select Parameters' },
  { id: 2, name: 'Select Skills' },
  { id: 3, name: 'Select Services' },
  { id: 4, name: 'Review' },
];

function CreateJD() {
  const [isAI, setIsAI] = useState(false);
  const [experience, setExperience] = useState(0);
  const accessToken = Cookies.get("accessToken");
  const navigate = useNavigate();
  const [isAddSkillOpen, setIsAddSkillOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkillsLoading, setSkillsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [sections, setSections] = useState(() => {
    const savedSections = localStorage.getItem("createJDSections");
    return savedSections
      ? JSON.parse(savedSections)
      : {
        sourcing: {
          isExpanded: false,
          isChecked: false,
          content:
            "Configure your candidate sourcing preferences, channels, and automation settings.",
        },
        screening: {
          isExpanded: false,
          isChecked: false,
          content:
            "Set up your screening criteria, questionnaires, and filtering parameters.",
        },
        assessment: {
          isExpanded: false,
          isChecked: false,
          content:
            "Customize your assessment methods, scoring criteria, and evaluation process.",
        },
      }
  });

  useEffect(() => {
    localStorage.setItem("createJDSections", JSON.stringify(sections));
  }, [sections]);

  const candidateRanges = [
    { value: "5", label: "5 candidates" },
    { value: "25", label: "25 candidates" },
    { value: "50", label: "50 candidates" },
    { value: "75", label: "75 candidates" },
    { value: "100", label: "100 candidates" },
    { value: "150", label: "150 candidates" },
    { value: "200", label: "200 candidates" },
    { value: "400", label: "400 candidates" },
  ];

  const totalSteps = 5;
 
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem("createJDFormData");
    const savedSections = localStorage.getItem("createJDSections");
    const parsedSections = savedSections ? JSON.parse(savedSections) : null;
  
    const initialFormData = savedData
      ? JSON.parse(savedData)
      : {
          jobRole: "",
          minExp: 0,
          maxExp: 1,
          responsibilities: "",
          requiredSkills: [],
          optionalSkills: [],
          aiSuggestedSkills: [],
          generatedJD: {},
          isAnalyzed: false,
          isFineTuned: false,
          customRequiredSkill: "",
          customOptionalSkill: "",
          showInterviewConfig: false,
          interview_timelimit: null,
          isInterviewTimeManuallySet: false,
          isMCQIncluded: false,
          isCodingIncluded: false,
          isBehavioralIncluded: false,
          MCQTimeLimit: 5,
          MCQPercentThreshold: 60,
          CodingTimeLimit: 10,
          CodingJudgingCriteria: "",
          behavioralTimeLimit: 5,
          selectedSet: "",
          selectedCodingSet: "",
          selectedBehavioralSet: "",
          sets: [],
          codingSets: [],
          behavioralSets: [],
          isCustomQuestionsIncluded: false,
          customQuestions: "",
          selectedServices: {
            sourcing: false,
            screening: false,
            assessment: false,
          },
          editedQuestions: [],
          questions: [],
          candidatesRequired: 5,
          searchLocations: [],
          assessmentSequence: ["interview"],
          companyName: "",
          companyDescription: "",
          jobType: "fulltime",
          workLocationType: "office",
          workLocationInput: "",
          workLocations: [],
          noticePeriod: 0,
          noticeStrictness: "strict",
          maxCTC: "",
          ctcStrictness: "strict",
          errors: { jobRole: "", minExp: "", maxExp: "", requiredSkills: "" },
          sequence: [
            {
              id: "interview",
              label: "Technical Interview",
              mandatory: true,
              icon: "👥",
            },
          ],
        };
  
    if (parsedSections) {
      initialFormData.selectedServices = {
        sourcing: parsedSections.sourcing?.isChecked || false,
        screening: parsedSections.screening?.isChecked || false,
        assessment: parsedSections.assessment?.isChecked || false,
      };
    }
  
    return initialFormData;
  });

  useEffect(() => {
    localStorage.setItem("createJDFormData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("createJDFormData");
      localStorage.removeItem("createJDSections");
      console.log("localStorage cleared on CreateJD unmount");
    };
  }, []);

  useEffect(() => {
    const { selectedSet, selectedBehavioralSet, sets, behavioralSets } = formData;
    
    if (selectedSet || selectedBehavioralSet) {
      let updates = {};
      
      if (selectedSet) {
        const selectedSetInfo = (formData.sets || []).find(set => set.name === selectedSet);
        if (selectedSetInfo?.timelimit) {
          updates.MCQTimeLimit = selectedSetInfo.timelimit;
        } else {
          updates.MCQTimeLimit = 5;
        }
      }

      if (selectedBehavioralSet) {
        const selectedBehavioralSetInfo = (formData.behavioralSets || []).find(
          set => set.name === selectedBehavioralSet
        );
        if (selectedBehavioralSetInfo?.timelimit) {
          updates.behavioralTimeLimit = selectedBehavioralSetInfo.timelimit;
        } else {
          updates.behavioralTimeLimit = 5;
        }
      }

      if (Object.keys(updates).length > 0) {
        updateFormData(updates);
      }
    }
  }, [formData.selectedSet, formData.selectedBehavioralSet]);

  useEffect(() => {
    let newSequence = [];

    if (formData.isMCQIncluded) {
      newSequence.push({
        id: "mcq",
        label: "MCQ Test",
        mandatory: false,
      });
    }

    if (formData.isCodingIncluded) {
      newSequence.push({
        id: "coding",
        label: "Coding Test",
        mandatory: false,
      });
    }

    if (formData.isBehavioralIncluded) {
      newSequence.push({
        id: "behavioral",
        label: "Behavioral Test",
        mandatory: false,
      });
    }

    newSequence.push({
      id: "interview",
      label: "Technical Interview",
      mandatory: true,
    });

    if (formData.sequence && formData.sequence.length > 0) {
      const orderedSequence = [];
      const newIds = new Set(newSequence.map((item) => item.id));

      formData.sequence.forEach((item) => {
        if (newIds.has(item.id) && item.id !== "interview") {
          orderedSequence.push({
            ...newSequence.find((n) => n.id === item.id),
            mandatory: item.mandatory,
          });
          newIds.delete(item.id);
        }
      });

      newSequence.forEach((item) => {
        if (newIds.has(item.id) && item.id !== "interview") {
          orderedSequence.push(item);
        }
      });

      orderedSequence.push({
        id: "interview",
        label: "Technical Interview",
        mandatory: true,
      });

      newSequence = orderedSequence;
    }
    updateFormData({
      sequence: newSequence,
      assessmentSequence: newSequence.map((item) => item.id)
    });
  }, [formData.isMCQIncluded, formData.isCodingIncluded, formData.isBehavioralIncluded]);

  const moveItem = (fromIndex, toIndex) => {
    const newSequence = [...formData.sequence];
    const [movedItem] = newSequence.splice(fromIndex, 1);
    newSequence.splice(toIndex, 0, movedItem);
    updateFormData({
      sequence: newSequence,
      assessmentSequence: newSequence.map((item) => item.id)
    });
  };

  const handleWorkLocationInputChange = (e) => {
    updateFormData({ workLocationInput: e.target.value });
  };
  
  const handleWorkLocationAdd = (e) => {
    if (e.key === "Enter" && formData.workLocationInput.trim()) {
      updateFormData({
        workLocations: [...formData.workLocations, formData.workLocationInput.trim()],
        workLocationInput: "",
      });
    }
  };

  const handleWorkLocationRemove = (location) => {
    updateFormData({
      workLocations: formData.workLocations.filter((loc) => loc !== location),
    });
  };

  const renderProgressBar = () => {
    return (
      <div className=" flex justify-center items-center gap-4 mb-6 mt-6">
        {steps.map((step, index) => (
          <div key={step.id} className="flex text-center justify-center items-center gap-2 relative group">
            <p className="text-sm mt-2 text-gray-300">{step.name}</p>
            <div
              className={`w-8 h-8 text-sm mx-auto mt-2 rounded-full flex items-center justify-center cursor-not-allowed transition-colors ${
                currentStep > step.id ? 'bg-green-600 text-white' : currentStep === step.id ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-600'
              }`}
              // onClick={() => setCurrentStep(step.id)}
            >
              {currentStep > step.id ? (
                <CheckCircleIcon className="w-5 h-5" />
              ) : (
                step.id
              )}
            </div>
            {/* <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
              Please Navigate Using the Below buttons
            </div> */}
            {index < steps.length - 1 && (
            <div
              className={`h-0.5 w-24 mx-2 mt-2 ${
                currentStep > step.id ? 'bg-blue-600' : 'bg-gray-300'
              }`}
            ></div>
          )}
          </div>
        ))}
      </div>
    );
  };

  const toggleSection = (sectionKey) => {
    if (!sections[sectionKey].isChecked) return;

    setSections((prev) => ({
      ...prev,
      [sectionKey]: {
        ...prev[sectionKey],
        isExpanded: !prev[sectionKey].isExpanded,
      },
    }));
  };

  const handleCheckboxChange = (sectionKey) => {
    setSections((prev) => {
      const updatedSections = {
        ...prev,
        [sectionKey]: {
          ...prev[sectionKey],
          isChecked: !prev[sectionKey].isChecked,
          isExpanded: !prev[sectionKey].isChecked, 
        },
      };
      // Sync with formData.selectedServices
      updateFormData({
        selectedServices: {
          ...formData.selectedServices,
          [sectionKey]: !prev[sectionKey].isChecked,
        },
      });
      return updatedSections;
    });
  };

  const DraggableItem = ({ item, sourceBoxId }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemType,
      item: { item, sourceBoxId },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });

    return (
      <div
        ref={drag}
        className={`cursor-pointer px-4 py-2 bg-gradient-to-br from-blue-400/80 to-blue-600/80 text-white rounded-full text-sm inline-flex items-center space-x-2 transition-all duration-300 ${
          isDragging ? "opacity-50 scale-95" : "opacity-100 hover:scale-105"
        }`}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <span>{item}</span>
        {sourceBoxId !== "aiSuggestedSkills" && (
          <button
            onClick={(e) => (
              e.stopPropagation(), deleteItem(item, sourceBoxId)
            )}
            className="ml-2 group relative"
          >
            <div className="absolute inset-0 bg-red-500/20 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"></div>
            <X
              className="relative z-10 text-red-400 group-hover:text-red-700 transition-transform group-hover:scale-110"
              strokeWidth={2.5}
            />
          </button>
        )}
      </div>
    );
  };

  const DraggableBox = ({
    id,
    name,
    items,
    handleDrop,
    bgColor = "bg-blue-50",
    textColor = "text-pink-500",
    icon: Icon = CheckCircleIcon,
  }) => {
    const [{ canDrop }, drop] = useDrop({
      accept: ItemType,
      drop: (draggedItem, monitor) =>
        id !== "aiSuggestedSkills" &&
        handleDrop(
          draggedItem,
          id,
          calculateTargetIndex(monitor.getClientOffset())
        ),
      canDrop: () => id !== "aiSuggestedSkills",
    });

    const calculateTargetIndex = (clientOffset) => {
      if (!clientOffset) return items.length;
      const bounds = document.getElementById(id).getBoundingClientRect();
      const offsetY = clientOffset.y - bounds.top;
      return items.reduce(
        (acc, _, i) => (offsetY <= 30 * (i + 1) ? acc : i + 1),
        0
      );
    };

    return (
      <div
        id={id}
        ref={drop}
        className={`glass-effect border border-dashed rounded-xl p-4 transition-all duration-300 ${bgColor} ${textColor} ${
          canDrop ? "border-blue-400 bg-blue-100 scale-101" : "border-gray-200"
        }`}
        style={{ width: "100%", minHeight: "150px", maxHeight: "200px" }}
      >
        <div className="flex items-center space-x-3 mb-3">
          <Icon className="w-6 h-6" />
          <h3 className="font-semibold text-lg">{name}</h3>
        </div>
        <div className="overflow-y-auto" style={{ maxHeight: "85px" }}>
          {items.length === 0 && name !== "AI Suggested Skills" ? (
            <p className="text-gray-400 italic">Drop skills here</p>
          ) : (
            <ul className="flex flex-wrap gap-[5px]">
              {items.map((item, index) => (
                <li key={index} className="flex-shrink-0 p-[1px]">
                  <DraggableItem
                    item={item}
                    index={index}
                    sourceBoxId={id}
                    deleteItem={deleteItem}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  };

  const deleteItem = (item, sourceBoxId) => {
    if (sourceBoxId === "required") {
      updateFormData({ requiredSkills: formData.requiredSkills.filter((skill) => skill !== item) });
    } else if (sourceBoxId === "optional") {
      updateFormData({ optionalSkills: formData.optionalSkills.filter((skill) => skill !== item) });
    }
  };

  useEffect(() => {
    const fetchCombinedData = async () => {
      try {
        const response = await fetch(`${host}/orgadmin/get_jd_data`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
      updateFormData({
        sets: Array.isArray(data.set_names) ? data.set_names : [], // Ensure array
        codingSets: Array.isArray(data.coding_sets) ? data.coding_sets : [], // Ensure array
        behavioralSets: Array.isArray(data.behavioral_sets) ? data.behavioral_sets : [], // Ensure array
      });
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
        setIsLoading(false);
      }
    };

    fetchCombinedData();
  }, [accessToken]);

  const updateFormData = (updates) => {
    setFormData((prev) => ({ ...prev, ...updates }));
  };

  const goToStep = (step) => {
    if (step >= 1 && step <= totalSteps) {
      setCurrentStep(step);
    }
  };

  const handleDrop = (draggedItem, targetBoxId, targetIndex) => {
    const removeItemFromSourceBox = (sourceBoxId, item) => {
      if (sourceBoxId === "required") {
        updateFormData({ requiredSkills: formData.requiredSkills.filter((skill) => skill !== item) })
      } else if (sourceBoxId === "optional") {
        updateFormData({ optionalSkills: formData.optionalSkills.filter((skill) => skill !== item) })
      } else if (sourceBoxId === "aiSuggestedSkills") {
        updateFormData({ aiSuggestedSkills: formData.aiSuggestedSkills.filter((skill) => skill !== item) });
      }
    };

    const addItemToTargetBox = (targetBoxId, item, targetIndex) => {
      if (targetBoxId === "required") {
        updateFormData({
          requiredSkills: [...formData.requiredSkills.slice(0, targetIndex), item, ...formData.requiredSkills.slice(targetIndex)],
        });
      } else if (targetBoxId === "optional") {
        updateFormData({
          optionalSkills: [...formData.optionalSkills.slice(0, targetIndex), item, ...formData.optionalSkills.slice(targetIndex)],
        });
      } else if (targetBoxId === "aiSuggestedSkills") {
        updateFormData({
          aiSuggestedSkills: [...formData.aiSuggestedSkills.slice(0, targetIndex), item, ...formData.aiSuggestedSkills.slice(targetIndex)],
        });
      }
    };

    // If dragged within the same box and reordering:
    if (draggedItem.sourceBoxId === targetBoxId) {
      removeItemFromSourceBox(targetBoxId, draggedItem.item);
      addItemToTargetBox(targetBoxId, draggedItem.item, targetIndex);
      return;
    }

    // Moving items between boxes:
    removeItemFromSourceBox(draggedItem.sourceBoxId, draggedItem.item);
    if (targetBoxId === "required" && formData.requiredSkills.includes(draggedItem.item))
      return;
    if (targetBoxId === "optional" && formData.optionalSkills.includes(draggedItem.item))
      return;
    if (
      targetBoxId === "aiSuggestedSkills" &&
      formData.aiSuggestedSkills.includes(draggedItem.item)
    )
      return;

    addItemToTargetBox(targetBoxId, draggedItem.item, targetIndex);
  };

  const handleJobRoleChange = (event) => {
    const value = event.target.value;
    updateFormData({
      jobRole: value,
      errors: {
        ...formData.errors,
        jobRole: value.trim() === "" ? "Job role is required" : "", 
      },
    });
  };

  const handleMinExpChange = (event) => {
    const value = parseInt(event.target.value) || 0; // Default to 0 if NaN
    updateFormData({ minExp: value });
    validateExperience(value, formData.maxExp);
    if (value <= formData.maxExp) {
      handleExperienceChange(value, formData.maxExp);
    }
  };

  const handleMaxExpChange = (event) => {
    const value = parseInt(event.target.value) || 1; // Default to 1 if NaN
    updateFormData({ maxExp: value });
    validateExperience(formData.minExp, value);
    if (value > formData.minExp) {
      handleExperienceChange(value, formData.minExp);
    }
  };

  const handleExperienceChange = (min, max) => {
    setExperience(parseInt((min + max) / 2));
  };
  
  const validateExperience = (min, max) => {
    updateFormData({
      errors: {
        ...formData.errors,
        minExp: min < 0 ? "Minimum experience cannot be negative" : "",
        maxExp: max <= min ? "Maximum experience must be greater than minimum" : "",
      },
    });
  };

  const handleResponsibilitiesChange = (event) => {
    updateFormData({ responsibilities: event.target.value });
  };

  const handleLocationAdd = (e) => {
    if (e.key === "Enter" && searchInput.trim() !== "") {
      updateFormData({
        searchLocations: [...formData.searchLocations, searchInput.trim()],
      });
      setSearchInput("");
    }
  };

  // Handle input change
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  // Handle removing a location
  const handleLocationRemove = (location) => {
    updateFormData({
      searchLocations: formData.searchLocations.filter((loc) => loc !== location),
    });
  };

  const handleGenerateJD = async () => {
    if (formData.requiredSkills.length < 5) {
      toast.warn("Please add atleast 5 required skills");
      return false;
    }
    setIsLoading2(true);
    const skills_list = {
      Hard: [],
      Medium: [],
      Easy: [],
    };

    const experience = (parseInt(formData.minExp) + parseInt(formData.maxExp)) / 2;

    formData.requiredSkills.forEach((skill) => {
      if (experience < 4) {
        skills_list.Medium.push(skill);
      } else {
        skills_list.Hard.push(skill);
      }
    });

    formData.optionalSkills.forEach((skill) => {
      if (experience < 4) {
        skills_list.Easy.push(skill);
      } else {
        skills_list.Medium.push(skill);
      }
    });

    const jd = `jobrole: ${formData.jobRole}
                MinExperience: ${formData.minExp},
                MaxExperience: ${formData.maxExp}
                SkillsRequired:${JSON.stringify(skills_list)}
               `;

    const dataToSend = { description: jd };
    console.log(dataToSend);
    try {
      //toast.success("Job description successfully sent for fine-tuning!");
      const response = await fetch(`${host}/orgadmin/fineTuneJD`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      const result = await response.json();
      console.log(result.fineTunedJD);
      
      if (!response.ok) {
        toast.error(
          result.message || "Something went wrong with the fine-tuning process."
        );
        return false;
      }

      updateFormData({ generatedJD: result.fineTunedJD, isFineTuned: true });
      return true;
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }

      console.error("Error connecting to the server:", error);
      toast.error("Failed to connect to the server. Please try again.");
      return false;
    } finally {
      setIsLoading2(false);
    }
  };

  const handleAnalyze = async () => {
    const newErrors = {
      jobRole: formData.jobRole.trim() === '' ? 'Job role is required' : '',
      minExp: formData.minExp < 0 ? 'Minimum experience cannot be negative' : '',
      maxExp: formData.maxExp <= formData.minExp ? 'Maximum experience must be greater than minimum' : '',
      requiredSkills: ''
    };
    updateFormData({errors: newErrors});
    if (Object.values(newErrors).some(error => error !== '')) {
      return;
    }

    if (isAI === "false" && formData.responsibilities.trim() === "") {
      updateFormData({
        errors: {
          ...newErrors,
          responsibilities: 'Please describe the role and responsibilities'
        }
      });
      return;
    }
    setSkillsLoading(true);
    try {
      const response = await axios.post(
        `${host}/analyze`,
        {
          jobRole: formData.jobRole,
          minExp: formData.minExp,
          maxExp: formData.maxExp,
          responsibilities:
            formData.responsibilities.trim() === "" ? "Not Provided" : formData.responsibilities,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API Response:", response.data);
      updateFormData({
        aiSuggestedSkills: response.data.essential_skills || [],
        requiredSkills: response.data.skills_mentioned_in_JD?.length > 0 
          ? response.data.skills_mentioned_in_JD 
          : formData.requiredSkills, // Preserve existing requiredSkills if empty
        isAnalyzed: true,
      });
      goToStep(2);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      console.error("Error sending data to backend:", error);
      toast.error("Failed to connect to the server. Please try again.");
    } finally {
      setSkillsLoading(false);
    }
  };

  const handleIncludeMCQ = () => {
    if (formData.isMCQIncluded) {
      updateFormData({
        isMCQIncluded: false,
        selectedSet: "NA",
        MCQTimeLimit: 0,
      });
      updateFormData({
        sequence: formData.sequence.filter(item => item.id !== "mcq")
      });
    } else {
      updateFormData({ 
        isMCQIncluded: true, 
        sets: formData.sets || [],
        sequence: [...(formData.sequence || []), { id: "mcq", label: "Knowledge Test", mandatory: false }]
      });
    }
  };

  const handleIncludeCoding = () => {
    if (formData.isCodingIncluded) {
      updateFormData({
        isCodingIncluded: false,
        selectedCodingSet: "NA",
        CodingTimeLimit: 0,
      });
      updateFormData({
        sequence: formData.sequence.filter(item => item.id !== "coding")
      });
    } else {
      updateFormData({ 
        isCodingIncluded: true,
        codingSets: formData.codingSets || [],
        sequence: [...(formData.sequence || []), { id: "coding", label: "Coding Test", mandatory: false }]  
      });
    }
  };

  const handleIncludeBehavioral = () => {
    if (formData.isBehavioralIncluded) {
      updateFormData({
        isBehavioralIncluded: false,
        selectedBehavioralSet: "NA",
        behavioralTimeLimit: 0,
      });
      updateFormData({
        sequence: formData.sequence.filter(item => item.id !== "behavioral")
      });
    } else {
      updateFormData({ 
        isBehavioralIncluded: true,
        behavioralSets: formData.behavioralSets || [],
        sequence: [...(formData.sequence || []), { id: "behavioral", label: "Behavioral Test", mandatory: false }]
      });
    }
  };

const handleQuestionChange = (index, newValue) => {
  const updatedEditedQuestions = [...formData.editedQuestions];
  updatedEditedQuestions[index] = newValue;
  const updatedOriginalQuestions = [...formData.questions];
  updatedOriginalQuestions[index] = newValue;
  updateFormData({
    editedQuestions: updatedEditedQuestions,
    questions: updatedOriginalQuestions,
  });
};

const handleAddSkills = () => {
  const experience = (parseInt(formData.minExp) + parseInt(formData.maxExp)) / 2;

  let updatedRequiredSkills = [...formData.requiredSkills];
  let updatedOptionalSkills = [...formData.optionalSkills];
  let updatedJD = formData.generatedJD ? {...formData.generatedJD} : { Required_Skills: { Hard: [], Medium: [], Easy: []}};
  
  if (formData.customRequiredSkill.trim()) {
    updatedRequiredSkills = [...updatedRequiredSkills, formData.customRequiredSkill.trim()];
    if (experience > 4) {
      updatedJD.Required_Skills = {
        ...updatedJD.Required_Skills,
        Hard: [...(updatedJD.Required_Skills?.Hard || []), formData.customRequiredSkill.trim()],
      };
    } else {
      updatedJD.Required_Skills = {
        ...updatedJD.Required_Skills,
        Medium: [...(updatedJD.Required_Skills?.Medium || []), formData.customRequiredSkill.trim()],
      };
    }
  }

  if (formData.customOptionalSkill.trim()) {
    updatedOptionalSkills = [...updatedOptionalSkills, formData.customOptionalSkill.trim()];

    if (experience > 4) {
      updatedJD.Required_Skills = {
        ...updatedJD.Required_Skills,
        Medium: [...(updatedJD.Required_Skills?.Medium || []), formData.customOptionalSkill.trim()],
      };
    } else {
      updatedJD.Required_Skills = {
        ...updatedJD.Required_Skills,
        Easy: [...(updatedJD.Required_Skills?.Easy || []), formData.customOptionalSkill.trim()],
      };
    }
  }

  updateFormData({
    requiredSkills: updatedRequiredSkills,
    optionalSkills: updatedOptionalSkills,
    generatedJD: updatedJD,
    customRequiredSkill: "",
    customOptionalSkill: "",
  });
};

const handleSaveCustomQuestions = async () => {
  if (formData.customQuestions.trim() === "") {
    toast.warn("Please enter Custom Questions!");
    return;
  }
  setIsLoading(true);
  try {
    const response = await axios.post(
      `${host}/upload-custom-questions/`,
      { questions: formData.customQuestions },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response.data.message);
    updateFormData({
      questions: response.data.questions,
      editedQuestions: response.data.questions,
      
    });
  } catch (error) {
    console.error("Error saving custom questions:", error);
    alert("Failed to save custom questions. Please try again.");
  } finally {
    setIsLoading(false);
  }
};

const toggleMode = () => {
  updateFormData({
    isAnalyzed: false,
    aiSuggestedSkills: [],
    requiredSkills: [],
    optionalSkills: [],
  });
  setIsAI((prev) => !prev);
};

  const handleFinalize = async () => {
    if (
      !formData.selectedServices.assessment &&
      !formData.selectedServices.sourcing &&
      !formData.selectedServices.screening
    ) {
      toast.warn("Please select at least one service!");
      return false;
    }
    if (formData.selectedServices.assessment) {
      if (formData.interview_timelimit < 5) {
        toast.warn("Interview Should be of Atleast 5 mins!");
        return false;
      }

      if (formData.isCustomQuestionsIncluded && formData.customQuestions.trim() === "" && formData.questions.length === 0) {
        toast.warn("Custom Questions cannot be Empty!");
        return false;
      }
      if (formData.isMCQIncluded) {
        if (!formData.selectedSet) {
          toast.warn("Please select a set for the MCQ tests.");
          return false;
        }
        if (formData.MCQTimeLimit < 5) {
          toast.warn("MCQ tests should be at least 5 minutes long.");
          return false;
        }
        if (formData.MCQPercentThreshold <= 0 || formData.MCQPercentThreshold > 100) {
          toast.warn("MCQ Percent Threshold should be between 0-100");
          return false;
        }
    }
      if (formData.isCodingIncluded) {
        if (!formData.selectedCodingSet) {
          toast.warn("Please select a set for the Coding tests.");
          return false;
        }
        if (formData.CodingTimeLimit < 10) {
          toast.warn("Coding tests should be at least 10 minutes long.");
          return false;
        }
        if (!formData.CodingJudgingCriteria) {
          toast.warn("Select a Judging Criteria for Coding");
          return false;
        }
      }
      if (formData.isBehavioralIncluded) {
        if (!formData.selectedBehavioralSet) {
          toast.warn("Please select a set for the Behavioral tests.");
          return false;
        }
        if (formData.behavioralTimeLimit < 5) {
          toast.warn("Behavioral test should be at least 5 minutes long.");
          return false;
        }
      }
      updateFormData({ questions: [...formData.editedQuestions] });
    }
    
    if (!formData.selectedServices.sourcing) {
      updateFormData({
        candidatesRequired: 0,
        searchLocations: [],
      });
    } else {
      if (formData.candidatesRequired <= 0) {
        toast.warn("Please specify candidates required from sourcing!");
        return false;
      }
      if (formData.searchLocations.length === 0) {
        toast.warn(
          "Please specify at least one location to search candidates!"
        );
        return false;
      }
    }
    if (formData.selectedServices.screening) {
      if (formData.companyName.trim() === "") {
        toast.warn("Please enter Company Name");
        return false;
      }
      if (formData.companyDescription.trim() === "") {
        toast.warn("Please enter a short description about Company");
        return false;
      }
      if (formData.workLocations.length == 0 && formData.workLocationType !== "remote") {
        toast.warn("Please enter atleast 1 Work Location");
        return false;
      }
      if (!formData.maxCTC || formData.maxCTC <= 0) {
        toast.warn("Please Enter the Max CTC");
        return false;
      }
    } else {
      updateFormData({
        companyName: "",
        companyDescription: "",
        workLocations: [],
        maxCTC: "",
      });
    }
    updateFormData({ showInterviewConfig: true });
    return true;
  }

  const handleUpdateJD = async () => {
    
    const dataToSend = {
      description: {
        jobrole: formData.jobRole,
        minExp: parseInt(formData.minExp),
        maxExp: parseInt(formData.maxExp),
        Required_Skills: {
          Easy: formData.generatedJD.Required_Skills?.Easy || [],
          Medium: formData.generatedJD.Required_Skills?.Medium || [],
          Hard: formData.generatedJD.Required_Skills?.Hard || [],
        },
        GoodtoHave: formData.generatedJD.GoodtoHave || [],
        Tools_and_Technologies: formData.generatedJD["Tools & Technologies"] || [],
        Roles_and_Responsibilities:
          formData.generatedJD["Roles & Responsibilities"] || [],
        selectedInterviewType: "Technical Interview",
        interview_timelimit: parseInt(formData.interview_timelimit),
        selectedSet: formData.selectedSet || "NA",
        MCQTimeLimit: parseInt(formData.MCQTimeLimit),
        MCQPercentThreshold: parseInt(formData.MCQPercentThreshold),
        selectedCodingSet: formData.selectedCodingSet || "NA",
        CodingTimeLimit: parseInt(formData.CodingTimeLimit),
        CodingJudgingCriteria: formData.CodingJudgingCriteria || "NA",
        selectedBehavioralSet: formData.selectedBehavioralSet || "NA",
        behavioralTimeLimit: parseInt(formData.behavioralTimeLimit),
        customQuestions: formData.questions || [],
        selectedServices: {
          sourcing: formData.selectedServices.sourcing,
          screening: formData.selectedServices.screening,
          assessment: formData.selectedServices.assessment,
        },
        assessmentSequence: formData.assessmentSequence,
        jobType: formData.jobType,
        companyName: formData.companyName,
        companyDescription: formData.companyDescription,
        candidatesRequired: parseInt(formData.candidatesRequired),
        searchLocations: formData.searchLocations,
        workLocationType: formData.workLocationType,
        workLocations: formData.workLocationType === "remote" ? "Remote" : formData.workLocations,
        noticePeriod: parseInt(formData.noticePeriod),
        noticeStrictness: formData.noticeStrictness,
        maxCTC: parseInt(formData.maxCTC),
        ctcStrictness: formData.ctcStrictness,
      },
    };

    try {
      setIsLoading(true);
      const response = await fetch(`${host}/store_final_jd`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      const result = await response.json();
      if (!response.ok) {
        const errorMessage = result.message || (await response.text());
        console.error(
          "Error updating JD:",
          errorMessage,
          "Status Code:",
          response.status
        );

        alert(
          errorMessage ||
            "Something went wrong while updating the job description."
        );
      } else {
        console.log("Success response:", result);
        toast.success("Job description updated successfully.");
        localStorage.removeItem("createJDFormData");
        localStorage.removeItem("createJDSections");
        setFormData({
          jobRole: "",
          minExp: 0,
          maxExp: 1,
          responsibilities: "",
          requiredSkills: [],
          optionalSkills: [],
          aiSuggestedSkills: [],
          generatedJD: {},
          isAnalyzed: false,
          isFineTuned: false,
          customRequiredSkill: "",
          customOptionalSkill: "",
          showInterviewConfig: false,
          interview_timelimit: null,
          isMCQIncluded: false,
          isCodingIncluded: false,
          isBehavioralIncluded: false,
          MCQTimeLimit: 5,
          MCQPercentThreshold: 60,
          CodingTimeLimit: 10,
          CodingJudgingCriteria: "",
          behavioralTimeLimit: 5,
          selectedSet: "",
          selectedCodingSet: "",
          selectedBehavioralSet: "",
          sets: [],
          codingSets: [],
          behavioralSets: [],
          isCustomQuestionsIncluded: false,
          customQuestions: "",
          selectedServices: {
            sourcing: false,
            screening: false,
            assessment: false,
          },
          editedQuestions: [],
          questions: [],
          candidatesRequired: 5,
          searchLocations: [],
          assessmentSequence: ["interview"],
          companyName: "",
          companyDescription: "",
          jobType: "fulltime",
          workLocationType: "office",
          workLocationInput: "",
          workLocations: [],
          noticePeriod: 0,
          noticeStrictness: "strict",
          maxCTC: "",
          ctcStrictness: "strict",
          errors: { jobRole: "", minExp: "", maxExp: "", requiredSkills: "" },
          sequence: [
            {
              id: "interview",
              label: "Technical Interview",
              mandatory: true,
              icon: "👥",
            },
          ],
        });
  
        // Reset sections to initial state
        setSections({
          sourcing: {
            isExpanded: false,
            isChecked: false,
            content:
              "Configure your candidate sourcing preferences, channels, and automation settings.",
          },
          screening: {
            isExpanded: false,
            isChecked: false,
            content:
              "Set up your screening criteria, questionnaires, and filtering parameters.",
          },
          assessment: {
            isExpanded: false,
            isChecked: false,
            content:
              "Customize your assessment methods, scoring criteria, and evaluation process.",
          },
        });
  
        // Reset other state variables
        setIsAI(false);
        setExperience(0);
        setIsAddSkillOpen(false);
        setCurrentStep(1); // Navigate back to step 1
        setSearchInput("");
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      console.error("Error connecting to the server:", error);
      toast.error("Failed to connect to the server. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  if (isSkillsLoading) {
    return <AIProcessingLoader />;
  }
  if (isLoading2) {
    return <JobDescriptionLoader />;
  }

  const renderStep1 = () => (
      <div className="space-y-6">
        <div className="flex justify-between items-center mb-10">
            <h1 className="text-xl font-medium text-white flex items-center space-x-3">
              <span>Create Job Vacancy</span>
            </h1>
            {!formData.isFineTuned && !formData.isAnalyzed && (
              <div
                className={`relative w-[300px] h-12 rounded-full cursor-pointer transition-all duration-500
                bg-gradient-to-l from-[#FB54AE] to-[#2F64FF]`}
                onClick={toggleMode}
              >
                <div
                  className={`absolute top-1 h-10 w-[147px] bg-black rounded-full shadow-md 
                  transition-transform duration-500 ${
                    isAI ? "translate-x-[146px]" : "translate-x-1"
                  }`}
                />
                <div
                  className={`absolute inset-y-0 left-0 w-[147px] flex items-center justify-center text-sm font-semibold
                  ${isAI ? "text-white" : "text-blue-600"}`}
                >
                  Generate with AI
                </div>

                <div
                  className={`absolute inset-y-0 right-0 w-[147px] flex items-center justify-center text-sm font-semibold
                  ${isAI ? "text-pink-400" : "text-white"}`}
                >
                  Add Custom JD
                </div>
              </div>
            )}
          </div>
        <div className="flex flex-col lg:flex-row items-start gap-x-12">
          <div className="space-y-2">
            <label className="block font-semibold text-gray-300">Job Role</label>
            <input
              type="text"
              placeholder="e.g., Senior Software Engineer"
              className="glass-effect w-80 px-4 py-3 rounded-xl focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-white"
              value={formData.jobRole}
              onChange={handleJobRoleChange}
            />
          </div>
          <div className="space-y-2">
            <label className="block font-semibold text-gray-300">Min Exp</label>
            <input
              type="number"
              placeholder="0"
              min={0}
              className="glass-effect w-32 px-4 py-3 rounded-xl focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-white"
              value={formData.minExp}
              onChange={handleMinExpChange}
              onKeyDown={(e) => {
                if (e.key === 'Backspace' && formData.minExp === 0) {
                  updateFormData({ minExp: '' });
                }
              }}
            />
          </div>
          <div className="space-y-2">
            <label className="block font-semibold text-gray-300">Max Exp</label>
            <input
              type="number"
              placeholder="Years of experience"
              className="glass-effect w-32 px-4 py-3 rounded-xl focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition text-white"
              value={formData.maxExp}
              max={40}
              min={1}
              onChange={handleMaxExpChange}
              onKeyDown={(e) => {
                if (e.key === 'Backspace' && formData.maxExp === 1) {
                  updateFormData({ maxExp: '' });
                }
              }}
            />
          </div>
        </div>
        <div>
          {Object.entries(formData.errors).map(([key, value]) => (
            value && (
              <p key={key} className="text-red-400 text-sm">
                {value}
              </p>
            )
          ))}
        </div>
        {isAI && (
          <div className="space-y-4">
            <label className="block text-gray-300 font-semibold">Roles and Responsibility</label>
            <textarea
              placeholder="Describe the role and responsibilities..."
              className="glass-effect w-2/3 h-44 px-4 py-3 rounded-xl transition text-white"
              value={formData.responsibilities}
              onChange={(e) => setFormData({ ...formData, responsibilities: e.target.value })}
            ></textarea>
          </div>
        )}
        <div className="flex justify-end">
          <button
            className="button flex items-center space-x-2 text-white px-6 py-3 rounded-full hover:scale-105 transition-transform mb-4"
            onClick={handleAnalyze}
          >
            <span className="flex gap-2">
              {/* <LightbulbIcon className="w-5 h-5" /> */}
              Next
            </span>
          </button>
        </div>
      </div>
  );

  const renderStep2 = () => (
    <div className="space-y-6 w-full">
      {formData.aiSuggestedSkills.length > 0 && (
        <DraggableBox
          id="aiSuggestedSkills"
          name="AI Suggested Skills"
          items={formData.aiSuggestedSkills}
          bgColor="bg-purple-50"
          textColor="text-purple-600"
          icon={SparklesIcon}
          setItems={(items) => setFormData({ ...formData, aiSuggestedSkills: items })}
          handleDrop={handleDrop}
        />
      )}
      <DraggableBox
        id="required"
        name="Required Skills"
        items={formData.requiredSkills}
        setItems={(items) => setFormData({ ...formData, requiredSkills: items })}
        handleDrop={handleDrop}
      />
      <DraggableBox
        id="optional"
        name="Optional Skills"
        items={formData.optionalSkills}
        setItems={(items) => setFormData({ ...formData, optionalSkills: items })}
        handleDrop={handleDrop}
        icon={LightbulbIcon}
        bgColor="bg-green-50"
        textColor="text-pink-500"
      />
      <div className="space-y-6 w-[25%]">
      <div className="glass-effect rounded-2xl p-4">
          <div
            className="flex items-center space-x-2 mb-3 cursor-pointer"
            onClick={() => setIsAddSkillOpen(!isAddSkillOpen)}
          >
            {isAddSkillOpen ? (
              <Minus className="w-4 h-4 text-gray-300" />
            ) : (
              <PlusIcon className="w-4 h-4 text-gray-300" />
            )}
            <h3 className="text-md font-medium text-gray-300">
              {isAddSkillOpen ? "Close Add Skills" : "Add New Skills"}
            </h3>
          </div>
          {isAddSkillOpen && (
            <div className="flex flex-col justify-between gap-2">
              <input
                type="text"
                placeholder="Add Required Skill"
                value={formData.customRequiredSkill}
                onChange={(e) => setFormData({ ...formData, customRequiredSkill: e.target.value })}
                className="glass-effect placeholder:text-sm placeholder:text-gray-400 w-full px-3 py-2 rounded-xl focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition"
              />
              <input
                type="text"
                placeholder="Add Optional Skill"
                value={formData.customOptionalSkill}
                onChange={(e) => setFormData({ ...formData, customOptionalSkill: e.target.value })}
                className="glass-effect placeholder:text-sm placeholder:text-gray-400 w-full px-3 py-2 rounded-xl focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition"
              />
              <button
                onClick={handleAddSkills}
                className="button w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-3 rounded-full hover:scale-105 transition-transform flex items-center justify-center space-x-2"
              >
                <span>Add Skill</span>
              </button>
            </div>
          )}
        </div>
        </div>
      <div className="flex justify-end gap-4">
        <button
          className="button px-6 py-3 rounded-full text-white"
          onClick={() => setCurrentStep(1)}
        >
          <span>Back</span>
        </button>
        <button
          className="button px-6 py-3 rounded-full text-white"
          onClick={async () => {
            const success = await handleGenerateJD();
            if(success)
              setCurrentStep(3);
          }}
        >
          <span>Next</span>
        </button>
      </div>
    </div>
  );

  const renderStep3 = () => {
    return (
      <div className="space-y-4">
        <div className="flex items-center space-x-3 mb-3">
          <ClockIcon className="w-5 h-5 text-pink-500" />
          <h3 className="text-md font-medium text-pink-500">Select the Services you want to Use</h3>
        </div>
        {/* Candidate Sourcing Section
        <div className="p-2 rounded-md shadow-sm w-[60%] border border-gray-500">
          <div className="flex items-center justify-between px-4 py-2">
            <div className="relative group">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  checked={sections.sourcing.isChecked}
                  onChange={() => handleCheckboxChange("sourcing")}
                  className="rounded"
                />
                <span className="font-medium text-gray-300">Candidate Sourcing</span>
              </label>
              <div className="absolute bottom-full left-1/4 -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                Beta Feature
              </div>
            </div>
            <button
              onClick={() => toggleSection("sourcing")}
              className={`p-1 rounded transition-colors ${
                sections.sourcing.isChecked ? "hover:bg-gray-100 text-gray-500" : "text-gray-300 cursor-not-allowed"
              }`}
              disabled={!sections.sourcing.isChecked}
            >
              {sections.sourcing.isExpanded ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
            </button>
          </div>
          {sections.sourcing.isExpanded && (
            <div className="p-4 flex gap-2 shadow-inner p-6">
              <div className="w-1/2">
                <label className="flex items-center text-sm font-medium text-gray-300">
                  <Users className="w-4 h-4 text-blue-600 mr-2 text-gray-300" />
                  <p className="whitespace-nowrap">Candidates Required</p>
                  <span className="ml-1 text-red-400">*</span>
                </label>
                <select
                  name="candidatesRequired"
                  value={formData.candidatesRequired}
                  onChange={(e) => updateFormData({ candidatesRequired: e.target.value })}
                  className="text-gray-400 glass-effect w-full mt-2 px-4 py-2.5 bg-gray-50 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                >
                  {candidateRanges.map((range) => (
                    <option
                      key={range.value}
                      value={range.value}
                      style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}
                    >
                      {range.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-2/3">
                <label className="flex items-center text-sm font-medium text-gray-300">
                  <MapPin className="w-4 h-4 text-blue-600 mr-2 text-gray-300" />
                  Search Locations <span className="ml-1 text-red-400">*</span>
                </label>
                <input
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyPress={handleLocationAdd}
                  className="text-gray-300 placeholder:text-gray-400 placeholder:text-sm glass-effect w-full mt-2 px-4 py-2.5 bg-gray-50 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                  placeholder="Add location and press Enter"
                />
                <div className="flex flex-wrap gap-2 mt-2">
                  {formData.searchLocations.map((location) => (
                    <span
                      key={location}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-green-100 text-green-800"
                    >
                      {location}
                      <button
                        onClick={() => handleLocationRemove(location)}
                        className="ml-2 text-green-600 hover:text-green-800 transition-colors"
                      >
                        ×
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div> */}
        {/* Candidate Screening Section */}
        {/* <div className="p-2 rounded-md shadow-sm w-[60%] border border-gray-500">
          <div className="flex items-center justify-between px-4 py-2">
            <div className="relative group">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  checked={sections.screening.isChecked}
                  onChange={() => handleCheckboxChange("screening")}
                  className="rounded"
                />
                <span className="font-medium text-gray-300">Candidate Screening</span>
              </label>
              <div className="absolute bottom-full left-1/4 -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                Beta Feature
              </div>
            </div>
            <button
              onClick={() => toggleSection("screening")}
              className={`p-1 rounded transition-colors ${
                sections.screening.isChecked ? "hover:bg-gray-100 text-gray-500" : "text-gray-300 cursor-not-allowed"
              }`}
              disabled={!sections.screening.isChecked}
            >
              {sections.screening.isExpanded ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
            </button>
          </div>
          {sections.screening.isExpanded && (
            <div className="p-4 shadow-inner rounded-lg">
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-300">Company Name<span className="ml-1 text-red-400">*</span></label>
                  <input
                    type="text"
                    required
                    value={formData.companyName}
                    onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                    className="text-gray-300 glass-effect placeholder:text-gray-400 placeholder:text-sm w-full mt-2 px-4 py-2.5 bg-gray-50 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                    placeholder="Enter company name"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300">Company Description<span className="ml-1 text-red-400">*</span></label>
                  <textarea
                    value={formData.companyDescription}
                    required
                    onChange={(e) => setFormData({ ...formData, companyDescription: e.target.value })}
                    className="placeholder:text-gray-400 placeholder:text-sm text-gray-300 glass-effect w-full mt-2 px-4 py-2.5 bg-gray-50 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                    placeholder="Enter job description"
                    rows="4"
                  ></textarea>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300">Job Type<span className="ml-1 text-red-400">*</span></label>
                  <select
                    value={formData.jobType}
                    required
                    onChange={(e) => setFormData({ ...formData, jobType: e.target.value })}
                    className="text-gray-400 glass-effect w-full mt-2 px-4 py-2.5 bg-gray-50 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                  >
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="fulltime">
                      Full-Time
                    </option>
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="internship">
                      Internship
                    </option>
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="parttime">
                      Part-Time
                    </option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300">Work Location Type<span className="ml-1 text-red-400">*</span></label>
                  <select
                    value={formData.workLocationType}
                    onChange={(e) => setFormData({ ...formData, workLocationType: e.target.value })}
                    className="text-gray-400 glass-effect w-full mt-2 px-4 py-2.5 bg-gray-50 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                  >
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="office">
                      Office
                    </option>
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="remote">
                      Remote
                    </option>
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="hybrid">
                      Hybrid
                    </option>
                  </select>
                </div>
              </div>
              {formData.workLocationType !== "remote" && (
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-300">Work Locations<span className="ml-1 text-red-400">*</span></label>
                  <input
                    type="text"
                    required
                    value={formData.workLocations.join(", ")}
                    onChange={handleWorkLocationInputChange}
                    onKeyPress={handleWorkLocationAdd}
                    className="placeholder:text-gray-400 placeholder:text-sm text-gray-300 glass-effect w-full mt-2 px-4 py-2.5 bg-gray-50 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                    placeholder="Add location and press Enter"
                  />
                  <div className="flex flex-wrap gap-2 mt-2">
                    {formData.workLocations.map((location) => (
                      <span
                        key={location}
                        className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-green-100 text-green-800"
                      >
                        {location}
                        <button
                          onClick={() => handleWorkLocationRemove(location)}
                          className="ml-2 text-green-600 hover:text-green-800 transition-colors"
                        >
                          ×
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <div className="rounded-lg mt-2">
                <label className="block text-sm font-medium text-gray-300 mb-2">Notice Period<span className="ml-1 text-red-400">*</span></label>
                <div className="flex gap-4">
                  <select
                    name="noticePeriod"
                    value={formData.noticePeriod}
                    onChange={(e) => setFormData({ ...formData, noticePeriod: parseInt(e.target.value) })}
                    className="text-gray-400 glass-effect w-full px-4 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  >
                    {[0, 1, 2, 3].map((month) => (
                      <option
                        style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}
                        key={month}
                        value={month}
                      >
                        {month === 0 ? "Immediate" : `${month} ${month === 1 ? "Month" : "Months"}`}
                      </option>
                    ))}
                  </select>
                  <select
                    name="noticeStrictness"
                    value={formData.noticeStrictness}
                    onChange={(e) => setFormData({ ...formData, noticeStrictness: e.target.value })}
                    className="text-gray-400 glass-effect w-full px-4 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  >
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="strict">
                      Strict
                    </option>
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="negotiable">
                      Negotiable
                    </option>
                  </select>
                </div>
              </div>
              <div className="rounded-lg mt-4 mb-4">
                <label className="block text-sm font-medium text-gray-300">CTC Details<span className="ml-1 text-red-400">*</span></label>
                <div className="flex gap-4">
                  <input
                    type="number"
                    name="maxCTC"
                    placeholder="Maximum CTC"
                    value={formData.maxCTC}
                    onChange={(e) => setFormData({ ...formData, maxCTC: e.target.value })}
                    className="mt-2 glass-effect w-full text-white px-4 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  />
                  <select
                    name="ctcStrictness"
                    value={formData.ctcStrictness}
                    onChange={(e) => setFormData({ ...formData, ctcStrictness: e.target.value })}
                    className="text-gray-400 glass-effect mt-2 w-full px-4 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none"
                    style={{ height: "47px" }}
                  >
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="strict">
                      Strict
                    </option>
                    <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="negotiable">
                      Negotiable
                    </option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </div> */}
        {/* Candidate Assessment Section */}
        <div className="p-2 rounded-md shadow-sm w-[60%] border border-gray-500">
          <div className="flex items-center justify-between px-4 py-2">
            <label className="flex items-center space-x-3">
              <input
                type="checkbox"
                checked={sections.assessment.isChecked}
                onChange={() => handleCheckboxChange("assessment")}
                className="rounded"
              />
              <span className="font-medium text-gray-300">
                Candidate Assessment</span>
            </label>
            <button
              onClick={() => toggleSection("assessment")}
              className={`p-1 rounded transition-colors ${
                sections.assessment.isChecked ? "hover:bg-gray-100 text-gray-500" : "text-gray-300 cursor-not-allowed"
              }`}
              disabled={!sections.assessment.isChecked}
            >
              {sections.assessment.isExpanded ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
            </button>
          </div>
          {sections.assessment.isExpanded && (
            <div className="shadow-inner p-4 space-y-6">
              <InterviewTimeCalculator
                skills={formData.generatedJD}
                customQuestions={formData.questions}
                onTimeCalculated={(time) => {
                  if (!formData.isInterviewTimeManuallySet && formData.interview_timelimit == null) {
                    updateFormData({ interview_timelimit: time });
                  }
                }}
              />
              <div className="flex flex-col gap-2 justify-start">
                <label className="text-sm font-medium block text-gray-300">
                  Interview TimeLimit (Mins)<span className="ml-1 text-red-400">*</span>
                </label>
                <input
                  type="number"
                  min="5"
                  max="90"
                  value={formData.interview_timelimit !== null ? formData.interview_timelimit : 5}
                  onChange={(e) => {
                    const value = e.target.value ? parseInt(e.target.value) : null;
                    updateFormData({
                      interview_timelimit: value,
                      isInterviewTimeManuallySet: true, // Mark as manually set
                    });
                  }}
                  className="text-white glass-effect px-3 py-2 rounded-xl focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3"
                />
              </div>
              {/* Custom Questions */}
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.isCustomQuestionsIncluded}
                    onChange={() => {
                      const newValue = !formData.isCustomQuestionsIncluded;
                      updateFormData({
                        isCustomQuestionsIncluded: newValue,
                        questions: newValue ? formData.questions : [], // Reset questions if unchecked
                        editedQuestions: newValue ? formData.editedQuestions : [], // Reset editedQuestions if unchecked
                      });
                    }}
                    className="w-[15px] h-[15px] rounded text-black-600 focus:ring-black-500"
                  />
                  <label className="text-sm font-medium text-gray-300">
                    Upload Custom Interview Questions
                  </label>
                </div>
                {formData.isCustomQuestionsIncluded && formData.questions.length === 0 ? (
                  <div className="space-y-2">
                    <textarea
                      placeholder="Enter custom interview questions..."
                      value={formData.customQuestions}
                      onChange={(e) => setFormData({ ...formData, customQuestions: e.target.value })}
                      rows="5"
                      className="placeholder:text-gray-400 placeholder:text-sm text-gray-300 glass-effect w-full px-4 py-3 rounded-xl focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition"
                    ></textarea>
                    <button
                      onClick={handleSaveCustomQuestions}
                      className="button px-6 py-3 rounded-full hover:scale-105 transition-transformflex items-center justify-center space-x-2"
                    >
                      <span>Save Questions</span>
                    </button>
                  </div>
                ) : (
                  <>
                    <ul style={{ listStyleType: "none", padding: 0, maxHeight: "200px", overflowY: "auto" }}>
                      {formData.questions.map((question, index) => (
                        <li key={index} style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}>
                          <input
                            value={question}
                            onChange={(e) => handleQuestionChange(index, e.target.value)}
                            className="glass-effect"
                            style={{
                              flexGrow: 1,
                              padding: "6px",
                              paddingLeft: "8px",
                              borderRadius: "5px",
                              border: "1px solid #ddd",
                              fontSize: "14px",
                              marginRight: "10px",
                            }}
                          />
                          <button
                            onClick={() =>
                              setFormData({
                                ...formData,
                                questions: formData.questions.filter((_, i) => i !== index),
                              })
                            }
                            style={{
                              color: "red",
                              border: "none",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            ×
                          </button>
                        </li>
                      ))}
                    </ul>
                    {formData.questions.length > 0 && (
                      <button
                        onClick={() => setFormData({ ...formData, questions: [...formData.questions, ""] })}
                        className="bg-gradient-to-r from-green-500 to-green-700 text-white text-sm px-3 py-2 rounded-full hover:scale-105 transition-transform flex items-center justify-center space-x-2"
                      >
                        Add Question
                      </button>
                    )}
                  </>
                )}
              </div>
              {/* MCQ Test */}
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.isMCQIncluded}
                    onChange={handleIncludeMCQ}
                    className="w-[15px] h-[15px] rounded text-black-600 focus:ring-black-500 mb-2"
                  />
                  <label className="text-sm font-medium text-gray-300 mb-2">Include Knowledge Test</label>
                </div>
                {formData.isMCQIncluded && (
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex flex-col gap-2">
                    <label className="block text-sm font-medium text-gray-300">Select a Set<span className="ml-1 text-red-400">*</span></label>
                    <select
                      value={formData.selectedSet}
                      onChange={(e) => updateFormData({ selectedSet: e.target.value })}
                      className="text-gray-400 glass-effect w-full px-3 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition"
                      style={{ height: "43px" }}
                    >
                      <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}>
                        Select MCQ Set
                      </option>
                      {(formData.sets || []).map((set, index) => ( // Fallback to empty array if undefined
                        <option
                          key={index}
                          value={set.name}
                          style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}
                        >
                          {set.name}
                        </option>
                      ))}
                    </select>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="block text-sm font-medium text-gray-300">TimeLimit (Mins)<span className="ml-1 text-red-400">*</span></label>
                      <input
                        type="number"
                        min="5"
                        value={formData.MCQTimeLimit}
                        onChange={(e) => updateFormData({ MCQTimeLimit: e.target.value })}
                        placeholder="MCQ Time Limit (Mins)"
                        className="glass-effect text-white w-full px-3 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="block text-sm font-medium text-gray-300">Passing Threshold<span className="ml-1 text-red-400">*</span></label>
                      <input
                        type="number"
                        min="10"
                        max="100"
                        value={formData.MCQPercentThreshold}
                        onChange={(e) => updateFormData({ MCQPercentThreshold: e.target.value })}
                        placeholder="MCQ Percent Threshold (%)"
                        className="glass-effect text-white w-full px-3 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition"
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* Coding Test */}
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.isCodingIncluded}
                    onChange={handleIncludeCoding}
                    className="w-[15px] h-[15px] rounded text-black-600 focus:ring-black-500 mb-2"
                  />
                  <label className="text-sm font-medium text-gray-300 mb-2">Include Coding Test</label>
                </div>
                {formData.isCodingIncluded && (
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex flex-col gap-2">
                      <label className="block text-sm font-medium text-gray-300">Select a Set<span className="ml-1 text-red-400">*</span></label>
                      <select
                        value={formData.selectedCodingSet}
                        onChange={(e) => updateFormData({ selectedCodingSet: e.target.value })}
                        className="text-gray-400 glass-effect w-full px-3 py-2 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition"
                        style={{ height: "43px" }}
                      >
                        <option value="" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}>
                          Select Coding Set
                        </option>
                        {(formData.codingSets || []).map((set, index) => ( // Fallback to empty array if undefined
                          <option
                            key={index}
                            value={set}
                            style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}
                          >
                            {set}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="block text-sm font-medium text-gray-300">TimeLimit (Mins)<span className="ml-1 text-red-400">*</span></label>
                      <input
                        min="10"
                        type="number"
                        value={formData.CodingTimeLimit}
                        onChange={(e) => updateFormData({ CodingTimeLimit: e.target.value })}
                        placeholder="Coding Time Limit (Mins)"
                        className="glass-effect text-white w-full px-3 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="block text-sm font-medium text-gray-300">Select Judging Criteria<span className="ml-1 text-red-400">*</span></label>
                      <select
                        value={formData.CodingJudgingCriteria}
                        onChange={(e) => updateFormData({ CodingJudgingCriteria: e.target.value })}
                        className="text-gray-400 glass-effect w-full px-3 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition"
                      >
                        <option value="" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}>
                          Select Judging Criteria
                        </option>
                        <option value="1" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}>
                          0
                        </option>
                        <option value="2" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}>
                          1
                        </option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
              {/* Behavioral Test */}
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.isBehavioralIncluded}
                    onChange={handleIncludeBehavioral}
                    className="w-[15px] h-[15px] rounded text-black-600 focus:ring-black-500 mb-2"
                  />
                  <label className="text-sm font-medium text-gray-300 mb-2">Include Behavioral Test</label>
                </div>
                {formData.isBehavioralIncluded && (
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex flex-col gap-2">
                      <label className="block text-sm font-medium text-gray-300">Select a Set<span className="ml-1 text-red-400">*</span></label>
                      <select
                        value={formData.selectedBehavioralSet}
                        onChange={(e) => updateFormData({ selectedBehavioralSet: e.target.value })}
                        className="text-gray-400 glass-effect w-full px-3 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition"
                        style={{ height: "43px" }}
                      >
                        <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}>
                          Select Behavioral Set
                        </option>
    
                      {(formData.behavioralSets || []).map((set, index) => ( // Fallback to empty array if undefined
                        <option
                          key={index}
                          value={set.name}
                          style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}
                        >
                          {set.name}
                        </option>
                      ))}
                      </select>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="block text-sm font-medium text-gray-300">TimeLimit (Mins)<span className="ml-1 text-red-400">*</span></label>
                      <input
                        min="5"
                        max="90"
                        type="number"
                        value={formData.behavioralTimeLimit}
                        onChange={(e) => updateFormData({ behavioralTimeLimit: e.target.value })}
                        placeholder="Behavioral Time Limit (Mins)"
                        className="glass-effect text-white w-full px-3 py-2.5 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition"
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* Assessment Sequence Section */}
              {(formData.isMCQIncluded || formData.isCodingIncluded || formData.isBehavioralIncluded) && (
                <div className="mt-6 space-y-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold text-gray-300 mt-6">Assessment Sequence</h3>
                    <div className="flex items-center text-sm text-gray-300 mt-6">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      Drag to reorder assessments
                    </div>
                  </div>
                  <div className="space-y-3">
                    {formData.sequence && formData.sequence.map((item, index) => (
                      <DraggableAssessmentItem
                        key={item.id}
                        id={item.id}
                        index={index}
                        moveItem={moveItem}
                      >
                        <div
                          className="glass-effect text-white flex items-center space-x-3 p-4 rounded-lg cursor-move bg-white border border-gray-500 hover:border-gray-300 transition-colors duration-200"
                        >
                          <span className={`flex-1 ${item.mandatory ? "font-medium text-pink-400" : "text-gray-300"}`}>
                            {item.label}
                          </span>
                          {item.mandatory && (
                            <span className="px-2 py-1 text-xs font-medium text-pink-700 bg-pink-100 rounded-full">
                              Required
                            </span>
                          )}
                        </div>
                      </DraggableAssessmentItem>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {!sections.sourcing.isChecked && !sections.assessment.isChecked && sections.screening.isChecked ? (
          <div className="mt-6 p-4 bg-red-50 rounded-lg flex items-start gap-3">
            <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 flex-shrink-0" />
            <div>
              <p className="font-medium text-red-800">Invalid combination selected</p>
            </div>
          </div>
        ) : (
          <div className="flex justify-end gap-4">
            <button
              className="button px-6 py-3 rounded-full text-white"
              onClick={() => setCurrentStep(2)}
            >
              <span>Back</span>
            </button>
            <button
              className="button text-white px-6 rounded-full hover:scale-105 transition-transform w-full sm:w-auto"
              onClick={async () => {
                const isValid = await handleFinalize();
                if(isValid) {
                  setCurrentStep(4);
                }
              }}
            >
              <span>Next</span>
            </button>
          </div>
        )}
      </div>
      );
    
    };
  

  const renderStep4 = () => (
    <>
    <div className="flex space-x-6 w-full gap-5">
      <div className="space-y-6">
        <div className="space-y-4">
          <div className="flex items-center space-x-3 mb-3">
            <SparklesIcon className="w-6 h-6 text-pink-500" />
            <h3 className="text-md font-medium text-pink-500">Generated Job Description</h3>
          </div>
          {formData.generatedJD ? (
            <>
              <pre className="border border-gray-500 text-white font-sans text-md whitespace-pre-wrap break-words p-6 rounded-2xl max-h-[500px] overflow-y-auto shadow-inner">
            {`Position: ${formData.jobRole}\nExperience Range: ${formData.minExp} - ${formData.maxExp} years \n\nRequired Skills:\n${
              formData.minExp < 4
                ? formData.generatedJD?.Required_Skills?.Medium?.length
                  ? formData.generatedJD.Required_Skills.Medium.map((skill, i) => `${i + 1}. ${skill}`).join("\n")
                  : "- None"
                : formData.generatedJD?.Required_Skills?.Hard?.length
                ? formData.generatedJD.Required_Skills.Hard.map((skill, i) => `${i + 1}. ${skill}`).join("\n")
                : "- None"
            }\n\nOptional Skills:\n${
              formData.minExp < 4
                ? formData.generatedJD?.Required_Skills?.Easy?.join(", ") || "None"
                : formData.generatedJD?.Required_Skills?.Medium?.join(", ") || "None"
            }\n\n${
              formData.generatedJD?.["Roles & Responsibilities"]?.length
                ? `Roles & Responsibilities:\n${formData.generatedJD["Roles & Responsibilities"]
                    .map((item, i) => `${i + 1}. ${item}`)
                    .join("\n")}`
                : ""
            }\n\n${
              formData.generatedJD?.GoodtoHave?.length
                ? `Good to Have:\n  ${formData.generatedJD.GoodtoHave.join(", ")}\n\n`
                : ""
            }${
              formData.generatedJD?.["Tools & Technologies"]?.length
                ? `Tools & Technologies:\n  ${formData.generatedJD["Tools & Technologies"].join(", ")}\n\n`
                : ""
            }`}
          </pre>
            </>
          ) : (
            <p>No job description generated yet.</p>
          )}
          
        </div>
      </div>
    </div>
    <div className="flex justify-end gap-4 mt-3">
      <button
        className="button px-6 py-3 rounded-full text-white"
        onClick={() => setCurrentStep(3)}
      >
        <span>Back</span>
      </button>
      <button
        className="button px-6 py-3 rounded-full text-white"
        onClick={handleUpdateJD}
      >
        <span>Submit</span>
      </button>
  </div>
  </>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="p-8">
        <div className="table-container glass-effect">

          {renderProgressBar()}
          <div className="px-4 mt-10">
            {currentStep === 1 && renderStep1()}
            {currentStep === 2 && renderStep2()}
            {currentStep === 3 && renderStep3()}
            {currentStep === 4 && renderStep4()}
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

export default CreateJD;
