import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardContent, Typography, IconButton, CircularProgress } from '@mui/material'; 
import { GetApp } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import host from '../../global';
import Cookies from 'js-cookie'
import { toast } from 'react-toastify';

export default function MCQTestReport({ name, mcq_percent }) {
  const { interview_id } = useParams();
  const [mcqTestResultData, setMCQTestResultData] = useState({});
  const [loading, setLoading] = useState(true);
  const reportRef = useRef();
  const accessToken = Cookies.get("accessToken")

  useEffect(() => {
    const displayMCQTestResult = async () => {
      try {
        const response = await axios.get(`${host}/get_mcqs_test_result`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            interview_id: interview_id,
          },
        });
        setMCQTestResultData(response.data);
        setLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
        setLoading(false);
      }
    };
    displayMCQTestResult();
  }, [interview_id,accessToken]);

  const handleDownloadPDF = useCallback(async () => {
    try {
      // Calculate formatted percent
      const calculatedPercent = typeof mcq_percent === 'number'
        ? mcq_percent.toFixed(2)
        : (parseFloat(mcq_percent) || 0).toFixed(2);

      // Convert time_taken to seconds if it's in "MM:SS" format
      const timeTakenSeconds = mcqTestResultData.time_taken
        ? parseInt(mcqTestResultData.time_taken.split(':')[0]) * 60 + parseInt(mcqTestResultData.time_taken.split(':')[1])
        : 0;

      // Create PDF document
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true,
      });

      // Define PDF dimensions
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 10;
      const contentWidth = pageWidth - 2 * margin;

      // Start position
      let currentY = margin;
      let pageCount = 1;

      // Add header to first page
      pdf.setFontSize(16);
      pdf.setTextColor(0, 0, 0);
      pdf.text(`MCQ Test Report - ${name}`, margin, currentY);
      currentY += 10;

      // Add some basic info
      pdf.setFontSize(10);
      pdf.text(`Date: ${mcqTestResultData.startDateTime || new Date().toLocaleDateString()}`, margin, currentY);
      currentY += 5;
      pdf.text(`Time Taken: ${timeTakenSeconds || 0}s`, margin, currentY);
      currentY += 5;
      pdf.text(`Score: ${calculatedPercent}%`, margin, currentY);
      currentY += 10;

      pdf.setFont('Helvetica', 'normal');

      // Helper function to add a new page
      const addNewPage = () => {
        pdf.addPage();
        pageCount++;
        currentY = margin;

        // Add header to new page
        pdf.setFontSize(10);
        pdf.text(`MCQ Test Report - ${name} (Page ${pageCount})`, margin, currentY);
        currentY += 10;
      };

      // Process MCQ Test Details
      pdf.setFontSize(14);
      pdf.text('MCQ Test Details', margin, currentY);
      currentY += 10;

      // Draw a divider line
      pdf.setDrawColor(200, 200, 200);
      pdf.line(margin, currentY, pageWidth - margin, currentY);
      currentY += 5;

      // Process each question
      if (mcqTestResultData.mcqs && Array.isArray(Object.values(mcqTestResultData.mcqs))) {
        Object.values(mcqTestResultData.mcqs).forEach((question, index) => {
          // Check if we need a new page
          if (currentY > pageHeight - 70) {
            addNewPage();
          }

          // Add question title
          pdf.setFontSize(12);
          pdf.setTextColor(0, 0, 100);
          const splitQuestion = pdf.splitTextToSize(`Question ${index + 1}: ${question.question}`, contentWidth);
          if (currentY + splitQuestion.length * 5 > pageHeight - 30) {
            addNewPage();
          }
          pdf.text(splitQuestion, margin, currentY);
          currentY += splitQuestion.length * 5 + 2;

          // Add options
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0);
          const options = [question.option1, question.option2, question.option3, question.option4].filter(Boolean);
          options.forEach((option, optIndex) => {
            const splitOption = pdf.splitTextToSize(`${String.fromCharCode(65 + optIndex)}. ${option}`, contentWidth - 10);
            if (currentY + splitOption.length * 5 > pageHeight - 30) {
              addNewPage();
            }
            pdf.text(splitOption, margin + 5, currentY);
            currentY += splitOption.length * 5 + 2;
          });

          // Add correct answer
          if (question.answer) {
            const splitCorrect = pdf.splitTextToSize(`Correct Answer: ${question.answer}`, contentWidth - 10);
            if (currentY + splitCorrect.length * 5 > pageHeight - 30) {
              addNewPage();
            }
            pdf.text(splitCorrect, margin + 5, currentY);
            currentY += splitCorrect.length * 5 + 2;
          }

          // Add user answer
          if (question.given_answer) {
            const splitUser = pdf.splitTextToSize(`Your Answer: ${question.given_answer}`, contentWidth - 10);
            pdf.setFont('Helvetica', 'bold');
            if(question.score === 1) {
              pdf.setTextColor(0, 128, 0);
            } else {
              pdf.setTextColor(255, 0, 0);
            }
            if (currentY + splitUser.length * 5 > pageHeight - 30) {
              addNewPage();
            }
            pdf.text(splitUser, margin + 5, currentY);
            pdf.setTextColor(0, 0, 0);
            pdf.setFont('Helvetica', 'normal');
            currentY += splitUser.length * 5 + 2;
          }

          // Add score (or "Not Attempted" in red and bold if no given_answer)
          if (question.score !== undefined && question.score !== null) {
            pdf.text(`Score: ${question.score || 0}`, margin + 5, currentY);
            currentY += 5;
          } else if (!question.given_answer) {
            if (currentY > pageHeight - 30) {
              addNewPage();
            }
            pdf.setTextColor(255, 0, 0); // Red color
            pdf.setFont('Helvetica', 'bold'); // Bold font
            pdf.text('Not Attempted', margin + 5, currentY);
            pdf.setTextColor(0, 0, 0); // Reset to black
            pdf.setFont('Helvetica', 'normal'); // Reset to normal font
            currentY += 10;
          }

          // Add space between questions
          currentY += 10;

          // Add a separator line between questions
          if (index < Object.values(mcqTestResultData.mcqs).length - 1) {
            pdf.setDrawColor(200, 200, 200);
            pdf.line(margin, currentY - 5, pageWidth - margin, currentY - 5);
          }
        });
      }

      // Add page numbers to footer of each page
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(100, 100, 100);
        pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 25, pageHeight - 10);
      }

      // Save the PDF
      pdf.save(`${name}_MCQ_Test_Report.pdf`);
      toast.success('PDF successfully generated!');
    } catch (error) {
      console.error('PDF generation error:', error);
      toast.error(`Error generating PDF: ${error.message || 'Unknown error'}`);
    }
  }, [name, mcq_percent, mcqTestResultData, accessToken]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ margin: '0 auto', padding: '16px' }} ref={reportRef}>
      <Card elevation={3}>
        <CardContent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '5px',
            borderBottom: '1px solid #e0e0e0',
          }}
        >
        <Typography variant="h5" fontWeight="bold" color="white">
          MCQ Test Report -  
          <Typography variant="h6" fontWeight="normal" color="white" style={{ display: 'inline', marginLeft:'5px' }}>
            {name}
          </Typography>
        </Typography>

          <IconButton onClick={handleDownloadPDF} className="no-print">
            <GetApp style={{color:"white"}}/>
          </IconButton>
        </CardContent>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingBottom: '10px' }}>
            <Typography variant="body1" color="white">
              Percent Score: {mcq_percent || '0'}%
            </Typography>
            <Typography variant="body1" color="white">
              Time span: {mcqTestResultData.time_taken || '0'}s
            </Typography>
            <Typography variant="body1" color="white">
              {mcqTestResultData.startDateTime || "NA"}
            </Typography>
          </div>

          {mcqTestResultData.mcqs &&
            Object.keys(mcqTestResultData.mcqs).map((key, index) => {
              const questionData = mcqTestResultData.mcqs[key];
              return (
                <div key={index} style={{ marginTop: '16px',paddingBottom:'10px',borderBottom: '1px solid #A09F9F' }}>
                  <Typography variant="subtitle1" fontWeight="normal" color="white">
                  <pre
                      style={{
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        margin: 0,
                        fontFamily:'Poppins',
                        color: "white"
                      }}
                    >
                      {index + 1}. {questionData.question}
                    </pre>                  </Typography>
                  <div style={{ marginLeft: '16px', marginTop: '8px' }}>
                    {[questionData.option1, questionData.option2, questionData.option3, questionData.option4].map(
                      (option, optIndex) => {
                        const isCorrect = option === questionData.answer;
                        const isGivenAnswer = option === questionData.given_answer;

                        return (
                          <div
                            key={optIndex}
                            style={{
                              padding: '8px',
                              borderRadius: '4px',
                              backgroundColor: isGivenAnswer ? (isCorrect ? 'rgb(146, 198, 158)' : 'rgb(239, 156, 165)') : 'rgba(229, 229, 229, 0.5)',
                              border: isCorrect
                                ? '1px solid rgb(146, 198, 158)'
                                : isGivenAnswer
                                ? '1px solid rgb(239, 156, 165)'
                                : '1px solid #dee2e6',
                              marginBottom: '4px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              overflowWrap: 'break-word'
                            }}
                          >
                            {option}
                            {isGivenAnswer && (isCorrect ? <DoneIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />)}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
        </CardContent>
      </Card>
    </div>
  );
}
