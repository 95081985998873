import React, { useEffect, useState } from "react";
import { Authenticator, button } from "@aws-amplify/ui-react";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import host from "../../global";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "../../css/orgadmin.css";
import { PlusCircle, Settings, Edit2, Search } from "lucide-react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Loader from "../Loader";
const CreatedJDs = () => {
  const [jobDescriptions, setJobDescriptions] = useState([]);
  const [showEditJDForm, setShowEditJDForm] = useState(false);
  const [jobRole, setJobRole] = useState("");
  const [jobdesc, setJobdesc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const accessToken = Cookies.get("accessToken");
  const [jd_id, setjd_id] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    const filtered = jobDescriptions.filter(job =>
      job.jobrole.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredJobs(filtered);
  }, [searchQuery, jobDescriptions]);
  const handleEditAssessment = (jobId) => {
    navigate('/org-admin/configurations', {
      state: {
        jobId: jobId,
        jobRole: jobRole,
        expandConfig: true
      },
    });
  };
  const fetchCombinedData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${host}/orgadmin/get_jd_data`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data.job_descriptions);
      setJobDescriptions(data.job_descriptions);
      // setFilteredJobs(data.job_descriptions);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchCombinedData();
  }, []);
  const handleAddJD = () => {
    navigate(`/org-admin/createaijd`);
  };
  const handleDeleteJD = async (e, jd_id) => {
    e.preventDefault();
    if (
      window.confirm(`Are you sure you want to delete the ${jobRole} job description?`)
    ) {
      try {
        const response = await axios.delete(`${host}/orgadmin/deletejd`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            jd_id: jd_id,
          },
        });
        if (response.status === 200) {
          toast.success("JD Deleted Successfully!");
          await fetchCombinedData();
          setShowEditJDForm(false);
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    }
  };
  const handleEditJDRequest = async (jdId) => {
    try {
      const response = await axios.get(`${host}/getjd`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          jd_id: jdId,
        },
      });
      setShowEditJDForm(true);
      if (response) {
        setjd_id(jdId);
        setJobRole(response.data.jobrole);
        setJobdesc(response.data.description);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  const handleViewCandidates = (jobId, selectedServices, jobrole) => {
    console.log(selectedServices);
    const services = Object.keys(selectedServices).filter(
      (service) => selectedServices[service]
    );
    navigate(`/org-admin/candidatelist`, {
      state: { jobId: jobId, services: services, jobrole: jobrole },
    });
  };
  const formatJobDescription = (jobrole, data) => {
    const experience = (data.minExp + data.maxExp) / 2;
    const result = (
      <pre className="font-sans glass-effect text-md whitespace-pre-wrap break-words p-6 rounded-2xl max-h-[500px] overflow-y-auto shadow-inner text-white ">
        {`Position: ${jobrole}\nExperience Range: ${data.minExp} - ${data.maxExp
          } years \n\nRequired Skills:\n${experience < 4
            ? data.Required_Skills?.Medium?.length
              ? data.Required_Skills.Medium.map(
                (skill, i) => `${i + 1}. ${skill}`
              ).join("\n")
              : "- None"
            : data.Required_Skills?.Hard?.length
              ? data.Required_Skills.Hard.map(
                (skill, i) => `${i + 1}. ${skill}`
              ).join("\n")
              : "- None"
          }\n\nOptional Skills:\n${experience < 4
            ? data.Required_Skills?.Easy?.join(", ") || "None"
            : data.Required_Skills?.Medium?.join(", ") || "None"
          }\n\n${data.Roles_and_Responsibilities?.length
            ? `Roles & Responsibilities:\n${data.Roles_and_Responsibilities.map(
              (item, i) => `${i + 1}. ${item}`
            ).join("\n")}`
            : ""
          }\n\n${data.Tools_and_Technologies?.length
            ? `Tools & Technologies:\n  ${data.Tools_and_Technologies.join(
              ", "
            )}\n\n`
            : ""
          }${data.GoodtoHave?.length
            ? `Good to Have:\n  ${data.GoodtoHave.join(", ")}\n\n`
            : ""
          }`}
      </pre>
    );
    return result;
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Authenticator>
        <div className="p-8">
          <div className="min-h-screen">
            {showEditJDForm && (
              <div
                className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-5 backdrop-blur-xl z-50`}
              >
                <div className="bg-gradient-to-br from-[#22346140] to-[#0b0a1f0d] rounded-lg shadow-lg backdrop-blur-[500px] w-full max-w-3xl p-6 relative">
                  <button
                    onClick={() => setShowEditJDForm(false)}
                    className="text-black hover:text-opacity-80 text-white"
                  >
                    ✖
                  </button>
                  <div className="p-6 space-y-6">
                    {jobdesc && (
                      <pre className="font-sans whitespace-pre-wrap backdrop-blur-[500px]">
                        {formatJobDescription(jobRole, jobdesc)}
                      </pre>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-center items-center">
              <div className="table-container glass-effect  min-h-screen">
                <div className="table-header">
                  <h1 className="mx-auto text-white tracking-tight">
                    Job Roles
                  </h1>
                  <div className="flex items-center justify-between mb-4">
                    <div className="relative w-64">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Search className="h-5 w-5 text-gray-400 pl-4 " />
                      </div>
                      <input
                        type="text"
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-full leading-5 bg-white bg-opacity-0 placeholder-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-white"
                        placeholder="Search by Job Role"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => navigate("/org-admin/configurations")}
                        className="button flex items-center gap-2"
                        style={{
                          float: "right",
                          marginBottom: "16px",
                        }}
                      >
                        <span className="flex gap-2">
                          <Settings className="w-5 h-5" />
                          Configurations
                        </span>
                      </button>
                      <button
                        onClick={handleAddJD}
                        className="button flex items-center gap-2"
                        style={{
                          float: "right",
                          marginBottom: "16px",
                        }}
                      >
                        <span className="flex gap-2">
                          <PlusCircle className="w-5 h-5" />
                          Add New JD
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    {filteredJobs.length > 0 ? (
                      <Table>
                        <Thead>
                          <Tr>
                            <Th
                              style={{ textAlign: "left", width: "180px"}}
                            >
                              Created Date
                            </Th>
                            <Th style={{width: "270px"}}>Job Role</Th>
                            <Th>Experience Range</Th>
                            <Th style={{ textAlign: "left" }}>
                              Services Selected{" "}
                            </Th>
                            <Th
                              style={{ textAlign: "center"}}
                            >
                              Action
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {filteredJobs.map((jd) => (
                            <Tr key={jd.jd_id}>
                              <Td
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "left",
                                  paddingLeft: "25px",
                                  width: "180px"
                                }}
                              >
                                <strong>{jd.postingDate}</strong>
                              </Td>
                              <Td
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "left",
                                  paddingLeft: "25px",
                                  width: "270px"
                                }}
                                key={jd.jdid}
                                onClick={() => handleEditJDRequest(jd.jd_id)}
                                className="cursor-pointer"
                              >
                                {jd.jobrole}
                              </Td>
                              <Td
                                style={{
                                  whiteSpace: "normal",
                                  textAlign: "left",
                                  paddingLeft: "30px",
                                }}
                              >
                                {jd.description &&
                                  jd.description.minExp !== undefined &&
                                  jd.description.maxExp !== undefined
                                  ? `${jd.description.minExp} - ${jd.description.maxExp} yrs`
                                  : "0-1 yrs"}
                              </Td>
                              <Td
                                style={{ textAlign: "left", paddingLeft: "30px" }}
                              >
                                {jd.selectedServices
                                  ? Object.keys(jd.selectedServices)
                                    .filter(
                                      (service) => jd.selectedServices[service]
                                    )
                                    .map((service, index) => (
                                      <span key={index}>
                                        -{" "}
                                        {service.charAt(0).toUpperCase() +
                                          service.slice(1)}
                                        <br />
                                      </span>
                                    ))
                                  : "- Assessment"}
                              </Td>
                              <Td>
                                <div className="flex gap-3 items-center justify-end">
                                  <button
                                    onClick={() =>
                                      handleViewCandidates(jd.jd_id, jd.selectedServices, jd.jobrole)
                                    }
                                    className={`group button relative px-1 py-2.5 flex-1 max-w-[150px] ${jd.selectedServices &&
                                      (jd.selectedServices["sourcing"] || jd.selectedServices["screening"])
                                      ? ""
                                      : "invisible"
                                      }`}
                                  >
                                    <span className="flex gap-2 items-center">
                                      {/* <svg
                                        className="w-4 h-4 !text-white transition-transform duration-300 group-hover:scale-110 group-hover:text-white"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="white"
                                      >
                                        <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                      </svg> */}
                                      Candidates
                                    </span>
                                  </button>
                                  <button
                                    onClick={() => handleEditAssessment(jd.jd_id)}
                                    className={`group relative px-1 py-2.5 flex-1 max-w-[100px] ${jd.selectedServices && jd.selectedServices["assessment"] ? "" : "invisible"
                                      }`}
                                  >
                                    <span className="flex gap-2 items-center">
                                      <Edit2 className="w-4 h-4" />
                                    </span>
                                  </button>
                                  <IconButton
                                    onClick={(e) => handleDeleteJD(e, jd.jd_id)}
                                    color="error"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    ) : (
                      <div className="text-white">
                        {searchQuery ? "No matching job descriptions found" : "No Job Descriptions Found"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Authenticator>
    </>
  );
};
export default CreatedJDs;

