import React from "react";
import { Home, LogOut, BarChart2, Briefcase } from "lucide-react";
import { useNavigate, Link } from "react-router-dom";
import { signOut } from "@aws-amplify/auth";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const SidebarIcon = ({ icon: Icon, label, path, onClick }) => (
  <Link
    to={path}
    className="flex w-full text-white cursor-pointer hover:bg-white/5 rounded-md transition-all"
    onClick={onClick}
  >
    <div className="flex flex-col items-center w-full gap-1 p-2">
      <Icon className="w-6 h-6" />
      <span className="text-[10px] font-light text-center w-full leading-tight opacity-80">
        {label}
      </span>
    </div>
  </Link>
);

const Sidebar = () => {
  const navigate = useNavigate();
  const handleSignOut = async () => {
    try {
      await signOut();
      Cookies.remove("accessToken");
      navigate("/");
    } catch (error) {
      console.error("Error signing out", error);
      toast.error("Error signing out");
    }
  };

  const sidebarItems = [
    { icon: Home, label: "Dashboard", path: "dashboard" },
    { icon: BarChart2, label: "Reports", path: "candidatereports" },
    { icon: Briefcase, label: "Job Library", path: "jobvacancies" },
  ];

  return (
    <div
      className="
        bg-app-background shadow-[4px_0_10px_-3px_rgba(0,0,0,0.7)] 
        transition-all duration-300 ease-in-out w-16 overflow-hidden flex flex-col items-center py-4"
    >
      <div className="flex flex-col gap-4 items-center justify-center py-2">
        <Link to="/hr/dashboard">
          <img
            src="/Homepagelogo.png"
            alt="Logo"
            style={{ height: "34px", width: "24px" }}
          />
        </Link>
        <div className="w-full px-2 space-y-2">
          {sidebarItems.map((item, index) => (
            <SidebarIcon
              key={index}
              icon={item.icon}
              label={item.label}
              path={item.path}
            />
          ))}
        </div>
        <div className="mt-auto px-2 w-full">
          <SidebarIcon
            icon={LogOut}
            label="Sign Out"
            path="#"
            onClick={handleSignOut}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
