import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, IconButton } from "@mui/material";
import { Tooltip } from '@mui/material';
import { CircleUserRound } from "lucide-react"
import { Bell } from "lucide-react";
import { useNavigate, Link } from "react-router-dom";
import "../../css/HRPortal.css";
import axios from "axios";
import host from "../../global";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { signOut } from "@aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";
import { LogOut, UserPen } from "lucide-react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [notification, setNotification] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);
  const [isMCQDropdownOpen, setIsMCQDropdownOpen] = useState(false);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const accessToken = Cookies.get("accessToken");
  const popupRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const availablePages = [
    { name: "Home", route: "/org-admin/dashboard" },
    { name: "Candidate Reports", route: "/org-admin/candidatereports" },
    { name: "Candidate Analytics", route: "/org-admin/candidateanalytics" },
    { name: "Profile", route: "/org-admin/org-admin-editprofile" },
    { name: "Resume Parser", route: "/org-admin/checkresume" },
    { name: "Create JD", route: "/org-admin/createaijd" },
    { name: "HR Profiles", route: "/org-admin/hrprofiles" },
    { name: "View JDs", route: "/org-admin/created-jds" },
  ];
  const [isTicketButtonHovered, setIsTicketButtonHovered] = useState(false);
  const ticketModalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isTicketModalOpen &&
        ticketModalRef.current &&
        !ticketModalRef.current.contains(event.target)
      ) {
        setIsTicketModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTicketModalOpen]);
  const fetchProfileData = async () => {
    try {
      const { userId } = await getCurrentUser();
      setUserId(userId);
      const response = await axios.get(`${host}/getadmindetails`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching Profile Data", error);
    }
  };
  useEffect(() => {
    fetchProfileData();
  }, [accessToken]);
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out", error);
      toast.error("Error signing out");
    }
  };
  const profileImageUrl = userData?.profile
    ? `${process.env.REACT_APP_PROFILE_PHOTOS_HOST}/OrganizationAdmins/${userId}_${userData.profile}`
    : "";
  const toggleTicketModal = () => setIsTicketModalOpen(!isTicketModalOpen);
  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${host}/raiseticket`,
        {},
        {
          params: { title: ticketTitle, description: ticketDescription },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Ticket Submitted Successfully");
        setTicketTitle("");
        setTicketDescription("");
        toggleTicketModal();
      } else {
        toast.error("Error submitting ticket:", response.statusText);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filteredSuggestions = availablePages.filter((page) =>
        page.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setNoResults(filteredSuggestions.length === 0);
    } else {
      setSuggestions([]);
      setNoResults(false);
    }
  };
  const handleMouseEnter = () => {
    setIsPopupVisible(true);
  };
  const handleMouseLeave = () => {
    setIsPopupVisible(false);
  };
  const handleSuggestionClick = (route) => {
    navigate(route);
    setSearchQuery("");
    setSuggestions([]);
  };
  const handleNotificationBellClick = async (event) => {
    fetchPendingAccountRequest();
    setIsPopupVisible(!isPopupVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isActionsDropdownOpen && !event.target.closest(".dropdown")) {
        setIsActionsDropdownOpen(false);
      }
      if (isMCQDropdownOpen && !event.target.closest(".mcqdropdown-menu")) {
        setIsMCQDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActionsDropdownOpen, isMCQDropdownOpen]);
  const fetchPendingAccountRequest = async () => {
    try {
      const response = await axios.get(`${host}/pendingaccounts`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setNotification(response.data.results);
    } catch (error) {
      console.error("Error fetching Profile Data", error);
    }
  };
  return (
    <>
      <header className="main-header flex justify-between items-center py-3 px-8 flex-wrap box-border">
        <nav>
          {/* <button
            onClick={() => navigate(`/org-admin/dashboard`)}
            className={`${location.pathname.includes('dashboard')
              ? 'text-transparent bg-gradient-to-r from-pink-500 to-blue-500 bg-clip-text relative'
              : 'text-white'
              }`}
          >
            Home
            {location.pathname.includes('dashboard') && (
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-3/5 h-0.5 bg-gradient-to-r from-pink-500 to-blue-500"></span>
            )}
          </button>

          <button
            onClick={() => navigate(`/org-admin/candidatereports`)}
            className={`${location.pathname.includes('candidatereports')
              ? 'text-transparent bg-gradient-to-r from-pink-500 to-blue-500 bg-clip-text relative'
              : 'text-white'
              }`}
          >
            Candidate Reports
            {location.pathname.includes('candidatereports') && (
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-4/5 h-0.5 bg-gradient-to-r from-pink-500 to-blue-500"></span>
            )}
          </button>
          <button
            onClick={() => navigate(`/org-admin/created-jds`)}
            className={`${location.pathname.includes('created-jds')
              ? 'text-transparent bg-gradient-to-r from-pink-500 to-blue-500 bg-clip-text relative'
              : 'text-white'
              }`}
          >
            Job Library
            {location.pathname.includes('created-jds') && (
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-4/5 h-0.5 bg-gradient-to-r from-pink-500 to-blue-500"></span>
            )}
          </button>
      <button onClick={() => navigate("/org-admin/testlibrary")}
        className={`${(location.pathname.includes('testlibrary') || location.pathname.includes('createmcqset') || location.pathname.includes('createcodingproblemset'))
          ? 'text-transparent bg-gradient-to-r from-pink-500 to-blue-500 bg-clip-text relative'
          : 'text-white'
          }`}>
        Test Library
        {(location.pathname.includes('testlibrary') || location.pathname.includes('createmcqset') || location.pathname.includes('createcodingproblemset')) && (
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-4/5 h-0.5 bg-gradient-to-r from-pink-500 to-blue-500"></span>
        )}
      </button> */}
        </nav>
        <div className="main-header-actions flex items-center gap-4 onMouseEnter={() => setIsTicketButtonHovered(true)}
            onMouseLeave={() => setIsTicketButtonHovered(false)}">

          <button className="py-2 px-4 bg-gradient-to-l from-[#FB54AE] to-[#2F64FF] rounded-full border-none !border-none text-white hover:border-[1.8px] !hover:border-[1.8px] hover:border-solid !hover:border-solid hover:border-[#FF60B6] !hover:border-[#FF60B6] transition-all"
            onClick={() => setIsTicketModalOpen(true)}>
            <span>Raise Ticket</span>
        </button>

          {noResults && <p>No results found</p>}
          <div className="relative">
            <IconButton className="hover:text-white" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}> <Bell size={28} color="white" /></IconButton>
            {notification.length > 0 && (
                  <span className="absolute top-1 right-1 flex items-center justify-center 
                                  w-5 h-5 bg-red-600 text-white text-xs font-bold 
                                  rounded-full border-2 border-gray-900">
                    {notification.length}
                  </span>
            )}
          {isPopupVisible && (
            <div ref={popupRef} className="bg-gradient-to-br from-[rgba(34,52,97,0.25)] to-[rgba(11,10,31,0.05)] backdrop-blur-[50px] rounded-2xl shadow-2xl fixed right-4 top-16 w-80 z-[100]" style={{ border: "solid 0.2px purple" }}>
              {Array.isArray(notification) && notification.length > 0 ? (
                <div className="glass-effect max-h-64 overflow-y-auto">
                  <ul className="divide-y divide-gray-300">
                    {notification.map((name, index) => (
                      <li
                        key={index}
                        className="cursor-pointer hover:bg-gray-50 transition-colors duration-200 p-4"
                      >
                        <div className="flex items-center space-x-3">
                          <div className="text-green-500">
                            <CheckCircleOutlineRoundedIcon
                              size={24}
                              className="flex-shrink-0"
                            />
                          </div>
                          <div className="flex-grow">
                            <p className="text-sm text-gray-700">
                              {index + 1}. {name}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="p-4 text-center text-gray-500">
                  No pending approvals.
                </div>
              )}
            </div>
            
          )}
          </div>
          <div className="group relative ml-2">
            <img
              className="w-[30x] h-[30px] text-white rounded-full border-1 border-white"
              src="/user.png"
            />
            <div className="hidden group-hover:flex group-hover:items-start absolute top-9 right-0 bg-none z-50 p-1 flex-col">
              <div className="w-[250px] glass-effect rounded-2xl shadow-2xl overflow-hidden" style={{ border: "solid 0.2px purple" }}>
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-purple-400/20 opacity-75"></div>
                  {userData ? (
                    <div className="bg-gradient-to-br from-[rgba(34,52,97,0.25)] to-[rgba(11,10,31,0.05)] backdrop-blur-[50px]">
                      <div className="relative z-10 flex flex-col sm:flex-col items-center p-2">
                        {profileImageUrl ? (
                          <img
                            className="w-[110px] h-[110px] rounded-full border-4 border-white shadow-lg object-cover"
                            src={profileImageUrl}
                            alt={userData?.name ? userData.name[0].toUpperCase() : "Profile Image"}
                          />
                        ) : (
                          <img
                            className="w-[90px] h-[90px] rounded-full border-1 border-white shadow-lg object-cover p-4 mb-2"
                            src="/user.png"
                          />
                        )}
                        <div className="flex flex-col gap-2 justify-center items-center">
                          <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500">
                            {userData?.name || "No Name Available"}
                          </h2>
                          <p className="text-lg text-white/90 font-medium -mt-2">
                            Organization Admin
                          </p>
                          <div className="flex justify-center sm:justify-start gap-6 p-2 mb-2">
                            <UserPen
                              className="text-blue-600 bg-blue-100 rounded-full p-2 w-10 h-10 hover:bg-blue-200 hover:text-blue-700 transition-all duration-300 cursor-pointer"
                              onClick={() => navigate(`/org-admin/editprofile`)}
                            />
                            <LogOut
                              className="text-red-600 bg-red-100 rounded-full p-2 w-10 h-10 hover:bg-red-200 hover:text-red-700 transition-all duration-300 cursor-pointer"
                              onClick={handleSignOut}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div class="relative flex w-64 animate-pulse gap-2 p-4">
                      <div class="h-12 w-12 rounded-full bg-slate-400"></div>
                      <div class="flex-1">
                        <div class="mb-1 h-5 w-3/5 rounded-lg bg-slate-400 text-lg"></div>
                        <div class="h-5 w-[90%] rounded-lg bg-slate-400 text-sm"></div>
                      </div>
                      <div class="absolute bottom-5 right-0 h-4 w-4 rounded-full bg-slate-400"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>


        {isTicketModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-[99999999]">
            <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg glass-effect"
              ref={ticketModalRef}
              style={{
                background: 'linear-gradient(to bottom right, rgba(34,52,97,0.25), rgba(11,10,31,0.05))',
                backdropFilter: 'blur(50px)',
                WebkitBackdropFilter: 'blur(50px)', 
                borderRadius: '10px'
              }}
              >
              <h3 className="text-xl font-semibold mb-4 text-center text-white">
                Raise a Ticket
              </h3>
              <select
                value={ticketTitle}
                onChange={(e) => setTicketTitle(e.target.value)}
                className="w-full p-2 mb-4 border rounded-lg focus:border-blue-500 text-gray-400"
                style={{
                  background:
                    "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                  backdropFilter: "blur(500px)",
                }}
              >
                <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value=""  >Select ticket title</option>
                {[
                  "Issue with Job Description",
                  "Technical Issue",
                  "HR Profile Issue",
                  "Host Coding problem",
                  "Other",
                ].map((option) => (
                  <option key={option} value={option} style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}>
                    {option}
                  </option>
                ))}
              </select>
              <textarea
                value={ticketDescription}
                onChange={(e) => setTicketDescription(e.target.value)}
                placeholder="Enter ticket description"
                rows="5"
                className="w-full p-2 mb-4 border rounded-lg focus:border-blue-500 text-white"
                style={{
                  background:
                    "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                  opacity: 1,
                  backdropFilter: "blur(500px)",
                }}
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                  Submit
                </button>
                <button
                  // onClick={toggleTicketModal}
                  onClick={() => setIsTicketModalOpen(false)}
                  className="bg-gray-300 px-4 py-2 rounded-lg hover:bg-gray-400"
                >
                  Close
                </button>
              </div>
            </div>
          </div>


        )}

      </header>

    </>
  );
};
export default Header;
