import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { GetApp } from "@mui/icons-material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { useParams } from "react-router-dom";
import host from "../../global";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export default function CodingTestReport({ name, coding_percent }) {
  const { interview_id } = useParams();
  const [problems, setProblems] = useState([]);
  const [codeSubmissions, setCodeSubmissions] = useState([]);
  const [timeTaken, setTimeTaken] = useState(0);
  const [startDateTime, setStartDateTime] = useState("");
  const [loading, setLoading] = useState(true);
  const reportRef = useRef();
  const [selectedProblemId, setSelectedProblemId] = useState(null);
  const handleToggleDetails = (problemId) => {
    setSelectedProblemId(selectedProblemId === problemId ? null : problemId);
  };
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    const fetchProblems = async () => {
      try {
        const response = await axios.get(
          `${host}/api/problems_from_codesubmissions`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              interview_id: interview_id,
            },
          }
        );
        setProblems(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };

    const fetchCodeSubmissions = async () => {
      try {
        const response = await axios.get(`${host}/api/get_codesubmissions`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            interview_id: interview_id,
          },
        });
        setCodeSubmissions(response.data.submissions);
        setTimeTaken(response.data.time_taken);
        setStartDateTime(response.data.startDateTime);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };

    const displayCodingTestResults = async () => {
      setLoading(true);
      await fetchProblems();
      await fetchCodeSubmissions();
      setLoading(false);
    };

    displayCodingTestResults();
  }, [interview_id, accessToken]);

  const handleDownloadPDF = useCallback(async () => {
    try {
      // Calculate formatted percent
      const calculatedPercent = typeof coding_percent === "number"
        ? coding_percent.toFixed(2)
        : (parseFloat(coding_percent) || 0).toFixed(2);
  
      // Create PDF document
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true,
      });
  
      // Define PDF dimensions
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 10;
      const contentWidth = pageWidth - 2 * margin;
  
      // Start position
      let currentY = margin;
      let pageCount = 1;
  
      // Add header to first page
      pdf.setFontSize(16);
      pdf.setTextColor(0, 0, 0);
      pdf.text(`Coding Test Report - ${name}`, margin, currentY);
      currentY += 10;
  
      // Add some basic info
      pdf.setFontSize(10);
      pdf.text(`Date: ${startDateTime || new Date().toLocaleDateString()}`, margin, currentY);
      currentY += 5;
      pdf.text(`Time Taken: ${timeTaken || 0}s`, margin, currentY);
      currentY += 5;
      if (calculatedPercent > 0)
        pdf.text(`Score: ${calculatedPercent}%`, margin, currentY);
      currentY += 10;
  
      pdf.setFont("Helvetica", "normal");
  
      // Helper function to add a new page
      const addNewPage = () => {
        pdf.addPage();
        pageCount++;
        currentY = margin;
  
        // Add header to new page
        pdf.setFontSize(10);
        pdf.text(`Coding Test Report - ${name} (Page ${pageCount})`, margin, currentY);
        currentY += 10;
      };
  
      // Process Coding Test Details
      pdf.setFontSize(14);
      pdf.text("Coding Test Details", margin, currentY);
      currentY += 10;
  
      // Draw a divider line
      pdf.setDrawColor(200, 200, 200);
      pdf.line(margin, currentY, pageWidth - margin, currentY);
      currentY += 5;
  
      // Process each problem
      problems.forEach((problem, index) => {
        const submission = codeSubmissions[problem.id] || {};
        const testCases = submission.test_cases_passed || [];
        const isDetailedTestCases = Array.isArray(testCases) && testCases.every(
          (tc) => tc.hasOwnProperty("status_message") && tc.hasOwnProperty("yourOutput") && tc.hasOwnProperty("expectedOutput")
        );
        const acceptedTestCasesCount = isDetailedTestCases
          ? testCases.filter((tc) => tc.status_message === "Accepted").length
          : 0;
        const totalTestCasesCount = isDetailedTestCases ? testCases.length : 0;
  
        // Check if we need a new page
        if (currentY > pageHeight - 70) {
          addNewPage();
        }
  
        // Add problem title
        pdf.setFontSize(12);
        pdf.setTextColor(0, 0, 100);
        pdf.text(`Problem ${index + 1}: ${problem.title}`, margin, currentY);
        currentY += 7;
  
        // Add description
        if (problem.description) {
          const splitDescription = pdf.splitTextToSize(`Description: ${problem.description}`, contentWidth);
          if (currentY + splitDescription.length * 5 > pageHeight - 30) {
            addNewPage();
          }
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0);
          pdf.text(splitDescription, margin, currentY);
          currentY += splitDescription.length * 5 + 5;
        }
  
        // Add input format
        if (problem.input_format) {
          const splitInputFormat = pdf.splitTextToSize(`Input Format: ${problem.input_format}`, contentWidth);
          if (currentY + splitInputFormat.length * 5 > pageHeight - 30) {
            addNewPage();
          }
          pdf.text(splitInputFormat, margin, currentY);
          currentY += splitInputFormat.length * 5 + 5;
        }
  
        // Add output format
        if (problem.output_format) {
          const splitOutputFormat = pdf.splitTextToSize(`Output Format: ${problem.output_format}`, contentWidth);
          if (currentY + splitOutputFormat.length * 5 > pageHeight - 30) {
            addNewPage();
          }
          pdf.text(splitOutputFormat, margin, currentY);
          currentY += splitOutputFormat.length * 5 + 5;
        }
  
        // Add constraints
        if (problem.constraints) {
          const splitConstraints = pdf.splitTextToSize(`Constraints: ${problem.constraints}`, contentWidth);
          if (currentY + splitConstraints.length * 5 > pageHeight - 30) {
            addNewPage();
          }
          pdf.text(splitConstraints, margin, currentY);
          currentY += splitConstraints.length * 5 + 5;
        }
  
        // Add examples
        if (problem.examples?.length > 0) {
          pdf.text("Examples:", margin, currentY);
          currentY += 5;
          problem.examples.forEach((example, exIndex) => {
            if (currentY > pageHeight - 50) {
              addNewPage();
            }
            const splitInput = pdf.splitTextToSize(`Input ${exIndex + 1}: ${example.input}`, contentWidth - 10);
            const splitOutput = pdf.splitTextToSize(`Output ${exIndex + 1}: ${example.output}`, contentWidth - 10);
            const splitExplanation = example.explanation ? pdf.splitTextToSize(`Explanation ${exIndex + 1}: ${example.explanation}`, contentWidth - 10) : [];
  
            if (currentY + (splitInput.length + splitOutput.length + splitExplanation.length) * 5 > pageHeight - 30) {
              addNewPage();
            }
  
            pdf.text(splitInput, margin + 5, currentY);
            currentY += splitInput.length * 5 + 2;
            pdf.text(splitOutput, margin + 5, currentY);
            currentY += splitOutput.length * 5 + 2;
            if (example.explanation) {
              pdf.text(splitExplanation, margin + 5, currentY);
              currentY += splitExplanation.length * 5 + 2;
            }
          });
          currentY += 5;
        }
  
        // Add submission details
        if (submission && submission.language && submission.code) {
          if (currentY > pageHeight - 70) {
            addNewPage();
          }
  
          pdf.text("Submission Details:", margin, currentY);
          currentY += 5;
  
          pdf.text(`Language: ${submission.language}`, margin + 5, currentY);
          currentY += 5;
  
          const splitCode = pdf.splitTextToSize(`Code: ${submission.code}`, contentWidth - 10);
          if (currentY + splitCode.length * 5 > pageHeight - 30) {
            addNewPage();
          }
          pdf.text(splitCode, margin + 5, currentY);
          currentY += splitCode.length * 5 + 5;
  
          pdf.text(`Test Cases Passed: ${acceptedTestCasesCount} / ${totalTestCasesCount}`, margin + 5, currentY);
          currentY += 5;
        } else {
          // Set text to red and bold for "Not Attempted"
          pdf.setTextColor(255, 0, 0); // Red color
          pdf.setFont("Helvetica", "bold"); // Bold font
          pdf.text("Not Attempted", margin + 5, currentY);
          pdf.setTextColor(0, 0, 0); // Reset to black
          pdf.setFont("Helvetica", "normal"); // Reset to normal font
          currentY += 10;
        }
  
        // Add detailed test cases if available
        if (submission && isDetailedTestCases && selectedProblemId === problem.id) {
          if (currentY > pageHeight - 70) {
            addNewPage();
          }
  
          pdf.text("Test Case Details:", margin, currentY);
          currentY += 5;
  
          testCases.forEach((testCase, tcIndex) => {
            if (currentY > pageHeight - 50) {
              addNewPage();
            }
  
            pdf.text(`Test Case ${tcIndex + 1}:`, margin + 5, currentY);
            currentY += 5;
  
            pdf.text(`Status: ${testCase.status_message}`, margin + 10, currentY);
            currentY += 5;
  
            const splitYourOutput = pdf.splitTextToSize(`Your Output: ${testCase.yourOutput}`, contentWidth - 20);
            if (currentY + splitYourOutput.length * 5 > pageHeight - 30) {
              addNewPage();
            }
            pdf.text(splitYourOutput, margin + 10, currentY);
            currentY += splitYourOutput.length * 5 + 2;
  
            const splitExpectedOutput = pdf.splitTextToSize(`Expected Output: ${testCase.expectedOutput}`, contentWidth - 20);
            if (currentY + splitExpectedOutput.length * 5 > pageHeight - 30) {
              addNewPage();
            }
            pdf.text(splitExpectedOutput, margin + 10, currentY);
            currentY += splitExpectedOutput.length * 5 + 2;
  
            if (testCase.stderr) {
              const splitStderr = pdf.splitTextToSize(`Stderr: ${testCase.stderr}`, contentWidth - 20);
              if (currentY + splitStderr.length * 5 > pageHeight - 30) {
                addNewPage();
              }
              pdf.text(splitStderr, margin + 10, currentY);
              currentY += splitStderr.length * 5 + 2;
            }
  
            currentY += 5;
          });
        }
  
        // Add space between problems
        currentY += 10;
  
        // Add a separator line between problems
        if (index < problems.length - 1) {
          pdf.setDrawColor(200, 200, 200);
          pdf.line(margin, currentY - 5, pageWidth - margin, currentY - 5);
        }
      });
  
      // Add page numbers to footer of each page
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(100, 100, 100);
        pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 25, pageHeight - 10);
      }
  
      // Save the PDF
      pdf.save(`${name}_Coding_Test_Report.pdf`);
      toast.success("PDF successfully generated!");
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error(`Error generating PDF: ${error.message || "Unknown error"}`);
    }
  }, [name, coding_percent, problems, codeSubmissions, timeTaken, startDateTime, selectedProblemId]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ margin: "0 auto", padding: "16px" }} ref={reportRef}>
      <Card elevation={3}>
        <CardContent
          style={{
            display: "flex",
            color:"white",
            justifyContent: "space-between",
            backgroundColor: "none",
            paddingBottom: "5px",
            border: "1px solid rgba(229, 229, 229, 0.5)",
            borderRadius:"8px"
          }}
        >
          <Typography variant="h5" fontWeight="bold" color="white">
            Coding Test Report -
            <Typography
              variant="h6"
              fontWeight="normal"
              color="white"
              style={{ display: "inline", marginLeft: "5px" }}
            >
              {name}
            </Typography>
          </Typography>

          <IconButton onClick={handleDownloadPDF} className="no-print">
            <GetApp style={{ color: "white" }} />
          </IconButton>
        </CardContent>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {/* <Typography variant="body1" color="white">
              Percent Score: {coding_percent || "0"}%
            </Typography> */}
            <Typography variant="body1" color="white">
              Time span: {timeTaken || "0"}s
            </Typography>
            <Typography variant="body1" color="white">
              {startDateTime || "NA"}
            </Typography>
          </div>
          {problems.map((problem) => {
            const problemId = problem.id;
            const submission = codeSubmissions[problemId];
            const testCases = submission?.test_cases_passed;

            const isDetailedTestCases =
              Array.isArray(testCases) &&
              testCases.every(
                (tc) =>
                  tc.hasOwnProperty("status_message") &&
                  tc.hasOwnProperty("yourOutput") &&
                  tc.hasOwnProperty("expectedOutput")
              );
            const totalTestCasesCount = isDetailedTestCases
              ? testCases.length
              : 0;
            const acceptedTestCasesCount = isDetailedTestCases
              ? testCases.filter(
                  (testCase) => testCase.status_message === "Accepted"
                ).length
              : 0;

            return (
              <div
                key={problem._id}
                style={{
                  marginTop: "16px",
                  padding: "16px",
                  borderRadius: "8px",
                  border: "1px solid rgba(229, 229, 229, 0.5)",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  paddingBottom: "10px",                  
                }}
              >
                <h3 style={{ margin: 0, fontWeight: "bold", color: "white" }}>
                  Problem Title: {problem.title}
                </h3>
                <p style={{ margin: "8px 0", color: "rgb(255,255,255,0.8)" }}>
                  <strong>Description:</strong> {problem.description}
                </p>
                <p style={{ margin: "8px 0", color: "rgb(255,255,255,0.8)" }}>
                  <strong>Input Format:</strong> {problem.input_format}
                </p>
                <p style={{ margin: "8px 0", color: "rgb(255,255,255,0.8)" }}>
                  <strong>Output Format:</strong> {problem.output_format}
                </p>
                <p style={{ margin: "8px 0", color: "rgb(255,255,255,0.8)" }}>
                  <strong>Constraints:</strong> {problem.constraints}
                </p>
                {problem.examples?.length > 0 && (
                  <div style={{ margin: "8px 0", color: "rgb(255,255,255,0.8)" }}>
                    <strong>Examples:</strong>
                    <ol>
                      {problem.examples.map((example, index) => (
                        <li key={index}>
                          <strong>Input:</strong> <pre>{example.input}</pre>
                          <strong>Output:</strong> <pre>{example.output}</pre>
                          {example.explanation && (
                            <>
                              <strong>Explanation:</strong>{" "}
                              <pre>{example.explanation}</pre>
                            </>
                          )}
                        </li>
                      ))}
                    </ol>
                  </div>
                )}

                {/* {submission && submission.language && submission.code && submission.coding_score !== undefined ? ( */}
                {submission && submission.language && submission.code ? (
                  <div style={{ marginTop: "16px" }}>
                    <p style={{ margin: "8px 0", color: "rgb(255,255,255,0.8)" }}>
                      <strong>Language:</strong> {submission.language}
                    </p>

                    <p style={{ margin: "8px 0", color: "rgb(255,255,255,0.8)" }}>
                      <strong>Code:</strong>
                      <pre
                        style={{
                          margin: "8px 0",
                          padding: "8px",
                          borderRadius: "4px",
                          overflowWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          fontFamily: "Poppins",
                        }}
                      >
                        {submission.code}
                      </pre>
                    </p>

                    {/* <p style={{ margin: '8px 0', color: 'rgb(255,255,255,0.8)' }}>
                      <strong>Score:</strong> {submission.coding_score} / 100
                    </p>
           
                    <p style={{ margin: '8px 0', color: 'rgb(255,255,255,0.8)' }}>
                      <strong>Test Cases Passed:</strong> {acceptedTestCasesCount}
                    </p>
                    <p style={{ margin: '8px 0', color: 'rgb(255,255,255,0.8)' }}>
                      <strong>Total Test Cases:</strong> {totalTestCasesCount}
                    </p>
           
                    <button
                      onClick={() => handleToggleDetails(problemId)}
                      className='no-print'
                      style={{
                        marginTop: '8px',
                        padding: '10px 16px',
                        borderRadius: '4px',
                        backgroundColor: '#007bff',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '16px'
                      }}>
                      {selectedProblemId === problemId ? 'Hide Details' : 'Show Details'}
                    </button>*/}

                    {selectedProblemId === problemId && (
                      <div
                        style={{
                          marginTop: "16px",
                          padding: "16px",
                          backgroundColor: "#f8f9fa",
                          borderRadius: "4px",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        {isDetailedTestCases ? (
                          testCases.map((testCase, index) => (
                            <div key={index} style={{ marginBottom: "16px" }}>
                              <h4 style={{ margin: "0", color: "#343a40" }}>
                                Test Case {index + 1}
                              </h4>
                              <p style={{ margin: "4px 0", color: "rgb(255,255,255,0.8)" }}>
                                <strong>Status:</strong>{" "}
                                {testCase.status_message}
                              </p>
                              <p style={{ margin: "4px 0", color: "rgb(255,255,255,0.8)" }}>
                                <strong>Your Output:</strong>
                              </p>
                              <pre
                                style={{
                                  margin: "8px 0",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  backgroundColor: "#ffffff",
                                  overflowWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {testCase.yourOutput}
                              </pre>
                              <p style={{ margin: "4px 0", color: "rgb(255,255,255,0.8)" }}>
                                <strong>Expected Output:</strong>
                              </p>
                              <pre
                                style={{
                                  margin: "8px 0",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  backgroundColor: "#ffffff",
                                  overflowWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {testCase.expectedOutput}
                              </pre>
                              {testCase.stderr && (
                                <div>
                                  <p
                                    style={{
                                      margin: "4px 0",
                                      color: "rgb(255,255,255,0.8)",
                                    }}
                                  >
                                    <strong>Stderr:</strong>
                                  </p>
                                  <pre
                                    style={{
                                      margin: "8px 0",
                                      padding: "8px",
                                      borderRadius: "4px",
                                      backgroundColor: "#ffffff",
                                      overflowWrap: "break-word",
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {testCase.stderr}
                                  </pre>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>
                            <p style={{ margin: "4px 0", color: "rgb(255,255,255,0.8)" }}>
                              <strong>Remark:</strong>
                            </p>
                            <pre
                              style={{
                                padding: "8px",
                                borderRadius: "4px",
                                backgroundColor: "#f8f9fa",
                                overflowWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                fontFamily: "Poppins",
                              }}
                            >
                              {testCases[0]}
                            </pre>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <p style={{ fontWeight: "bold", color: "red" }}>
                    Not Attempted
                  </p>
                )}
              </div>
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
}