import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import host from "../../global.jsx";
import {
  BrainCog,
  Code2,
  Users,
  Rocket,
  Trophy,
  Timer,
  Lock,
  CheckCircle,
  ChevronRight,
  Target,
  MessageSquare,
} from "lucide-react";
import { toast } from "react-toastify";
export default function Component() {
  const navigate = useNavigate();
  const { interview_id } = useParams();
  const [mcqStatus, setMCQStatus] = useState("Incomplete");
  const [codingStatus, setCodingStatus] = useState("Incomplete");
  const [interviewStatus, setInterviewStatus] = useState("Incomplete");
  const [mcqTimeLimit, setMCQTimeLimit] = useState(0);
  const [codingTimeLimit, setCodingTimeLimit] = useState(0);
  const [interviewTimeLimit, setInterviewTimeLimit] = useState(0);
  const [behavioralStatus, setBehavioralStatus] = useState("Incomplete");
  const [behavioralTimeLimit, setBehavioralTimeLimit] = React.useState("");
  const [assessmentSequence, setAssessmentSequence] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const totalAssessments = [mcqStatus, codingStatus, interviewStatus, behavioralStatus].filter(
    (status) => status !== "NA"
  ).length;
  const progress = [
    mcqStatus === "Complete" || mcqStatus === "Passed",
    codingStatus === "Complete",
    behavioralStatus === "Complete",
    interviewStatus === "Complete" || interviewStatus === "Passed",
  ].filter(Boolean).length;

  useEffect(() => {
    const checkStatus = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${host}/check_test_progress/${interview_id}`
        );
        if (response) {  
          console.log("Behavioral Status from API:", response.data.behavioral_status);
          const {
            mcq_status, coding_status, behavioral_status, interview_status,
            mcq_time_limit, coding_time_limit, interview_time_limit, behavioral_time_limit,
            assessment_sequence
          } = response.data;
          setMCQStatus(mcq_status);
          setCodingStatus(coding_status);
          setBehavioralStatus(behavioral_status); 
          setInterviewStatus(interview_status);
          setMCQTimeLimit(mcq_time_limit);
          setCodingTimeLimit(coding_time_limit);
          setInterviewTimeLimit(interview_time_limit);
          setBehavioralTimeLimit(behavioral_time_limit);
          setAssessmentSequence(assessment_sequence);
          console.log("Behavioral Status in state:", behavioralStatus);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    checkStatus();
  }, [interview_id]);

  const navigateMCQtest = async () => {
    try {
      const currentIndex = assessmentSequence.indexOf('mcq');
      const previousType = currentIndex > 0 ? assessmentSequence[currentIndex - 1] : null;

      const canStartMCQ = !previousType || (
        previousType === 'coding' ? ['Complete', 'NA'].includes(codingStatus) :
          previousType === 'interview' ? ['Complete', 'NA'].includes(interviewStatus) : true
      );

      if (canStartMCQ && mcqStatus === "Incomplete") {
        localStorage.setItem(
          `timeLeft_${interview_id}_mcq`,
          mcqTimeLimit * 60
        );
        setTimeout(() => {
          navigate(`/candidate/${interview_id}/mcqinstructions`);
        }, 1000);
      } else if (!canStartMCQ) {
        toast.warn("Please complete the previous assessment first!");
      } else {
        toast.warn("Knowledge Test is already completed!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigateCodingtest = async () => {
    try {
      const currentIndex = assessmentSequence.indexOf('coding');
      const previousType = currentIndex > 0 ? assessmentSequence[currentIndex - 1] : null;

      const canStartCoding = !previousType || (
        previousType === 'mcq' ? ['Passed', 'NA'].includes(mcqStatus) :
          previousType === 'interview' ? ['Complete', 'NA'].includes(interviewStatus) : true
      );

      if (canStartCoding && codingStatus !== "Complete") {
        localStorage.setItem(
          `timeLeft_${interview_id}_coding`,
          codingTimeLimit * 60
        );
        setTimeout(() => {
          navigate(`/candidate/${interview_id}/codinginstructions`);
        }, 1000);
      } else if (!canStartCoding) {
        toast.warn("Please complete the previous assessment first!");
      } else {
        toast.error("Coding Test is already completed!");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const navigateBehavioral = async () => {
    try {
      const currentIndex = assessmentSequence.indexOf('behavioral');
      const previousType = currentIndex > 0 ? assessmentSequence[currentIndex - 1] : null;

      const canStartBehavioral = !previousType || (
        previousType === 'mcq' ? ['Passed', 'NA'].includes(mcqStatus) :
        previousType === 'coding' ? ['Complete', 'NA'].includes(codingStatus) :
        previousType === 'interview' ? ['Complete', 'NA'].includes(interviewStatus) : true
      );

      if (canStartBehavioral && behavioralStatus !== "Complete") {
        localStorage.setItem(
          `timeLeft_${interview_id}_behavioral`,
          behavioralTimeLimit * 60
        );
        setTimeout(() => {
          navigate(`/candidate/${interview_id}/BehaviouralInstructions`);
        }, 1000);
      } else if (!canStartBehavioral) {
        toast.warn("Please complete the previous assessment first!");
      } else {
        toast.error("Behavioral Interview is already completed!");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const navigateInterview = async () => {
    try {
      const currentIndex = assessmentSequence.indexOf('interview');
      const previousType = currentIndex > 0 ? assessmentSequence[currentIndex - 1] : null;

      const canStartInterview = !previousType || (
        previousType === 'mcq' ? ['Passed', 'NA'].includes(mcqStatus) :
        previousType === 'coding' ? ['Complete', 'NA'].includes(codingStatus) : true
      );

      if (canStartInterview) {
        navigate(`/candidate/${interview_id}/interviewinstructions`);
      } else {
        toast.warn("Please complete the previous assessment first!");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const SkeletonCardComponent = () => {
    return (
      <div className="relative rounded-3xl transition-all duration-500 bg-gray-800">
        <div className="p-4 pl-10 h-full">
          <div className="flex items-start justify-between gap-10">
            <div className="flex-1">
              <div className="flex items-center gap-4 mb-4">
                <div className="p-4 rounded-2xl bg-gray-700 animate-pulse w-16 h-16"></div>
                <div className="space-y-2">
                  <div className="h-6 bg-gray-700 rounded animate-pulse w-40"></div>
                  <div className="h-4 bg-gray-700 rounded animate-pulse w-24"></div>
                </div>
              </div>
              <div className="h-4 bg-gray-700 rounded animate-pulse w-full mb-2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAssessmentCard = (type, index) => {

    if(isLoading) {
      return <SkeletonCardComponent key={`skeleton-${index}`} />;
    }

    const validAssessments = assessmentSequence.filter(assessType => {
      if (assessType === 'mcq') return mcqStatus !== 'NA';
      if (assessType === 'coding') return codingStatus !== 'NA';
      if (assessType === 'interview') return interviewStatus !== 'NA';
      if (assessType === 'behavioral') return behavioralStatus !== 'NA';

      return false;
    });

    const stepNumber = validAssessments.indexOf(type) + 1;

    const cardProps = {
      mcq: {
        title: "Knowledge Assessment",
        icon: BrainCog,
        description: "Begin our adaptive assessment to test your knowledge.",
        onNavigate: navigateMCQtest,
        status: mcqStatus,
      },
      coding: {
        title: "Coding Challenge",
        icon: Code2,
        description: "Solve real-world problems to showcase your coding skills.",
        onNavigate: navigateCodingtest,
        status: codingStatus,
      },
      behavioral: {
        title: "Behavioral Interview",
        icon: MessageSquare,
        description: "Demonstrate your soft skills and professional experience.",
        onNavigate: navigateBehavioral,
        status: behavioralStatus,
      },
      interview: {
        title: "Technical Interview",
        icon: Users,
        description: "Join an AI interview to discuss your expertise.",
        onNavigate: navigateInterview,
        status: interviewStatus,
      }
    };

    const { title, icon, description, onNavigate, status } = cardProps[type];

    if (status === "NA") return null;

    return (
      <CardComponent
        key={type}
        title={title}
        icon={icon}
        description={description}
        onNavigate={onNavigate}
        status={status}
        stepNumber={stepNumber}
        validAssessments={validAssessments}
      />
    );
  };

  function formatTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return hours > 0
      ? `${String(hours).padStart(2, "0")} H ${String(mins).padStart(
        2,
        "0"
      )} mins`
      : `${String(mins).padStart(2, "0")} mins`;
  }

  const CardComponent = ({
    title,
    icon: Icon,
    onNavigate,
    description,
    status,
    stepNumber,
    validAssessments
  }) => {
    const isCompleted = status === "Complete" || status === "Passed";

    const getCurrentType = () => {
      if (title === "Knowledge Assessment") return "mcq";
      if (title === "Coding Challenge") return "coding";
      if (title === "Behavioral Interview") return "behavioral";

      return "interview";
    };

    const checkIsLocked = () => {
      const currentType = getCurrentType();
      const currentIndex = validAssessments.indexOf(currentType);

      if (currentIndex === 0) return false;

      const previousType = validAssessments[currentIndex - 1];
      if (!previousType) return false;

      return previousType === 'mcq' ? !['Passed', 'NA'].includes(mcqStatus) :
        previousType === 'coding' ? !['Complete', 'NA'].includes(codingStatus) :
        previousType === 'behavioral' ? !['Complete', 'NA'].includes(behavioralStatus) :
        previousType === 'interview' ? !['Complete', 'NA'].includes(interviewStatus) : false;
    };

    const shouldShowStartButton = () => {
      const currentType = getCurrentType();
      const currentIndex = validAssessments.indexOf(currentType);
      
      // For first assessment, show button only if not completed
      if (currentIndex === 0) {
        return !isCompleted; // Hide button if completed
      }
      
      // For subsequent assessments
      const previousType = validAssessments[currentIndex - 1];
      if (!previousType) return false;
      
      // Check previous assessment status
      const previousPassed = 
        previousType === 'mcq' ? mcqStatus === 'Passed' :
        previousType === 'coding' ? codingStatus === 'Complete' :
        previousType === 'behavioral' ? behavioralStatus === 'Complete' :
        previousType === 'interview' ? interviewStatus === 'Complete' : false;
      
      // Show button only if previous is passed AND current is not completed
      return previousPassed && !isCompleted;
    };

    const isLocked = checkIsLocked();
    const isInProgress = !isLocked && !isCompleted;
    const showStartButton = shouldShowStartButton();

    return (
      
      <div className={`
        relative rounded-3xl transition-all duration-500
        ${(isInProgress && "cardPattern shadow-md")}
        ${(!isInProgress && isCompleted) ? "bg-gray-700 opacity-75" : ""}
        ${(!isInProgress && isLocked) ? "bg-gray-600 opacity-75" : ""}
      `}>
        <div className="absolute -left-6 top-1/2 -translate-y-1/2 flex items-center">
          <div
            className={`
            w-12 h-12 rounded-full flex items-center justify-center text-md font-bold
            ${isCompleted
                ? "bg-green-400 text-white"
                : isInProgress
                  ? "bg-gradient-to-r from-[#69007f] to-[#fc0987] text-white"
                  : "bg-gray-200 text-gray-500"
              }
          `}
          >
            {isLocked ? <Lock className="w-6 h-6" /> : (isCompleted ? <CheckCircle className="w-6 h-6" /> : stepNumber)}
            
          </div>
        </div>

        {stepNumber < validAssessments.length && (
          <div
            className={`absolute -bottom-12 left-0 w-[2px] h-12 -z-10 ${isCompleted ? "bg-[#4ade80]" : "bg-gray-200"
              }`}
            style={{ left: "19px" }}
          />
        )}

        <div className=" p-4 pl-12 h-full">
          <div className="flex items-start justify-between gap-10">
            <div className="flex-1">
              <div className="flex items-center gap-4 mb-4">
                <div
                  className={`
                  p-4 rounded-2xl
                  
                `}
                >
                  <Icon className="w-9 h-9 text-pink-500" />
                </div>
                <div>
                  <h2
                    className={`
                    text-xl font-bold font-['Poppins'] tracking-tight
                    ${!isInProgress ? "text-gray-400" : "text-white"}
                  `}
                  >
                    {title}
                  </h2>
                  <div className="flex items-center gap-2 mt-1">
                    {!isInProgress && (
                      <Lock className="w-4 h-4 text-gray-400" />
                    )}
                    <span
                      className={`
                      text-sm font-medium
                      ${isCompleted
                          ? "text-green-500"
                          : isInProgress
                            ? "text-pink-500"
                            : "text-gray-400"
                        }
                    `}
                    >
                      {isCompleted
                        ? "Completed"
                        : isInProgress
                          ? "In Progress"
                          : "Locked"}
                    </span>
                  </div>
                </div>
              </div>
              <p
                className={`
                text-md
                ${!isInProgress ? "text-gray-400" : "text-gray-300"}
              `}
              >
                {description}
              </p>
            </div>
            {showStartButton && (
              <div className="flex items-center justify-center h-full">
                <button
                  onClick={onNavigate}
                  disabled={!isInProgress}
                  className={`
                  px-6 py-3 rounded-xl font-semibold transition-all duration-300 
                  flex items-center gap-2 button
                  ${!isInProgress
                      ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                      : "bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white hover:gap-4"
                    }
                `}
                >
                  <span className="flex gap-2">
                    Start Now
                    <ChevronRight className="w-5 h-5" />
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-h-screen h-full overflow-auto px-8 py-8">
      <div className="flex flex-wrap justify-between items-start">
        {/* Left Column - Stats */}
        <div className="w-full lg:w-5/12 space-y-8 py-4">

          <div className="p-6 shadow-xl rounded-3xl shadow-xl p-4 text-white relative overflow-hidden cardPattern">
            <div className="relative z-10 space-y-6">
              <div className="flex items-center gap-3">
                <Rocket className="w-6 h-6 text-pink-500" />
                <h1 className="text-2xl font-bold">Assessment Flow</h1>
              </div>

              <p className="text-lg opacity-90">
                Complete each step to unlock the next challenge
              </p>

              <div className="grid grid-cols-1 gap-4">
                {(mcqStatus === "NA" || codingStatus === "NA" || mcqStatus === "Passed" || codingStatus === "Complete" || mcqStatus === "Incomplete") ? (
                  <>
                    <div className="backdrop-blur-lg rounded-2xl p-4 m-4 shadow-lg">
                      <div className="flex items-center gap-4">
                        
                        {isLoading ? (
                          <div className="flex items-center gap-2">
                            <div className="w-8 h-8 bg-gray-500 rounded-xl animate-pulse"></div>
                            <div className="h-8 bg-gray-500 rounded animate-pulse w-12"></div>
                          </div>
                        ) : (
                          <>
                          <Trophy className="w-6 h-6 mb-1" />
                          <div className="text-2xl font-semibold text-white">
                          {progress}/{totalAssessments}
                        </div>
                        </>
                        )}
                      </div>
                      {isLoading ? (
                        <div className="h-4 bg-gray-500 rounded animate-pulse w-32 mt-2"></div>
                      ) : (
                        <div className="text-sm text-white opacity-90 mt-2">
                          {progress !== totalAssessments ? (
                            <span>Progress</span>
                          ) : (
                            <span className="text-black-400">
                              All assessments are complete!
                            </span>
                          )}
                        </div>
                      )}
                      
                      <div className="w-full h-1 mt-4 bg-gray-300 rounded-full">
                        <div
                          className="h-full bg-green-500 rounded-full"
                          style={{
                            width: `${(progress / totalAssessments) * 100}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="max-w-md mx-auto transform">
                    <div className="relative overflow-hidden rounded-2xl shadow-xl">
                      {/* Gradient border effect using pseudo-element */}
                      <div className="absolute inset-0" />

                      {/* Inner content with glass effect */}
                      <div className="relative m-[2px] p-8 rounded-2xl">
                        {/* Decorative elements */}
                        <div className="absolute top-0 left-0 w-24 h-24 bg-[#3ECFDA] rounded-full opacity-10 blur-2xl" />
                        <div className="absolute bottom-0 right-0 w-32 h-32 bg-[#0952DA] rounded-full opacity-10 blur-2xl" />

                        {/* Content */}
                        <div className="relative z-10 text-center">
                          <h2 className="text-lg font-bold text-white">
                            Unfortunately, Your Journey Ends Here!
                          </h2>
                          <p className="mt-4 text-xs text-white-400 opacity-75  text-center">
                            Keep growing! Every end is a new beginning ✨
                          </p>
                          <div className="mt-4 h-[2px] w-16 mx-auto bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] rounded-full" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Decorative elements */}
            <div className="absolute top-0 right-0 w-64 h-64 bg-white/10 rounded-full blur-3xl -translate-y-1/2 translate-x-1/2" />
            <div className="absolute -bottom-8 -left-8 w-48 h-48 bg-white/10 rounded-full blur-2xl" />
          </div>

          {/* Progress Tracker */}
          <div className="backdrop-blur-sm rounded-3xl p-6 shadow-lg cardPattern">
  <div className="flex items-center gap-3 mb-6">
    <Target className="w-6 h-6 text-pink-500" />
    <h2 className="text-xl font-semibold text-white">Assessment Details</h2>
  </div>
  <div className="space-y-4">
    {isLoading ? (
      <div className="space-y-4">
        {[1, 2].map((i) => (
          <div key={i} className="flex items-center gap-2">
            <div className="flex-1">
              <div className="h-4 bg-gray-700 rounded animate-pulse w-32"></div>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-4 h-4 bg-gray-700 rounded animate-pulse"></div>
              <div className="h-3 bg-gray-700 rounded animate-pulse w-16"></div>
            </div>
          </div>
        ))}
      </div>
    ) : (
      assessmentSequence.map((type) => {
        const assessmentDetails = {
          mcq: {
            title: "Knowledge Assessment",
            timeLimit: mcqTimeLimit,
            status: mcqStatus,
          },
          coding: {
            title: "Coding Challenge",
            timeLimit: codingTimeLimit,
            status: codingStatus,
          },
          behavioral: {
            title: "Behavioral & Soft Skills Test",
            timeLimit: behavioralTimeLimit,
            status: behavioralStatus,
          },
          interview: {
            title: "Technical Interview",
            timeLimit: interviewTimeLimit,
            status: interviewStatus,
          },
        };

        const { title, timeLimit, status } = assessmentDetails[type];

        // Skip rendering if status is "NA"
        if (status === "NA") return null;

        return (
          <div key={type} className="flex items-center gap-2">
            <div className="flex-1">
              <div className="text-sm text-gray-300">{title}</div>
            </div>
            <div className="flex items-center gap-1">
              <Timer className="w-4 h-4 text-gray-300" />
              <span className="h-3.5 text-xs text-gray-300 w-16">{formatTime(timeLimit)}</span>
            </div>
          </div>
        );
      })
    )}
  </div>
</div>
        </div>

        {/* Right Column - Assessment Cards */}
        <div className="space-y-8 lg:w-6/12 relative pl-6 py-4">
          {isLoading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <SkeletonCardComponent key={`skeleton-${index}`} />
            ))
          ) : (
            assessmentSequence.map((type, index) =>
              renderAssessmentCard(type, index)
            )
          )}
        </div>
      </div>
    </div>
  );
}
