import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import { Card, Typography, IconButton, CircularProgress, Box, Divider, Chip, Fab, Tabs, Tab, Stack, Paper, Modal, Dialog } from "@mui/material";
import { GetApp, Assessment, QuestionAnswer, Videocam, LightbulbOutlined, CheckCircleOutline, WarningAmber, ThumbUpOutlined } from "@mui/icons-material";
import { Play, Pause } from "lucide-react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import host from "../../global";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const styles = {
  root: { maxWidth: 1000, mx: "auto", my: 4, "@media print": { maxWidth: "100%", m: 0 } },
  card: { bgcolor: "#1f2937" },
  paper: { p: 2, bgcolor: "rgba(255,255,255,0.05)", borderRadius: 2, transition: "all 0.3s", "&:hover": { bgcolor: "rgba(255,255,255,0.08)" }, pageBreakInside: "avoid" },
  header: { bgcolor: "#1f2937", p: 3, borderRadius: 3, mb: 3 },
  chip: { color: "white", fontWeight: "bold", py: 2, variant: "outlined" },
  tab: { color: "white", minWidth: 140, fontWeight: "bold", textTransform: "none", fontSize: "1rem", "&:hover": { color: "#4f46e5" } },
  fab: { position: "fixed", bottom: 32, right: 32, bgcolor: "#4f46e5", "&:hover": { bgcolor: "#4338ca" }, boxShadow: "0 4px 15px rgba(79,70,229,0.4)", displayPrint: "none" },
  videoModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 800,
    bgcolor: '#1f2937',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
  },
  chartContainer: {
    height: 400,
    width: "100%",
    minWidth: 400,
    position: "relative",
  },
};

const TabPanel = memo(({ children, value, index }) => (
  <Box hidden={value !== index} sx={{ p: 2 }}>{value === index && children}</Box>
), (prev, next) => prev.value === next.value && prev.index === next.index);

const ConsistencyCircle = memo(({ percent }) => {
  const color = percent <= 50 ? "#ef4444" : percent <= 75 ? "#f59e0b" : "#22c55e";
  return (
    <Box sx={{ width: 200, height: 200, mx: "auto", my: 2 }}>
      <svg width="200" height="200" viewBox="0 0 200 200">
        <circle cx="100" cy="100" r="90" fill="none" stroke="#374151" strokeWidth="20" />
        <motion.circle cx="100" cy="100" r="90" fill="none" stroke={color} strokeWidth="20" strokeDasharray="565.48" strokeDashoffset={565.48 - (565.48 * percent) / 100} initial={{ strokeDashoffset: 565.48 }} animate={{ strokeDashoffset: 565.48 - (565.48 * percent) / 100 }} transition={{ duration: 1.5, ease: "easeOut" }} />
        <text x="50%" y="50%" textAnchor="middle" dy=".3em" fill="white" fontSize="40" fontWeight="bold">{percent}%</text>
      </svg>
    </Box>
  );
}, (prev, next) => prev.percent === next.percent);

const BehavioralEvaluationReport = memo(({ name, behavioral_percent }) => {
  const { interview_id } = useParams();
  const [data, setData] = useState({ questions: [], answers: [], audio_urls: [], video_urls: [], traits: [], evaluation_parameters: [], behavioral_insights: [], startDateTime: "", final_evaluation: {} });
  const [loading, setLoading] = useState(true);
  const [audioState, setAudioState] = useState({ playing: false, index: null });
  const [activeTab, setActiveTab] = useState(0);
  const [videoOpen, setVideoOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const audioRefs = useRef({});
  const reportRef = useRef();

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(`${host}/behavioral_evaluation`, {
        headers: { Authorization: `Bearer ${Cookies.get("accessToken") || ""}` },
        params: { interview_id },
      });
      console.log("API Response:", res.data);
      const transformedData = {
        questions: res.data.questions || [],
        answers: res.data.answers || [],
        audio_urls: res.data.audio_urls || [],
        video_urls: (res.data.video_urls || []).filter(url => url && typeof url === "string"),
        traits: res.data.traits || [],
        evaluation_parameters: res.data.evaluation_parameters || [],
        behavioral_insights: res.data.behavioral_insights || [],
        startDateTime: res.data.startDateTime || "",
        final_evaluation: res.data.final_evaluation || {},
      };
      setData(transformedData);
      console.log(transformedData);
    } catch (error) {
      console.error("Fetch Error:", error);
      toast.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [interview_id]);

  useEffect(() => { fetchData(); }, [fetchData]);

  const handleDownloadPDF = useCallback(async () => {
    try {
      // Calculate formatted percent
      const calculatedPercent = typeof behavioral_percent === "number"
        ? behavioral_percent.toFixed(2)
        : (parseFloat(behavioral_percent) || 0).toFixed(2);
  
      // Create PDF document
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true,
      });
  
      // Define PDF dimensions
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 10;
      const contentWidth = pageWidth - 2 * margin;
  
      // Start position
      let currentY = margin;
      let pageCount = 1;
  
      // Add header to first page
      pdf.setFontSize(16);
      pdf.setTextColor(0, 0, 0);
      pdf.text(`Behavioral Assessment Report - ${name}`, margin, currentY);
      currentY += 10;
  
      // Add some basic info
      pdf.setFontSize(10);
      pdf.text(`Date: ${data.startDateTime || new Date().toLocaleDateString()}`, margin, currentY);
      currentY += 5;
      pdf.text(`Consistency: ${calculatedPercent}%`, margin, currentY);
      currentY += 5;
      pdf.setFont("Helvetica", "bold");
      pdf.setFontSize(10);
      pdf.text(`Recommendation: ${data.final_evaluation?.hiring_recommendation || "N/A"}`, margin, currentY);
      currentY += 10;
  
      pdf.setFont("Helvetica", "normal");
  
      // Helper function to add a new page
      const addNewPage = () => {
        pdf.addPage();
        pageCount++;
        currentY = margin;
  
        // Add header to new page
        pdf.setFontSize(10);
        pdf.text(`Behavioral Assessment Report - ${name} (Page ${pageCount})`, margin, currentY);
        currentY += 10;
      };
  
      // Process Questions & Answers by Trait
      pdf.setFontSize(14);
      pdf.text("Questions & Answers", margin, currentY);
      currentY += 10;
  
      // Draw a divider line
      pdf.setDrawColor(200, 200, 200);
      pdf.line(margin, currentY, pageWidth - margin, currentY);
      currentY += 5;
  
      // Group questions by trait (already done in the component)
      const groupedQuestions = data.questions.reduce((acc, q, i) => {
        const trait = data.traits[i] || "Unknown";
        acc[trait] = acc[trait] || [];
        acc[trait].push({
          question: q,
          answer: data.answers[i],
          insight: data.behavioral_insights[i],
          audio_url: data.audio_urls[i],
          video_url: data.video_urls[i],
          originalIndex: i,
        });
        return acc;
      }, {});
  
      // Process each trait group
      for (const [trait, questions] of Object.entries(groupedQuestions)) {
        if (currentY > pageHeight - 30) {
          addNewPage();
        }
  
        // Add trait header
        pdf.setFontSize(12);
        pdf.setTextColor(0, 0, 100);
        pdf.text(trait, margin, currentY);
        currentY += 7;
  
        // Process each question in the trait
        for (let i = 0; i < questions.length; i++) {
          const q = questions[i];
  
          // Check if we need a new page
          if (currentY > pageHeight - 30) {
            addNewPage();
          }
  
          // Add question number and text
          pdf.setFontSize(12);
          pdf.setTextColor(0, 0, 100);
          const questionText = `Question ${i + 1}: ${q.question}`;
          const splitQuestion = pdf.splitTextToSize(questionText, contentWidth);
  
          if (currentY + splitQuestion.length * 5 > pageHeight - 30) {
            addNewPage();
          }
  
          pdf.text(splitQuestion, margin, currentY);
          currentY += splitQuestion.length * 5 + 3;
  
          if (currentY > pageHeight - 50) {
            addNewPage();
          }
  
          // Add answer with word wrapping
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0);
  
          const answer = q.answer || "No answer provided";
          const splitAnswer = pdf.splitTextToSize(answer, contentWidth);
  
          if (currentY + splitAnswer.length * 5 > pageHeight - 30) {
            addNewPage();
          }
  
          pdf.text("Answer:", margin, currentY);
          currentY += 5;
          pdf.text(splitAnswer, margin + 5, currentY);
          currentY += splitAnswer.length * 5 + 5;
  
          // Add insight
          if (q.insight) {
            if (currentY > pageHeight - 30) {
              addNewPage();
            }
  
            const insightText = `Insight: ${q.insight}`;
            const splitInsight = pdf.splitTextToSize(insightText, contentWidth);
  
            if (currentY + splitInsight.length * 5 > pageHeight - 20) {
              addNewPage();
            }
  
            pdf.text(splitInsight, margin, currentY);
            currentY += splitInsight.length * 5;
          }
  
          // Add video link if available
          if (q.video_url) {
            if (currentY > pageHeight - 30) {
              addNewPage();
            }
  
            const videoLinkText = "Video Response";
            const videoLinkX = margin;
            const videoLinkY = currentY;
            const videoLinkWidth = pdf.getTextWidth(videoLinkText);
            const videoLinkHeight = 5;
  
            pdf.setTextColor(0, 0, 255);
            pdf.text(videoLinkText, videoLinkX, videoLinkY);
  
            pdf.link(videoLinkX, videoLinkY - 4, videoLinkWidth, videoLinkHeight, {
              url: q.video_url,
            });
  
            pdf.setTextColor(0, 0, 0);
            currentY += 7;
          }
  
          // Add space between questions
          currentY += 10;
  
          // Add a separator line between questions
          if (i < questions.length - 1) {
            pdf.setDrawColor(200, 200, 200);
            pdf.line(margin, currentY - 5, pageWidth - margin, currentY - 5);
          }
        }
  
        // Add extra space between trait groups
        currentY += 10;
      }
  
      // Add Behavioral Insights section on a new page
      addNewPage();
  
      pdf.setFontSize(14);
      pdf.setTextColor(0, 0, 0);
      pdf.text("Behavioral Insights", margin, currentY);
      currentY += 10;
  
      // Draw a divider line
      pdf.setDrawColor(200, 200, 200);
      pdf.line(margin, currentY, pageWidth - margin, currentY);
      currentY += 10;
  
      // Add overall trait scores
      if (Object.keys(data.final_evaluation?.overall_trait_scores || {}).length > 0) {
        pdf.setFontSize(12);
        pdf.text("Trait Performance Overview:", margin, currentY);
        currentY += 7;
  
        Object.entries(data.final_evaluation.overall_trait_scores).forEach(([trait, score]) => {
          pdf.setFontSize(10);
          pdf.text(`${trait.charAt(0).toUpperCase() + trait.slice(1)}: ${score}%`, margin + 5, currentY);
          currentY += 5;
        });
        currentY += 5;
      } else {
        pdf.setFontSize(10);
        pdf.text("No trait scores available.", margin + 5, currentY);
        currentY += 10;
      }
  
      // Add overall insight
      if (currentY > pageHeight - 70) {
        addNewPage();
      }
  
      pdf.setFontSize(12);
      pdf.text("Overall Insight:", margin, currentY);
      currentY += 7;
  
      pdf.setFontSize(10);
      if (data.final_evaluation?.overall_insight) {
        const insightText = data.final_evaluation.overall_insight;
        const splitInsight = pdf.splitTextToSize(insightText, contentWidth - 10);
  
        if (currentY + splitInsight.length * 5 > pageHeight - 30) {
          addNewPage();
        }
  
        pdf.text(splitInsight, margin + 5, currentY);
        currentY += splitInsight.length * 5 + 5;
      } else {
        pdf.text("No overall insight available.", margin + 5, currentY);
        currentY += 10;
      }
  
      // Add strengths
      if (currentY > pageHeight - 60) {
        addNewPage();
      }
  
      pdf.setFontSize(12);
      pdf.text("Strengths:", margin, currentY);
      currentY += 7;
  
      if (data.final_evaluation?.strongest_traits?.length > 0) {
        pdf.setFontSize(10);
        data.final_evaluation.strongest_traits.forEach((trait) => {
          pdf.text(`• ${trait}`, margin + 5, currentY);
          currentY += 5;
        });
        currentY += 5;
      } else {
        pdf.setFontSize(10);
        pdf.text("No strengths identified.", margin + 5, currentY);
        currentY += 10;
      }
  
      // Add areas to improve
      if (currentY > pageHeight - 60) {
        addNewPage();
      }
  
      pdf.setFontSize(12);
      pdf.text("Areas to Improve:", margin, currentY);
      currentY += 7;
  
      if (data.final_evaluation?.weakest_traits?.length > 0) {
        pdf.setFontSize(10);
        data.final_evaluation.weakest_traits.forEach((trait) => {
          pdf.text(`• ${trait}`, margin + 5, currentY);
          currentY += 5;
        });
        currentY += 5;
      } else {
        pdf.setFontSize(10);
        pdf.text("No areas to improve identified.", margin + 5, currentY);
        currentY += 10;
      }
  
      // Add response consistency
      if (currentY > pageHeight - 50) {
        addNewPage();
      }
  
      pdf.setFontSize(12);
      pdf.text("Response Consistency:", margin, currentY);
      currentY += 7;
  
      pdf.setFontSize(10);
      pdf.text(`Consistency Score: ${calculatedPercent}%`, margin + 5, currentY);
      currentY += 5;
  
      if (data.final_evaluation?.consistency_analysis) {
        const consistencyText = data.final_evaluation.consistency_analysis;
        const splitConsistency = pdf.splitTextToSize(consistencyText, contentWidth - 10);
  
        if (currentY + splitConsistency.length * 5 > pageHeight - 30) {
          addNewPage();
        }
  
        pdf.text(splitConsistency, margin + 5, currentY);
        currentY += splitConsistency.length * 5 + 5;
      }
  
      // Add hiring recommendation
      if (currentY > pageHeight - 70) {
        addNewPage();
      }
  
      pdf.setFontSize(12);
      pdf.text("Hiring Recommendation:", margin, currentY);
      currentY += 7;
  
      pdf.setFontSize(10);
      if (data.final_evaluation?.hiring_recommendation) {
        pdf.text(`Recommendation: ${data.final_evaluation.hiring_recommendation}`, margin + 5, currentY);
        currentY += 5;
      } else {
        pdf.text("No recommendation provided.", margin + 5, currentY);
        currentY += 10;
      }
  
      // Add page numbers to footer of each page
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(100, 100, 100);
        pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 25, pageHeight - 10);
      }
  
      // Save the PDF
      pdf.save(`${name}_Behavioral_Assessment_Report.pdf`);
      toast.success("PDF successfully generated!");
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error(`Error generating PDF: ${error.message || "Unknown error"}`);
    }
  }, [name, data, behavioral_percent]);

  const toggleAudio = useCallback((originalIndex) => {
    setAudioState(prev => {
      if (prev.playing && prev.index === originalIndex) {
        audioRefs.current[originalIndex]?.pause();
        return { playing: false, index: null };
      }
      if (prev.index !== null) audioRefs.current[prev.index]?.pause();
      audioRefs.current[originalIndex]?.play();
      return { playing: true, index: originalIndex };
    });
  }, []);

  const handleVideoOpen = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setVideoOpen(true);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
    setSelectedVideoUrl("");
  };

  const getColor = (value, type) => 
    type === "score" 
      ? value <= 33 ? "#ef4444"
        : value <= 66 ? "#f59e0b"
        : "#22c55e"
      : value <= 50 ? "#ef4444" 
        : value <= 75 ? "#f59e0b" 
        : "#22c55e";

  const barData = useCallback(() => ({
    labels: Object.keys(data.final_evaluation?.overall_trait_scores || {}).map(t => t.charAt(0).toUpperCase() + t.slice(1)),
    datasets: [{
      label: "Trait Scores",
      data: Object.values(data.final_evaluation?.overall_trait_scores || {}).map(Number),
      backgroundColor: Object.values(data.final_evaluation?.overall_trait_scores || {}).map(s => getColor(s, "score")),
      borderRadius: 6
    }],
  }), [data.final_evaluation?.overall_trait_scores]);

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: { color: "white", font: { size: 14, weight: "bold" } }
      },
      tooltip: {
        backgroundColor: "rgba(31,41,55,0.9)",
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}%`
        }
      },
      title: {
        display: true,
        text: "Trait Performance Overview",
        color: "white",
        font: { size: 18, weight: "bold" }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          color: "white",
          stepSize: 20,
          callback: (value) => `${value}%`
        },
        grid: { color: "rgba(255,255,255,0.1)" }
      },
      x: {
        ticks: { color: "white" },
        grid: { display: false }
      }
    }
  };

  if (loading) return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}><CircularProgress size={60} /></Box>;
  if (!data) return <Typography color="white" align="center" sx={{ mt: 4 }}>No data available.</Typography>;

  const groupedQuestions = data.questions.reduce((acc, q, i) => {
    const trait = data.traits[i] || "Unknown";
    acc[trait] = acc[trait] || [];
    acc[trait].push({ 
      question: q, 
      answer: data.answers[i], 
      insight: data.behavioral_insights[i], 
      audio_url: data.audio_urls[i], 
      video_url: data.video_urls[i],
      originalIndex: i 
    });
    return acc;
  }, {});

  return (
    <Box sx={styles.root} ref={reportRef}>
      <Paper sx={styles.header}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Assessment sx={{ color: "#4f46e5", fontSize: 48 }} />
            <Box>
              <Typography variant="h3" color="white" fontWeight="bold" sx={{ fontSize: { xs: "1.5rem", md: "2.125rem" } }}>Behavioral Assessment Report</Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">Candidate: <strong>{name}</strong></Typography>
            </Box>
          </Stack>
          <IconButton onClick={handleDownloadPDF} sx={{ bgcolor: "#4f46e5", color: "white", "&:hover": { bgcolor: "#4338ca" } }}><GetApp fontSize="large" /></IconButton>
        </Stack>
        <Stack direction="row" spacing={2} mt={2} flexWrap="wrap" gap={1}>
          <Chip label={`Assessment: ${behavioral_percent > 60 ? "Satisfactory" : "Needs Improvement"}`} color={behavioral_percent > 60 ? "success" : "warning"} sx={{ ...styles.chip, borderColor: behavioral_percent > 60 ? "#2e7d32" : "#ed6c02" }} />
          <Chip label={`Date: ${data.startDateTime || "NA"}`} sx={styles.chip} />
        </Stack>
      </Paper>

      <Tabs value={activeTab} onChange={(_, v) => setActiveTab(v)} centered sx={{ borderRadius: 3, mb: 3, py: 1, "& .MuiTab-root": styles.tab, "& .Mui-selected": { color: "#4f46e5", bgcolor: "rgba(79,70,229,0.1)", borderRadius: 1 }, "& .MuiTabs-indicator": { bgcolor: "#4f46e5", height: 3, borderRadius: 5 } }}>
        <Tab icon={<QuestionAnswer />} label="Candidate Responses" />
        <Tab icon={<Assessment />} label="Smart Evaluation" />
      </Tabs>

      <Card sx={styles.card}>
      <TabPanel value={activeTab} index={1}>
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Typography variant="h5" color="white" fontWeight="bold" mb={2}>
      Genie Smart Report
    </Typography>
    <Divider sx={{ bgcolor: "rgba(255,255,255,0.2)", mb: 3 }} />

    {/* Overall Score Circles */}
    <Paper
      sx={{
        ...styles.paper,
        mb: 3,
        p: 3,
        bgcolor: "rgba(31,41,55,0.95)",
        border: "1px solid rgba(79,70,229,0.2)",
      }}
    >
      <Typography
        variant="h6"
        color="white"
        fontWeight="bold"
        mb={3}
        sx={{
          background: "linear-gradient(45deg, #4f46e5, #7c3aed)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Overall Score
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
          gap: 3,
        }}
      >
        {barData().labels.map((label, index) => {
          const score = barData().datasets[0].data[index];
          const numericScore = Number(score) || 0;
          return (
            <Box
              key={label}
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1.5,
                p: 2,
                borderRadius: 2,
                bgcolor: "rgba(255,255,255,0.03)",
                transition: "all 0.3s ease",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={90}
                  thickness={5}
                  sx={{
                    color: "rgba(255,255,255,0.1)",
                    position: "absolute",
                  }}
                />
                <CircularProgress
                  variant="determinate"
                  value={numericScore}
                  size={90}
                  thickness={5}
                  sx={{
                    color: barData().datasets[0].backgroundColor[index], // Using the same color from bar chart
                    "& .MuiCircularProgress-circle": {
                      strokeLinecap: "round",
                      transition: "stroke-dashoffset 0.5s ease-in-out",
                    },
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    {numericScore.toFixed(1)}%
                  </Typography>
                </Box>
              </Box>
              <Typography
                color="white"
                sx={{
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "medium",
                  textTransform: "capitalize",
                }}
              >
                {label}
              </Typography>
              <Box
                sx={{
                  width: "50%",
                  height: 4,
                  bgcolor: "rgba(255,255,255,0.1)",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: `${numericScore}%`,
                    height: "100%",
                    bgcolor: barData().datasets[0].backgroundColor[index],
                    transition: "width 0.5s ease-in-out",
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Paper>

    <Stack spacing={2}>
      {[
        {
          title: "Overall Insight",
          icon: <LightbulbOutlined sx={{ color: "#7c3aed" }} />,
          content: (
            <Typography
              sx={{
                color: "white",
                p: 2,
                bgcolor: "rgba(124,58,237,0.08)",
                borderRadius: 2,
                borderLeft: "4px solid #7c3aed",
                fontSize: "1rem",
                lineHeight: 1.5,
              }}
            >
              {data.final_evaluation?.overall_insight || "No insight available"}
            </Typography>
          ),
        },
        {
          title: "Strengths",
          icon: <CheckCircleOutline sx={{ color: "#22c55e" }} />,
          content: (
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {data.final_evaluation?.strongest_traits?.length ? (
                data.final_evaluation.strongest_traits.map((trait, i) => (
                  <Chip
                    key={i}
                    label={trait}
                    sx={{
                      bgcolor: "rgba(34,197,94,0.1)",
                      color: "#22c55e",
                      border: "1px solid rgba(34,197,94,0.3)",
                      fontWeight: "medium",
                      "&:hover": { bgcolor: "rgba(34,197,94,0.2)" },
                    }}
                  />
                ))
              ) : (
                <Typography color="rgba(255,255,255,0.7)">
                  No strengths identified
                </Typography>
              )}
            </Stack>
          ),
        },
        {
          title: "Areas to Improve",
          icon: <WarningAmber sx={{ color: "#f59e0b" }} />,
          content: (
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {data.final_evaluation?.weakest_traits?.length ? (
                data.final_evaluation.weakest_traits.map((trait, i) => (
                  <Chip
                    key={i}
                    label={trait}
                    sx={{
                      bgcolor: "rgba(245,158,11,0.1)",
                      color: "#f59e0b",
                      border: "1px solid rgba(245,158,11,0.3)",
                      fontWeight: "medium",
                      "&:hover": { bgcolor: "rgba(245,158,11,0.2)" },
                    }}
                  />
                ))
              ) : (
                <Typography color="rgba(255,255,255,0.7)">
                  No areas identified
                </Typography>
              )}
            </Stack>
          ),
        },
        {
          title: "Response Consistency",
          icon: <LightbulbOutlined sx={{ color: "#7c3aed" }} />,
          content: (
            <Typography
              sx={{
                color: "white",
                p: 2,
                bgcolor: "rgba(124,58,237,0.08)",
                borderRadius: 2,
                borderLeft: "4px solid #7c3aed",
                fontSize: "1rem",
                lineHeight: 1.5,
              }}
            >
              {data.final_evaluation?.consistency_analysis ||
                "Consistency indicates response reliability."}
            </Typography>
          ),
        },
        {
          title: "Hiring Recommendation",
          icon: (
            <ThumbUpOutlined
              sx={{
                color:
                  data.final_evaluation?.hiring_recommendation === "Strong"
                    ? "#22c55e"
                    : data.final_evaluation?.hiring_recommendation === "Moderate"
                    ? "#f59e0b"
                    : "#ef4444",
              }}
            />
          ),
          content: (
            <Box
              sx={{
                p: 2,
                bgcolor:
                  data.final_evaluation?.hiring_recommendation === "Strong"
                    ? "rgba(34,197,94,0.08)"
                    : data.final_evaluation?.hiring_recommendation === "Moderate"
                    ? "rgba(245,158,11,0.08)"
                    : "rgba(239,68,68,0.08)",
                borderRadius: 2,
                borderLeft: `4px solid ${
                  data.final_evaluation?.hiring_recommendation === "Strong"
                    ? "#22c55e"
                    : data.final_evaluation?.hiring_recommendation === "Moderate"
                    ? "#f59e0b"
                    : "#ef4444"
                }`,
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "medium",
                  mb: 1,
                  fontSize: "1rem",
                }}
              >
                {data.final_evaluation?.hiring_recommendation || "NA"}
              </Typography>
              {/* <Typography
                sx={{
                  color: "rgba(255,255,255,0.8)",
                  fontSize: "0.95rem",
                  lineHeight: 1.5,
                }}
              >
                {data.final_evaluation?.recommendation_reason ||
                  "No reasoning provided"}
              </Typography> */}
            </Box>
          ),
        },
      ].map(({ title, icon, content }) => (
        <Paper
          key={title}
          component={motion.div}
          whileHover={{ y: -2 }}
          sx={{
            ...styles.paper,
            bgcolor: "rgba(31,41,55,0.95)",
            border: "1px solid rgba(255,255,255,0.05)",
            transition: "all 0.3s ease",
            "&:hover": {
              borderColor: "rgba(79,70,229,0.2)",
              boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1.5} mb={2}>
            {icon}
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontWeight: "bold",
                background: "linear-gradient(45deg, #4f46e5, #7c3aed)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {title}
            </Typography>
          </Stack>
          {content}
        </Paper>
      ))}
    </Stack>
  </motion.div>
</TabPanel>
        <TabPanel value={activeTab} index={0}>
          <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Typography variant="h5" color="white" fontWeight="bold" mb={2}>Questions & Answers</Typography>
            <Divider sx={{ bgcolor: "rgba(255,255,255,0.2)", mb: 3 }} />
            <Stack spacing={3}>
              {Object.entries(groupedQuestions).map(([trait, qs], idx) => (
                <Box key={idx}>
                  <Typography variant="h6" color="white" mb={2} sx={{ bgcolor: "rgba(79,70,229,0.1)", p: 2, borderRadius: 2 }}>{trait}</Typography>
                  <Stack spacing={2}>
                    {qs.map((q, i) => (
                      <Paper key={i} sx={{ ...styles.paper, "&:hover": { transform: "translateY(-3px)", boxShadow: "0 4px 15px rgba(0,0,0,0.1)" }}}>
                        <Stack spacing={2}>
                          <Typography variant="subtitle1" color="white" fontWeight="bold" sx={{ display: "flex", alignItems: "center", gap: 1 }}><Chip label={i + 1} sx={{ bgcolor: "#4f46e5", color: "white", width: 32, height: 32 }} />{q.question}</Typography>
                          <Paper elevation={0} sx={{ p: 2, bgcolor: "rgba(79,70,229,0.1)", borderRadius: 2, borderLeft: "4px solid #4f46e5" }}>
                            <Typography color="white" sx={{ mb: 1 }}><strong>Response:</strong> {q.answer ?? "No answer"}</Typography>
                            <Typography color="white"><strong>Insight:</strong> {q.insight ?? "No insight"}</Typography>
                          </Paper>
                          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            {q.audio_url && (
                              <>
                                <IconButton onClick={() => toggleAudio(q.originalIndex)} sx={{ color: "white", bgcolor: "rgba(79,70,229,0.2)", "&:hover": { bgcolor: "rgba(79,70,229,0.3)" }, displayPrint: "none" }}>
                                  {audioState.playing && audioState.index === q.originalIndex ? <Pause size={24} /> : <Play size={24} />}
                                </IconButton>
                                <Typography variant="caption" color="rgba(255,255,255,0.7)" sx={{ displayPrint: "none" }}>Audio available</Typography>
                                <audio ref={el => audioRefs.current[q.originalIndex] = el} src={q.audio_url} onEnded={() => setAudioState({ playing: false, index: null })} />
                              </>
                            )}
                            {q.video_url && typeof q.video_url === "string" && q.video_url.trim() !== "" && (
                              <>
                                <IconButton onClick={() => handleVideoOpen(q.video_url)} sx={{ color: "white", bgcolor: "rgba(79,70,229,0.2)", "&:hover": { bgcolor: "rgba(79,70,229,0.3)" }, displayPrint: "none" }}>
                                  <Videocam />
                                </IconButton>
                                <Typography variant="caption" color="rgba(255,255,255,0.7)" sx={{ displayPrint: "none" }}>Video available</Typography>
                              </>
                            )}
                          </Box>
                        </Stack>
                      </Paper>
                    ))}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </motion.div>
        </TabPanel>
      </Card>

      <Modal
        open={videoOpen}
        onClose={handleVideoClose}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <Box sx={styles.videoModal}>
          <Typography id="video-modal-title" variant="h6" color="white" sx={{ mb: 2 }}>
            Video Response
          </Typography>
          {selectedVideoUrl ? (
  <video width="100%" controls autoPlay>
    <source src={selectedVideoUrl} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
) : (
  <Typography color="white">No video available.</Typography>
)}
        </Box>
      </Modal>

      {/* <Fab onClick={() => setActiveTab(activeTab === 0 ? 1 : 0)} sx={styles.fab}>{activeTab === 0 ? <QuestionAnswer sx={{ fontSize: 28 }} /> : <Assessment sx={{ fontSize: 28 }} />}</Fab> */}
    </Box>
  );
}, (prev, next) => prev.name === next.name && prev.behavioral_percent === next.behavioral_percent);

export default BehavioralEvaluationReport;