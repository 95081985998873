import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { useLocation } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import { LogOut } from 'lucide-react';
import Loader from '../Loader';

const Layout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const orgadmin_accessToken = Cookies.get('accessToken');

  useEffect(() => {
    setIsLoading(true);
    const checkSession = async () => {
      try {
        const session = await fetchAuthSession();
        const { accessToken } = session.tokens ?? {};
        const groups = accessToken.payload['cognito:groups'] ?? [];
        if (groups.includes('Company_Admin') && orgadmin_accessToken === accessToken.toString()) {
          setIsAuthenticated(true);
        } else {
          await new Promise((resolve) => {
            Cookies.remove('accessToken');
            resolve();
          });        
          await signOut();
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkSession();
  }, [location.pathname, orgadmin_accessToken]);

  const handleSignOut = async () => {
    try {
      Cookies.remove('accessToken');
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen flex items-center justify-center app-background p-4">
        <div className="max-w-md w-full">
          <div className="relative overflow-hidden rounded-xl shadow-2xl p-8">
            <div className="absolute inset-0 z-0 glass-effect" />          
            <div className="relative z-10 text-center space-y-6">
              <div className="mx-auto bg-white/10 rounded-full p-4 w-fit backdrop-blur-sm">
                <LogOut className="w-8 h-8 text-white" />
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl font-bold text-white">Session Expired</h2>
                <p className="text-gray-300">
                  Please login again to continue your session
                </p>
              </div>
              <button 
                onClick={handleSignOut}
                className="w-full bg-black text-white font-semibold py-3 px-6 rounded-lg 
                         transform transition-all duration-200 hover:scale-105 
                         hover:shadow-lg active:scale-95 button"
              >
                <span>Return to Login</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Authenticator>
      <ToastContainer />
      <div className='app-background flex h-screen max-h-screen overflow-hidden'>
        <Sidebar 
          // isSidebarExpanded={isSidebarExpanded}
          // setIsSidebarExpanded={setIsSidebarExpanded}
          handleSignOut={handleSignOut}
        />
        
        <div className="flex flex-1 flex-col overflow-hidden">
          <Header />
          <main 
            className="flex-1 overflow-y-auto p-4"
            style={{ 
              // marginLeft: isSidebarExpanded ? '0px' : '0px', 
              transition: 'margin-left 0.3s ease-in-out' 
            }}
          >
            <Outlet />
          </main>
        </div>
      </div>
    </Authenticator>
  );
};

export default Layout;