import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Authenticator, Icon } from "@aws-amplify/ui-react";
import Modal from "react-modal";
import { Button,IconButton } from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';
import {Dialog,DialogTitle,DialogContent,DialogActions,} from "@mui/material";
import Loader from "../Loader";
import host from "../../global";
import DeleteIcon from "@mui/icons-material/Delete";

Modal.setAppElement("#root");

function App() {
  const [shortlisted, setShortlisted] = useState([]);
  const [retrofit, setRetrofit] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [invalidData, setInvalidData] = useState([]);
  const [CandidateUniqueID, setCandidateUniqueID] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const { upload_id } = useParams();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = Cookies.get("accessToken");
  const [editingCandidate, setEditingCandidate] = useState(null);
  const [editingInvalidIndex, setEditingInvalidIndex] = useState(null);
  const [newInvalidEmail, setNewInvalidEmail] = useState("");
  const [editingInvalidCandidate, setEditingInvalidCandidate] = useState(null);
  const [resumeScoreThreshold,setResumeScoreThreshold] = useState(60)
  const [selectedShortlistedCandidates, setSelectedShortlistedCandidates] = useState([]);

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await axios.get(`${host}/resumes`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            upload_id: upload_id,
          },
        });
        const fetchedCandidates = response.data.data;
        let score_threshold = response.data.resume_score_threshold
        setResumeScoreThreshold(response.data.resume_score_threshold)

        if (Array.isArray(fetchedCandidates)) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const isValidEmail = (email) => {
            return email && typeof email === "string" && emailRegex.test(email);
          };
          setShortlisted(
            fetchedCandidates.filter(
              (c) => c.score >= score_threshold && isValidEmail(c.candidate_email)
            )
          );
          setRetrofit(
            fetchedCandidates.filter(
              (c) =>
                c.score < score_threshold &&
                c.score !== null &&
                isValidEmail(c.candidate_email)
            )
          );
          setInvalidData(
            fetchedCandidates.filter(
              (c) =>
                !isValidEmail(c.candidate_email) ||
                c.score === null ||
                c.candidate_email === "not provided" ||
                c.candidate_email === ""
            )
          );
          setIsLoading(false);
        } else {
          console.error("Unexpected data format:", fetchedCandidates);
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchCandidates();
  }, [upload_id, accessToken, navigate]);

  const getRowStyle = (profile_type) => ({
    backgroundColor: profile_type === 'duplicate' ? 'rgba(255, 0, 0, 0.2)' : 'transparent'
  });

  const renderTableRow = (candidate, index, arrayType) => {
    const isEditing = CandidateUniqueID === candidate.unique_id;

    return(
      <Tr key={index} style={getRowStyle(candidate.profile_type)}>
      {arrayType !== 'invalid' && (
        <Td style={{ width: "60px", textAlign: "center", paddingLeft: "40px" }}>
          <input
            type="checkbox"
            checked={arrayType === 'shortlisted' 
              ? selectedShortlistedCandidates.includes(candidate)
              : selectedCandidates.includes(candidate)}
            onChange={() => arrayType === 'shortlisted'
              ? handleShortlistCheckboxChange(candidate)
              : handleCheckboxChange(candidate)}
          />
        </Td>
      )}
      <Td style={{width: "130px"}}>{index + 1}</Td>
      <Td style={{width: "220px", textAlign: "left"}}>{candidate.name}</Td>
      <Td style={{width: "350px", textAlign: "left"}}>
      {isEditing ? (
          <input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Enter email"
            required
            className="bg-transparent w-full"
            style={{ 
              border: "1px solid white", 
              outline: "none",
              color: "white",
              textAlign: "left"
            }}
          />
        ) : (
          candidate.candidate_email || "Not Provided"
        )}
      </Td>
      <Td className="text-center" style={{width: "100px"}}>
        {candidate.score || "N/A"}
      </Td>
      <Td className="text-center" style={{width: "100px"}}>
        <div className="tooltip-icon" onClick={() => handleReportClick(candidate)}>
          <VisibilityRoundedIcon style={{ height: "30px", width: "30px" }} />
          <span className="tooltip-text">View Report</span>
        </div>
      </Td>
      <Td style={{width: "100px"}}>
        <div className="flex items-center justify-center space-x-2">
          {isEditing ? (
            <IconButton 
              onClick={() => saveEmailChange(candidate)} 
              color="primary"
            >
              <BookmarkSharpIcon />
            </IconButton>
          ) : (
            <IconButton 
              onClick={() => {
                setNewEmail(candidate.candidate_email || "");
                setCandidateUniqueID(candidate.unique_id);
              }} 
              color="secondary"
            >
              <ModeEditOutlineSharpIcon />
            </IconButton>
          )}
          {arrayType === 'invalid' && (
            <IconButton
              onClick={() => deleteSingleCandidate(candidate)}
              style={{ color: 'white' }}
              onMouseEnter={(e) => e.target.style.color = 'red'}
              onMouseLeave={(e) => e.target.style.color = 'white'}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </Td>
    </Tr> 
  );
}

  const scheduleInterviewForAll = async () => {
    try {
      await axios.post(
        `${host}/schedule_interviews`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            upload_id: upload_id,
          },
        }
      );
      toast.success("Interviews have been scheduled for all candidates.");
      setTimeout(() => {
        navigate("/hr/dashboard");
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const determineProfileType = (type) => {
    if (type === "unique") {
      return <div className="status-completed">Unique</div>;
    } else{
      return <div className="status-in-progress">Duplicate</div>;
    }
  };

  const handleReportClick = (candidate) => {
    setCurrentReport(candidate.report);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentReport(null);
  };

  const startEditingEmail = (candidate) => {
    setCandidateUniqueID(candidate.unique_id);
    setNewEmail(candidate.candidate_email || "");
    setEditingCandidate(candidate);
  };

  const saveEmailChange = async (candidate) => {
    if (!newEmail) {
      toast.error("Please enter a valid email");
      return;
    }
    
    if (newEmail === candidate.candidate_email) {
      toast.info("No changes made to email");
      setCandidateUniqueID(null);
      setNewEmail("");
      setEditingCandidate(null);
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(newEmail)) {
      toast.error(
        "Please enter a valid email address (e.g., example@gmail.com or official@example.com)"
      );
      return;
    }

    try {
      const params = new URLSearchParams({
        upload_id: upload_id,
        unique_id : candidate.unique_id,
        new_email: newEmail,
      });

      await axios.put(
        `${host}/update_candidate_email`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: params,
        }
      );

      const updateCandidateInArray = (array) => {
        return array.map((c) => {
          if (c.unique_id === candidate.unique_id) {
            return { ...c, candidate_email: newEmail };
          }
          return c;
        });
      };

      setShortlisted(updateCandidateInArray(shortlisted));
      setRetrofit(updateCandidateInArray(retrofit));
      setInvalidData(updateCandidateInArray(invalidData));
      
      const hasInvalidEmail = invalidData.some(c => c.unique_id !== candidate.unique_id && !c.candidate_email);
      if (!hasInvalidEmail){
        window.location.reload();
      }

      setCandidateUniqueID(null);
      setNewEmail("");
      setEditingCandidate(null);
      toast.success("Email updated successfully!");
    } catch (error) {
      console.log(error)
      console.error("Error details:", error.response?.data);
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else if (status === 422) {
          toast.error(`Invalid request: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      // setCandidateUniqueID(null);
      // setNewEmail("");
      // setEditingCandidate(null);
    }
  };

  const handleCheckboxChange = (candidate) => {
    setSelectedCandidates((prevSelected) => {
      if (prevSelected.includes(candidate)) {
        return prevSelected.filter((c) => c !== candidate);
      } else {
        return [...prevSelected, candidate];
      }
    });
  };

  const handleShortlistCheckboxChange = (candidate) => {
    setSelectedShortlistedCandidates((prev) =>
      prev.includes(candidate)
        ? prev.filter((c) => c !== candidate)
        : [...prev, candidate]
    );
  };

  const deleteSelectedCandidates = async () => {
    const selectedEmails = selectedCandidates.map(
      (candidate) => candidate.candidate_email
    );

    const selectedUniqueIds = selectedCandidates.map(
      (candidate) => candidate.unique_id
    );

    try {
      await axios.delete(`${host}/deletecandidateBulkUpload`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          upload_id: upload_id,
          emails: selectedEmails.join(","),
          unique_id: selectedUniqueIds.join(",")
        },
      });
      toast.success("Selected candidates deleted successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const deleteSingleCandidate = async (candidate) => {
    try {
      await axios.delete(`${host}/deletecandidateBulkUpload`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          upload_id: upload_id,
          emails: candidate.candidate_email || "",
          unique_id: candidate.unique_id
        },
      });
      toast.success("Candidate deleted successfully");
      setInvalidData(invalidData.filter((c) => c !== candidate));
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const shortlistSelectedCandidates = () => {
    const candidatesToShortlist = selectedCandidates.filter(
      (candidate) => candidate.score < resumeScoreThreshold
    );
    setRetrofit(retrofit.filter((c) => !candidatesToShortlist.includes(c)));
    setShortlisted([...shortlisted, ...candidatesToShortlist]);
    setSelectedCandidates([]);
  };
  if (isLoading) {
    return <Loader />;
  }

  const deleteSelectedShortlistedCandidates = () => {
    setShortlisted((prev) =>
      prev.filter((candidate) => !selectedShortlistedCandidates.includes(candidate))
    );
    setSelectedShortlistedCandidates([]);
  };

  return (
    <Authenticator>
      <div className="p-8 company-admin-container">
        <div className="rounded-lg content glass-effect">
          <div className="maincontent">
          <div className="container mx-auto px-4">
          <h1 className="text-white font-bold mb-4">Candidate Management</h1>
              {invalidData.length > 0 ? (
                <div className="table-container">
                  <div className="table-header">
                    <h2 className="text-white font-semibold mb-1">Invalid Data Candidates</h2>
                    {invalidData.length > 0 && (
                    <div>
                        <p className="text-red-400 font-light text-xs mb-3">
                          Please validate the invalid candidates before proceeding
                          further.
                        </p>
                      </div>
                    )}
                    <Table>
                      <Thead>
                        <Tr>
                          <Th className="custom-th whitespace-nowrap" style={{width: "130px", textAlign: "center"}}>Sr. No</Th>
                          <Th className="custom-th whitespace-nowrap" style={{width: "220px", textAlign: "left"}}>Candidate Name</Th>
                          <Th className="custom-th" style={{width: "350px"}}>Email</Th>
                          <Th className="custom-th" style={{width: "100px"}}>
                            Score
                          </Th>

                          <Th className="custom-th" style={{width: "100px"}}>Report</Th>
                          <Th className="custom-th" style={{width: "60px"}}>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                      {invalidData.map((candidate, index) => renderTableRow(candidate, index, 'invalid'))}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              ):(
              <>
              {shortlisted.length > 0 && (
                <div className="table-container">
                  <div className="table-header">
                    <h2 className="text-white font-semibold">Shortlisted Candidates</h2>
                    <div className="flex gap-4 py-4" >
                    {invalidData.length === 0 && retrofit.length === 0 && (
                      <button
                        className="button"
                        onClick={scheduleInterviewForAll}
                      >
                        <span> Schedule Interviews for All</span>
                      </button>
                    )}
                    {selectedShortlistedCandidates.length > 0 && (
                      <button className="button" onClick={deleteSelectedShortlistedCandidates}>
                        <span> Delete Selected Candidates</span>
                      </button>
                    )}
                    </div>
      
                    <Table>
                      <Thead>
                        <Tr>
                          <Th className="custom-th"  style={{width: "60px"}}>Actions</Th>
                          <Th className="custom-th"  style={{width: "130px", textAlign: "center"}}>Sr. No</Th>
                          <Th className="custom-th whitespace-nowrap" style={{width: "220px", textAlign: "left"}}>Candidate Name</Th>
                          <Th className="custom-th" style={{ width: "350px"}}>Email</Th>
                          <Th className="custom-th" style={{width: "100px"}}>
                            Score
                          </Th>
                          <Th className="custom-th" style={{width: "100px"}}>Report</Th>
                          <Th className="custom-th" style={{width: "100px"}}>Edit</Th>
                        </Tr>
                      </Thead>
                      <Tbody  >
                      {shortlisted.map((candidate, index) => renderTableRow(candidate, index, 'shortlisted'))}
                      </Tbody>
                    </Table>

                    <Dialog open={modalIsOpen} onClose={closeModal}  PaperProps={{
                      style: {
                        background:
                        "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                      opacity: 1,
                      backdropFilter: "blur(500px)",
                      }
                    }}>
                      <DialogTitle className="text-white">Candidate Report</DialogTitle>
                      <DialogContent dividers className="text-white mt-2">
                        {currentReport ? (
                          <p>{currentReport}</p>
                        ) : (
                          <p>No report available</p>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={closeModal} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              )}

              {retrofit.length > 0 && (
                <div className="table-container">
                  <div className="table-header">
                    <h2 className="font-semibold text-white mb-7">RetroFit Candidates</h2>
                    {selectedCandidates.length > 0 && (
                      <div className="flex gap-4 py-4">
                        <button
                          className="button"
                          onClick={deleteSelectedCandidates}
                        >
                          <span> Delete Selected Candidates</span>
                        </button>
                        <button
                          className="button"
                          onClick={shortlistSelectedCandidates}
                        >
                          <span>Shortlist Selected Candidates</span>
                        </button>
                      </div>
                    )}
                    <Table>
                      <Thead>
                        <Tr>
                        <Th className="custom-th" style={{width: "60px"}}>Actions</Th>
                          <Th className="custom-th" style={{width: "130px", textAlign: "center"}}>Sr. No</Th>
                          <Th className="custom-th whitespace-nowrap" style={{width: "220px", textAlign: "left"}}>Candidate Name</Th>
                          <Th className="custom-th" style={{width: "350px"}}>Email</Th>
                          <Th className="custom-th" style={{width: "100px"}}>
                            Score
                          </Th>
                          <Th className="custom-th" style={{width: "100px"}}>Report</Th>
                          <Th className="custom-th" style={{width: "100px"}}>Edit</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                      {retrofit.map((candidate, index) => renderTableRow(candidate, index, 'retrofit'))}
                      </Tbody>
                    </Table>
                    </div>
                  </div>
              )}
              </>
            )}

            <Dialog open={modalIsOpen} onClose={closeModal} PaperProps={{
                style: {
                  background: "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                  opacity: 1,
                  backdropFilter: "blur(500px)",
                }
              }}>
                <DialogTitle className="text-white">Candidate Report</DialogTitle>
                <DialogContent dividers className="text-white mt-2">
                  {currentReport ? <p>{currentReport}</p> : <p>No report available</p>}
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeModal} color="primary">Close</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </Authenticator>
  );
}

export default App;
