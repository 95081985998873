import React, { useEffect, useState } from 'react';
import { Button, Authenticator } from '@aws-amplify/ui-react';
import DeleteIcon from "@mui/icons-material/Delete";
import axios from 'axios';
import host from '../../global.jsx';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Loader from '../Loader.jsx';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Upload } from 'lucide-react';
import { X, Edit2 } from 'lucide-react';
import { IconButton } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const DraggableTopic = ({ topic, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'TOPIC',
    item: { topic },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`glass-effect p-2 m-1 rounded-lg cursor-move text-white ${
        isDragging ? 'opacity-50' : 'opacity-100'
      }`}
    >
      {topic}
    </div>
  );
};

// Topic Drop Zone Component
const TopicDropZone = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'TOPIC',
    drop: (item) => onDrop(item.topic),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`border-2 border-dashed p-4 rounded-lg ${
        isOver ? 'border-blue-500 bg-blue-100/10' : 'border-gray-300'
      }`}
    >
      <p className="text-gray-400 text-center">Drop topics here to add them to your list</p>
    </div>
  );
};

const CreateMCQSet = () => {
  const [setsData, setSetsData] = useState({});
  const navigate = useNavigate();
  const [selectedSet, setSelectedSet] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [isAIMode, setIsAIMode] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [topics, setTopics] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [questions, setQuestions] = useState([]);
  const [questionsPerTopic, setQuestionsPerTopic] = useState({});
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [setName, setSetName] = useState("");
  const [areTopicsLoading, setAreTopicsLoading] = useState(false);
  const [newSetName, setNewSetName] = useState('');
  const [renameSetName, setRenameSetName] = useState('');
  const [experience, setExperience] = useState(0);
  const [minExp, setMinExp] = useState(0);
  const [maxExp, setMaxExp] = useState(1);
  const [mcqTimelimit, setMcqTimelimit] = useState(0);
  const [formData, setFormData] = useState({
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    answer: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [jobRole, setJobRole] = useState("");
  const [suggestedTopics, setSuggestedTopics] = useState([]);
  const [isTopicsFetching, setIsTopicsFetching] = useState(false);
  const [isTopicsFetched, setIsTopicsFetched] = useState(false);
  const [showQuestionBox, setShowQuestionBox] = useState(false);

  const handleBack = () => {
    navigate(-1); 
  };

  const handleInputKeyDown = (e) => {
    if(e.key == 'Enter' && inputValue.trim()) {
      const newTopic = inputValue.trim();
      if(!topics.includes(newTopic)) {
        setTopics(prevTopics => [...prevTopics, newTopic]);
        setQuestionsPerTopic(prev => ({
          ...prev,
          [newTopic]: 0
        }));
      }
      setInputValue('');
    }
  };

  const handleRemoveTopic = (topicToRemove) => {
    setTopics(prevTopics => prevTopics.filter(topic => topic !== topicToRemove));
    setQuestionsPerTopic(prev => {
      const updated = {...prev};
      delete updated[topicToRemove];
      return updated;
    });
  }

  const handleQuestionsPerTopicChange = (topic, value) => {
    setQuestionsPerTopic(prev => ({
      ...prev,
      [topic]: parseInt(value) || 0
    }));
  };

  const handleSetChange = (e) => {
    setSetName(e.target.value);
  };

  const handleSaveSet = async () => {
    try {
      const questionsDict = {};
      questions.forEach((question, index) => {
        questionsDict[(index + 1).toString()] = question;
      });

      const payload = {
        data: {
          ...questionsDict
        },
        mcq_timelimit: mcqTimelimit > 0 ? mcqTimelimit : null
      };

      const encodedSetName = encodeURIComponent(setName);

      const res = await axios.post(
        `${host}/create_mcq_set?setName=${encodedSetName}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.status === "success") {
        toast.success("Data Inserted");
        setSetName("");
        setMcqTimelimit('');
        window.location.reload();
      }
    } catch (e) {
      console.error("Error details:", e.response?.data);
      toast.error("Error saving set");
    }
  };

  const handleTimelimitChange = (e) => {
    setMcqTimelimit(e.target.value);
  };

  const handleFetchTopics = async () => {
    if (!jobRole.trim()) {
      toast.warn("Please enter a job role");
      return;
    }
    if (maxExp <= minExp) {
      toast.warn("Maximum experience must be greater than minimum experience");
      return;
    }

    setIsTopicsFetching(true);
    try {
      const response = await axios.post(
        `${host}/suggest_topics`,
        {
          jobRole,
          minExp,
          maxExp
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      const topics = response.data.topics;
      if (Array.isArray(topics)) {
        setSuggestedTopics(topics);
      } else {
        console.error('Invalid topics format:', topics);
        toast.error("Received invalid topics format from server");
        setSuggestedTopics([]);
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
      toast.error("Error fetching topics");
      setSuggestedTopics([]);
    } finally {
      setIsTopicsFetching(false);
      setIsTopicsFetched(true);
    }
  };

  const handleTopicDrop = (topic) => {
    if (!topics.includes(topic)) {
      setTopics(prev => [...prev, topic]);
      setQuestionsPerTopic(prev => ({
        ...prev,
        [topic]: 0
      }));
    }
  };

  const handleFetchQuestions = async () => {
    try {
      setShowQuestionBox(true);
      setIsLoadingQuestions(true);
      
      const payload = {
        topic_counts: Object.entries(questionsPerTopic)
          .filter(([topic, count]) => count > 0)
          .map(([topic, count]) => ({ topic, count })),
        jobRole: jobRole,  
        minExp: minExp,   
        maxExp: maxExp  
      };

      const response = await axios.post(`${host}/fetchmcq`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions", error);
      toast.error("Error fetching questions");
    } finally {
      setIsLoadingQuestions(false);
    }
  };

  const toggleMode = () => {
    setIsAIMode(!isAIMode);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${host}/fetch_all_mcqs`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setSetsData(response.data.sets["0"] || {});
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();      
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleSetQuestion = async (e) => {
    e.preventDefault();
    const { question, option1, option2, option3, option4, answer } = formData;

    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      toast.error("All fields must be filled.");
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      toast.error("All four options must be different.");
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      toast.error("Correct answer must match one of the options.");
      return;
    }

    const setName = selectedSet || newSetName;

    if (!setName) {
      toast.error("You must select or create a set.");
      return;
    }

    if (newSetName && Object.keys(setsData).includes(newSetName)) {
      toast.error("A set with this name already exists.");
      return;
    }
    console.log(formData);
    try {
      await axios.post(`${host}/add_mcq_to_set`, formData, {
        params: {
          set_name: setName
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });
      toast.success("Question Inserted");

      if (newSetName) {
        setSelectedSet(newSetName);
      }

      setFormData({
        question: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        answer: ''
      });
      setNewSetName('');
      fetchData();
    } catch (error) {
      toast.error("Error adding question.");
    }
  };

  const handleEditQuestion = (setName, index) => {
    setEditIndex({ setName, index });
    setEditFormData(setsData[setName][index]);
  };

  const handleSaveQuestion = async (setName, index) => {
    const { question, option1, option2, option3, option4, answer } = editFormData;

    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      toast.error("All fields must be filled.");
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      toast.error("All four options must be different.");
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      toast.error("Correct answer must match one of the options.");
      return;
    }
    console.log(editFormData);
    try {
      await axios.put(`${host}/editmcq`, editFormData, {
        params: {
          set_name: setName,
          index: index
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      });
      toast.success("Question Updated Successfully!");
      setEditIndex(null);
      fetchData();
    } catch (error) {
      toast.error("Error updating question.");
    }
  };

  const handleDeleteQuestion = async (setName, index) => {
    try {
      await axios.delete(`${host}/deletemcq`, {
        params: {
          set_name: setName,
          index: index,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      toast.success("Question Deleted Successfully!");
      fetchData();
    } catch (error) {
      toast.error("Error deleting question.");
    }
  };

  const handleRenameSet = async (e) => {
    e.preventDefault();
    if (!selectedSet || !renameSetName.trim()) {
      toast.error('Please enter a new name for the set');
      return;
    }

    if (setsData.hasOwnProperty(renameSetName.trim())) {
      toast.error('A set with this name already exists');
      return;
    }

    try {
      const trimmedNewName = renameSetName.trim();
      
      const response = await axios({
        method: 'put',
        url: `${host}/rename_set`,
        params: {
          old_name: selectedSet,
          new_name: trimmedNewName,
          set_type: 'mcq'
        },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.data.status === 'success') {
        const updatedSetsData = { ...setsData };
        updatedSetsData[trimmedNewName] = updatedSetsData[selectedSet];
        delete updatedSetsData[selectedSet];
        setSetsData(updatedSetsData);
        setSelectedSet(trimmedNewName);
        setRenameSetName('');
        toast.success('Set renamed successfully');
      }
    } catch (error) {
      console.error('Rename error:', error);
      const errorMessage = error.response?.data?.detail || 'Error renaming set';
      toast.error(errorMessage);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file.type !== 'application/pdf') {
      toast.error('Please upload a PDF file only');
      return;
    }
    setUploadedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type !== 'application/pdf') {
      toast.error('Please upload a PDF file only');
      return;
    }
    setUploadedFile(file);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!uploadedFile) {
      toast.error('Please select a PDF file');
      return;
    }

    if (!newSetName.trim()) {
      toast.error('Please enter a set name');
      return;
    }

    setIsProcessing(true);
    const formData = new FormData();
    formData.append('file', uploadedFile);
    formData.append('set_name', newSetName);

    try {
      const response = await axios.post(
        `${host}/parse_mcq_pdf`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
     

      if (response.data.status === 'success') {
        toast.success('MCQ Set created successfully!');
        setUploadedFile(null);
        setNewSetName('');
        setSelectedSet(newSetName);
        fetchData(); 
      }
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error.response?.data?.detail || 'Error processing PDF');
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
    <DndProvider backend={HTML5Backend}>
      <Authenticator>
        <div className="p-4 md:p-8 max-w-full">
          <div className="table-container glass-effect w-full">
            <div className="flex justify-between px-4 md:px-8 py-2">
              <div className="flex items-center space-x-4">
                <button
                  onClick={handleBack}
                  className="flex items-center space-x-2 text-white hover:text-gray-300 transition-colors"
                >
                  <ArrowLeft size={24} />
                </button>
                <h1 className="text-2xl font-semibold text-white">
                  <span>MCQ</span>
                </h1>
              </div>
              <div
                className="relative w-[300px] h-12 rounded-full cursor-pointer transition-all duration-500 bg-gradient-to-l from-[#FB54AE] to-[#2F64FF]"
                onClick={toggleMode}
              >

                <div
                  className={`absolute top-1 h-10 w-[148px] bg-black rounded-full shadow-md 
          transition-transform duration-500 ${isAIMode ? "translate-x-[146px]" : "translate-x-1"}`}
                />

                <div
                  className={`absolute inset-y-0 left-0 w-[147px] flex items-center justify-center text-sm font-semibold
          ${isAIMode ? "text-white" : "text-blue-600"}`}
                >
                  Generate with AI
                </div>

                <div
                  className={`absolute inset-y-0 right-0 w-[147px] flex items-center justify-center text-sm font-semibold
          ${isAIMode ? "text-pink-400" : "text-white"}`}
                >
                  Create Your Set
                </div>
              </div>
            </div>
            {isAIMode ? (
              <div className="flex flex-col md:flex-row w-full gap-4 md:gap-8 p-4 md:p-6">
                {/* Create Your Own Set */}
                <div className="flex flex-col w-full md:w-1/3 h-[calc(100vh-150px)] overflow-y-auto">
                  <h1 className="text-lg font-normal text-gray-800 mb-4 md:mb-7 text-left text-white">Create Your Own Set</h1>
                  <form class="space-y-4">
                  <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                      <label className="text-white w-full sm:w-1/3 mt-1" htmlFor="setSelect">
                        Select a Set
                      </label>
                      <select
                        id="setSelect"
                        className="glass-effect text-gray-400 text-md border border-gray-300 rounded-full px-3 py-1.5 h-10 w-full sm:w-2/3"
                        value={selectedSet}
                        onChange={(e) => {
                          setSelectedSet(e.target.value);
                          setRenameSetName('');
                        }}
                      >
                        <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="">
                          Select Set Name
                        </option>
                        {Object.keys(setsData).map((setName) => (
                          <option
                            key={setName}
                            value={setName}
                            style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}
                          >
                            SET {setName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                      <label class="mt-2 text-white w-full sm:w-1/3 whitespace-nowrap">
                        Create New
                      </label>
                      <input
                        name="newSetName"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full px-3 py-1.5 h-10 w-full sm:w-2/3 backdrop-blur-[10px] bg-white/30"
                        placeholder="Enter New Set Name"
                        value={newSetName}
                        onChange={(e) => setNewSetName(e.target.value)}
                        disabled={!!selectedSet}
                      />
                    </div>

                    {/* PDF Upload Section */}
                    <div className="mb-8">
                      <div className="flex flex-col space-y-6">
                        <div
                          className="glass-effect relative border-2 border-dashed border-gray-300/50 hover:border-gray-300/80 rounded-xl p-4 transition-all duration-300"
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          onClick={() => document.getElementById('fileInput').click()}
                        >
                          <input
                            id="fileInput"
                            type="file"
                            accept=".pdf"
                            className="hidden"
                            onChange={handleFileSelect}
                          />
                          
                          <div className="flex flex-col items-center justify-center space-y-2">
                            <div className="w-14 h-14 rounded-full bg-white/10 flex items-center justify-center">
                              <Upload size={22} className="text-gray-300" />
                            </div>
                            
                            {uploadedFile ? (
                              <div className="text-center">
                                <p className="text-gray-300 font-medium mb-2">{uploadedFile.name}</p>
                                <button
                                  className="text-red-400 hover:text-red-500 text-sm font-medium transition-colors duration-200"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setUploadedFile(null);
                                  }}
                                >
                                  Remove File
                                </button>
                              </div>
                            ) : (
                              <div className="text-center">
                                <p className="text-gray-300 font-medium text-sm mb-1">
                                  Drag and drop your PDF here
                                </p>
                                <p className="text-gray-300 text-[12px]">
                                  or <span className="text-blue-400">browse</span> to choose a file
                                </p>
                                <p className="text-white text-[10px] mt-2 font-light">
                                  Only PDF files are supported
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <button
                          className={`button w-full flex items-center justify-center space-x-2 py-3 rounded-xl font-medium transition-all duration-300 ${
                            isProcessing || !uploadedFile
                              ? 'bg-white/5 text-gray-400 cursor-not-allowed'
                              : 'bg-white/10 text-white hover:bg-white/20'
                          }`}
                          onClick={handleUpload}
                          disabled={isProcessing || !uploadedFile}
                        >
                          {isProcessing ? (
                            <>
                              <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                  fill="none"
                                />
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                />
                              </svg>
                              <span>Processing...</span>
                            </>
                          ) : (
                            <>
                              <span className="flex gap-2">
                              <Upload size={20} />
                              Process PDF</span>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                    <input
                      name="question"
                      placeholder="Enter Question"
                      class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-1.5 h-10 backdrop-blur-[10px] bg-white/30"
                      value={formData.question}
                      onChange={handleFormChange}
                    />

                    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                      <input
                        name="option1"
                        placeholder="Enter Option 1"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-1.5 h-10 backdrop-blur-[10px] bg-white/30"
                        value={formData.option1}
                        onChange={handleFormChange}
                      />
                      <input
                        name="option2"
                        placeholder="Enter Option 2"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-1.5 h-10 backdrop-blur-[10px] bg-white/30"
                        value={formData.option2}
                        onChange={handleFormChange}
                      />
                    </div>

                    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                      <input
                        name="option3"
                        placeholder="Enter Option 3"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-1.5 h-10 backdrop-blur-[10px] bg-white/30"
                        value={formData.option3}
                        onChange={handleFormChange}
                      />
                      <input
                        name="option4"
                        placeholder="Enter Option 4"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-1.5 h-10 backdrop-blur-[10px] bg-white/30"
                        value={formData.option4}
                        onChange={handleFormChange}
                      />
                    </div>

                    <input
                      name="answer"
                      placeholder="Enter Answer"
                      class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-1.5 h-10 backdrop-blur-[10px] bg-white/30"
                      value={formData.answer}
                      onChange={handleFormChange}
                    />

                    {/* Rename set section */}
                    {selectedSet && (
                      <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                        <label class="mt-2 text-white w-full sm:w-1/3 whitespace-nowrap">
                          Rename Set
                        </label>
                        <div className="flex w-full sm:w-2/3 space-x-2">
                          <input
                            class="glass-effect text-white text-md border border-gray-300 rounded-full px-3 py-1.5 h-10 w-full backdrop-blur-[10px] bg-white/30"
                            placeholder="Enter New Name for Selected Set"
                            value={renameSetName}
                            onChange={(e) => setRenameSetName(e.target.value)}
                          />
                          {renameSetName && (
                            <button
                              type="button"
                              onClick={handleRenameSet}
                              className="button bg-white/10 backdrop-blur-sm rounded-full px-4 text-white hover:bg-white/20"
                            >
                              <span>Save</span>
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    <div class="flex justify-center">
                      <button
                        class="button mx-auto mt-2.5 bg-white/10 backdrop-blur-sm rounded px-5 py-2.5 text-black shadow-lg font-normal text-base w-auto max-w-[300px] box-border"
                        onClick={handleSetQuestion}
                      >
                        <span>Add Question</span>
                      </button>
                    </div>
                  </form>
                </div>

                <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
                  <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">Questions</h3>
                  <div className="overflow-y-auto flex-grow">
                    {selectedSet ? (
                      <div class="displayQuestions">
                        {setsData[selectedSet] && (
                          <div class="questionsContainer">

                            {Object.entries(setsData[selectedSet]).map(([key, question], index) => {
                              if (key === 'mcq_timelimit' || !question.question || question.question.trim() === '') {
                                return null;
                              }

                              return (
                              <div
                                key={key}
                                class="displayedQuestions py-4"
                              >
                                {editIndex &&
                                  editIndex.setName === selectedSet &&
                                  editIndex.index === Number(key) ? (
                                  <>
                                    <div className="flex items-center mb-4">
                                      <label className="font-medium mr-4 text-gray-300">
                                        {editIndex.index + 1}.
                                      </label>
                                      <input
                                        name="question"
                                        placeholder="Enter Question"
                                        className="glass-effect text-gray-300 rounded w-full px-4 py-2 font-medium"
                                        value={editFormData.question}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">a</label>
                                      <input
                                        name="option1"
                                        placeholder="Enter Option A"
                                        className="glass-effect text-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.option1}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">b</label>
                                      <input
                                        name="option2"
                                        placeholder="Enter Option B"
                                        className="glass-effect text-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.option2}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">c</label>
                                      <input
                                        name="option3"
                                        placeholder="Enter Option C"
                                        className="glass-effect text-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.option3}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">d</label>
                                      <input
                                        name="option4"
                                        placeholder="Enter Option D"
                                        className="glass-effect text-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.option4}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">Answer</label>
                                      <input
                                        name="answer"
                                        placeholder="Enter Answer"
                                        className="glass-effect text-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.answer}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>
                                    <div class="flex space-x-4 mt-2">
                                      <button
                                        onClick={() =>
                                          handleSaveQuestion(selectedSet, Number(key))
                                        }
                                        className="button mt-4"
                                      >
                                        <span>Save</span>
                                      </button>
                                      <button
                                        onClick={() => setEditIndex(null)}
                                        className="button mt-4"
                                      >
                                        <span>Cancel</span>
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p class="displayedQuestion text-gray-700 font-medium">
                                      <div className="flex justify-between gap-x-2 min-h-[2rem] relative">
                                      <div className="text-base font-medium text-gray-300 text-left mb-2 pr-24 mt-0"
                                        style={{
                                          fontFamily: "Poppins",
                                          whiteSpace: "pre-wrap",
                                          wordWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {Number(index) + 1}.{" "}
                                        {setsData[selectedSet][key].question}
                                      </div>
                                      <div class="flex space-x-4 absolute top-0 right-0 mt-0">
                                      <button
                                        onClick={() =>
                                          handleEditQuestion(selectedSet, Number(key))
                                        }
                                      >
                                        <span className="flex gap-2 items-center">
                                      <Edit2 className="w-4 h-4 text-white" />
                                    </span>
                                      </button>
                                      <IconButton
                                        color="error"
                                        onClick={() =>
                                          handleDeleteQuestion(selectedSet, Number(key))
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                    </div>
                                    </p>
                                    <ul class="list-none text-gray-300 text-base">
                                      <li>a. {setsData[selectedSet][key].option1}</li>
                                      <li>b. {setsData[selectedSet][key].option2}</li>
                                      <li>c. {setsData[selectedSet][key].option3}</li>
                                      <li>d. {setsData[selectedSet][key].option4}</li>
                                    </ul>
                                    <p class="text-gray-300 mt-2">
                                      Correct Answer:{" "}
                                      <span class="font-medium">
                                        {setsData[selectedSet][key].answer}
                                      </span>
                                    </p>
                                  </>
                                )}
                              </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {/* <img
                  className="mx-auto w-1/2 h-auto"
                  src="/fetch-question.jpg"
                  alt="Question"
                /> */}
                        <p className="mt-10 text-center text-xs text-gray-400">
                          Feels empty! Select or Create a Set for questions to appear.
                        </p>
                      </div>

                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {areTopicsLoading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col md:flex-row w-full gap-8 p-10">
                    {/* Create MCQ's with AI */}
                    <div className="flex flex-col w-full h-auto overflow-y-auto">
                      <h1 className="text-lg font-normal text-white mb-7 text-left">
                        Create MCQ's with AI
                      </h1>
                      {!showQuestionBox ? (
                <>
                  {/* Job Role and Experience Inputs */}
                  <div className="flex flex-col w-full space-y-6">

                    <div className="flex gap-4">
                      <div className="space-y-4 w-1/2">
                        <label className="block font-semibold text-gray-300">Job Role</label>
                        <input 
                          type="text"
                          placeholder="e.g., Senior Software Engineer"
                          className="glass-effect w-full px-4 py-3 rounded-xl text-white"
                          value={jobRole}
                          onChange={(e) => setJobRole(e.target.value)}
                        />
                      </div>

                      <div className="space-y-4 w-1/4">
                        <label className="block font-semibold text-gray-300">Min Experience</label>
                        <input 
                          type="number"
                          min="0"
                          className="glass-effect w-full px-4 py-3 rounded-xl text-white"
                          value={minExp}
                          onChange={(e) => setMinExp(parseInt(e.target.value))}
                        />
                      </div>
                      <div className="space-y-4 w-1/4">
                        <label className="block font-semibold text-gray-300">Max Experience</label>
                        <input 
                          type="number"
                          min="0"
                          className="glass-effect w-full px-4 py-3 rounded-xl text-white"
                          value={maxExp}
                          onChange={(e) => setMaxExp(parseInt(e.target.value))}
                        />
                      </div>
                    </div>

                    <button
                      onClick={handleFetchTopics}
                      className="button mx-auto block"
                      disabled={isTopicsFetching}
                    >
                      <span>{isTopicsFetching ? 'Fetching Topics...' : 'Fetch Topics'}</span>
                    </button>

                    {/* Suggested Topics Section */}
                    {isTopicsFetched &&
                      <>
                      <div className="flex flex-col w-full border border-gray-300 rounded-2xl p-4">
                      <h3 className="text-lg font-semibold text-gray-300 mb-4">Suggested Topics</h3>
                      <div className="flex flex-wrap gap-2">
                        {suggestedTopics.map((topic) => (
                          <DraggableTopic key={topic} topic={topic} onDrop={handleTopicDrop} />
                        ))}
                      </div>
                    </div>

                    {/* Topics Management Section */}
                    <div className="mt-6">
                      <h3 className="text-lg font-semibold text-gray-300 mb-4">Selected Topics</h3>
                      <TopicDropZone onDrop={handleTopicDrop} />
                      
                      {/* Manual Topic Input */}
                      <div className="mt-4">
                        <input 
                          type="text"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          onKeyDown={handleInputKeyDown}
                          placeholder="Type a topic and press Enter"
                          className="glass-effect w-full px-4 py-2 rounded-lg text-white"
                        />
                      </div>

                      {/* Selected Topics List */}
                      <div className="mt-4 space-y-2">
                        {topics.map((topic) => (
                          <div key={topic} className="flex items-center justify-between p-2 rounded-lg">
                            <span className="text-white">{topic}</span>
                            <div className="flex items-center gap-2">
                              <input
                                type="number"
                                min="0"
                                max="10"
                                value={questionsPerTopic[topic] || 0}
                                onChange={(e) => handleQuestionsPerTopicChange(topic, e.target.value)}
                                className="glass-effect w-20 px-2 py-1 rounded text-white"
                              />
                              <button
                                onClick={() => handleRemoveTopic(topic)}
                                className="text-red-400 hover:text-red-500"
                              >
                                <X size={16} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>

                      {topics.length > 0 && (
                        <button
                          onClick={handleFetchQuestions}
                          className="mt-4 button sm:w-auto mx-auto block mb-8"
                        >
                          <span>Fetch Questions</span>
                        </button>
                      )}
                    </div>
                    </>
                    }
                    
                  </div>
                </>
              ) : (
                <div className="w-full">
                  {/* Question Box Section would go here */}
                  {isLoadingQuestions ? (
  <div className="flex flex-col w-full border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
    <h3 className="text-md font-semibold text-gray-300 mb-4 text-left">
      Questions
    </h3>
    <div className="flex flex-col items-center justify-center flex-grow">
      <p className="text-white text-lg mb-4">Please Wait...</p>
      <div className="w-full max-w-md">
        <div className="relative pt-1">
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-700">
            <div 
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 animate-[progress_2s_infinite]"
            ></div>
          </div>
        </div>
      </div>
      <p className="text-gray-400 text-sm mt-2">
        Generating your MCQs, this might take a moment
      </p>
    </div>
  </div>
) : questions.length > 0 ? (
  <div>
    <div className="flex flex-col w-full border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
      <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">
        Questions
      </h3>
      <div className="overflow-y-auto flex-grow">
        {questions.map((question, index) => (
          <div key={index} className="question rounded">
            <h3 className="text-base font-medium text-gray-300 text-left mt-4 mb-2">
              {Number(index) + 1}.{" "}
              {question.question}
            </h3>
            <ul className="list-none text-gray-300 text-base">
              <li>a. {question.option1}</li>
              <li>b. {question.option2}</li>
              <li>c. {question.option3}</li>
              <li>d. {question.option4}</li>
            </ul>
            <div className="border-b border-gray-300 mt-4"></div>
          </div>
        ))}
      </div>
    </div>
    <div className="flex flex-col space-y-4 mt-4">
      <div className="flex gap-2 items-center">
        <label className="mr-2 whitespace-nowrap text-md text-white">
          Set MCQ Timelimit:
        </label>
        <input 
          type="number"
          min="0"
          className="glass-effect w-32 px-4 py-3 rounded-xl text-white"
          value={mcqTimelimit}
          onChange={handleTimelimitChange}
          placeholder="Enter time limit"
        />
      </div>
      <div className="flex items-center gap-2">
        <label className="mr-2 whitespace-nowrap text-md text-white mb-2">
          Save As Set:
        </label>
        <input
          placeholder="Set Name"
          value={setName}
          onChange={handleSetChange}
          className={`text-white glass-effect rounded-xl px-4 py-3 w-32 mb-2`}
        />
        <button
          onClick={handleSaveSet}
          className="button text-md py-2 px-4 rounded hover:font-semibold bg-[#2d2d4a] text-white"
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
) : (
  <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
    <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">
      Questions
    </h3>
    <p className="mt-10 text-center text-xs text-gray-400">
      Feels empty! Select or Create a Set for questions to appear.
    </p>
  </div>
)}
                </div>
              )}
              </div>
            </div>
            )}
            </div>
          )}

          </div>
        </div>
      </Authenticator>
      </DndProvider>
    </>
  );
};

export default CreateMCQSet;