import React, { useState, useRef } from "react";
import { TextField } from "@mui/material";
import { CheckCircle, Star, Sparkles } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

import {
  User,
  Phone,
  Users,
  Mail,
  ArrowRight,
  Check,
  Calendar,
} from "lucide-react";
import host from "../../global.jsx";

const GenieHireForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    workEmail: "",
  });

  const [errors, setErrors] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    }
  };

  const handleKeyDown = (e, currentRef, nextRef) => {
    const { name, value } = e.target;

    if (name === "phoneNumber") {
      if (
        !/[\d+]/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "ArrowLeft" &&
        e.key !== "ArrowRight"
      ) {
        e.preventDefault();
      }
    }

    if (e.key === "Enter") {
      e.preventDefault();

      const { name, value } = e.target;
      let isValid = true;
      const newErrors = { ...errors };

      switch (name) {
        case "name":
          if (!value.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
          }
          break;
        case "workEmail":
          if (!value.trim()) {
            newErrors.workEmail = "Work email is required";
            isValid = false;
          } else if (!validateEmail(value)) {
            newErrors.workEmail = "Please enter a valid work email";
            isValid = false;
          }
          break;
        case "phoneNumber":
          if (value && !validatePhone(value)) {
            newErrors.phoneNumber = "Please enter a valid phone number";
            isValid = false;
          }
          break;
      }

      if (isValid) {
        if (nextRef && nextRef.current) {
          nextRef.current.focus();
        }
      } else {
        setErrors(newErrors);
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!re.test(email)) return false;

    const personalDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "icloud.com",
      "protonmail.com",
      "aol.com",
      "msn.com",
      "live.com",
      "me.com",
      "mac.com",
      "zoho.com",
      "yandex.com",
      "mail.com",
      "inbox.com",
      "gmx.com",
      "gmx.net",
      "fastmail.com",
      "hushmail.com",
      "rocketmail.com",
      "rediffmail.com",
      "comcast.net",
      "att.net",
      "verizon.net",
      "bellsouth.net",
      "sbcglobal.net",
      "cox.net",
      "earthlink.net",
      "juno.com",
      "netscape.net",
      "proton.me",
      "tutanota.com",
      "mail.ru",
      "btinternet.com",
      "charter.net",
      "shaw.ca",
      "rogers.com",
      "optonline.net",
      "web.de",
      "qq.com",
      "163.com",
      "sina.com",
    ];

    const domain = email.split("@")[1].toLowerCase();
    return !personalDomains.includes(domain);
  };
  const validatePhone = (phone) => {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return phone === "" || re.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Full name is required";
    }

    if (!formData.workEmail.trim()) {
      newErrors.workEmail = "Work email is required";
    } else if (!validateEmail(formData.workEmail)) {
      newErrors.workEmail = "Please enter a valid work email";
    }

    if (formData.phoneNumber && !validatePhone(formData.phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid phone number";
    }

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const response = await fetch(`${host}/api/demo-request`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        console.log("Success:", result);
        setShowSuccessModal(true);
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrors({ submit: "Failed to submit form. Please try again." });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    setFormData({
      name: "",
      phoneNumber: "",
      workEmail: "",
    });
  };

  return (
    <div
      className="min-h-screen bg-center bg-no-repeat relative p-4 md:p-12 items-center justify-center"
      style={{
        backgroundImage: "url('/bg.jpg')",
        backgroundSize: "cover",
        backgroundColor: "rgba(0,0,0,0.7)", //dark overlay
        backgroundBlendMode: "multiply",
      }}
    >
      
      <div className="grid grid-cols-1 md:grid-cols-[45%_55%] gap-6 max-w-6xl mx-auto">
        <div className="flex px-4 md:pl-10 justify-center">
          <div className="flex flex-col justify-center gap-2 w-full max-w-[400px]">
          <img
              src="/genie-demo-logo.png"
              alt="GenieHire logo"
              className="w-[150px] mb-6 md:mb-10 md:w-[200px] mt-4 mx-auto md:mx-0 "
            />
            <h2 className="text-center md:text-left font-bold text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#2960FD] to-[#FF60B6]">
              Hire Smarter, Not Harder!
            </h2>
            <p className="text-sm text-gray-300 text-center md:text-left w-full md:w-[350px] font-light mb-4 md:mb-0">
              Get a free demo session with one of our experts
            </p>
            <form onSubmit={handleSubmit} className="space-y-6 w-full">
              <div className="relative">
                <TextField
                  label="Name *"
                  name="name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  inputRef={nameRef}
                  value={formData.name}
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown(e, nameRef, emailRef)}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    mt: 3,
                    mb: 0,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "25px",
                      "& fieldset": {
                        borderColor: "#4a5b7a",
                      },
                      "&:hover fieldset": {
                        borderColor: "#4a5b7a",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#6b7c9b",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px 14px",
                      color: "#fff",
                      fontSize: "13px",
                      lineHeight: "1.5",
                      "&::placeholder": {
                        color: "#a0a8bd",
                        opacity: 1,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#a0a8bd",
                      fontSize: "16px",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#a0a8bd",
                    },
                  }}
                />
                {errors.name && (
                  <p className="text-xs text-red-400 mt-2 pl-4">{errors.name}</p>
                )}
              </div>
              <div className="relative">
                <TextField
                  label="Work Email *"
                  name="workEmail"
                  type="email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  inputRef={emailRef}
                  value={formData.workEmail}
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown(e, emailRef, phoneRef)}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    mt:0,
                    mb:0,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "25px",
                      "& fieldset": {
                        borderColor: "#4a5b7a",
                      },
                      "&:hover fieldset": {
                        borderColor: "#4a5b7a",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#6b7c9b",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "14px 14px",
                        color: "#fff",
                        fontSize: "14px",
                        lineHeight: "1.5",
                        "&::placeholder": {
                          color: "#a0a8bd",
                          opacity: 1,
                        },
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#a0a8bd",
                      fontSize: "16px",
                      transform: "translate(14px, -6px) scale(0.75)",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#a0a8bd",
                    },
                  }}
                />
                {errors.workEmail && (
                  <p className="text-xs text-red-400 mt-2 pl-4">{errors.workEmail}</p>
                )}
              </div>
              <div className="relative">
                <TextField
                  label="Contact Number (Optional)"
                  name="phoneNumber"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  inputRef={phoneRef}
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown(e, phoneRef, null)}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    mt: 0,
                    mb: 0,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "25px",
                      "& fieldset": {
                        borderColor: "#4a5b7a",
                      },
                      "&:hover fieldset": {
                        borderColor: "#4a5b7a",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#6b7c9b",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "14px 14px",
                        color: "#fff",
                        fontSize: "14px",
                        lineHeight: "1.5",
                        "&::placeholder": {
                          color: "#a0a8bd",
                          opacity: 1,
                        },
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#a0a8bd",
                      fontSize: "16px",
                      transform: "translate(14px, -6px) scale(0.75)",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#a0a8bd",
                    },
                  }}
                />
                {errors.phoneNumber && (
                  <p className="text-xs text-red-400 mt-2 pl-4">{errors.phoneNumber}</p>
                )}
              </div>
              {errors.submit && (
                <p className="text-sm text-red-500 mt-2">{errors.submit}</p>
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full py-2.5 px-3 bg-gradient-to-r from-blue-500 to-pink-500 text-white rounded-full hover:from-blue-600 hover:to-pink-600 flex items-center justify-center"
              >
                {isSubmitting ? "Processing..." : "Request Demo"}
                {/* {!isSubmitting && <ArrowRight className="ml-2" size={18} />} */}
              </button>
            </form>
          </div>
        </div>

        {/* <div className="w-[500px] h-[595px] flex items-center justify-center">
          <img
            className="w-full h-full object-cover"
            src="/Demo-image.png"
            alt="Image"
          />
        </div> */}
        <div className="mt-8 md:mt-12 px-4 md:px-0 flex justify-center">
          <div className="flex flex-col md:flex-row gap-4 md:h-[450px] w-full max-w-[600px]">
            <div className="flex flex-col md:flex-row gap-4 w-full">
              <div className="flex flex-col gap-4 md:max-w-[230px] w-full">
                <div className="flex flex-col border border-gray-300/30 bg-gray-100/10 p-6 md:p-10 gap-1 rounded-xl flex-1">
                  <h3 className="font-bold text-3xl md:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-[#2960FD] via-[#FF60B6] to-[#FF60B6]">70<span className="text-2xl md:text-3xl">%</span></h3>
                  <p className="text-gray-300 font-light text-sm md:text-base">Reduction in Time-To-Hire & Cost-To-Hire</p>
                </div>
                <div className="flex flex-col border border-gray-300/30 bg-gray-100/10 p-6 md:p-10 gap-1 rounded-xl flex-1">
                  <h3 className="font-bold text-3xl md:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-[#2960FD] via-[#FF60B6] to-[#FF60B6]">3<span className="text-2xl md:text-3xl">x</span></h3>
                  <p className="text-gray-300 font-light text-sm md:text-base">Improvement In Candidate Experience</p>
                </div>
              </div>
              <div className="flex flex-col gap-4 md:max-w-[280px] w-full">
                <div className="h-[200px] md:h-[27%]">
                  <img 
                    src="/demo-page-image-1.png" 
                    alt="Image" 
                    className="border border-gray-300/30 bg-gray-50/10 rounded-xl w-full h-full object-cover opacity-80" 
                  />
                </div>
                <div className="flex flex-col border border-gray-300/30 bg-gray-100/10 p-6 md:p-10 gap-1 rounded-xl md:h-[46%]">
                  <h3 className="font-bold text-3xl md:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-[#2960FD] via-[#FF60B6] to-[#FF60B6]">80<span className="text-2xl md:text-3xl">%</span></h3>
                  <p className="text-gray-300 font-light text-sm md:text-base">Growth in Candidate Conversion</p>
                </div>
                <div className="h-[200px] md:h-[27%]">
                  <img 
                    src="/demo-page-image-2.png" 
                    alt="Image" 
                    className="border border-gray-300/30 bg-gray-50/10 rounded-xl w-full h-full object-cover opacity-80" 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showSuccessModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60 backdrop-blur-xs"
            onClick={closeSuccessModal}
          >
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{
                scale: 1,
                opacity: 1,
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 25,
                },
              }}
              exit={{
                scale: 0.8,
                opacity: 0,
                transition: { duration: 0.3 },
              }}
              className="bg-white rounded-3xl p-10 w-full max-w-md shadow-2xl relative overflow-hidden"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Subtle Sparkle Background */}
              <motion.div
                className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: [0, 0.2, 0],
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut",
                  },
                }}
              >
                {[...Array(20)].map((_, i) => (
                  <motion.div
                    key={i}
                    className="absolute bg-[#D500F9]/30 rounded-full"
                    style={{
                      width: `${Math.random() * 20 + 5}px`,
                      height: `${Math.random() * 20 + 5}px`,
                      top: `${Math.random() * 100}%`,
                      left: `${Math.random() * 100}%`,
                    }}
                    animate={{
                      scale: [1, 1.5, 1],
                      opacity: [0.5, 1, 0.5],
                      transition: {
                        duration: Math.random() * 2 + 1,
                        repeat: Infinity,
                        repeatType: "mirror",
                      },
                    }}
                  />
                ))}
              </motion.div>

              {/* Animated Content */}
              <div className="text-center relative z-10">
                {/* Animated Check Circle */}
                <motion.div
                  initial={{ scale: 0, rotate: -180 }}
                  animate={{
                    scale: [0, 1.2, 1],
                    rotate: 0,
                    transition: {
                      type: "spring",
                      stiffness: 260,
                      damping: 20,
                    },
                  }}
                  className="mx-auto flex items-center justify-center mb-6 relative"
                >
                  <CheckCircle
                    className="h-20 w-20 text-[#D500F9] drop-shadow-lg"
                    strokeWidth={1.5}
                  />
                  {/* Orbiting Stars */}
                  <motion.div
                    className="absolute"
                    animate={{
                      rotate: 360,
                      transition: {
                        duration: 5,
                        repeat: Infinity,
                        ease: "linear",
                      },
                    }}
                  >
                    <Star
                      className="absolute top-[-48px] left-[-27px] text-yellow-400"
                      size={24}
                      fill="currentColor"
                    />
                    <Star
                      className="absolute bottom-[-46px] right-[-27px] text-amber-300"
                      size={20}
                      fill="currentColor"
                    />
                  </motion.div>
                </motion.div>

                {/* Animated Text */}
                <motion.h3 className="text-2xl font-semibold text-gray-800 mb-4 flex justify-center items-center gap-3">
                  Demo Requested!
                  {/* <Sparkles 
                  className="text-[#D500F9]" 
                  size={32} 
                /> */}
                </motion.h3>

                <motion.p className="text-sm text-gray-700 mb-6 leading-relaxed">
                  Our team will contact you in{" "}
                  <span className="text-[#D500F9] font-semibold">2 hours</span>.
                  Get ready for some hiring magic! ✨
                </motion.p>

                {/* Subtle Close Hint */}
                <motion.div className="text-xs text-gray-500 italic">
                  Click anywhere to close
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GenieHireForm;
