import React, { useEffect, useState } from "react";
import axios from "axios";
import host from "../../global.jsx";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell
} from "recharts";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  PaginationItem,
  TextField,
} from "@mui/material";
import Rating from "@mui/material/Rating";

const Feedback = () => {
  const [showFeedback, setShowFeedback] = useState([]);
  const [allFeedback, setAllFeedback] = useState([]);
  const [filteredFeedback, setFilteredFeedback] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalFeedbacks, setTotalFeedbacks] = useState(0);
  const [ratingDistribution, setRatingDistribution] = useState([]);
  const [comfortLevelDistribution, setComfortLevelDistribution] = useState([]);
  const [difficultyLevelDistribution, setDifficultyLevelDistribution] = useState([]);
  const [relevanceDistribution, setRelevanceDistribution] = useState([]);
  const [questionQualityDistribution, setQuestionQualityDistribution] = useState([]);
  const [criticalFeedback, setCriticalFeedback] = useState([]);
  const [positiveFeedback, setPositiveFeedback] = useState([]);
  const [overallSentiment, setOverallSentiment] = useState({
    positive: 0,
    neutral: 0,
    negative: 0,
  });
  const [averageRatings, setAverageRatings] = useState({
    rating: 0,
    comfortLevel: 0,
    difficultyLevel: 0,
    relevance: 0,
    questionQuality: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");

  const itemsPerPage = 10;
  const accessToken = Cookies.get("accessToken");
  const [filters, setFilters] = useState({
    sortField: "",
    sortOrder: "",
  });

  // Sentiment Analysis Function
  const analyzeSentiment = (text) => {
    if (!text || text.trim() === "") return { score: 0, label: "neutral" };

    const positiveWords = new Set([
      'great', 'excellent', 'good', 'amazing', 'wonderful', 'fantastic',
      'helpful', 'best', 'love', 'perfect', 'outstanding', 'awesome',
      'happy', 'pleased', 'satisfied', 'thank', 'thanks', 'impressive',
      'delightful', 'superb', 'exceptional', 'brilliant', 'favorable', 
      'out of this world', 'positive', 'uplifting', 'joyful', 'motivated',
      'encouraging', 'inspiring', 'rewarding', 'elevated', 'accomplished',
      'valuable', 'good job', 'well done', 'appreciated', 'pleasing',
      'outstanding', 'remarkable', 'extraordinary', 'incredible', 'dynamic',
      'phenomenal', 'stellar', 'refreshing', 'engaging', 'commendable',
      'impressive', 'masterful', 'motivational', 'impactful', 'successful',
      'brilliant', 'solid', 'powerful', 'polished', 'top-notch', 'world-class',
      'exceptionally good', 'fantastically helpful', 'rewarding experience',
      'highly recommend', 'incredible experience', 'above and beyond',
      'smooth', 'efficient', 'intuitive', 'quick', 'user-friendly', 'seamless',
      'easy', 'responsive', 'clear', 'accessible', 'organized', 'simple',
      'fast', 'clear instructions', 'well structured', 'well organized',
      'on time', 'up-to-date', 'convenient', 'personalized', 'interactive',
      'smooth experience', 'time-saving', 'informative', 'responsive support'
    ]);
    const negativeWords = new Set([
      'bad', 'poor', 'terrible', 'horrible', 'awful', 'worst',
      'disappointing', 'useless', 'hate', 'difficult', 'frustrated',
      'annoying', 'slow', 'expensive', 'waste', 'error', 'problem',
      'unhelpful', 'unsatisfactory', 'mediocre', 'regret', 'unpleasant',
      'underwhelming', 'irritating', 'uncomfortable', 'unproductive', 'discouraging',
      'stressful', 'overwhelming', 'unprofessional', 'confusing', 'challenging',
      'complicated', 'unmotivating', 'disorganized', 'disheartening', 'impossible',
      'disastrous', 'infuriating', 'unsuccessful', 'lacking', 'unqualified',
      'deficient', 'uncooperative', 'misleading', 'frustrating', 'unfit',
      'dismal', 'unresolved', 'shocking', 'negative experience', 'painful',
      'broken', 'disrupted', 'unproductive', 'unfocused', 'unwanted',
      'awkward', 'unresponsive', 'overwhelmed', 'outdated', 'uninspiring',
      'unconvincing', 'unprofessional', 'unstructured', 'chaotic', 'disturbing',
      'negative', 'unreliable', 'lacking direction', 'unfulfilled', 'disconnected',
      'lagging', 'glitchy', 'buggy', 'difficult to navigate', 'clunky', 'outdated',
      'cumbersome', 'poor performance', 'crashed', 'slow loading', 'confusing layout',
      'no support', 'unavailable', 'difficult to use', 'inconvenient', 'complicated',
      'unusable', 'unintuitive', 'hard to find', 'unhelpful resources', 'disjointed',
      'unavailable features', 'missing features', 'unsuitable', 'unsupported',
      'error message', 'unexpected behavior', 'long wait', 'limited functionality',
      'too complicated', 'lack of clarity', 'time-consuming', 'unreliable support'
    ]);

    const words = text.toLowerCase().split(/\W+/);
    let score = 0;

    words.forEach((word) => {
      if (positiveWords.has(word)) score += 1;
      if (negativeWords.has(word)) score -= 1;
    });

    const normalizedScore = score / Math.max(words.length / 2, 1);

    return {
      score: normalizedScore,
      label:
        normalizedScore > 0.1 ? "positive" : normalizedScore < -0.1 ? "negative" : "neutral",
    };
  };

  const getAllFeedback = async () => {
    try {
      const response = await axios.get(`${host}/get-all-feedback`, {
        params: {
          limit: 999999,
          sort_field: filters.sortField,
          sort_order: filters.sortOrder,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data) {
        setAllFeedback(response.data.feedbacks);
        processData(response.data.feedbacks);
      }
    } catch (error) {
      console.error("Error fetching all feedback:", error);
      toast.error("Error fetching analytics data");
    }
  };

  const getPaginatedFeedback = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${host}/get-all-feedback`, {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          sort_field: filters.sortField,
          sort_order: filters.sortOrder,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data) {
        setShowFeedback(response.data.feedbacks);
        setFilteredFeedback(response.data.feedbacks);
        setTotalFeedbacks(response.data.total_count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching paginated data:", error);
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          Cookies.remove("accessToken");
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllFeedback();
    getPaginatedFeedback();
  }, [currentPage, filters.sortField, filters.sortOrder, accessToken]);

  const processData = (feedbackData) => {
    const ratingDist = Array(11).fill(0);
    const comfortDist = Array(11).fill(0);
    const difficultyDist = Array(11).fill(0);
    const relevanceDist = Array(11).fill(0);
    const questionQualityDist = Array(11).fill(0);

    let totalRating = 0,
      totalComfort = 0,
      totalDifficulty = 0,
      totalRelevance = 0,
      totalQuestionQuality = 0;
    let ratingCount = 0,
      comfortCount = 0,
      difficultyCount = 0,
      relevanceCount = 0,
      questionQualityCount = 0;

    feedbackData.forEach((feedback) => {
      const rating = feedback.rating || 0;
      ratingDist[rating]++;
      if (rating > 0) {
        totalRating += rating;
        ratingCount++;
      }

      const comfortLevel = feedback.comfortLevel || 0;
      comfortDist[comfortLevel]++;
      if (comfortLevel > 0) {
        totalComfort += comfortLevel;
        comfortCount++;
      }

      const difficultyLevel = feedback.difficultyLevel || 0;
      difficultyDist[difficultyLevel]++;
      if (difficultyLevel > 0) {
        totalDifficulty += difficultyLevel;
        difficultyCount++;
      }

      const relevance = feedback.relevanceRating || 0;
      relevanceDist[relevance]++;
      if (relevance > 0) {
        totalRelevance += relevance;
        relevanceCount++;
      }

      const questionQuality = feedback.questionQuality || 0;
      questionQualityDist[questionQuality]++;
      if (questionQuality > 0) {
        totalQuestionQuality += questionQuality;
        questionQualityCount++;
      }
    });

    setRatingDistribution(
      ratingDist.map((count, index) => ({
        rating: index === 0 ? "No Rating" : `${index}`,
        count,
      }))
    );
    setComfortLevelDistribution(
      comfortDist.map((count, index) => ({
        rating: index === 0 ? "No Rating" : `${index}`,
        count,
      }))
    );
    setDifficultyLevelDistribution(
      difficultyDist.map((count, index) => ({
        rating: index === 0 ? "No Rating" : `${index}`,
        count,
      }))
    );
    setRelevanceDistribution(
      relevanceDist.map((count, index) => ({
        rating: index === 0 ? "No Rating" : `${index}`,
        count,
      }))
    );
    setQuestionQualityDistribution(
      questionQualityDist.map((count, index) => ({
        rating: index === 0 ? "No Rating" : `${index}`,
        count,
      }))
    );

    setAverageRatings({
      rating: ratingCount ? ((totalRating / ratingCount)).toFixed(1) : 0,  //need to change for new admins by including /2 as in others
      comfortLevel: comfortCount ? ((totalComfort / comfortCount)/2).toFixed(1) : 0,
      difficultyLevel: difficultyCount ? ((totalDifficulty / difficultyCount)/2).toFixed(1) : 0,
      relevance: relevanceCount ? ((totalRelevance / relevanceCount)/2).toFixed(1) : 0,
      questionQuality: questionQualityCount ? ((totalQuestionQuality / questionQualityCount)/2).toFixed(1) : 0,
    });

    const feedbackWithSentiment = feedbackData.map((feedback) => ({
      ...feedback,
      sentiment: analyzeSentiment(feedback.comments),
      improvementsSentiment: analyzeSentiment(feedback.improvements),
    }));

    const sentimentCounts = feedbackWithSentiment.reduce(
      (acc, curr) => {
        if (curr.comments) acc[curr.sentiment.label]++;
        return acc;
      },
      { positive: 0, neutral: 0, negative: 0 }
    );

    setOverallSentiment(sentimentCounts);

    const critical = feedbackWithSentiment.filter(
      (feedback) =>
        (feedback.rating !== null && feedback.rating <= 2 && feedback.rating > 0) ||
        feedback.comfortLevel <= 2 ||
        feedback.difficultyLevel <= 2 ||
        feedback.relevanceRating <= 2 ||
        feedback.questionQuality <= 2
    );
    setCriticalFeedback(critical);

    const positive = feedbackWithSentiment.filter(
      (feedback) =>
        (feedback.rating === 5 ||
          feedback.comfortLevel >= 9 ||
          feedback.difficultyLevel >= 9 ||
          feedback.relevanceRating >= 9 ||
          feedback.questionQuality >= 9) &&
        (feedback.comments || feedback.improvements)
    );
    setPositiveFeedback(positive);

    setAllFeedback(feedbackWithSentiment);
  };

  const getSentimentColor = (sentiment) => {
    switch (sentiment.label) {
      case "positive":
        return "text-green-600";
      case "negative":
        return "text-red-600";
      default:
        return "text-gray-600";
    }
  };

  const COLORS = [
    "#d3d3d3", "#FF8042", "#FFBB28", "#00C49F", "#0088FE",
    "#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#a4de6c", "#d0ed57"
  ];

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (!query) {
      setFilteredFeedback(showFeedback);
      setTotalFeedbacks(showFeedback.length);
      return;
    }

    const lowerQuery = query.toLowerCase();
    const filtered = showFeedback.filter((feedback) =>
      (feedback.comments && feedback.comments.toLowerCase().includes(lowerQuery)) ||
      (feedback.improvements && feedback.improvements.toLowerCase().includes(lowerQuery)) ||
      (feedback.repetitiveQuestions && feedback.repetitiveQuestions.toLowerCase().includes(lowerQuery))
    );
    setFilteredFeedback(filtered);
    setTotalFeedbacks(filtered.length);
  };

  const totalPages = Math.ceil(totalFeedbacks / itemsPerPage);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-4 px-12 mb-10">
      <h1 className="text-2xl font-semibold text-center mb-10 text-gray-800">Feedback Insights Dashboard</h1>

      {/* Summary Statistics */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8 border border-gray-200">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Key Metrics Overview</h2>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
          {[
            { label: "Avg. Overall Rating", value: averageRatings.rating, color: averageRatings.rating < 3 ? "text-red-600" : "text-green-600" },
            { label: "Avg. Comfort Level", value: averageRatings.comfortLevel, color: averageRatings.comfortLevel < 3 ? "text-red-600" : "text-green-600" },
            { label: "Avg. Difficulty", value: averageRatings.difficultyLevel, color: averageRatings.difficultyLevel < 3 ? "text-red-600" : "text-green-600" },
            { label: "Avg. Relevance", value: averageRatings.relevance, color: averageRatings.relevance < 3 ? "text-red-600" : "text-green-600" },
            { label: "Avg. Question Quality", value: averageRatings.questionQuality, color: averageRatings.questionQuality < 3 ? "text-red-600" : "text-green-600" },
          ].map((metric, index) => (
            <div key={index} className="p-4 bg-gray-50 rounded-lg border border-gray-200">
              <div className="text-gray-600 font-medium text-sm">{metric.label}</div>
              <div className={`text-2xl font-bold ${metric.color}`}>{metric.value}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Sentiment Analysis Overview */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8 border border-gray-200">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Sentiment Analysis Overview</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {[
            { label: "Positive", value: overallSentiment.positive, color: "bg-green-50 text-green-600" },
            { label: "Neutral", value: overallSentiment.neutral, color: "bg-gray-50 text-gray-600" },
            { label: "Negative", value: overallSentiment.negative, color: "bg-red-50 text-red-600" },
          ].map((sentiment, index) => (
            <div key={index} className={`p-4 rounded-lg ${sentiment.color}`}>
              <div className="font-semibold">{sentiment.label}</div>
              <div className="text-2xl font-bold">{sentiment.value}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Distribution Charts */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8 border border-gray-200">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Rating Distributions</h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {[
            { title: "Overall Rating", data: ratingDistribution, color: "#8884d8" },
            { title: "Comfort Level", data: comfortLevelDistribution, color: "#82ca9d" },
            { title: "Difficulty Level", data: difficultyLevelDistribution, color: "#ffc658" },
            { title: "Relevance", data: relevanceDistribution, color: "#ff7300" },
            { title: "Question Quality", data: questionQualityDistribution, color: "#a4de6c" },
          ].map((chart, index) => (
            <div key={index} className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-medium mb-4">{chart.title}</h3>
              <BarChart
                width={300}
                height={200}
                data={chart.data}
                margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="rating" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill={chart.color}>
                  {chart.data.map((entry, i) => (
                    <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </div>
          ))}
        </div>
      </div>

      {/* Positive and Critical Feedback */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        {/* Positive Feedback */}
        <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
          <h2 className="text-2xl font-semibold mb-4 text-green-600">Positive Feedback (High Scores)</h2>
          <div className="space-y-4 max-h-[400px] overflow-y-auto">
            {positiveFeedback.length === 0 ? (
              <p className="text-gray-500 italic">No high-score feedback found</p>
            ) : (
              positiveFeedback.map((feedback, index) => (
                <div key={index} className="border-l-4 border-green-500 pl-4 py-2">
                  <div className="grid grid-cols-2 gap-2 mb-2">
                    <div><span className="font-semibold">Rating:</span> {feedback.rating || "N/A"}</div>
                    <div><span className="font-semibold">Comfort:</span> {feedback.comfortLevel || "N/A"}</div>
                    <div><span className="font-semibold">Difficulty:</span> {feedback.difficultyLevel || "N/A"}</div>
                    <div><span className="font-semibold">Relevance:</span> {feedback.relevanceRating || "N/A"}</div>
                    <div><span className="font-semibold">Quality:</span> {feedback.questionQuality || "N/A"}</div>
                  </div>
                  {feedback.comments && (
                    <p className="text-gray-700"><span className="font-semibold">Comments:</span> {feedback.comments}</p>
                  )}
                  {feedback.improvements && (
                    <p className="text-gray-700"><span className="font-semibold">Improvements:</span> {feedback.improvements}</p>
                  )}
                </div>
              ))
            )}
          </div>
        </div>

        {/* Critical Feedback */}
        <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
          <h2 className="text-2xl font-semibold mb-4 text-red-600">Critical Feedback (Low Scores)</h2>
          <div className="space-y-4 max-h-[400px] overflow-y-auto">
            {criticalFeedback.length === 0 ? (
              <p className="text-gray-500 italic">No low-score feedback found</p>
            ) : (
              criticalFeedback.map((feedback, index) => (
                <div key={index} className="border-l-4 border-red-500 pl-4 py-2">
                  <div className="grid grid-cols-2 gap-2 mb-2">
                    <div><span className="font-semibold">Rating:</span> {feedback.rating || "N/A"}</div>
                    <div><span className="font-semibold">Comfort:</span> {feedback.comfortLevel || "N/A"}</div>
                    <div><span className="font-semibold">Difficulty:</span> {feedback.difficultyLevel || "N/A"}</div>
                    <div><span className="font-semibold">Relevance:</span> {feedback.relevanceRating || "N/A"}</div>
                    <div><span className="font-semibold">Quality:</span> {feedback.questionQuality || "N/A"}</div>
                  </div>
                  {feedback.comments && (
                    <p className="text-gray-700">
                      <span className="font-semibold">Comments:</span> {feedback.comments}{" "}
                      <span className={getSentimentColor(feedback.sentiment)}>
                        ({feedback.sentiment.label}: {(feedback.sentiment.score >= 0 ? "+" : "") + feedback.sentiment.score.toFixed(2)})
                      </span>
                    </p>
                  )}
                  {feedback.improvements && (
                    <p className="text-gray-700">
                      <span className="font-semibold">Improvements:</span> {feedback.improvements}{" "}
                      <span className={getSentimentColor(feedback.improvementsSentiment)}>
                        ({feedback.improvementsSentiment.label}: {(feedback.improvementsSentiment.score >= 0 ? "+" : "") + feedback.improvementsSentiment.score.toFixed(2)})
                      </span>
                    </p>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Filters and Search */}
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6 gap-4">
        <TextField
          label="Search Feedback"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search comments, improvements..."
          className="w-full sm:w-1/3"
        />
        <div className="flex gap-4">
          <FormControl variant="outlined" size="small" style={{ minWidth: 150 }}>
            <InputLabel>Sort By Field</InputLabel>
            <Select
              value={filters.sortField}
              onChange={(e) => {
                setCurrentPage(1);
                setFilters((prev) => ({ ...prev, sortField: e.target.value }));
              }}
              label="Sort By Field"
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="rating">Overall Rating</MenuItem>
              <MenuItem value="comfortLevel">Comfort Level</MenuItem>
              <MenuItem value="difficultyLevel">Difficulty Level</MenuItem>
              <MenuItem value="relevanceRating">Relevance</MenuItem>
              <MenuItem value="questionQuality">Question Quality</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" style={{ minWidth: 150 }}>
            <InputLabel>Sort Order</InputLabel>
            <Select
              value={filters.sortOrder}
              onChange={(e) => {
                setCurrentPage(1);
                setFilters((prev) => ({ ...prev, sortOrder: e.target.value }));
              }}
              label="Sort Order"
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="desc">High to Low</MenuItem>
              <MenuItem value="asc">Low to High</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      {/* Feedback Table */}
      {filteredFeedback.length === 0 ? (
        <div className="text-center text-gray-500">No feedback available</div>
      ) : (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200">
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border-b p-4 text-left text-gray-700">Rating</th>
                  <th className="border-b p-4 text-left text-gray-700">Comfort</th>
                  <th className="border-b p-4 text-left text-gray-700">Difficulty</th>
                  <th className="border-b p-4 text-left text-gray-700">Relevance</th>
                  <th className="border-b p-4 text-left text-gray-700 whitespace-nowrap">Question Quality</th>
                  <th className="border-b p-4 text-left text-gray-700">Comments</th>
                  {/* <th className="border-b p-4 text-left text-gray-700">Repetitive Questions</th>
                  <th className="border-b p-4 text-left text-gray-700">Improvements</th> */}
                </tr>
              </thead>
              <tbody>
                {filteredFeedback.map((feedback, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="border-b p-4 text-center">{feedback.rating || "N/A"}</td>
                    <td className="border-b p-4 text-center">{feedback.comfortLevel || "N/A"}</td>
                    <td className="border-b p-4 text-center">{feedback.difficultyLevel || "N/A"}</td>
                    <td className="border-b p-4 text-center">{feedback.relevanceRating || "N/A"}</td>
                    <td className="border-b p-4 text-center">{feedback.questionQuality || "N/A"}</td>
                    <td className="border-b p-4">{feedback.comments || "No comment"}</td>
                    {/* <td className="border-b p-4">{feedback.repetitiveQuestions || "N/A"}</td>
                    <td className="border-b p-4">{feedback.improvements || "N/A"}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Pagination Section */}
      <div className="mt-6 flex flex-col sm:flex-row justify-between items-center gap-4">
        <div className="text-sm text-gray-600">
          Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
          {Math.min(currentPage * itemsPerPage, totalFeedbacks)} of{" "}
          {totalFeedbacks} results
        </div>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          color="primary"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#4B5D67",
                  fontWeight: 600,
                  borderRadius: "12px",
                  padding: "5px 10px",
                },
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Feedback;