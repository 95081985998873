import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Pagination, PaginationItem } from "@mui/material";
import { Authenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import host from "../../global";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
const determineStatus = (status) => {
  if (status === "Completed") {
    return <div className="status-completed">Completed</div>;
  } else if (status === "In Progress") {
    return <div className="status-in-progress">In Progress</div>;
  } else {
    return <div className="status-pending">Pending</div>;
  }
};
export default function CandidateTable() {
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [itemsPerPage] = useState(10);
  const [areCandidatesLoading, setAreCandidatesLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [jobPositions, setJobPositions] = useState([]);
  const statusOptions = ["Completed", "In Progress", "Pending"];
  const isSmallScreen = useMediaQuery("(max-width: 750px)");
  const accessToken = Cookies.get("accessToken");
  const location = useLocation();
  const { page, job_position, combined_status } = location.state || {};
  const [currentPage, setCurrentPage] = useState(page || 1);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [filters, setFilters] = useState({
    jobPosition: job_position || "All",
    combinedStatus: combined_status || "All",
    startDate: null,
    endDate: null
  });
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);
  useEffect(() => {
    if (debouncedQuery.trim() === "") {
      setSearchResults([]);
      return;
    }
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get(`${host}/search_candidates`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            search_query: debouncedQuery,
          },
        });
        setSearchResults(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchSearchResults();
  }, [debouncedQuery, accessToken]);
  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(`${host}/jobroles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setJobPositions(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchJobPositions();
  }, [accessToken]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setAreCandidatesLoading(true);
        const { jobPosition, combinedStatus, startDate, endDate} = filters;
        
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate, true);

        const response = await axios.get(`${host}/get_candidates_data`, {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            job_position: jobPosition,
            combined_status: combinedStatus,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setCandidates(response.data.candidates);
        setTotalCandidates(response.data.total_count);
        setAreCandidatesLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
        if (error.message === "UserUnAuthenticatedException") {
          navigate("/");
        }
      }
    };
    fetchData();
  }, [filters, currentPage, accessToken, navigate, itemsPerPage]);

  const formatDate = (date, isEndDate = false) => {
    if (!date) return null;
  
    const formattedDate = new Date(date);
    if (isEndDate) {
      formattedDate.setHours(23, 59, 59, 999);
    } else {
      formattedDate.setHours(0, 0, 0, 0);
    }
    return formattedDate.toISOString();
  };

  const handleDateChange = (value) => {
    setDateRange(value);
    setShowCalendar(false);
    setCurrentPage(1);
    setFilters(prev => ({
      ...prev,
      startDate: value[0],
      endDate: value[1]
    }));
  };

  const formatDateRange = (dateRange) => {
    if (!dateRange[0] || !dateRange[1]) return 'Select Date Range';
    return `${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`;
  };

  const totalPages = Math.ceil(totalCandidates / itemsPerPage);
  const getPaginationRange = (currentPage, totalPages) => {
    const range = [];
    const start = Math.max(1, currentPage - 1);
    const end = Math.min(totalPages, currentPage + 9);
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };
  const totalPagesArray = getPaginationRange(currentPage, totalPages);
  const handleViewCandidate = (interviewId) => {
    navigate(`/org-admin/evaluationpage/${interviewId}`, {
      state: {
        page: currentPage,
        job_position: filters.jobPosition,
        combined_status: filters.combinedStatus,
      },
    });
  };
  if (areCandidatesLoading) {
    return <Loader />;
  }
  return (
    <Authenticator>
      <div className="p-8">
        <div className="table-container glass-effect  min-h-screen">
          <h1 className="text-white">Candidate Reports</h1>
          <div className="search-option">
            <div className="search-input">
              <TextField
                type="search"
                placeholder="Search by name"
                variant="outlined"
                size="small"
                className="search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  marginLeft: "10px",
                  minWidth: 250,
                  maxWidth: 300,
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(229, 229, 229, 0.5)",
                  },
                }}
                InputProps={{
                  style: {
                    color: "rgba(216, 216, 216, 1)",
                    borderColor: "rgba(229, 229, 229, 0.5)",
                    borderRadius: "50px",
                  },
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "50px",
                      color: "rgba(216, 216, 216, 1)",
                
                      "& fieldset": {
                        borderColor: "rgba(229, 229, 229, 0.5)",
                      },
                      "&:hover fieldset": {
                        borderColor: "rgba(229, 229, 229, 0.75)",
                        borderWidth: "1px",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(229, 229, 229, 1)",
                        borderWidth: "1px",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "rgba(216, 216, 216, 1) !important",
                      },
                  
                      "& .MuiIconButton-root": {
                        color: "rgba(216, 216, 216, 1) !important",
                      },
                  },
                }}
              />
              {searchQuery && (
                <div className="search-results">
                  {searchResults.length > 0 ? (
                    <ul>
                      {searchResults.map((candidate, index) => (
                        <li
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleViewCandidate(candidate.interview_id)
                          }
                        >
                          {candidate.name}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    searchQuery.trim() !== "" && (
                      <p className="no-results">No results found</p>
                    )
                  )}
                </div>
              )}
            </div>
              <div className="flex flex-wrap gap-[20px]">
            <FormControl
              variant="outlined"
              size="small"
              style={{
                marginRight: "10px",
                minWidth: 150,
                maxWidth: 150,
              }}
            >
              <InputLabel
                style={{
                  color: "rgba(229, 229, 229, 0.5)",
                }}
              >
                Job Position
              </InputLabel>
              <Select
                value={filters.jobPosition}
                onChange={(e) => {
                  setCurrentPage(1);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    jobPosition: e.target.value,
                  }));
                }}
                name="jobPosition"
                label="Job Position"
                style={{
                  color: "rgba(216, 216, 216, 1)",
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      background:
                        "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                      opacity: 1,
                      backdropFilter: "blur(500px)",
                      maxHeight: "300px",
                      overflow: "scroll",
                    },
                  },
                  getContentAnchorEl: null,
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(229, 229, 229, 0.5)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(229, 229, 229, 0.75)",
                    borderWidth: "1px"
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(229, 229, 229, 1)",
                    borderWidth: "1px"
                  },
                  "& .MuiSvgIcon-root.MuiSelect-icon": {
                    color: "rgba(216, 216, 216, 1) !important",
                  },
                }}
              >
                <MenuItem value="All" style={{ color: "rgba(216, 216, 216, 1)" }}>
                  All
                </MenuItem>
                {jobPositions.map((position) => (
                  <MenuItem
                    key={position}
                    value={position}
                    style={{ color: "rgba(216, 216, 216, 1)" }}
                  >
                    {position}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              size="small"
              style={{
                marginRight: "10px",
                minWidth: 150,
                maxWidth: 150,
              }}
            >
              <InputLabel
                style={{
                  color: "rgba(229, 229, 229, 0.5)",
                }}
              >
                Status
              </InputLabel>
              <Select
                value={filters.combinedStatus}
                onChange={(e) => {
                  setCurrentPage(1);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    combinedStatus: e.target.value,
                  }));
                }}
                name="combinedStatus"
                label="Status"
                style={{
                  color: "rgba(216, 216, 216, 1)",
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      background:
                        "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                      opacity: 1,
                      backdropFilter: "blur(500px)",
                    },
                  },
                  getContentAnchorEl: null,
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(229, 229, 229, 0.5)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(229, 229, 229, 0.75)",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(229, 229, 229, 1)",
                    borderWidth: "1px",
                  },
                  "& .MuiSvgIcon-root.MuiSelect-icon": {
                    color: "rgba(216, 216, 216, 1) !important",
                  },
                }}
              >
                <MenuItem
                  value="All"
                  style={{ color: "rgba(216, 216, 216, 1)" }}
                >
                  All
                </MenuItem>
                {statusOptions.map((status) => (
                  <MenuItem
                    key={status}
                    value={status}
                    style={{ color: "rgba(216, 216, 216, 1)" }}
                  >
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="calendar-containers">
      <TextField
        variant="outlined"
        size="small"
        value={formatDateRange(dateRange)}
        onClick={() => setShowCalendar(!showCalendar)}
        readOnly
        label="Select Date Range"
        InputLabelProps={{
          shrink: true,
          style: {
            color: "rgba(229, 229, 229, 0.5)",
          },
        }}
        InputProps={{
          style: {
            color: "rgba(216, 216, 216, 1)",
          },
        }}
        sx={{
          minWidth: 200,
          maxWidth: 210,
          "& .MuiOutlinedInput-root": {
            borderRadius: "50px",
            color: "rgba(216, 216, 216, 1)",
            "& fieldset": {
              borderColor: "rgba(229, 229, 229, 0.5)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(229, 229, 229, 0.75)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(229, 229, 229, 1)",
            },
          },
          "& input[type='date']::-webkit-calendar-picker-indicator": {
            filter: "invert(1)",
            opacity: 0.8,
          },
          "& input[type='date']::-moz-calendar-picker-indicator": {
            filter: "invert(1)",
          },
        }}
      />
      
      {showCalendar && (
        <div className="calendar-popup">
          <Calendar
            selectRange={true}
            onChange={handleDateChange}
            value={dateRange}
            tileClassName={({ date }) => {
              if (dateRange[0] && dateRange[1]) {
                return date >= dateRange[0] && date <= dateRange[1]
                  ? "highlighted"
                  : null;
              }
              return null;
            }}
          />
        </div>
      )}
    </div>
          </div>
          </div>
          <Table>
            <Thead>
              <Tr>
                <Th className="custom-th">Invited Date</Th>
                <Th className="custom-th" style={{ textAlign: "left" }}>
                  Candidate Name
                </Th>
                <Th className="custom-th" style={{ textAlign: "left" }}>
                  Job Role
                </Th>
                <Th className="custom-th" style={{ width: "fit-content" }}>
                  Knowledge Test Score
                </Th>
                <Th className="custom-th" style={{ width: "fit-content" }}>
                  Coding Score
                </Th>
                <Th className="custom-th" style={{ width: "fit-content" }}>
                  Interview Score
                </Th>
                <Th className="custom-th">Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {candidates.map((candidate) => {
                return (
                  <Tr
                    style={{ cursor: "pointer" }}
                    key={candidate.interview_id}
                    onClick={() => handleViewCandidate(candidate.interview_id)}
                  >
                    <Td>{candidate.invited_date}</Td>
                    <Td>
                      {!isSmallScreen ? (
                        <div
                          className="flex items-center"
                          style={{
                            whiteSpace: "normal",
                            textAlign: "left",
                            fontWeight: 450,
                          }}
                        >
                          <div style={{ whiteSpace: "normal" }}>
                            {candidate.candidate_name || "Unknown"}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "1rem",
                            fontWeight: "450",
                            color: "#1f2937",
                            whiteSpace: "normal",
                          }}
                        >
                          {candidate.candidate_name
                            .toLowerCase()
                            .replace(/\b\w/g, (char) => char.toUpperCase()) ||
                            "Unknown"}
                        </div>
                      )}
                    </Td>
                    <Td style={{ whiteSpace: "normal", textAlign: "left" }}>
                      {candidate.jdposition
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase()) ||
                        "Unknown Role"}
                    </Td>
                    <Td
                      style={{
                        width: "fit-content",
                        color:
                          isNaN(candidate.mcq_percent) ||
                          candidate.mcq_percent === "NA"
                            ? "[rgba(216, 216, 216, 1)]"
                            : candidate.mcq_percent > 60
                            ? "green"
                            : candidate.mcq_percent >= 45
                            ? "orange"
                            : "red",
                        fontWeight: "400",
                      }}
                    >
                      {isNaN(candidate.mcq_percent) ||
                      candidate.mcq_percent === "NA"
                        ? "NA"
                        : Number(candidate.mcq_percent).toFixed(2)}
                    </Td>
                    <Td
                      style={{
                        width: "fit-content",
                        color:
                          isNaN(candidate.coding_percent) ||
                          candidate.coding_percent === "NA"
                            ? "[rgba(216, 216, 216, 1)]"
                            : candidate.coding_percent > 60
                            ? "green"
                            : candidate.coding_percent >= 45
                            ? "orange"
                            : "red",
                        fontWeight: "400",
                      }}
                    >
                      {isNaN(candidate.coding_percent) ||
                      candidate.coding_percent === "NA"
                        ? "NA"
                        : Number(candidate.coding_percent).toFixed(2)}
                    </Td>
                    <Td
                      style={{
                        width: "fit-content",
                        color:
                          isNaN(candidate.interview_percent) ||
                          candidate.interview_percent === "NA"
                            ? "[rgba(216, 216, 216, 1)]"
                            : candidate.interview_percent > 60
                            ? "green"
                            : candidate.interview_percent >= 45
                            ? "orange"
                            : "red",
                        fontWeight: "400",
                      }}
                    >
                      {isNaN(candidate.interview_percent) ||
                      candidate.interview_percent === "NA"
                        ? "NA"
                        : Number(candidate.interview_percent).toFixed(2)}
                    </Td>
                    <Td className="report-status">
                      {determineStatus(candidate.combined_status)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <div className="table-footer">
            <div className="pagination-text">
              Showing{" "}
              <span className="font-medium">
                {(currentPage - 1) * itemsPerPage + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(currentPage * itemsPerPage, totalCandidates)}
              </span>{" "}
              of <span className="font-medium">{totalCandidates}</span> results
            </div>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              renderItem={(item) => {
                if (totalPagesArray.includes(item.page)) {
                  return (
                    <PaginationItem
                      component="button"
                      onClick={() => setCurrentPage(item.page)}
                      {...item}
                    />
                  );
                }
                return null;
              }}
            />
          </div>
        </div>
      </div>
    </Authenticator>
  );
}
