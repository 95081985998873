import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import host from "../../global";
import { Send, MessageSquare, Award } from 'lucide-react';

const ThankYou = () => {
  const { interview_id } = useParams();
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [feedback, setFeedback] = useState({
    rating: 0,
    comfortLevel: 0,
    relevanceRating: 0,
    questionQuality: 0,
    difficultyLevel: 0,
    comments: ""
  });

  const handleRedirect = () => {
    setIsRedirecting(true);
    setTimeout(() => {
      navigate(`/candidate/${interview_id}/testhomepage`);
    }, 2000);
  };

  const handleRatingClick = (field, rating) => {
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [field]: rating,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        `${host}/submit_feedback?interview_id=${interview_id}`,
        feedback
      );
      setFormSubmitted(true);
      handleRedirect();
    } catch (error) {
      console.error("Failed to submit feedback:", error);
      alert("There was an error submitting your feedback. Please try again.");
    }
  };

  const handleSkipFeedback = () => {
    setFormSubmitted(true);
    handleRedirect();
  };

  // Helper function to render rating scale
  const renderRatingScale = (field, lowLabel, highLabel) => {
    return (
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full mb-2">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
            <button
              key={rating}
              type="button"
              onClick={() => handleRatingClick(field, rating)}
              className={`w-9 h-9 rounded-full flex items-center justify-center transition-all duration-300 ease-in-out border ${
                feedback[field] === rating
                  ? 'bg-gradient-to-r from-[#2960FD] to-[#FF60B6] text-white scale-110 border-transparent'
                  : 'bg-transparent border-gray-300 text-gray-300 hover:bg-gray-600/50 hover:text-gray-200 hover:border-gray-200'
              }`}
            >
              {rating}
            </button>
          ))}
        </div>
        <div className="mt-1 flex justify-between w-full text-xs text-gray-300 mb-2">
          <span>{lowLabel}</span>
          <span>{highLabel}</span>
        </div>
      </div>
    );
  };

  if (formSubmitted) {
    return (
      <div className="min-h-screen app-background py-12 px-4 sm:px-6 lg:px-8 animate-gradient-x">
        <div className="max-w-4xl mx-auto">
          <div className="text-center my-16 animate-fade-in">
            <div className="flex justify-center mb-6">
              <Award className="w-20 h-20 text-yellow-400 animate-pulse" />
            </div>
            <h1 className="text-6xl font-semibold tracking-tight bg-gradient-to-r from-[#2960FD] to-[#FF60B6] text-transparent bg-clip-text mb-4">
              Thank You!
            </h1>
            <h3 className="text-2xl text-gray-200 mb-6">Your interview is complete.</h3>
            {isRedirecting && (
              <p className="text-gray-400 mb-4 animate-pulse">
                Redirecting to Home Page...
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }  

  return (
    <div className="min-h-screen app-background py-12 px-6 sm:px-8 lg:px-12 animate-gradient-x">
      <div className="max-w-4xl mx-auto">
        <div className="text-center my-10 animate-fade-in">
          <div className="flex justify-center mb-6">
            <Award className="w-20 h-20 text-yellow-400 animate-pulse" />
          </div>
          <h1 className="text-4xl font-bold tracking-tight bg-gradient-to-r from-[#2960FD] to-[#FF60B6] text-transparent bg-clip-text mb-4">
            Thank You!
          </h1>
          <h3 className="text-md text-gray-200 mb-2">Your interview is complete.</h3>
          <p className="text-gray-400 text-sm mb-14">
            Your feedback will help us improve. Please take a moment to share your thoughts.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-10 animate-fade-in">
          {/* Question 1 */}
          <div className="mb-8">
            <label className="block font-light text-gray-200 text-md mb-6">
              1. How would you rate your experience with the AI Interviewer?
            </label>
            {renderRatingScale("rating", "Very Bad", "Fantastic")}
          </div>

          {/* Question 2 */}
          <div className="mb-8">
            <label className="block font-light text-gray-200 text-md mb-6">
              2. How comfortable did you feel conversing with the AI Interviewer?
            </label>
            {renderRatingScale("comfortLevel", "Very Uncomfortable", "Extremely Comfortable")}
          </div>

          {/* Question 3 */}
          <div className="mb-8">
            <label className="block font-light text-gray-200 text-md mb-6">
              3. Were the questions relevant to the job role you applied for?
            </label>
            {renderRatingScale("relevanceRating", "Not Relevant", "Highly Relevant")}
          </div>

          {/* Question 4 */}
          <div className="mb-8">
            <label className="block font-light text-gray-200 text-md mb-6">
              4. How would you rate the quality of the questions and follow-up questions?
            </label>
            {renderRatingScale("questionQuality", "Poor Quality", "Excellent Quality")}
          </div>

          {/* Question 5 */}
          <div className="mb-8">
            <label className="block font-light text-gray-200 text-md mb-6">
              5. How appropriate was the difficulty level of the questions asked?
            </label>
            {renderRatingScale("difficultyLevel", "Too Easy", "Too Difficult")}
          </div>

          {/* Question 6 */}
          {/* <div className="mb-8">
            <label className="block font-light text-gray-200 text-md mb-4">
              6. Were there any questions that seemed repetitive or unnecessary?
            </label>
            <textarea
              name="repetitiveQuestions"
              value={feedback.repetitiveQuestions}
              onChange={handleInputChange}
              placeholder="Please share your thoughts..."
              rows="3"
              className="w-full max-w-2xl px-4 py-3 rounded-lg bg-gray-800/50 text-sm text-gray-200 text-white placeholder:text-sm placeholder-gray-400 border border-gray-700 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition-all duration-200"
            />
          </div> */}

          {/* Question 7 */}
          {/* <div className="mb-8">
            <label className="block font-light text-gray-200 text-md mb-4">
              7. What improvements would make the AI interview experience better?
            </label>
            <textarea
              name="improvements"
              value={feedback.improvements}
              onChange={handleInputChange}
              placeholder="Share your suggestions..."
              rows="3"
              className="w-full max-w-2xl px-4 py-3 rounded-lg bg-gray-800/50 text-sm text-gray-200 text-white placeholder:text-sm placeholder-gray-400 border border-gray-700 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition-all duration-200"
            />
          </div> */}

          {/* Question 8 */}
          <div className="mb-8">
            <label className="block font-light text-gray-200 text-md mb-4">
              6. Is there anything else you'd like to share about your interview experience?
            </label>
            <textarea
              name="comments"
              value={feedback.comments}
              onChange={handleInputChange}
              placeholder="Any additional thoughts..."
              rows="3"
              className="w-full max-w-2xl px-4 py-3 rounded-lg bg-gray-800/50 text-sm text-gray-200 text-white placeholder:text-sm placeholder-gray-400 border border-gray-700 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition-all duration-200"
            />
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-start mt-12 mb-16">
            <button
              type="submit"
              className="text-sm group px-3 py-2.5 bg-gradient-to-r from-[#2960FD] to-[#FF60B6] rounded-xl font-normal text-white transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 flex items-center justify-center gap-2"
            >
              {/* <span><Send className="w-5 h-5 group-hover:-translate-y-1 group-hover:translate-x-1 transition-transform" /></span> */}
              <span>Submit Feedback</span>
            </button>
            <button
              type="button"
              onClick={handleSkipFeedback}
              className="text-sm group px-3 py-2.5 bg-gray-800 hover:bg-gray-700 rounded-xl font-medium shadow-lg text-gray-200 transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 flex items-center justify-center gap-2"
            >
              <span>Skip Feedback</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ThankYou;