// import React, { useState, useMemo, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import host from "../../global";
// import { toast } from "react-toastify";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ResponsiveContainer,
//   Cell,
//   CartesianGrid,
//   LabelList,
// } from "recharts";
// import { SlidersHorizontal, Users, Trophy, ArrowUpRight } from "lucide-react";
// import {
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   Box,
//   Typography,
//   Card,
//   CardContent,
//   CardHeader,
//   Container,
//   Grid,
//   Slider,
//   Paper,
//   Chip,
//   LinearProgress,
//   TextField
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import { blue, green, grey } from "@mui/material/colors";
// import { useNavigate, useLocation } from "react-router-dom";
// const DashboardContainer = styled(Container)(({ theme }) => ({
//   minHeight: "100vh",
//   padding: theme.spacing(4),
// }));
// const HeaderSection = styled(Box)(({ theme }) => ({
//   marginBottom: theme.spacing(4),
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
// }));
// const StatCardStyled = styled(Card)(({ theme }) => ({
//   height: "100%",
//   transition: "transform 0.3s ease-in-out",
//   "&:hover": {
//     transform: "scale(1.03)",
//   },
// }));
// const FilterCard = styled(Card)(({ theme }) => ({
//   marginBottom: theme.spacing(3),
// }));
// const StatCard = ({ title, value, icon: Icon, trend, isLoading }) => (
//   <StatCardStyled elevation={3} sx={{ backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
//     <CardContent>
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="flex-start"
//       >
//         <Paper
//           elevation={0}
//           sx={{
//             backgroundColor: 'rgba(24, 14, 34, 0.5)',
//             p: 1.5,
//             borderRadius: 2,
//           }}
//         >
//           <Icon color={blue[600]} size={24} />
//         </Paper>
//         {trend && (
//           <Chip
//             icon={<ArrowUpRight size={16} />}
//             label={`${trend}%`}
//             color="success"
//             size="small"
//             variant="outlined"
//           />
//         )}
//       </Box>
//       <Box mt={2}>
//         <Typography variant="body2" color="white">
//           {title}
//         </Typography>
//         {isLoading ? (
//           <Box
//             sx={{
//               height: 32,
//               width: 80,
//               backgroundColor: grey[600],
//               borderRadius: 1,
//             }}
//           />
//         ) : (
//           <Typography variant="h5" fontWeight="bold" color="white">
//             {value}
//           </Typography>
//         )}
//       </Box>
//     </CardContent>
//   </StatCardStyled>
// );
// const CandidateAnalyticsDashboard = () => {
//   const [candidates, setCandidates] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [minScore, setMinScore] = useState(6);
//   const [avgScore, setAvgScore] = useState(0);
//   const [selectedDate, setSelectedDate] = useState();
//   const accessToken = Cookies.get("accessToken");
//   const [topPerformerCount, setTopPerformerCount] = useState(0);
//   const [jobPositions, setJobPositions] = useState([]);
//   const location = useLocation();
//   const { selectedJobPosition: locationJobPosition } = location.state || {};
//   const [selectedJobPosition, setSelectedJobPosition] = useState(
//     locationJobPosition || ""
//   );
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchJobPositions = async () => {
//       try {
//         setIsLoading(true);
//         const response = await axios.get(`${host}/getjdpositions`, {
//           headers: {
//             Authorization: `Bearer ${accessToken.toString()}`,
//           },
//         });
//         setJobPositions(response.data);
//         setIsLoading(false);
//       } catch (error) {
//         setIsLoading(false);
//         if (error.response) {
//           const status = error.response.status;
//           const detail = error.response.data.detail;
//           if (status === 401) {
//             await new Promise((resolve) => {
//               Cookies.remove("accessToken");
//               resolve();
//             });
//             toast.error(`Unauthorized: ${detail}`);
//           } else if (status === 404) {
//             toast.warning(`Not Found: ${detail}`);
//           } else {
//             toast.error(`Error: ${detail}`);
//           }
//         } else {
//           toast.error("An unexpected error occurred. Please try again.");
//         }
//       }
//     };
//     fetchJobPositions();
//   }, [accessToken]);

//   const CandidateService = {
//     async fetchCandidates(accessToken, jobRole, selectedDate) {
//       try {
//         const response = await axios.get(`${host}/candidateanalytics`, {
//           headers: { Authorization: `Bearer ${accessToken}` },
//           params: {
//             job_role: jobRole,
//             selected_date: selectedDate
//           }
//         });
//         return response.data;
//       } catch (error) {
//         console.error("Error fetching candidates:", error.message);
//         return [];
//       }
//     },
//   };
//   useEffect(() => {
//     const fetchData = async () => {
//       setIsLoading(true);
//       const data = await CandidateService.fetchCandidates(
//         accessToken,
//         selectedJobPosition.job_role,
//         selectedDate
//       );
//       setCandidates(data.candidates);
//       setIsLoading(false);
//     };
//     fetchData(); // eslint-disable-next-line
//   }, [accessToken, selectedJobPosition, selectedDate]);
//   useEffect(() => {
//     if (candidates && Array.isArray(candidates) && candidates.length > 0) {
//       const totalScore = candidates.reduce((sum, candidate) => {
//         const score =
//           candidate.average_score === "NA"
//             ? 0
//             : parseFloat(candidate.average_score);
//         return sum + score;
//       }, 0);
//       const topPerformerCount = candidates.filter((candidate) => {
//         const score =
//           candidate.average_score === "NA"
//             ? 0
//             : parseFloat(candidate.average_score);
//         return score > totalScore / candidates.length;
//       }).length;
//       setAvgScore(totalScore / candidates.length);
//       setTopPerformerCount(topPerformerCount);
//     }
//     else {
//       setAvgScore(0);
//       setTopPerformerCount(0);
//     }
//   }, [candidates, selectedJobPosition, selectedDate]);

//   const filteredCandidatesForChart = useMemo(() => {
//     if (!candidates || !Array.isArray(candidates)) {
//       return [];
//     }

//     return candidates.filter((candidate) => {
//       const score =
//         candidate.average_score === "NA"
//           ? 0
//           : parseFloat(candidate.average_score);
//       return score >= minScore;
//     });
//   }, [candidates, minScore]);


//   return (
//     <DashboardContainer maxWidth="xl">
//       <HeaderSection >
//         <Box>
//           <Typography variant="h4" fontWeight="bold" color="white">
//             Candidate Analytics
//           </Typography>
//           <Typography variant="body1" color="white"></Typography>
//         </Box>
//       </HeaderSection>
//       {/* Filters */}
//       <FilterCard elevation={2} sx={{backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
//         <CardContent>
//           <Grid container spacing={4} alignItems="center">
//             {/* Job Position Filter */}
//             <Grid item xs={5} md={6}>
//               <FormControl fullWidth>
//                 <InputLabel id="select-job-position-label" style={{color: "rgba(229, 229, 229, 0.5)",}}>Select Job Position</InputLabel>
//                 <Select
//                   labelId="demo-simple-select-label"
//                   id="demo-simple-select"
//                   label="Select Job Position"
//                   value={selectedJobPosition}
//                   onChange={(e) => setSelectedJobPosition(e.target.value)}
//                   style={{
//                     color: "rgba(216, 216, 216, 1)",
//                   }}
//                   MenuProps={{
//                     PaperProps: {
//                       style: {
//                         background:
//                           "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
//                         opacity: 1,
//                         backdropFilter: "blur(500px)",
//                         maxHeight: "300px",
//                         overflow: "scroll",
//                       },
//                     },
//                     getContentAnchorEl: null,
//                   }}
//                   sx={{
//                     "& .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "rgba(229, 229, 229, 0.5)",
//                     },
//                     "&:hover .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "rgba(229, 229, 229, 0.75)",
//                     },
//                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "rgba(229, 229, 229, 1)",
//                     },
//                     "& .MuiSvgIcon-root.MuiSelect-icon": {
//                       color: "rgba(216, 216, 216, 1) !important",
//                     },
//                   }}
//                 >
//                   {Object.entries(jobPositions).map(([id, position]) => (
//                     <MenuItem key={id} value={position} style={{ color: "rgba(216, 216, 216, 1)"}}>
//                       {position.job_role}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>

//             <Grid item xs={12} md={2}>
//             <TextField
//               type="date"
//               value={selectedDate}
//               onChange={(e) => setSelectedDate(e.target.value)}
//               fullWidth
//               label="Invite Date"
//               variant="outlined"
//               InputLabelProps={{
//                 shrink: true,
//                 style: { color: "rgba(229, 229, 229, 0.5)" }
//               }}
//               InputProps={{
//                 style: { color: "rgba(216, 216, 216, 1)" }
//               }}
//               sx={{
//                 "& .MuiOutlinedInput-notchedOutline": {
//                   borderColor: "rgba(229, 229, 229, 0.5) !important", // Keep border consistent
//                 },
//                 "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                   borderColor: "rgba(229, 229, 229, 1) !important", // Ensure focused state also matches
//                 },
//                 "&:hover .MuiOutlinedInput-notchedOutline": {
//                   borderColor: "rgba(229, 229, 229, 0.5) !important", // Prevent hover change
//                 },
//                 "& input[type='date']::-webkit-calendar-picker-indicator": {
//                   filter: "invert(1)",
//                   opacity: 0.8,
//                 },
//                 "& input[type='date']::-moz-calendar-picker-indicator": {
//                   filter: "invert(1)",
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   color: "rgba(216, 216, 216, 1)",
//                 }
//               }}
//             />
//             </Grid>
//             {/* Min Score Filter */}
//             <Grid item xs={12} md={4}>
//               <Box display="flex" alignItems="center" gap={2}>
//                 <Typography variant="body2" color="white" sx={{
//                   whiteSpace: "nowrap",
//                 }}>
//                   Min Score:
//                 </Typography>
//                 <Slider
//                   value={minScore}
//                   onChange={(_, value) => setMinScore(value)}
//                   min={5}
//                   max={100}
//                   step={5}
//                   marks
//                   valueLabelDisplay="auto"
//                 />
//               </Box>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </FilterCard>

//       {/* Stats Overview */}
//       <Grid container spacing={3} mb={3} >
//         <Grid item xs={12} sm={4} >
//           <StatCard
//             title="Total Candidates"
//             value={filteredCandidatesForChart.length}
//             icon={Users}
//             isLoading={isLoading}
//           />
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <StatCard
//             title="Average Score"
//             value={`${avgScore.toFixed(2)}/100`}
//             icon={SlidersHorizontal}
//             isLoading={isLoading}
//           />
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <StatCard
//             title="Top Performers"
//             value={topPerformerCount}
//             icon={Trophy}
//             isLoading={isLoading}
//           />
//         </Grid>
//       </Grid>
      
//       {/* Chart Section */}
//       <Card sx={{backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
//         <CardHeader title="Score Distribution" sx={{backgroundColor: 'rgba(105, 13, 197, 0.103)',color:"white" }} />
//         <CardContent sx={{backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
//           {isLoading ? (
//             <LinearProgress color="primary" />
//           ) : (
//             <Box
//               sx={{
//                 width: "100%",
//                 overflowX: "auto",
//                 overflowY: "hidden",
//                 padding: "8px 0",
//                backgroundColor: 'rgba(105, 13, 197, 0.103)',
//               }}
//             >
//               <Box
//                 sx={{
//                   width: `${Math.max(
//                     filteredCandidatesForChart.length * 50,
//                     1000
//                   )}px`,
//                   minWidth: "100%",
//                  backgroundColor: 'rgba(105, 13, 197, 0.103)',
//                 }}
//               >
//                 <ResponsiveContainer width="100%" height={300}>
//                   <BarChart
//                     data={filteredCandidatesForChart.map((candidate) => ({
//                       name: candidate.name,
//                       jobPosition: selectedJobPosition,
//                       score:
//                         candidate.average_score === "NA"
//                           ? 0
//                           : parseFloat(candidate.average_score),
//                       interviewId: candidate.interview_id,
//                     }))}
//                   >
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis
//                       dataKey="name"
//                       label={{
//                         value: "Candidate",
//                         position: "insideBottom",
//                         offset: 0,
//                       }}
//                       tick={{ fontSize: 12 }}
//                       interval={0}
//                       height={60}
//                     />
//                     <YAxis domain={[0, 100]} />
//                     <Tooltip />
//                     <Bar
//                       dataKey="score"
//                       radius={[4, 4, 0, 0]}
//                       barSize={40}
//                       onClick={(data) => {
//                         navigate(
//                           `/org-admin/evaluationpage/${data.interviewId}`,
//                           {
//                             state: {
//                               candidateanalytics: true,
//                               selectedJobPosition: data.jobPosition,
//                             },
//                           }
//                         );
//                       }}
//                     >
//                       <LabelList
//                         dataKey="score"
//                         position="top"
//                         style={{
//                           fontSize: "12px",
//                           fontWeight: "bold",
//                           fill: "#777",
//                         }}
//                       />
//                       {filteredCandidatesForChart.map((entry, index) => (
//                         <Cell
//                           key={`cell-${index}`}
//                           fill={
//                             parseFloat(entry.average_score) >= avgScore
//                               ? green[500]
//                               : blue[500]
//                           }
//                         />
//                       ))}
//                     </Bar>
//                   </BarChart>
//                 </ResponsiveContainer>
//               </Box>
//             </Box>
//           )}
//         </CardContent>
//       </Card>
//     </DashboardContainer>
//   );
// };
// export default CandidateAnalyticsDashboard;
import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import host from "../../global";
import { toast } from "react-toastify";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  CartesianGrid,
  LabelList,
  ScatterChart,
  Scatter,
} from "recharts";
import {
  SlidersHorizontal,
  Users,
  Trophy,
  ArrowUpRight,
} from "lucide-react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Slider,
  Paper,
  Chip,
  LinearProgress,
  TextField,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { blue, green, grey } from "@mui/material/colors";
import { useNavigate, useLocation } from "react-router-dom";

// Styled components remain unchanged
const DashboardContainer = styled(Container)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(4),
}));
const HeaderSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
const StatCardStyled = styled(Card)(({ theme }) => ({
  height: "100%",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.03)",
  },
}));
const FilterCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StatCard = ({ title, value, icon: Icon, trend, isLoading }) => (
  <StatCardStyled elevation={3} sx={{ backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
    <CardContent>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Paper elevation={0} sx={{ backgroundColor: 'rgba(24, 14, 34, 0.5)', p: 1.5, borderRadius: 2 }}>
          <Icon color={blue[600]} size={24} />
        </Paper>
        {trend && (
          <Chip icon={<ArrowUpRight size={16} />} label={`${trend}%`} color="success" size="small" variant="outlined" />
        )}
      </Box>
      <Box mt={2}>
        <Typography variant="body2" color="white">{title}</Typography>
        {isLoading ? (
          <Box sx={{ height: 32, width: 80, backgroundColor: grey[600], borderRadius: 1 }} />
        ) : (
          <Typography variant="h5" fontWeight="bold" color="white">{value}</Typography>
        )}
      </Box>
    </CardContent>
  </StatCardStyled>
);

// Custom Tooltip component to show candidate name and score
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload; // Extract the data from the payload
    return (
      <div style={{ backgroundColor: "#fff", padding: "5px 10px", border: "1px solid #ccc", borderRadius: "4px" }}>
        <p style={{ margin: 0, fontWeight: "bold" }}>{`Name: ${data.name}`}</p>
        <p style={{ margin: 0 }}>{`Score: ${data.score}`}</p>
      </div>
    );
  }
  return null;
};

const CandidateAnalyticsDashboard = () => {
  const [candidates, setCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [minScore, setMinScore] = useState(6);
  const [avgScore, setAvgScore] = useState(0);
  const [selectedDate, setSelectedDate] = useState();
  const [chartType, setChartType] = useState("bar");
  const accessToken = Cookies.get("accessToken");
  const [topPerformerCount, setTopPerformerCount] = useState(0);
  const [jobPositions, setJobPositions] = useState([]);
  const location = useLocation();
  const { selectedJobPosition: locationJobPosition } = location.state || {};
  const [selectedJobPosition, setSelectedJobPosition] = useState(locationJobPosition || "");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${host}/getjdpositions`, {
          headers: { Authorization: `Bearer ${accessToken.toString()}` },
        });
        setJobPositions(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchJobPositions();
  }, [accessToken]);

  const CandidateService = {
    async fetchCandidates(accessToken, jobRole, selectedDate) {
      try {
        const response = await axios.get(`${host}/candidateanalytics`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { job_role: jobRole, selected_date: selectedDate },
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching candidates:", error.message);
        return [];
      }
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await CandidateService.fetchCandidates(
        accessToken,
        selectedJobPosition.job_role,
        selectedDate
      );
      setCandidates(data.candidates);
      setIsLoading(false);
    };
    fetchData();
  }, [accessToken, selectedJobPosition, selectedDate]);

  useEffect(() => {
    if (candidates && Array.isArray(candidates) && candidates.length > 0) {
      const totalScore = candidates.reduce((sum, candidate) => {
        const score = candidate.average_score === "NA" ? 0 : parseFloat(candidate.average_score);
        return sum + score;
      }, 0);
      const topPerformerCount = candidates.filter((candidate) => {
        const score = candidate.average_score === "NA" ? 0 : parseFloat(candidate.average_score);
        return score > totalScore / candidates.length;
      }).length;
      setAvgScore(totalScore / candidates.length);
      setTopPerformerCount(topPerformerCount);
    } else {
      setAvgScore(0);
      setTopPerformerCount(0);
    }
  }, [candidates, selectedJobPosition, selectedDate]);

  const filteredCandidatesForChart = useMemo(() => {
    if (!candidates || !Array.isArray(candidates)) return [];
    return candidates.filter((candidate) => {
      const score = candidate.average_score === "NA" ? 0 : parseFloat(candidate.average_score);
      return score >= minScore;
    }).map((candidate, index) => ({
      name: candidate.name,
      jobPosition: selectedJobPosition,
      score: candidate.average_score === "NA" ? 0 : parseFloat(candidate.average_score),
      interviewId: candidate.interview_id,
      index, // Still needed for x-axis positioning
    }));
  }, [candidates, minScore, selectedJobPosition]);

  const renderChart = () => {
    if (isLoading) return <LinearProgress color="primary" />;

    if (chartType === "bar") {
      return (
        <Box sx={{ width: "100%", overflowX: "auto", overflowY: "hidden", padding: "8px 0", backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
          <Box sx={{ width: `${Math.max(filteredCandidatesForChart.length * 50, 1000)}px`, minWidth: "100%", backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={filteredCandidatesForChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" label={{ value: "Candidate", position: "insideBottom", offset: 0 }} tick={{ fontSize: 12 }} interval={0} height={60} />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Bar
                  dataKey="score"
                  radius={[4, 4, 0, 0]}
                  barSize={40}
                  onClick={(data) => navigate(`/org-admin/evaluationpage/${data.interviewId}`, { state: { candidateanalytics: true, selectedJobPosition: data.jobPosition } })}
                >
                  <LabelList dataKey="score" position="top" style={{ fontSize: "12px", fontWeight: "bold", fill: "#777" }} />
                  {filteredCandidatesForChart.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.score >= avgScore ? green[500] : blue[500]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      );
    } else {
      return (
        <ResponsiveContainer width="100%" height={300}>
          <ScatterChart>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="index"
              label={{ value: "Candidate Index", position: "insideBottom", offset: 0 }}
              domain={[0, filteredCandidatesForChart.length - 1]}
            />
            <YAxis dataKey="score" label={{ value: "Score", angle: -90, position: "insideLeft" }} domain={[0, 100]} />
            <Tooltip content={<CustomTooltip />} />
            <Scatter
              name="Candidates"
              data={filteredCandidatesForChart}
              fill={green[500]}
              onClick={(data) => navigate(`/org-admin/evaluationpage/${data.interviewId}`, { state: { candidateanalytics: true, selectedJobPosition: data.jobPosition } })}
            />
          </ScatterChart>
        </ResponsiveContainer>
      );
    }
  };

  return (
    <DashboardContainer maxWidth="xl">
      <HeaderSection>
        <Box>
          <Typography variant="h4" fontWeight="bold" color="white">Candidate Analytics</Typography>
          <Typography variant="body1" color="white"></Typography>
        </Box>
      </HeaderSection>

      <FilterCard elevation={2} sx={{ backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={5} md={6}>
              <FormControl fullWidth>
                <InputLabel id="select-job-position-label" style={{ color: "rgba(229, 229, 229, 0.5)" }}>Select Job Position</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Job Position"
                  value={selectedJobPosition}
                  onChange={(e) => setSelectedJobPosition(e.target.value)}
                  style={{ color: "rgba(216, 216, 216, 1)" }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        background: "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                        opacity: 1,
                        backdropFilter: "blur(500px)",
                        maxHeight: "300px",
                        overflow: "scroll",
                      },
                    },
                    getContentAnchorEl: null,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(229, 229, 229, 0.5)" },
                    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(229, 229, 229, 0.75)" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(229, 229, 229, 1)" },
                    "& .MuiSvgIcon-root.MuiSelect-icon": { color: "rgba(216, 216, 216, 1) !important" },
                  }}
                >
                  {Object.entries(jobPositions).map(([id, position]) => (
                    <MenuItem key={id} value={position} style={{ color: "rgba(216, 216, 216, 1)" }}>{position.job_role}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                fullWidth
                label="Invite Date"
                variant="outlined"
                InputLabelProps={{ shrink: true, style: { color: "rgba(229, 229, 229, 0.5)" } }}
                InputProps={{ style: { color: "rgba(216, 216, 216, 1)" } }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(229, 229, 229, 0.5) !important" },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(229, 229, 229, 1) !important" },
                  "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(229, 229, 229, 0.5) !important" },
                  "& input[type='date']::-webkit-calendar-picker-indicator": { filter: "invert(1)", opacity: 0.8 },
                  "& input[type='date']::-moz-calendar-picker-indicator": { filter: "invert(1)" },
                  "& .MuiOutlinedInput-root": { color: "rgba(216, 216, 216, 1)" },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex" alignItems="center" gap={2}>
                <Typography variant="body2" color="white" sx={{ whiteSpace: "nowrap" }}>Min Score:</Typography>
                <Slider
                  value={minScore}
                  onChange={(_, value) => setMinScore(value)}
                  min={5}
                  max={100}
                  step={5}
                  marks
                  valueLabelDisplay="auto"
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </FilterCard>

      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={4}>
          <StatCard title="Total Candidates" value={filteredCandidatesForChart.length} icon={Users} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard title="Average Score" value={`${avgScore.toFixed(2)}/100`} icon={SlidersHorizontal} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard title="Top Performers" value={topPerformerCount} icon={Trophy} isLoading={isLoading} />
        </Grid>
      </Grid>

      <Card sx={{ backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
        <CardHeader
          title="Score Distribution"
          sx={{ backgroundColor: 'rgba(105, 13, 197, 0.103)', color: "white" }}
          action={
            <Box>
              <Button
                variant="outlined"
                onClick={() => setChartType(chartType === "bar" ? "scatter" : "bar")}
                sx={{ color: "white", borderColor: "white" }}
              >
                {chartType === "bar" ? "Switch to Scatter" : "Switch to Bar"}
              </Button>
            </Box>
          }
        />
        <CardContent sx={{ backgroundColor: 'rgba(105, 13, 197, 0.103)' }}>
          {renderChart()}
        </CardContent>
      </Card>
    </DashboardContainer>
  );
};

export default CandidateAnalyticsDashboard;