import React, { useState, useEffect, useRef } from "react";
import {
  LibraryBig,
  X,
  AlertCircle,
  Info,
  CheckCircle2,
  HelpCircle,
  FolderPlus,
  Target,
  Book,
  Plus,
} from "lucide-react";
import host from "../../global";
import Cookies from "js-cookie";
import axios from "axios";

// Memoized Modal component to prevent unnecessary re-renders
const Modal = React.memo(
  ({
    isOpen,
    onClose,
    testName,
    setTestName,
    timelimit,
    setTimelimit,
    handleFinalize,
    isSaving,
  }) => {
    const textareaRef = useRef(null);
    const [errors, setErrors] = useState({ testName: ''});

    useEffect(() => {
      if (isOpen && textareaRef.current) {
        textareaRef.current.focus();
        console.log("Modal opened, focusing textarea");
      }
    }, [isOpen]);

    const validateForm = () => {
      let newErrors = { testName: ''};
      if(!testName.trim()) {
        newErrors.testName = 'Assessment name is required';
      }
      setErrors(newErrors);
      return !newErrors.testName;
    }

    const handleSave = () => {
      if(validateForm()) {
        handleFinalize();
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-[rgba(240,248,255,0.691)]">
              Assessment Details
            </h3>
            <div className="space-y-1">
              <label htmlFor="testName" className="text-sm text-[rgba(240,248,255,0.691)]">
                Assessment Name
              </label>
              <textarea
                ref={textareaRef}
                id="testName"
                value={testName}
                onChange={(e) => {
                  setTestName(e.target.value);
                  if(e.target.value.trim()) {
                    setErrors(prev => ({...prev, testName: ''}));
                  }
                  console.log("Textarea changed:", e.target.value);
                }}
                placeholder="Enter assessment name..."
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:border-blue-500 resize-y min-h-[100px]"
              />
              {errors.testName && (
                  <p className="text-red-400 text-xs mt-1">{errors.testName}</p>
              )}
            </div>

            <div className="space-y-1">
              <label 
                htmlFor="timelimit" 
                className="text-sm text-[rgba(240,248,255,0.691)]"
              >
                Time Limit (minutes)
              </label>
              <input
                type="number"
                min="5"
                max="90"
                value={timelimit}
                onChange={(e) => {
                  setTimelimit(e.target.value);
                }}
                className="w-full p-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:border-blue-500"
                placeholder="Enter time limit"
              />
            </div>
            
            <div className="flex justify-end gap-2">
              <button
                onClick={onClose}
                className="px-4 py-2 rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleFinalize}
                disabled={isSaving}
                className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200 disabled:opacity-50"
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const preBuiltTemplates = {
  "Leadership Assessment": [
    "Leadership & Collaboration",
    "Emotional Intelligence (EQ)",
    "Problem-Solving & Decision-Making",
    "Extraversion",
    "Conscientiousness",
  ],
  "Problem-Solving Focus": [
    "Problem-Solving & Decision-Making",
    "Time & Task Management",
    "Openness to Experience",
    "Self-Development & Growth Mindset",
  ],
  "Team Collaboration": [
    " Agreeableness",
    "Emotional Intelligence (EQ)",
    "Leadership & Collaboration",
    "Time & Task Management",
  ],
};

const BehavioralTestBuilder = () => {
  const [traitCategories, setTraitCategories] = useState({});
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [activeInfo, setActiveInfo] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [showNameModal, setShowNameModal] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [testName, setTestName] = useState("");
  const [timelimit, setTimelimit] = useState(5);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const accessToken = Cookies.get("accessToken");

  const fetchTraitCategories = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${host}/fetch_behavioral_traits`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const traitData = response.data.AssessmentData;
      const formattedTraits = transformTraitData(traitData);
      setTraitCategories(formattedTraits);
      console.log("Formatted trait data:", formattedTraits);
      console.log("trait data:", traitData);
      setIsLoading(false);
    } catch (err) {
      setError(err.response?.data?.detail || err.message);
      setIsLoading(false);
    }
  };

  const transformTraitData = (data) => {
    const transformed = {};
    Object.entries(data).forEach(([categoryKey, categoryValue]) => {
      if (
        typeof categoryValue !== "object" ||
        !categoryValue ||
        Array.isArray(categoryValue)
      ) {
        return;
      }
      transformed[categoryKey] = categoryValue;
    });
    return transformed;
  };

  useEffect(() => {
    fetchTraitCategories();
  }, []);

  // const handleDragStart = (e, trait, isCategory = false) => {
  //   e.dataTransfer.setData("trait", trait);
  //   e.dataTransfer.setData("isCategory", isCategory);
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const trait = e.dataTransfer.getData("trait");
  //   const isCategory = e.dataTransfer.getData("isCategory") === "true";

  //   if (isCategory) {
  //     const categoryTraits = Object.keys(traitCategories[trait] || {});
  //     const newTraits = [...new Set([...selectedTraits, ...categoryTraits])];
  //     setSelectedTraits(newTraits);
  //   } else if (!selectedTraits.includes(trait)) {
  //     setSelectedTraits([...selectedTraits, trait]);
  //   }
  // };

  const handleTraitRemove = (traitToRemove) => {
    setSelectedTraits(
      selectedTraits.filter((trait) => trait !== traitToRemove)
    );
  };

  const handleSelectAll = (category) => {
    const categoryTraits = Object.keys(traitCategories[category] || {});
    const newTraits = [...new Set([...selectedTraits, ...categoryTraits])];
    setSelectedTraits(newTraits);
  };

  const handleTemplateSelect = (templateName) => {
    setSelectedTraits(preBuiltTemplates[templateName]);
  };

  const handleAddTrait = (trait) => {
    if (!selectedTraits.includes(trait)) {
      setSelectedTraits([...selectedTraits, trait]);
    }
  };

  const TraitInformationPanel = ({ activeInfo, renderTraitInfo }) => {
    const TraitInfoSection = ({ icon: Icon, title, content }) => (
      <div className="bg-gradient-to-br from-white/10 to-white/5 rounded-xl p-5 transition-all duration-300 hover:from-white/15 hover:to-white/10">
        <div className="flex items-start gap-4">
          <div className="p-2 bg-blue-500/10 rounded-lg">
            <Icon size={20} className="text-blue-400" />
          </div>
          <div className="flex-1">
            <h4 className="font-semibold text-white/90 mb-3 flex items-center gap-2">
              {title}
            </h4>
            {Array.isArray(content) ? (
              <ul className="space-y-3">
                {content.map((item, index) => (
                  <li key={index} className="flex items-start gap-3 group">
                    <div className="mt-1 p-1 rounded-full bg-emerald-500/10 group-hover:bg-emerald-500/20 transition-colors duration-200">
                      <CheckCircle2 size={14} className="text-emerald-400" />
                    </div>
                    <span className="text-white/70 leading-relaxed">
                      {item}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-white/70 leading-relaxed">{content}</p>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <div className="p-6 rounded-2xl border border-white/30">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-blue-500/10 rounded-lg">
            <Book size={16} className="text-blue-400" />
          </div>
          <h2 className="text-lg font-medium text-white/90">Category Details</h2>
        </div>

        {activeInfo ? (
          <div className="space-y-4">
            <div className="grid gap-4">{renderTraitInfo(activeInfo)}</div>
          </div>
        ) : (
          <div className="h-[400px] flex items-center justify-center rounded-xl">
            <div className="text-center">
              <div className="w-12 h-12 mx-auto mb-4 rounded-full bg-blue-500/10 flex items-center justify-center">
                <Target size={30} className="text-blue-400" />
              </div>
              <h3 className="text-md font-normal text-white/90 mb-2">
                No Category Selected
              </h3>
              <p className="text-xs font-light text-white/50 max-w-[240px] mx-auto">
                Select an item from the list to view detailed information
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const ResultsModal = ({ isOpen, onClose, results }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <div className="bg-gray-900 rounded-xl w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
          <div className="p-6 border-b border-gray-800">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-medium text-white">
                Assessment Topic Preview
              </h2>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-white transition-colors"
              >
                <X size={24} />
              </button>
            </div>
            <p className="text-sm font-normal text-white/70 mt-2">
              These are the topics that will be used to generate your assessment
              questions
            </p>
          </div>

          <div className="p-6 overflow-y-auto flex-1">
            {results && results.traits ? (
              Object.entries(results.traits).map(([trait, topics]) => (
                <div key={trait} className="mb-8 last:mb-0">
                  <h3 className="text-md font-normal mb-4 text-white/90 flex items-center gap-2">
                    <Book size={16} className="text-blue-400" />
                    {trait}
                  </h3>
                  <div className="space-y-3">
                    {Array.isArray(topics) ? (
                      topics.map((topic, index) => (
                        <div
                          key={index}
                          className="bg-white/5 p-4 rounded-lg flex items-start gap-3 hover:bg-white/10 transition-colors"
                        >
                          <Target
                            size={16}
                            className="text-blue-400 mt-1 flex-shrink-0"
                          />
                          <p className="text-sm font-light text-white/80">{topic}</p>
                        </div>
                      ))
                    ) : (
                      <p className="text-red-400">
                        Invalid topic format for {trait}
                      </p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-white/70">
                <AlertCircle size={24} className="mx-auto mb-2" />
                <p>No topics available to preview</p>
              </div>
            )}
          </div>

          <div className="p-6 border-t border-gray-800 bg-gray-900/50">
            <div className="flex justify-end gap-4">
              <button
                onClick={onClose}
                className="px-6 py-2 rounded-lg bg-gray-800 text-white hover:bg-gray-700 transition-colors"
              >
                Edit
              </button>
              <button
                onClick={() => {
                  onClose();
                  setShowNameModal(true);
                }}
                className="px-6 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-500 transition-colors"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTemplateSection = () => (
    <div className="mb-8 bg-white/5 p-6 rounded-2xl">
      <h2 className="text-md font-medium mb-4 text-gray-300">
        Pre-Made Assessments
      </h2>
      <div className="flex flex-col gap-2">
        {Object.entries(preBuiltTemplates).map(([name]) => (
          <button
            key={name}
            onClick={() => handleTemplateSelect(name)}
            className="bg-white/5 p-4 rounded-xl text-[rgba(240,248,255,0.691)] 
                      hover:bg-white/10 transition-all duration-200 text-left"
          >
            <div className="flex items-center gap-2">
              <FolderPlus size={16} />
              <span className="text-sm font-normal">{name}</span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );

  const renderAvailableTraits = () => {
    if (isLoading) {
      return (
        <div className="bg-white/5 p-6 rounded-2xl h-fit">
          <h2 className="text-lg font-medium mb-6 text-[rgba(240,248,255,0.691)]">
            Assessment Categories
          </h2>
          <div className="flex items-center justify-center h-64">
            <div className="text-center">
              <svg
                className="animate-spin h-10 w-10 text-blue-400 mx-auto mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p className="text-[rgba(240,248,255,0.691)]">
                Loading categories...
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="bg-white/5 p-6 rounded-2xl h-fit">
          <h2 className="text-xl font-bold mb-6 text-[rgba(240,248,255,0.691)]">
            Assessment Categories
          </h2>
          <div className="bg-red-500/10 p-4 rounded-lg text-red-300">
            <p className="flex items-center gap-2">
              <AlertCircle size={20} />
              <span>Error loading categories: {error}</span>
            </p>
            <button
              onClick={fetchTraitCategories}
              className="mt-4 px-4 py-2 bg-red-500/20 rounded-lg hover:bg-red-500/30 transition-colors"
            >
              Retry
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="bg-white/5 p-6 rounded-2xl h-fit">
        <h2 className="text-lg font-medium mb-6 text-[rgba(240,248,255,0.691)]">
          Assessment Categories
        </h2>
        {Object.entries(traitCategories).map(([category, traits]) => (
          <div key={category} className="mb-6 last:mb-0">
            <div className="flex justify-between items-center mb-3">
              <h3 className="text-md font-medium text-[rgba(240,248,255,0.691)]">
                {category}
              </h3>
              <div className="flex gap-2">
                <button
                  onClick={() => handleSelectAll(category)}
                  className="text-sm bg-white/10 px-3 py-1.5 rounded-lg text-[rgba(240,248,255,0.691)]
                            hover:bg-white/20 transition-all duration-200"
                >
                  Add All
                </button>
              </div>
            </div>
            <div className="space-y-2">
              {Object.keys(traits).map((trait) => (
                <div
                  key={trait}
                  // draggable
                  // onDragStart={(e) => handleDragStart(e, trait)}
                  // onClick={() => setActiveInfo(trait)}
                  className={`bg-white/5 p-4 rounded-xl text-[rgba(240,248,255,0.691)]
                            hover:bg-white/10 transition-all duration-200 flex items-center gap-3 ${activeInfo === trait ? 'border border-blue-500' : 'border border-transparent'}`}
                >
                  <div 
                    onClick={() => setActiveInfo(trait)}
                    className="flex items-center gap-3 cursor-pointer flex-1"
                  >
                  <LibraryBig size={16} />
                  <span className="text-sm font-normal">{trait}</span>
                </div>
                <div className="relative group">
                  <button
                    onClick={() => handleAddTrait(trait)}
                    className="text-[rgba(240,248,255,0.691)] hover:text-green-400 transition-colors duration-200">
                      {selectedTraits.includes(trait) ? (
                        <CheckCircle2 size={16} className="text-green-400" />
                      ) : (
                        <Plus size={16} className="text-[rgba(240,248,255,0.691)] hover:text-green-400" />
                      )}
                  </button>
                  <span className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 hidden group-hover:block
                    bg-gray-300 text-gray-800 text-xs rounded py-1 px-2 whitespace-nowrap
                    transition-opacity duration-200 opacity-0 group-hover:opacity-100">
                    {selectedTraits.includes(trait)
                      ? "Added to Your Assessment"
                      : "Add to Your Assessment"}
                  </span>
                </div>
              </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleAnalyze = async () => {
    setIsAnalyzing(true);
    try {
      const response = await fetch(`${host}/analyze_traits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ traits: selectedTraits }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Analysis Results:", data.analysis);
      setAnalysisResults(data.analysis);
      setShowResultsModal(true);
    } catch (error) {
      console.error("Error analyzing traits:", error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleFinalize = async () => {
    if (!testName.trim()) {
      alert("Please enter an assessment name");
      return;
    }

    setIsSaving(true);
    try {
      const response = await fetch(`${host}/save_test`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          name: testName,
          timelimit: parseInt(timelimit),
          traits: selectedTraits.map((trait) => ({
            [trait.category]: trait.values,
          })),
          analysis: analysisResults,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert("Assessment saved successfully!");
      setShowNameModal(false);
      setTestName("");
      setTimelimit(5);
    } catch (error) {
      console.error("Error saving assessment:", error);
      alert("Failed to save assessment. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const TraitInfoCard = ({ icon, title, content }) => (
    <div className="bg-white/5 p-4 rounded-xl flex gap-3 items-start">
      <div className="text-[rgba(240,248,255,0.691)] mt-1">{icon}</div>
      <div>
        <h4 className="text-md font-medium text-[rgba(240,248,255,0.691)] mb-2">
          {title}
        </h4>
        {Array.isArray(content) ? (
          <ul className="space-y-2">
            {content.map((item, index) => (
              <li
                key={index}
                className="text-[rgba(240,248,255,0.691)] opacity-80 flex gap-2 items-start"
              >
                <CheckCircle2 size={16} className="mt-1 min-w-[16px]" />
                <span className="text-sm font-light">{item}</span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-[rgba(240,248,255,0.691)] opacity-80 text-sm font-light">{content}</p>
        )}
      </div>
    </div>
  );

  const renderTraitInfo = (trait) => {
    let traitData;
    Object.values(traitCategories).forEach((category) => {
      if (category[trait]) traitData = category[trait];
    });

    if (!traitData) return null;

    return (
      <div className="space-y-4">
        <TraitInfoCard
          icon={<Info size={16} />}
          title="Description"
          content={traitData.description}
        />
        <TraitInfoCard
          icon={<HelpCircle size={16} />}
          title="Assessment Questions"
          content={traitData.sampleQuestions}
        />
      </div>
    );
  };

  return (
    <div className="min-h-screen">
      <div className="p-8 responsive-container">
        <h1 className="text-lg font-normal text-gray-800 mb-4 md:mb-7 text-left text-white">
          Create Your Own Behavioral Test
        </h1>
        <div className="grid grid-cols-10 gap-6 mb-6">
          <div
            className="col-span-7 bg-white/5 p-6 rounded-2xl relative"
            // onDragOver={handleDragOver}
            // onDrop={(e) => handleDrop(e, "selected")}
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-medium text-gray-300">
                Your Assessment
              </h2>
              {selectedTraits.length > 0 && (
                <button
                  onClick={handleAnalyze}
                  disabled={isAnalyzing}
                  className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition-colors duration-200"
                >
                  {isAnalyzing ? (
                    <span className="flex items-center justify-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Saving...
                    </span>
                  ) : (
                    <span>Save Assessment</span>
                  )}
                </button>
              )}
            </div>
            <div className="min-h-[400px]">
              {selectedTraits.map((trait) => (
                <div
                  key={trait}
                  className={`bg-white/5 p-4 rounded-xl flex justify-between items-center mb-2
                                            text-[rgba(240,248,255,0.691)] hover:bg-white/10 transition-all duration-200 ${activeInfo === trait ? 'border border-blue-500' : ''}`}
                  onClick={() => setActiveInfo(trait)}
                >
                  <div className="flex items-center gap-3">
                    <LibraryBig size={16} />
                    <span className="text-sm font-normal">{trait}</span>
                  </div>
                  <div className="relative group">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTraitRemove(trait);
                    }}
                    className="text-[rgba(240,248,255,0.691)] hover:text-red-400 transition-colors duration-200"
                  >
                    <X size={16} />
                  </button>
                  <span className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 hidden group-hover:block
                    bg-gray-300 text-gray-800 text-xs rounded py-1 px-2 whitespace-nowrap
                    transition-opacity duration-200 opacity-0 group-hover:opacity-100">
                    Remove from Your Assessment
                  </span>
                  </div>
                </div>
              ))}
              {selectedTraits.length === 0 && (
                <div className="h-[400px] flex items-center justify-center border-2 border-dashed border-white/10 rounded-xl">
                  <div className="text-center text-[rgba(240,248,255,0.691)] opacity-60">
                    <AlertCircle size={20} className="mx-auto mb-2 font-light" />
                    <p className="mb-1 font-light text-sm">Drop items here</p>
                    <p className="text-xs font-light">
                      Move items from the categories list
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-span-3">{renderTemplateSection()}</div>
        </div>
        <div className="grid grid-cols-10 gap-6">
        <div className="col-span-7">{renderAvailableTraits()}</div>

        <div className="col-span-3">
          <TraitInformationPanel
            activeInfo={activeInfo}
            renderTraitInfo={renderTraitInfo}
          />
        </div>
      </div>
      </div>

      
      <ResultsModal
        isOpen={showResultsModal}
        onClose={() => setShowResultsModal(false)}
        results={analysisResults}
      />
      <Modal
        isOpen={showNameModal}
        onClose={() => setShowNameModal(false)}
        testName={testName}
        setTestName={setTestName}
        timelimit={timelimit}
        setTimelimit={setTimelimit}
        handleFinalize={handleFinalize}
        isSaving={isSaving}
      />
    </div>
  );
};

export default BehavioralTestBuilder;
