import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import host from "../../global.jsx";
import ConfirmationNumberRoundedIcon from "@mui/icons-material/ConfirmationNumberRounded";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Notifications, ExitToApp } from "@mui/icons-material";
import { signOut } from "aws-amplify/auth";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { fetchAuthSession } from "aws-amplify/auth";
import { Outlet } from "react-router-dom";
import Loader from "../Loader.jsx";

const GenieHireAdminDashboard = () => {
  const navigate = useNavigate();
  const [showPending, setShowPending] = useState(false);
  const [PendingProfiles, setPendingProfiles] = useState(null);
  const [showDemo, setShowDemo] = useState(false);
  const [DemoProfiles, setDemoProfiles] = useState(null);
  const [viewDemoDetails, setViewDemoDetails] = useState(false);
  const [demoOrgData, setDemoOrgData] = useState(null);
  const [TicketsRaised, setTicketsRaised] = useState(null);
  const [showTickets, setShowTickets] = useState(false);
  const [TicketDetails, setTicketDetails] = useState(null);
  const [viewTicket, setViewTicket] = useState(false);
  const [ticketRemark, setTicketRemark] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const convaiadmin_accessToken = Cookies.get("accessToken");
  const [acceptedDemos, setAcceptedDemos] = useState([]); // New state for accepted demos

  useEffect(() => {
    setIsLoading(true);
    const checkSession = async () => {
      try {
        const session = await fetchAuthSession();
        const { accessToken } = session.tokens ?? {};
        const groups = accessToken.payload["cognito:groups"] ?? [];
        if (
          groups.includes("ConvAI_Admin") &&
          convaiadmin_accessToken === accessToken.toString()
        ) {
          setIsAuthenticated(true);
        } else {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          await signOut();
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };
    checkSession();
  }, [location.pathname, convaiadmin_accessToken]);

  useEffect(() => {
    const updateDashboardData = async () => {
      await getPendingProfilesData();
      await getDemoProfilesData();
      await fetchRaisedTicketsData();
    };
    updateDashboardData();
    const intervalId = setInterval(() => {
      updateDashboardData();
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  const getPendingProfilesData = async () => {
    try {
      const response = await axios.get(`${host}/org-admins`, {
        headers: {
          Authorization: `Bearer ${convaiadmin_accessToken}`,
        },
      });
      if (response) {
        setPendingProfiles(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getDemoProfilesData = async () => {
    try {
      const response = await axios.get(`${host}/get-demo-requests`, {
        headers: {
          Authorization: `Bearer ${convaiadmin_accessToken}`,
        },
      });
      if (response) {
        setDemoProfiles(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRaisedTicketsData = async () => {
    try {
      const response = await axios.get(`${host}/get-raised-tickets`, {
        headers: {
          Authorization: `Bearer ${convaiadmin_accessToken}`,
        },
      });
      if (response) {
        setTicketsRaised(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      Cookies.remove("accesstoken");
      await signOut();
      navigate("/");
    } catch (error) {
      console.error("Error during sign out:", error);
    }
  };

  const toggleVisibility = (setter, currentValue) => {
    setShowPending(false);
    setShowDemo(false);
    setShowTickets(false);
    setter(!currentValue);
  };

  const toggleShowDemo = () => toggleVisibility(setShowDemo, showDemo);
  const toggleShowTickets = () => toggleVisibility(setShowTickets, showTickets);
  const toggleShowPending = () => toggleVisibility(setShowPending, showPending);

  const showDemoDetails = async (demo_id) => {
    if (viewDemoDetails) {
      setViewDemoDetails(false);
    } else {
      setViewDemoDetails(true);
      try {
        const result = await axios.get(`${host}/demo-org-data/${demo_id}`, {
          headers: {
            Authorization: `Bearer ${convaiadmin_accessToken}`,
          },
        });
        if (result) {
          setDemoOrgData(result.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleDemoAccept = async (demo_id) => {
    try {
      const response = await axios.put(
        `${host}/update-demo-status/${demo_id}/accepted`,
        {},
        { headers: { Authorization: `Bearer ${convaiadmin_accessToken}` } }
      );

      // Update local state
      const demoToAccept = DemoProfiles.find((demo) => demo._id === demo_id);
      if (demoToAccept) {
        setDemoProfiles((prev) => prev.filter((demo) => demo._id !== demo_id));
        setAcceptedDemos((prev) => [
          ...prev,
          { ...demoToAccept, status: "accepted" },
        ]);
      }
    } catch (error) {
      console.error("Error accepting demo:", error);
    }
  };

  const handleDemoReject = async (demo_id) => {
    try {
      const response = await axios.put(
        `${host}/update-demo-status/${demo_id}/rejected`,
        {},
        { headers: { Authorization: `Bearer ${convaiadmin_accessToken}` } }
      );

      setDemoProfiles((prev) => prev.filter((demo) => demo._id !== demo_id));
    } catch (error) {
      console.error("Error rejecting demo:", error);
    }
  };

  const showTicketDetails = (index) => {
    setTicketDetails(TicketsRaised[index]);
    toggleShowTickets();
    setViewTicket(true);
  };

  const hideTicket = () => {
    setViewTicket(false);
  };

  const handleTicketRemarkChange = (e) => {
    setTicketRemark(e.target.value);
  };

  const updateTicketStatus = async (ticketId) => {
    if (ticketRemark !== "") {
      try {
        const result = await axios.post(
          `${host}/update-ticket-status/${ticketId}/${ticketRemark}`,
          {
            headers: {
              Authorization: `Bearer ${convaiadmin_accessToken}`,
            },
          }
        );
        if (result) {
          alert("Status updated successfully.");
          fetchRaisedTicketsData();
          setViewTicket(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      alert("Fill out remark first");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ToastContainer />
      {viewTicket && TicketDetails && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-800">
                Ticket Details
              </p>
              <CloseRoundedIcon
                className="text-red-500 cursor-pointer"
                onClick={hideTicket}
              />
            </div>
            <hr className="border-gray-300 mb-4" />
            <div className="space-y-3 text-gray-700">
              {[
                { label: "Ticket Title", value: TicketDetails.title },
                { label: "Description", value: TicketDetails.description },
                { label: "Raised by Admin", value: TicketDetails.orgadminName },
                { label: "Admin Email", value: TicketDetails.orgadminEmail },
                { label: "Date", value: TicketDetails.createdDate },
              ].map(({ label, value }) => (
                <p key={label}>
                  <strong>{label}:</strong> {value}
                </p>
              ))}
            </div>
            <label className="block text-gray-700 mt-4">
              <strong>Add Remark:</strong>
            </label>
            <textarea
              id="ticketRemark"
              value={ticketRemark}
              onChange={handleTicketRemarkChange}
              required
              className="w-full p-2 mt-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={() => updateTicketStatus(TicketDetails._id)}
              className="mt-4 bg-blue-500 text-white py-1 px-4 rounded-lg hover:bg-blue-600"
            >
              Update Status
            </button>
          </div>
        </div>
      )}
      <div className="p-4 bg-gray-100 h-full overflow-auto">
        <header className="flex justify-between items-center p-4 bg-white shadow-md rounded-lg mb-6 relative">
          <h1
            className="text-xl font-bold cursor-pointer"
            onClick={() => navigate(`/geniehireadmin/dashboard`)}
          >
            GenieHire AI
          </h1>
          <div className="flex space-x-6 items-center">
            {[
              {
                icon: (
                  <ConfirmationNumberRoundedIcon onClick={toggleShowTickets} />
                ),
                count: TicketsRaised?.length,
              },
              {
                icon: <Notifications onClick={toggleShowDemo} />,
                count: DemoProfiles?.length,
              },
              {
                icon: (
                  <InsertCommentOutlinedIcon
                    onClick={() => navigate(`/geniehireadmin/feedback`)}
                  />
                ),
              },
            ].map(({ icon, count }, index) => (
              <div key={index} className="relative flex items-center">
                <div className="absolute top-0 right-0 text-xs bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center">
                  {count || 0}
                </div>
                {React.cloneElement(icon, {
                  style: { fontSize: 36 },
                  className: "cursor-pointer hover:text-blue-400",
                })}
              </div>
            ))}
            <div
              className="cursor-pointer hover:text-blue-400"
              onClick={handleSignOut}
            >
              <ExitToApp style={{ fontSize: "36px" }} className="text-2xl" />
            </div>
          </div>
          {(showPending || showDemo || showTickets) && (
            <div className="absolute top-full right-0 mt-2 bg-white rounded-lg shadow-lg w-full max-w-md h-96 overflow-y-auto z-10">
              <div className="p-4">
                {showPending && (
                  <div className="mb-4">
                    <h2 className="font-bold text-lg mb-2">Pending Profiles</h2>
                    {PendingProfiles?.length > 0 ? (
                      PendingProfiles.map((item, index) => (
                        <div
                          key={index}
                          className="mb-4 p-4 border rounded-lg shadow-sm"
                        >
                          <ul className="text-gray-700 space-y-1">
                            <li>
                              <strong>HR Name: </strong>
                              {item.hrName}
                            </li>
                            <li>
                              <strong>HR Email: </strong>
                              {item.hremail}
                            </li>
                            <li>
                              <strong>Request from: </strong>
                              {item.request_by}
                            </li>
                          </ul>
                          <div className="flex space-x-4 mt-2">
                            <button className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center space-x-2 hover:bg-green-600">
                              <CheckCircleIcon />
                              <span>Accept</span>
                            </button>
                            <button className="px-4 py-2 bg-red-500 text-white rounded-lg flex items-center space-x-2 hover:bg-red-600">
                              <CancelIcon />
                              <span>Reject</span>
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No Pending Actions</p>
                    )}
                  </div>
                )}
                {showDemo && (
                  <div className="mb-4">
                    <h2 className="font-bold text-lg mb-2">
                      Pending Demo Requests
                    </h2>
                    {DemoProfiles?.length > 0 ? (
                      DemoProfiles.map((item) => (
                        <div
                          key={item._id}
                          className="mb-4 p-4 border rounded-lg shadow-sm"
                        >
                          <ul className="text-gray-700 space-y-1">
                            <li>
                              <strong>Name:</strong> {item.name}
                            </li>
                            <li>
                              <strong>Email:</strong> {item.workEmail}
                            </li>
                            <li>
                              <strong>Phone:</strong>{" "}
                              {item.phoneNumber || "N/A"}
                            </li>
                            {/* <li>
                              <strong>Company Size:</strong> {item.companySize}
                            </li> */}
                            <li>
                              <strong>Requested:</strong>{" "}
                              {new Date(item.created_at).toLocaleString()}
                            </li>
                          </ul>
                          <div className="flex space-x-2 mt-2">
                            <button
                              onClick={() => handleDemoAccept(item._id)}
                              className="bg-blue-500 text-white py-1 px-4 rounded-lg hover:bg-blue-600"
                            >
                              Accept
                            </button>
                            <button
                              onClick={() => handleDemoReject(item._id)}
                              className="bg-red-500 text-white py-1 px-4 rounded-lg hover:bg-red-600"
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No Demo Requests</p>
                    )}
                  </div>
                )}{" "}
                {showTickets && TicketsRaised?.length > 0 ? (
                  <div className="bg-white-100 p-4 rounded-lg shadow-md">
                    <h2 className="font-bold text-lg">Tickets Raised</h2>
                    {TicketsRaised &&
                      TicketsRaised.map((item, index) => (
                        <ul key={index}>
                          <li>
                            <strong>Title: </strong>
                            {item.title}
                          </li>
                          <li>
                            <strong>From: </strong>
                            {item.orgadminName}
                          </li>
                          <li>
                            <strong>Date: </strong>
                            {item.createdDate}
                          </li>
                          <button
                            className="mt-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-200 ease-in-out"
                            id="viewPending"
                            onClick={() => showTicketDetails(index)}
                          >
                            View
                          </button>
                        </ul>
                      ))}
                  </div>
                ) : (
                  showTickets && <p className="p-4">No Raised Tickets</p>
                )}
              </div>
            </div>
          )}
        </header>

        <Outlet />
      </div>
      {/* Demo Details Modal */}
      {viewDemoDetails && demoOrgData && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-800">
                Demo Details
              </p>
              <CloseRoundedIcon
                className="text-red-500 cursor-pointer"
                onClick={() => setViewDemoDetails(false)}
              />
            </div>
            <hr className="border-gray-300 mb-4" />
            <div className="space-y-3 text-gray-700">
              <p>
                <strong>Name:</strong> {demoOrgData.name}
              </p>
              <p>
                <strong>Email:</strong> {demoOrgData.workEmail}
              </p>
              <p>
                <strong>Phone:</strong> {demoOrgData.phoneNumber || "N/A"}
              </p>
              {/* <p>
                <strong>Company Size:</strong> {demoOrgData.companySize}
              </p> */}
              <p></p>
              <p>
                <strong>Requested:</strong>{" "}
                {new Date(demoOrgData.created_at).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default GenieHireAdminDashboard;
