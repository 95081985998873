import { ArrowLeft, Download, Trash2, CheckCircle } from "lucide-react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
} from "@mui/material";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MCQReport from "../Reports/MCQResult";
import { useState, useEffect } from "react";
import BehavioralEvaluationReport from "../Reports/BehavioralResult";
import axios from "axios";
import host from "../../global";
import CodingTestReport from "../Reports/CodingResult";
import InterviewEvaluationReport from "../Reports/InterviewResult";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { styled } from "@mui/material/styles";
import StepLabel from "@mui/material/StepLabel";
import PDFGenerator from "../Reports/PrintReport";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import Loader from "../Loader";
import jsPDF from "jspdf";

export default function CandidateAssessment() {
  const { interview_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState({
    mcq: false,
    coding: false,
    behavioral: false,
    interview: false,
  });
  const [selectedBehavioralSet, setSelectedBehavioralSet] = useState("");
  const [behavioralTimeLimit, setBehavioralTimeLimit] = useState(0);
  const toggleModal = (modal) => {
    setOpenModal((prev) => ({ ...prev, [modal]: !prev[modal] }));
  };
  const [candidateData, setCandidateData] = useState({
    mcq_status: null,
    coding_status: null,
    behavioral_status: null,
    name: "",
    job_role: "",
    invited_date: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stages, setStages] = useState([]);
  const [completedStages, setCompleteStages] = useState(0);
  const [scores, setScores] = useState({});
  const [averageScore, setAverageScore] = useState(0);
  const accessToken = Cookies.get("accessToken");
  const navigate = useNavigate();
  const location = useLocation();
  const {
    candidateanalytics,
    selectedJobPosition,
    page,
    job_position,
    combined_status,
  } = location.state || {};

  const handleNavigate = () => {
    if (candidateanalytics) {
      navigate("/org-admin/candidateanalytics", {
        state: {
          selectedJobPosition,
        },
      });
    } else {
      navigate("/org-admin/candidatereports", {
        state: {
          page,
          job_position,
          combined_status,
        },
      });
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${host}/deletecandidate_data`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          interview_id: interview_id,
        },
      });
      toast.success(response.data.message);
      setTimeout(() => {
        navigate(`/org-admin/candidatereports`, {
          state: {
            page,
            job_position,
            combined_status,
          },
        });
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  useEffect(() => {
    const fetchCandidateData = async () => {
      try {
        const response = await axios.get(
          `${host}/check_test_progress/${interview_id}`
        );
        setCandidateData(response.data);
        const assessmentSequence = response.data.assessment_sequence || ["mcq", "coding", "behavioral", "interview"];
        const testTypeMap = {
          mcq: { 
            name: "MCQ", 
            status: response.data.mcq_status 
          },
          coding: { 
            name: "Coding", 
            status: response.data.coding_status 
          },
          behavioral: { 
            name: "Behavioral", 
            status: response.data.behavioral_status 
          },
          interview: { 
            name: "AI Interview", 
            status: response.data.interview_status 
          }
        };

        const dynamicStages = [
          {name: "Invited for Test", status: "Complete"}
        ];

        assessmentSequence.forEach(testType => {
          if(testTypeMap[testType]) {
            dynamicStages.push({
              name: testTypeMap[testType].name,
              status: testTypeMap[testType].status || "Incomplete"
            });
          }
        });

        dynamicStages.push({name: "Test Complete", status: "Incomplete"});
        const validStages = dynamicStages.filter(
          (stage) =>
            stage.status !== "NA" &&
            stage.status !== undefined &&
            stage.status !== null
        );
        const allOthersComplete = validStages
          .filter((stage) => stage.name !== "Test Complete")
          .every(
            (stage) => stage.status === "Complete" || stage.status === "Passed"
          );
        if (allOthersComplete) {
          const testCompleteStage = validStages.find(
            (stage) => stage.name === "Test Complete"
          );
          if (testCompleteStage) {
            testCompleteStage.status = "Complete";
          }
        }
        setStages(validStages);
        console.log("Valid Stages",validStages);
        const completedStages = validStages.reduce((acc, stage) => {
          return acc + (stage.status !== "Incomplete" ? 1 : 0);
        }, 0);
        const adjustedCompletedStages =
          completedStages === validStages.length - 1
            ? completedStages + 1
            : completedStages;
        setCompleteStages(adjustedCompletedStages);
        console.log("Completed Stages:", completedStages);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    const fetchScores = async () => {
      try {
        const response = await axios.get(`${host}/get_testscores`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            interview_id: interview_id,
          },
        });
        const formattedScores = Object.keys(response.data)
          .filter((key) => response.data[key] !== "NA")
          .reduce((acc, key) => {
            const value = parseFloat(response.data[key]);
            acc[key] = value.toFixed(2);
            return acc;
          }, {});
        setScores(formattedScores);
        const validValues = Object.keys(response.data)
          .filter((key) => response.data[key] !== "NA")
          .map((key) => parseFloat(response.data[key]));
        const total = validValues.reduce((acc, value) => acc + value, 0);
        const average = validValues.length > 0 ? total / validValues.length : 0;
        setAverageScore(average);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchCandidateData();
    fetchScores();
  }, [interview_id, accessToken]);

  const ColorlibConnector = styled(StepConnector)(
    ({ theme, statuses, index }) => {
      const status = statuses[index];
      return {
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 10,
          color: "white",
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              "linear-gradient(95deg, rgb(34 197 94), rgb(34 197 94), rgb(34 197 94))",
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            status === "Complete" || status === "Passed" ? "blue" : "gray",
          borderRadius: 1,
        },
      };
    }
  );

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="min-h-screen p-4">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2 sm:gap-1">
          <button className="no-print p-2 text-white" onClick={handleNavigate}>
            <ArrowLeft style={{ color: "white" }} />
          </button>
          <h1 className="text-base text-white md:text-2xl lg:text-3xl font-bold font-poppins">
            {candidateData.name}
          </h1>
          <div className="border-l h-6 mx-2 border-gray-400"></div>
          <div className="flex flex-col">
            <p className="text-sm md:text-base text-white/50 font-poppins">
              Job Role
            </p>
            <p className="text-base text-white md:text-xl font-bold text-white font-poppins">
              {candidateData.job_role}
            </p>
          </div>
          {candidateData.invited_date && (
            <>
              <div className="border-l h-6 mx-2 border-gray-400"></div>
              <div className="flex flex-col">
                <p className="text-sm md:text-base text-white/50 font-poppins">
                  Invited Date
                </p>
                <p className="text-base text-white md:text-xl font-bold text-white font-poppins">
                  {candidateData.invited_date}
                </p>
              </div>
              <button
                className="ml-4 button  px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                onClick={() => setIsModalOpen(true)}
              >
                <span>View Resume</span>
              </button>
            </>
          )}
        </div>
        {isModalOpen && (
                    // <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-lg">
                    //   <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full"
                    //     style={{
                    //       background: 'linear-gradient(to bottom right, rgba(34,52,97,0.25), rgba(11,10,31,0.05))',
                    //       backdropFilter: 'blur(50px)',
                    //       WebkitBackdropFilter: 'blur(50px)',
                    //       borderRadius: '10px'
                    //     }}>
                    //     <h2 className="text-xl font-bold mb-4 text-gray-400 ">{candidateData.name}'s Resume</h2>
                    //     <div className=" glass-effect max-h-96 overflow-y-auto  text-gray-400">
                    //       {candidateData.formatted_resume}
                    //     </div>
                    //     <button
                    //       className="button mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    //       onClick={() => setIsModalOpen(false)}
                    //     >
                    //       <span>Close</span>
                    //     </button>
                    //   </div>
                    // </div>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-lg">
                        <div
                            className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full"
                            style={{
                                background: 'linear-gradient(to bottom right, rgba(34,52,97,0.25), rgba(11,10,31,0.05))',
                                backdropFilter: 'blur(50px)',
                                WebkitBackdropFilter: 'blur(50px)',
                                borderRadius: '10px',
                            }}
                        >
                            <h2 className="text-xl font-bold mb-4 text-gray-400">
                                {candidateData.name}'s Resume
                            </h2>
                            <div className="glass-effect max-h-96 overflow-y-auto text-gray-400">
                                {/* Render formatted_resume object */}
                                {candidateData.formatted_resume && (
                                    <div>
                                        {Object.entries(candidateData.formatted_resume).map(([key, value]) => (
                                            <div key={key} className="mb-4">
                                                <strong className="capitalize">{key.replace(/_/g, ' ')}:</strong>
                                                {Array.isArray(value) ? (
                                                    <ul className="list-disc list-inside">
                                                        {value.map((item, index) => (
                                                            <li key={index}>{item}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span className="ml-2">{value || "Not specified"}</span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <button
                                className="button mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                onClick={() => setIsModalOpen(false)}
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                )}
        <div className="flex-col items-center gap-2 md:flex-col lg:flex-col">
          <PDFGenerator
            name={candidateData.name}
            role={candidateData.job_role}
            scores={scores}
            stages={stages}
            interview_id={interview_id}
          />
          <button className="p-2 text-white" onClick={handleDelete}>
            <Trash2 />
          </button>
        </div>
      </div>
      <div className="mb-8">
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={1}
            connector={<ColorlibConnector statuses={completedStages} />}
          >
            {stages.map((stage, index) => (
              <Step
                key={stage.name}
                completed={
                  stage.status === "Complete" || stage.status === "Passed"
                }
              >
                <StepLabel
                  sx={{
                    "& .MuiStepLabel-label": { color: "white !important" },
                    "& .MuiStepLabel-label.Mui-completed": {
                      color: "white !important",
                    },
                    "& .MuiStepLabel-label.Mui-active": {
                      color: "white !important",
                    },
                  }}
                  StepIconComponent={(props) => (
                    <VerifiedRoundedIcon
                      style={{
                        color:
                          stage.status === "Complete" ||
                          stage.status === "Passed"
                            ? "rgb(34 197 94)"
                            : "gray",
                      }}
                      {...props}
                    />
                  )}
                >
                  {stage.name}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={8}>
          <Card sx={{ backgroundColor: "rgba(105, 13, 197, 0.103)" }}>
            <CardHeader
              title="Test Score & Reports"
              sx={{ fontFamily: "Poppins", color: "white" }}
            />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Card
                    sx={{
                      backgroundColor: "rgba(105, 13, 197, 0.103)",
                      height: "100%",
                    }}
                  >
                    <CardContent>
                      <Typography
                        style={{ fontFamily: "Poppins" }}
                        color="white"
                        mb={2}
                      >
                        Total Average Score
                      </Typography>
                      <p className="custom-h3">{averageScore.toFixed(2)}%</p>
                    </CardContent>
                  </Card>
                </Grid>
                {candidateData.mcq_status !== "NA" && (
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        backgroundColor: "rgba(105, 13, 197, 0.103)",
                        backdropFilter: "blur(50px)",
                        height: "100%",
                        cursor:
                          candidateData.mcq_status !== "Complete" &&
                          candidateData.mcq_status !== "Passed"
                            ? "not-allowed"
                            : "pointer",
                        opacity:
                          candidateData.mcq_status !== "Complete" &&
                          candidateData.mcq_status !== "Passed"
                            ? 0.5
                            : 1,
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Box
                          mb={2}
                          onClick={() => {
                            if (
                              candidateData.mcq_status === "Complete" ||
                              candidateData.mcq_status === "Passed"
                            ) {
                              toggleModal("mcq");
                            }
                          }}
                        >
                          <Typography
                            style={{ fontFamily: "Poppins" }}
                            color="gray"
                            mb={2}
                          >
                            Multiple Choice Questions
                          </Typography>
                          <p className="custom-h3">
                            {scores.mcq_percent ? (
                              <>
                                <span>{`${scores.mcq_percent}%`}</span>
                              </>
                            ) : (
                              "NA"
                            )}
                          </p>
                        </Box>
                        <Dialog
                          open={openModal.mcq}
                          onClose={() => toggleModal("mcq")}
                          fullWidth
                          maxWidth="md"
                          sx={{
                            "& .MuiPaper-root": {
                              backgroundColor: "rgba(24, 14, 34, 1)",
                            },
                          }}
                        >
                          <DialogTitle>
                            <IconButton
                              onClick={() => toggleModal("mcq")}
                              edge="start"
                              color="inherit"
                              className="no-print"
                            >
                              <ArrowLeft style={{ color: "white" }} />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <MCQReport
                              name={candidateData.name}
                              mcq_percent={scores.mcq_percent}
                            />
                          </DialogContent>
                        </Dialog>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {candidateData.coding_status !== "NA" && (
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        backgroundColor: "rgba(105, 13, 197, 0.103)",
                        backdropFilter: "blur(50px)",
                        height: "100%",
                        cursor:
                          candidateData.coding_status !== "Complete"
                            ? "not-allowed"
                            : "pointer",
                        opacity:
                          candidateData.coding_status !== "Complete" ? 0.5 : 1,
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Box
                          mb={2}
                          onClick={() => {
                            if (candidateData.coding_status === "Complete") {
                              toggleModal("coding");
                            }
                          }}
                        >
                          <Typography
                            style={{ fontFamily: "Poppins", color: "white" }}
                            color="gray"
                            mb={2}
                          >
                            Coding Test
                          </Typography>
                          <p className="custom-h3">
                            {scores.coding_percent > 0 ? (
                              `${scores.coding_percent}%`
                            ) : candidateData.coding_status === "Complete" ? (
                              <span className="text-[20px] text-green-700">
                                Completed
                              </span>
                            ) : (
                              "NA"
                            )}
                          </p>
                        </Box>
                        <Dialog
                          open={openModal.coding}
                          onClose={() => toggleModal("coding")}
                          fullWidth
                          maxWidth="md"
                          sx={{
                            "& .MuiPaper-root": {
                              backgroundColor: "rgba(24, 14, 34, 1)",
                            },
                          }}
                        >
                          <DialogTitle>
                            <IconButton
                              onClick={() => toggleModal("coding")}
                              edge="start"
                              color="inherit"
                              className="no-print"
                            >
                              <ArrowLeft style={{ color: "white" }} />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <CodingTestReport
                              name={candidateData.name}
                              coding_percent={scores.coding_percent}
                            />
                          </DialogContent>
                        </Dialog>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {candidateData.behavioral_status !== "NA" && (
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        backgroundColor: "rgba(105, 13, 197, 0.103)",
                        backdropFilter: "blur(50px)",
                        height: "100%",
                        cursor:
                          candidateData.behavioral_status !== "Complete"
                            ? "not-allowed"
                            : "pointer",
                        opacity:
                          candidateData.behavioral_status !== "Complete"
                            ? 0.5
                            : 1,
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Box
                          mb={2}
                          onClick={() => {
                            if (
                              candidateData.behavioral_status === "Complete"
                            ) {
                              toggleModal("behavioral");
                            }
                          }}
                        >
                          <Typography
                            style={{ fontFamily: "Poppins", color: "white" }}
                            color="gray"
                            mb={2}
                          >
                            Behavioral Assessment
                          </Typography>
                          <p className="custom-h3">
                            {scores.behavioral_percent ? (
                              `${scores.behavioral_percent}%`
                            ) : candidateData.behavioral_status ===
                              "Complete" ? (
                              <span className="text-[20px] text-green-700">
                                Completed
                              </span>
                            ) : (
                              "NA"
                            )}
                          </p>
                        </Box>
                        <Dialog
                          open={openModal.behavioral}
                          onClose={() => toggleModal("behavioral")}
                          fullWidth
                          maxWidth="md"
                          sx={{
                            "& .MuiPaper-root": {
                              backgroundColor: "rgba(24, 14, 34, 1)",
                            },
                          }}
                        >
                          <DialogTitle>
                            <IconButton
                              onClick={() => toggleModal("behavioral")}
                              edge="start"
                              color="inherit"
                              className="no-print"
                            >
                              <ArrowLeft style={{ color: "white" }} />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <BehavioralEvaluationReport
                              name={candidateData.name}
                              behavioral_percent={scores.behavioral_percent}
                              selectedSet={selectedBehavioralSet}
                              timeLimit={behavioralTimeLimit}
                            />
                          </DialogContent>
                        </Dialog>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {candidateData.interview_status !== "NA" && (
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        backgroundColor: "rgba(105, 13, 197, 0.103)",
                        backdropFilter: "blur(50px)",
                        height: "100%",
                        cursor:
                          candidateData.interview_status !== "Complete"
                            ? "not-allowed"
                            : "pointer",
                        opacity:
                          candidateData.interview_status !== "Complete"
                            ? 0.5
                            : 1,
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Box
                          mb={2}
                          onClick={() => {
                            if (candidateData.interview_status === "Complete") {
                              toggleModal("interview");
                            }
                          }}
                        >
                          <Typography
                            style={{ fontFamily: "Poppins", color: "white" }}
                            color="gray"
                            mb={2}
                          >
                            AI Interview
                          </Typography>
                          <p className="custom-h3">
                            {scores.interview_percent ? (
                              <>
                                <span>{`${scores.interview_percent}%`}</span>
                              </>
                            ) : (
                              "NA"
                            )}
                          </p>
                        </Box>
                        <Dialog
                          open={openModal.interview}
                          onClose={() => toggleModal("interview")}
                          fullWidth
                          maxWidth="md"
                          sx={{
                            "& .MuiPaper-root": {
                              backgroundColor: "rgba(24, 14, 34, 1)",
                            },
                          }}
                        >
                          <DialogTitle>
                            <IconButton
                              onClick={() => toggleModal("interview")}
                              edge="start"
                              color="inherit"
                              className="no-print"
                            >
                              <ArrowLeft style={{ color: "white" }} />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <InterviewEvaluationReport
                              name={candidateData.name}
                              interview_percent={scores.interview_percent}
                            />
                          </DialogContent>
                        </Dialog>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ backgroundColor: "rgba(105, 13, 197, 0.103)" }}>
            <CardHeader
              title="Honesty Monitor"
              sx={{ fontFamily: "Poppins", color: "white" }}
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Avatar
                style={{
                  width: "100px",
                  height: "100px",
                  background: "rgba(105, 13, 197, 0.103)",
                  color: "white",
                }}
              >
                {candidateData.name[0]}
              </Avatar>
            </CardContent>
            <CardContent style={{ fontFamily: "Poppins" }}>
              <Box mb={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Device Used
                </Typography>
                <Typography color="white">Desktop</Typography>
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Webcam Enabled?
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Full Screen mode always active?
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Mouse always in Test Window?
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Filled out only once from IP address
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
