import React, { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Dialog, DialogTitle, DialogContent, DialogActions, button, TextField, Typography } from '@mui/material';
import host from '../../global';
import { PlusCircle, Edit2 } from 'lucide-react';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useMediaQuery } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Cookies from 'js-cookie'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../Loader';
const HrProfile = () => {
  const [hrProfiles, setHrProfiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedHrId, setSelectedHrId] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width: 1100px)");
  const accessToken = Cookies.get('accessToken');
  const fetchHrProfiles = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(`${host}/orgadmin/get_hrprofiles`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setHrProfiles(data);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching HR profiles:', error);
      setIsLoading(false)
    }
  };
  useEffect(() => {
    fetchHrProfiles();
  }, []);
  const handleAddHR = () => {
    setModalTitle('Add HR');
    setModalContent(
      <form onSubmit={handleAddHRSubmit}>
        <TextField
          label="Name"
          name="name"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ 
            mt: 2,
            '& .MuiOutlinedInput-input': {  
              color: 'rgb(209, 213, 219)'   
            },
            '& .MuiInputLabel-root': {      
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {               
                borderColor: 'rgb(209, 213, 219)'
              },
              '&:hover fieldset': {         
                borderColor: 'rgb(209, 213, 219)'
              },
              '&.Mui-focused fieldset': {   
                borderColor: 'rgb(209, 213, 219)'
              }
            }
          }}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ 
            mt: 2,
            '& .MuiOutlinedInput-input': {  
              color: 'rgb(209, 213, 219)'  
            },
            '& .MuiInputLabel-root': {      
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiInputLabel-root.Mui-focused': {  
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {               
                borderColor: 'rgb(209, 213, 219)'
              },
              '&:hover fieldset': {         
                borderColor: 'rgb(209, 213, 219)'
              },
              '&.Mui-focused fieldset': {   
                borderColor: 'rgb(209, 213, 219)'
              }
            }
          }}
        />
        <TextField
          label="Contact Number"
          name="contactNumber"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ 
            mt: 2,
            '& .MuiOutlinedInput-input': {  
              color: 'rgb(209, 213, 219)'   
            },
            '& .MuiInputLabel-root': {      
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiInputLabel-root.Mui-focused': {  
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {              
                borderColor: 'rgb(209, 213, 219)'
              },
              '&:hover fieldset': {         
                borderColor: 'rgb(209, 213, 219)'
              },
              '&.Mui-focused fieldset': {   
                borderColor: 'rgb(209, 213, 219)'
              }
            }
          }}
        />
        <TextField
          label="Department"
          name="department"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ 
            mt: 2,
            '& .MuiOutlinedInput-input': {  
              color: 'rgb(209, 213, 219)'   
            },
            '& .MuiInputLabel-root': {      
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiInputLabel-root.Mui-focused': {  
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {               
                borderColor: 'rgb(209, 213, 219)'
              },
              '&:hover fieldset': {        
                borderColor: 'rgb(209, 213, 219)'
              },
              '&.Mui-focused fieldset': {   
                borderColor: 'rgb(209, 213, 219)'
              }
            }
          }}
        />
        <button className="button my-2" type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
         <span>Save Changes </span>
        </button>
      </form>
    );
    setShowModal(true);
  };

  const handleDeleteConfirmation = (hrId) => {
    setSelectedHrId(hrId);
    setShowConfirmDialog(true);
  };

  const handleEditHR = (hrProfile) => {
    setModalTitle('Edit HR');
    setModalContent(
      <form onSubmit={(event) => handleEditHRSubmit(event, hrProfile.hrId)}>
        <TextField
          label="Name"
          name="name"
          defaultValue={hrProfile.hrName}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ 
            mt: 2,
            '& .MuiOutlinedInput-input': {  
              color: 'rgb(209, 213, 219)'   
            },
            '& .MuiInputLabel-root': {      
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiInputLabel-root.Mui-focused': {  
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {               
                borderColor: 'rgb(209, 213, 219)'
              },
              '&:hover fieldset': {         
                borderColor: 'rgb(209, 213, 219)'
              },
              '&.Mui-focused fieldset': {  
                borderColor: 'rgb(209, 213, 219)'
              }
            }
          }}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          defaultValue={hrProfile.hremail}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ 
            mt: 2,
            '& .MuiOutlinedInput-input': {  
              color: 'rgb(209, 213, 219)'   
            },
            '& .MuiInputLabel-root': {      
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiInputLabel-root.Mui-focused': {  
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {              
                borderColor: 'rgb(209, 213, 219)'
              },
              '&:hover fieldset': {         
                borderColor: 'rgb(209, 213, 219)'
              },
              '&.Mui-focused fieldset': {   
                borderColor: 'rgb(209, 213, 219)'
              }
            }
          }}
        />
        <TextField
          label="Contact Number"
          name="contactNumber"
          defaultValue={hrProfile.contactNumber}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ 
            mt: 2,
            '& .MuiOutlinedInput-input': {  
              color: 'rgb(209, 213, 219)'   
            },
            '& .MuiInputLabel-root': {      
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiInputLabel-root.Mui-focused': {  
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {               
                borderColor: 'rgb(209, 213, 219)'
              },
              '&:hover fieldset': {     
                borderColor: 'rgb(209, 213, 219)'
              },
              '&.Mui-focused fieldset': {   
                borderColor: 'rgb(209, 213, 219)'
              }
            }
          }}
        />
        <TextField
          label="Department"
          name="department"
          defaultValue={hrProfile.HRDepartment}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ 
            mt: 2,
            '& .MuiOutlinedInput-input': {  
              color: 'rgb(209, 213, 219)'  
            },
            '& .MuiInputLabel-root': {      
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiInputLabel-root.Mui-focused': {  
              color: 'rgb(209, 213, 219)'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {              
                borderColor: 'rgb(209, 213, 219)'
              },
              '&:hover fieldset': {         
                borderColor: 'rgb(209, 213, 219)'
              },
              '&.Mui-focused fieldset': {   
                borderColor: 'rgb(209, 213, 219)'
              }
            }
          }}
        />
        <button className="button my-2" type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
         <span>Save Changes </span>
        </button>
      </form>
    );
    setShowModal(true);
  };

  const handleDeleteHR = async (preserveData) => {
    
    try{
      setIsLoading(true);
      const response = await fetch(`${host}/orgadmin/deletehrprofile/${selectedHrId}?preserve_data=${preserveData}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      if (response.ok) {
        toast.success('HR profile Deleted successfully');
        await fetchHrProfiles();
      } else {
        const errorData = await response.json();
        toast.error(`${errorData.detail || 'Error deleting HR profile'}`);
        if (errorData.detail.includes("User not found in Cognito")) {
          await fetchHrProfiles(); 
        }
      }
    } catch (error) {
      toast.error(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
      setShowConfirmDialog(false);
      setSelectedHrId(null);
    }
  }

  const handleAddHRSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newHR = {
      hrName: formData.get('name'),
      hremail: formData.get('email'),
      contactNumber: formData.get('contactNumber'),
      HRDepartment: formData.get('department'),
    };
    try {
      setIsLoading(true)
      const response = await fetch(`${host}/orgadmin/addhrprofile`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newHR),
      });
      if (response.ok) {
        const result = await response.json();
        toast.success(`HR profile added successfully!`, {});
        setShowModal(false);
        await fetchHrProfiles();
      } else {
        const errorData = await response.json();
        toast.error(`${errorData.detail || 'Unknown error'}`, {});
      }
    } catch (error) {
      toast.error(`An error occurred: ${error.message}`, {});
    } finally {
      setIsLoading(false)
    }
  };
  const handleEditHRSubmit = async (event, hrId) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const updatedHR = {
      hrName: formData.get('name'),
      hremail: formData.get('email'),
      contactNumber: formData.get('contactNumber'),
      HRDepartment: formData.get('department')
    };
    try {
      await axios.put(`${host}/orgadmin/updatehrprofile`, updatedHR, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          hrId: hrId,
        },
      });
      alert('HR Profile Updated Successfully');
      setShowModal(false);
      await fetchHrProfiles();
    } catch (error) {
      console.error('Error updating HR profile:', error);
    }
  };
  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <Authenticator>
      <div className="p-8 hr-profiles min-h-screen rounded-lg">
        <div className="p-4 glass-effect rounded-lg">
        <button
          class="button float-right mb-4 px-4 py-2"
          onClick={handleAddHR}
        >

          <span className="flex gap-2">
            <PlusCircle className="w-5 h-5" />
            Add HR
          </span>
        </button>
        <h1 className="text-white">HR Profiles</h1>
        <div className="table-container">
          <div className="table-header">
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ textAlign: 'left', paddingLeft: '30px' }}>HR Name</Th>
                  {isSmallScreen ? (
                    <Th style={{ textAlign: 'Center' }}>Email</Th>
                  ) : (
                    <Th style={{ width: '400px', textAlign: 'left', paddingLeft: '130px' }}>Email</Th>
                  )}
                  <Th style={{ textAlign: 'left', paddingLeft: '60px' }}>Contact</Th>
                  <Th style={{ textAlign: 'left', paddingLeft: '50px' }}>Department</Th>
                  <Th style={{ textAlign: 'center' }}>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {hrProfiles.length > 0 ? (
                  hrProfiles.map((hr) => (
                    <Tr key={hr.hrId}>
                      <Td style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left' }}>{hr.hrName}</Td>
                      {isSmallScreen ? (
                        <Td style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left' }}>{hr.hremail}</Td>
                      ) : (
                        <Td style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '400px', textAlign: 'left' }}>{hr.hremail}</Td>
                      )}
                      <Td style={{ textAlign: 'left', paddingLeft: '50px' }}>{hr.contactNumber}</Td>
                      <Td style={{ textAlign: 'left', paddingLeft: '50px' }}>{hr.HRDepartment}</Td>
                      <Td>
                      <div className="flex gap-4 items-center justify-center">
                      <button
                        className="px-1 py-2.5 max-w-[100px]" onClick={() => handleEditHR(hr)}
                      >
                        <span className="flex items-center">
                          <Edit2 className="w-4 h-4" />
                        </span>
                      </button>
                      <IconButton
                        onClick={(e) => handleDeleteConfirmation(hr.hrId)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                      </div>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="5">No HR profiles found.</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </div>
        </div>
        <Dialog  
          PaperProps={{
              sx: {
                background: 'linear-gradient(to bottom right, rgba(34,52,97,0.25), rgba(11,10,31,0.05))',
                backdropFilter: 'blur(50px)',
                WebkitBackdropFilter: 'blur(50px)', 
                borderRadius: '10px'
              }
            }} open={showModal} onClose={() => setShowModal(false)}>
          <DialogTitle>
            <Typography variant="h6" className="text-gray-300">{modalTitle}</Typography>
          </DialogTitle>
          <DialogContent >
            <Typography className="text-gray-300">{modalContent}</Typography>
          </DialogContent>
          {/* <DialogActions>
            <div className="flex justify-end gap-3 p-2">
            <button className="button" type="submit" variant="contained" color="primary">
              <span>Save</span>
            </button>
            <button className="button" onClick={() => setShowModal(false)} color="primary">
              <span>Cancel</span>
            </button>
            </div>
          </DialogActions> */}
        </Dialog>

        <Dialog
          PaperProps={{
            sx: {
              background: 'white',
              borderRadius: '10px',
              maxWidth: '400px',
              padding: '2px',
              paddingBottom: '10px',
              position: 'relative'
            }
          }}
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
        >
          <DialogTitle>
            <Typography variant="h6">Confirm Delete</Typography>
            <IconButton
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                fontFamily: 'Poppins'
              }}
              onClick={() => setShowConfirmDialog(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography className="mb-4">
              Do you want to preserve the candidate data associated with this HR?
            </Typography>
          </DialogContent>
          <DialogActions className="p-4">
            <div className="flex gap-2 justify-end">
              <button
                className="px-3 py-2 rounded-full text-white bg-green-600 hover:bg-green-700"
                onClick={() => handleDeleteHR(true)}
              >
                <span>Keep</span>
              </button>
              <button
                className="px-3 py-2 rounded-full text-white bg-red-600 hover:bg-red-700"
                onClick={() => handleDeleteHR(false)}
              >
                <span>Delete</span>
              </button>
              {/* <button
                className="button"
                onClick={() => setShowConfirmDialog(false)}
              >
                <span>Cancel</span>
              </button> */}
            </div>
          </DialogActions>
        </Dialog>
        </div>
      </div>
    </Authenticator>
  );
};
export default HrProfile;
