import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  Divider,
  Chip,
  Fab,
  Tabs,
  Tab,
  Stack,
  Paper,
  Modal,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import {
  GetApp,
  Assessment,
  QuestionAnswer,
  Videocam,
  CheckCircleOutline,
  WarningAmber,
  StarOutline,
  LightbulbOutlined,
  ThumbUpOutlined,
  InfoOutlined,
  VerifiedUser,
} from "@mui/icons-material";
import { Play, Pause } from "lucide-react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip, // Renamed this
  Legend,
} from "chart.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import host from "../../global";
// Register all necessary ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend
);

const styles = {
  root: {
    maxWidth: 1000,
    mx: "auto",
    my: 4,
    "@media print": { maxWidth: "100%", m: 0 },
  },
  card: {
    bgcolor: "#1f2937",
    // borderRadius: 3,
    // boxShadow: "0 4px 20px rgba(0,0,0,0.2)",
  },
  paper: {
    p: 2,
    bgcolor: "rgba(255,255,255,0.05)",
    borderRadius: 2,
    transition: "all 0.3s",
    pageBreakInside: "avoid",
  },
  header: {
    bgcolor: "#1f2937",
    p: 3,
    borderRadius: 3,
    mb: 3,
    // boxShadow: "0 4px 20px rgba(0,0,0,0.2)",
  },
  chip: { color: "white", fontWeight: "bold", py: 2, variant: "outlined" },
  tab: {
    color: "white",
    minWidth: 140,
    fontWeight: "bold",
    textTransform: "none",
    fontSize: "1rem",
    "&:hover": { color: "#4f46e5" },
  },
  fab: {
    position: "fixed",
    bottom: 32,
    right: 32,
    bgcolor: "#4f46e5",
    "&:hover": { bgcolor: "#4338ca" },
    boxShadow: "0 4px 15px rgba(79,70,229,0.4)",
    displayPrint: "none",
  },
  videoModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 800,
    bgcolor: "#1f2937",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
  },
  chartContainer: {
    height: 400,
    width: "100%",
    minWidth: 400,
    position: "relative",
  },
};

const TabPanel = ({ children, value, index }) => (
  <Box hidden={value !== index} sx={{ p: 2 }}>
    {value === index && children}
  </Box>
);

const InterviewEvaluationReport = ({ name, interview_percent }) => {
  const { interview_id } = useParams();
  const [data, setData] = useState({
    questions: [],
    answers: [],
    scores: [],
    remarks: [],
    audio_urls: [],
    video_urls: [],
    startDateTime: "",
    final_evaluation: {
      overall_score: {},
      top_skills: [],
      improvement_areas: [],
      future_potential: "",
      recommendation: "",
      recommendation_reason: "",
      hidden_insights: "",
      authenticity_confidence_score: 0, // Added for authenticity metrics
      plagiarism_score: 0, // Added for plagiarism metrics
    },
    skill_performance: {},
  });
  const [loading, setLoading] = useState(true);
  const [audioState, setAudioState] = useState({ playing: false, index: null });
  const [activeTab, setActiveTab] = useState(0);
  const [videoOpen, setVideoOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const audioRefs = useRef({});
  const reportRef = useRef();

  const fetchData = useCallback(async () => {
    try {
      // console.log("Fetching data for interview_id:", interview_id);
      // console.log("Host:", host);
      // console.log("Access Token:", Cookies.get("accessToken"));

      const response = await axios.get(`${host}/evaluation`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken") || ""}`,
        },
        params: { interview_id },
      });

      // console.log("Full API Response:", response.data);

      const {
        questions,
        answers,
        scores,
        remarks,
        audio_urls,
        video_urls,
        startDateTime,
        Interview_final_evaluation = {}, // Keep this as is
      } = response.data;

      // Extract skill_performance from Interview_final_evaluation
      const skill_performance =
        Interview_final_evaluation?.skill_performance || {};

      // Normalize skill_performance to ensure numeric values
      Object.keys(skill_performance).forEach((key) => {
        skill_performance[key] = Number(skill_performance[key]) || 0;
      });
      // console.log("Normalized Skill Performance:", skill_performance); // Debug normalized data

      const transformedData = {
        questions: questions || [],
        answers: answers || [],
        scores: scores || [],
        remarks: remarks || [],
        audio_urls: audio_urls || [],
        video_urls: (video_urls || []).filter(
          (url) => url && typeof url === "string"
        ),
        startDateTime: startDateTime || "",
        final_evaluation: {
          overall_score: Interview_final_evaluation.overall_score || {},
          top_skills: Interview_final_evaluation.top_skills || [],
          improvement_areas: Interview_final_evaluation.improvement_areas || [],
          future_potential: Interview_final_evaluation.future_potential || "",
          recommendation: Interview_final_evaluation.recommendation || "",
          recommendation_reason: Interview_final_evaluation.recommendation_reason || "",
          hidden_insights: Interview_final_evaluation.hidden_insights || "",
          authenticity_confidence_score:
            Interview_final_evaluation.authenticity_confidence_score ?? 0,
          plagiarism_score: Interview_final_evaluation.plagiarism_score ?? 0,
        },
        skill_performance: skill_performance, // Assign the corrected skill_performance
      };
      setData(transformedData);
    } catch (error) {
      console.error("Fetch Error:", error);
      if (error.response) {
        toast.error(
          `Error ${error.response.status}: ${
            error.response.data?.detail || "Unknown server error"
          }`
        );
      } else if (error.request) {
        toast.error("Network error: No response received from server");
      } else {
        toast.error(`Request error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  }, [interview_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDownloadPDF = useCallback(async () => {
  try {
    // // Show a loading toast
    // const loadingToast = toast.loading("Generating PDF, please wait...");
    
    // Calculate formatted percent here to avoid reference error
    const calculatedPercent = typeof interview_percent === "number"
      ? interview_percent.toFixed(2)
      : (parseFloat(interview_percent) || 0).toFixed(2);
    
    // First make sure all charts/graphs are fully rendered
    // Add a delay to ensure everything is properly rendered
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Create PDF document
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      compress: true
    });
    
    // Define PDF dimensions
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10;
    const contentWidth = pageWidth - 2 * margin;
    
    // Start position
    let currentY = margin;
    let pageCount = 1;
    
    // Add header to first page
    pdf.setFontSize(16);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`Interview Evaluation Report - ${name}`, margin, currentY);
    currentY += 10;
    
    // Add some basic info
    pdf.setFontSize(10);
    pdf.text(`Date: ${data.startDateTime || new Date().toLocaleDateString()}`, margin, currentY);
    currentY += 5;
    pdf.text(`Score: ${calculatedPercent}%`, margin, currentY);
    currentY += 5;
    pdf.setFont("Helvetica", "bold");
    pdf.setFontSize(10);
    pdf.text(`Recommendation: ${data.final_evaluation.recommendation || "N/A"}`, margin, currentY);
    currentY += 10;
    
    pdf.setFont("Helvetica", "normal");
    // Helper function to add a new page
    const addNewPage = () => {
      pdf.addPage();
      pageCount++;
      currentY = margin;
      
      // Add header to new page
      pdf.setFontSize(10);
      pdf.text(`Interview Evaluation Report - ${name} (Page ${pageCount})`, margin, currentY);
      currentY += 10;
    };
    
    // Process Questions & Answers
    pdf.setFontSize(14);
    pdf.text("Questions & Answers", margin, currentY);
    currentY += 10;
    
    // Draw a divider line
    pdf.setDrawColor(200, 200, 200);
    pdf.line(margin, currentY, pageWidth - margin, currentY);
    currentY += 5;
    
    // Process each question one by one
    for (let i = 0; i < data.questions.length; i++) {
      // Check if we need a new page
      if (currentY > pageHeight - 30) {
        addNewPage();
      }
      
      // Add question number and text
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 100);
      const questionText = `Question ${i + 1}: ${data.questions[i]}`;
      const splitQuestion = pdf.splitTextToSize(questionText, contentWidth);
      
      // Check if we need a new page
      if (currentY + splitQuestion.length * 5 > pageHeight - 30) {
        addNewPage();
      }

      pdf.text(splitQuestion, margin, currentY);
      currentY += splitQuestion.length * 5 + 3;

      if (currentY > pageHeight - 50) {
        addNewPage();
      }
      
      // Add answer with word wrapping
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      
      // Handle answer
      const answer = data.answers[i] || "No answer provided";
      const splitAnswer = pdf.splitTextToSize(answer, contentWidth);
      
      // Check if answer needs a new page
      if (currentY + splitAnswer.length * 5 > pageHeight - 30) {
        addNewPage();
      }
      
      pdf.text("Answer:", margin, currentY);
      currentY += 5;
      pdf.text(splitAnswer, margin + 5, currentY);
      currentY += splitAnswer.length * 5 + 5;
      
      // Add score and remark
      if (currentY > pageHeight - 30) {
        addNewPage();
      }
      
      pdf.text(`Score: ${data.scores[i] || "N/A"}`, margin, currentY);
      currentY += 5;
      
      if (data.remarks[i]) {
        // Handle remark with word wrapping
        const remark = data.remarks[i];
        const splitRemark = pdf.splitTextToSize(`Remark: ${remark}`, contentWidth);
        
        // Check if remark needs a new page
        if (currentY + splitRemark.length * 5 > pageHeight - 20) {
          addNewPage();
        }
        
        pdf.text(splitRemark, margin, currentY);
        currentY += splitRemark.length * 5;
      }

      if(data.video_urls[i]) {
        if(currentY > pageHeight-30) {
          addNewPage();
        }

        const videoLinkText = "Video Response";
        const videoLinkX = margin;
        const videoLinkY = currentY;
        const videoLinkWidth = pdf.getTextWidth(videoLinkText);
        const videoLinkHeight = 5;

        pdf.setTextColor(0, 0, 255);
        pdf.text(videoLinkText, videoLinkX, videoLinkY);

        pdf.link(videoLinkX, videoLinkY - 4, videoLinkWidth, videoLinkHeight, {
          url: data.video_urls[i],
        });
    
        pdf.setTextColor(0, 0, 0);
        currentY += 7; 
      }

      // Add space between questions
      currentY += 10;
      
      // Add a separator line between questions
      if (i < data.questions.length - 1) {
        pdf.setDrawColor(200, 200, 200);
        pdf.line(margin, currentY - 5, pageWidth - margin, currentY - 5);
      }
    }
    
    // Add Smart Evaluation section on a new page
    addNewPage();
    
    pdf.setFontSize(14);
    pdf.setTextColor(0, 0, 0);
    pdf.text("Smart Evaluation", margin, currentY);
    currentY += 10;
    
    // Draw a divider line
    pdf.setDrawColor(200, 200, 200);
    pdf.line(margin, currentY, pageWidth - margin, currentY);
    currentY += 10;
    
    // Add overall score
    pdf.setFontSize(12);
    pdf.text("Overall Score:", margin, currentY);
    currentY += 7;
    
    // List overall scores as text
    if (Object.keys(data.final_evaluation.overall_score || {}).length > 0) {
      Object.entries(data.final_evaluation.overall_score).forEach(([category, score]) => {
        pdf.setFontSize(10);
        pdf.text(`${category.charAt(0).toUpperCase() + category.slice(1)}: ${score}%`, margin + 5, currentY);
        currentY += 5;
      });
      currentY += 5;
    } else {
      pdf.setFontSize(10);
      pdf.text("No overall scores available.", margin + 5, currentY);
      currentY += 10;
    }
    
    // Add skill performance
    if (currentY > pageHeight - 60) {
      addNewPage();
    }
    
    pdf.setFontSize(12);
    pdf.text("Skill Performance:", margin, currentY);
    currentY += 7;
    
    // List skill performance as text
    if (Object.keys(data.skill_performance || {}).length > 0) {
      Object.entries(data.skill_performance).forEach(([skill, score]) => {
        const skillName = skill.replace(/([A-Z])/g, " $1").trim();
        pdf.setFontSize(10);
        pdf.text(`${skillName}: ${Number(score).toFixed(1)}%`, margin + 5, currentY);
        currentY += 5;
      });
      currentY += 5;
    } else {
      pdf.setFontSize(10);
      pdf.text("No skill performance data available.", margin + 5, currentY);
      currentY += 10;
    }
    
    // Add top skills section
    if (currentY > pageHeight - 60) {
      addNewPage();
    }
    
    pdf.setFontSize(12);
    pdf.text("Top Skills:", margin, currentY);
    currentY += 7;
    
    if (data.final_evaluation.top_skills && data.final_evaluation.top_skills.length > 0) {
      pdf.setFontSize(10);
      data.final_evaluation.top_skills.forEach(skill => {
        pdf.text(`• ${skill}`, margin + 5, currentY);
        currentY += 5;
      });
      currentY += 5;
    } else {
      pdf.setFontSize(10);
      pdf.text("No top skills identified.", margin + 5, currentY);
      currentY += 10;
    }
    
    // Add improvement areas
    if (currentY > pageHeight - 60) {
      addNewPage();
    }
    
    pdf.setFontSize(12);
    pdf.text("Improvement Areas:", margin, currentY);
    currentY += 7;
    
    if (data.final_evaluation.improvement_areas && data.final_evaluation.improvement_areas.length > 0) {
      pdf.setFontSize(10);
      data.final_evaluation.improvement_areas.forEach(area => {
        pdf.text(`• ${area}`, margin + 5, currentY);
        currentY += 5;
      });
      currentY += 5;
    } else {
      pdf.setFontSize(10);
      pdf.text("No improvement areas identified.", margin + 5, currentY);
      currentY += 10;
    }
    
    // Add future potential
    if (currentY > pageHeight - 70) {
      addNewPage();
    }
    
    pdf.setFontSize(12);
    pdf.text("Future Potential:", margin, currentY);
    currentY += 7;
    
    pdf.setFontSize(10);
    if (data.final_evaluation.future_potential) {
      const potentialText = data.final_evaluation.future_potential;
      const splitPotential = pdf.splitTextToSize(potentialText, contentWidth - 10);
      
      // Check if potential needs a new page
      if (currentY + splitPotential.length * 5 > pageHeight - 30) {
        addNewPage();
      }
      
      pdf.text(splitPotential, margin + 5, currentY);
      currentY += splitPotential.length * 5 + 5;
    } else {
      pdf.text("No future potential assessment available.", margin + 5, currentY);
      currentY += 10;
    }
    
    // Add recommendation
    if (currentY > pageHeight - 70) {
      addNewPage();
    }
    
    pdf.setFontSize(12);
    pdf.text("Recommendation:", margin, currentY);
    currentY += 7;
    
    pdf.setFontSize(10);
    if (data.final_evaluation.recommendation) {
      pdf.text(`Decision: ${data.final_evaluation.recommendation}`, margin + 5, currentY);
      currentY += 5;
      
      if (data.final_evaluation.recommendation_reason) {
        const reasonText = data.final_evaluation.recommendation_reason;
        const splitReason = pdf.splitTextToSize(`Reason: ${reasonText}`, contentWidth - 10);
        
        // Check if reason needs a new page
        if (currentY + splitReason.length * 5 > pageHeight - 20) {
          addNewPage();
        }
        
        pdf.text(splitReason, margin + 5, currentY);
        currentY += splitReason.length * 5 + 5;
      }
    } else {
      pdf.text("No recommendation provided.", margin + 5, currentY);
      currentY += 10;
    }
    
    // Add hidden insights if available
    if (data.final_evaluation.hidden_insights) {
      if (currentY > pageHeight - 70) {
        addNewPage();
      }
      
      pdf.setFontSize(12);
      pdf.text("Hidden Insights:", margin, currentY);
      currentY += 5;
      
      const insightsText = data.final_evaluation.hidden_insights;
      const splitInsights = pdf.splitTextToSize(insightsText, contentWidth - 10);
      
      if (currentY + splitInsights.length * 5 > pageHeight - 30) {
        addNewPage();
      }

      pdf.setFontSize(10);
      pdf.text(splitInsights, margin + 5, currentY);
      currentY += splitInsights.length * 5 + 5;
    }
    
    // Add authenticity info if available
    if (data.final_evaluation.authenticity_confidence_score > 0) {
      if (currentY > pageHeight - 50) {
        addNewPage();
      }
      
      pdf.setFontSize(12);
      pdf.text("Authenticity Report:", margin, currentY);
      currentY += 5;
      
      pdf.setFontSize(10);
      let authSectionHeight = 0;
      pdf.text(`Authenticity Confidence Score: ${data.final_evaluation.authenticity_confidence_score.toFixed(1)}%`, margin + 5, currentY);
      authSectionHeight += 5; 
      currentY += 5;;
      
      if (data.final_evaluation.plagiarism_score > 0) {
        if (currentY > pageHeight - 30) {
          addNewPage();
        }
        pdf.text(`Plagiarism Detection Score: ${data.final_evaluation.plagiarism_score.toFixed(1)}%`, margin + 5, currentY);
        authSectionHeight += 5;
        currentY += 5;
      }
      
      currentY += 10;
    }
    
    // Add page numbers to footer of each page
    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.setTextColor(100, 100, 100);
      pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 25, pageHeight - 10);
    }
    
    // Save the PDF
    pdf.save(`${name}_Interview_Evaluation_Report.pdf`);
    toast.success("PDF successfully generated!");
  } catch (error) {
    console.error("PDF generation error:", error);
    toast.error(`Error generating PDF: ${error.message || "Unknown error"}`);
  }
}, [name, data, interview_percent]);

  const toggleAudio = useCallback((index) => {
    setAudioState((prev) => {
      if (prev.playing && prev.index === index) {
        audioRefs.current[index]?.pause();
        return { playing: false, index: null };
      }
      if (prev.index !== null) audioRefs.current[prev.index]?.pause();
      audioRefs.current[index]?.play();
      return { playing: true, index };
    });
  }, []);

  const handleVideoOpen = useCallback((videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setVideoOpen(true);
  }, []);

  const handleVideoClose = useCallback(() => {
    setVideoOpen(false);
    setSelectedVideoUrl("");
  }, []);

  const getColor = (value) =>
    value <= 33 ? "#ef4444" : value <= 66 ? "#f59e0b" : "#22c55e";

  const barDataOverall = useCallback(
    () => ({
      labels: Object.keys(data.final_evaluation.overall_score).map(
        (t) => t.charAt(0).toUpperCase() + t.slice(1)
      ),
      datasets: [
        {
          label: "Overall Scores",
          data: Object.values(data.final_evaluation.overall_score),
          backgroundColor: Object.values(
            data.final_evaluation.overall_score
          ).map((s) => getColor(s)),
          borderRadius: 6,
        },
      ],
    }),
    [data.final_evaluation.overall_score]
  );

  const getPlagiarismColor = (value) =>
    value >= 66 ? "#ef4444" : value >= 33 ? "#f59e0b" : "#22c55e";

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: { color: "white", font: { size: 14, weight: "bold" } },
      },
      tooltip: {
        backgroundColor: "rgba(31,41,55,0.9)",
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}%`,
        },
      },
      title: {
        display: true,
        color: "white",
        font: { size: 18, weight: "bold" },
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        max: 100,
        min: 0,
        ticks: {
          color: "white",
          stepSize: 20,
          callback: (value) => `${value}%`,
        },
        grid: { color: "rgba(255,255,255,0.1)" },
        angleLines: { color: "rgba(255,255,255,0.1)" }, // Ensure angle lines are visible
      },
    },
  };

  const overallBarOptions = {
    ...barOptions,
    plugins: {
      ...barOptions.plugins,
      title: {
        ...barOptions.plugins.title,
        text: "Overall Score Breakdown",
      },
    },
  };

  const radarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: { color: "white", font: { size: 14, weight: "bold" } },
      },
      tooltip: {
        backgroundColor: "rgba(31,41,55,0.9)",
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}%`,
        },
      },
      title: {
        display: true,
        text: "Skill Performance Breakdown",
        color: "white",
        font: { size: 18, weight: "bold" },
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        max: 100,
        min: 0,
        ticks: {
          color: "white",
          stepSize: 20,
          callback: (value) => `${value}%`,
        },
        grid: { color: "rgba(255,255,255,0.1)" },
        angleLines: { color: "rgba(255,255,255,0.1)" }, // Ensure angle lines are visible
      },
    },
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );

  const formattedPercent =
    typeof interview_percent === "number"
      ? interview_percent.toFixed(2)
      : (parseFloat(interview_percent) || 0).toFixed(2);

  const authenticityScore =
    parseFloat(data.final_evaluation.authenticity_confidence_score) || 0;
  const plagiarismScore =
    parseFloat(data.final_evaluation.plagiarism_score) || 0;

  return (
    <Box sx={styles.root} ref={reportRef} >
      <Paper sx={styles.header}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Assessment sx={{ color: "#4f46e5", fontSize: 48 }} />
            <Box>
              <Typography
                variant="h3"
                color="white"
                fontWeight="bold"
                sx={{ fontSize: { xs: "1.5rem", md: "2.125rem" } }}
              >
                Interview Evaluation Report
              </Typography>
              <Typography variant="subtitle1" color="rgba(255,255,255,0.8)">
                Candidate: <strong>{name}</strong>
              </Typography>
            </Box>
          </Stack>
          <IconButton
            onClick={handleDownloadPDF}
            sx={{
              bgcolor: "#4f46e5",
              color: "white",
              "&:hover": { bgcolor: "#4338ca" },
            }}
          >
            <GetApp fontSize="large" />
          </IconButton>
        </Stack>
        <Stack direction="row" spacing={2} mt={2} flexWrap="wrap" gap={1}>
          <Chip
            label={`Recommendation: ${
              data.final_evaluation.recommendation || "NA"
            }`}
            color={
              data.final_evaluation.recommendation === "Selected"
                ? "success"
                : "error"
            }
            sx={{
              ...styles.chip,
              borderColor:
                data.final_evaluation.recommendation === "Selected"
                  ? "#2e7d32"
                  : "#d32f2f",
            }}
          />
          <Chip label={`Score: ${formattedPercent}%`} sx={styles.chip} />
          <Chip
            label={`Date: ${data.startDateTime || "NA"}`}
            sx={styles.chip}
          />
        </Stack>
      </Paper>

      <Tabs
        value={activeTab}
        onChange={(_, v) => setActiveTab(v)}
        centered
        sx={{
          // bgcolor: "#1f2937",
          borderRadius: 3,
          mb: 3,
          py: 1,
          "& .MuiTab-root": styles.tab,
          "& .Mui-selected": {
            color: "#4f46e5",
            bgcolor: "rgba(79,70,229,0.1)",
            borderRadius: 1,
          },
          "& .MuiTabs-indicator": { bgcolor: "#4f46e5", height: 3, borderRadius: 5, },
        }}
      >
        <Tab icon={<QuestionAnswer />} label="Candidate Responses" />
        <Tab icon={<Assessment />} label="Smart Evaluation" />
      </Tabs>

      <Card sx={styles.card}>
        <TabPanel value={activeTab} index={0}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h5" color="white" fontWeight="bold" mb={2}>
              Questions & Answers
            </Typography>
            <Divider sx={{ bgcolor: "rgba(255,255,255,0.2)", mb: 3 }} />
            <Stack spacing={3}>
              {data.questions.map((question, index) => (
                <Paper
                  key={index}
                  sx={{
                    ...styles.paper,
                    "&:hover": {
                      transform: "translateY(-3px)",
                      boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <Stack spacing={2}>
                    <Typography
                      variant="subtitle1"
                      color="white"
                      fontWeight="bold"
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    >
                      <Chip
                        label={index + 1}
                        sx={{
                          bgcolor: "#4f46e5",
                          color: "white",
                          width: 32,
                          height: 32,
                        }}
                      />{" "}
                      {question}
                    </Typography>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        bgcolor: "rgba(79,70,229,0.1)",
                        borderRadius: 2,
                        borderLeft: "4px solid #4f46e5",
                      }}
                    >
                      <Typography color="white" sx={{ mb: 1 }}>
                        <strong>Response:</strong>{" "}
                        {data.answers[index] || "No answer"}
                      </Typography>
                      <Typography color="white" sx={{ mb: 1 }}>
                      <strong>Score:</strong> {data.scores[index] ?? "NA"}
                      </Typography>
                      <Typography color="white">
                        <strong>Remark:</strong>{" "}
                        {data.remarks[index] || "No remark"}
                      </Typography>
                    </Paper>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {data.audio_urls[index] && (
                        <>
                          <IconButton
                            onClick={() => toggleAudio(index)}
                            sx={{
                              color: "white",
                              bgcolor: "rgba(79,70,229,0.2)",
                              "&:hover": { bgcolor: "rgba(79,70,229,0.3)" },
                              displayPrint: "none",
                            }}
                          >
                            {audioState.playing &&
                            audioState.index === index ? (
                              <Pause size={24} />
                            ) : (
                              <Play size={24} />
                            )}
                          </IconButton>
                          <Typography
                            variant="caption"
                            color="rgba(255,255,255,0.7)"
                            sx={{ displayPrint: "none" }}
                          >
                            Audio available
                          </Typography>
                          <audio
                            ref={(el) => (audioRefs.current[index] = el)}
                            src={data.audio_urls[index]}
                            onEnded={() =>
                              setAudioState({ playing: false, index: null })
                            }
                          />
                        </>
                      )}
                      {data.video_urls[index] && (
                        <>
                          <IconButton
                            onClick={() =>
                              handleVideoOpen(data.video_urls[index])
                            }
                            sx={{
                              color: "white",
                              bgcolor: "rgba(79,70,229,0.2)",
                              "&:hover": { bgcolor: "rgba(79,70,229,0.3)" },
                              displayPrint: "none",
                            }}
                          >
                            <Videocam />
                          </IconButton>
                          <Typography
                            variant="caption"
                            color="rgba(255,255,255,0.7)"
                            sx={{ displayPrint: "none" }}
                          >
                            Video available
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Stack>
                </Paper>
              ))}
            </Stack>
          </motion.div>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h5" color="white" fontWeight="bold" mb={2}>
              {" "}
              Genie Smart Report{" "}
            </Typography>
            <Divider sx={{ bgcolor: "rgba(255,255,255,0.2)", mb: 3 }} />
            {/* Overall Score Circles */}
            {Object.keys(data.final_evaluation.overall_score || {}).length > 0 ? (
              <Paper
                sx={{
                  ...styles.paper,
                  mb: 3,
                  p: 3,
                  bgcolor: "rgba(31,41,55,0.95)",
                  border: "1px solid rgba(79,70,229,0.2)",
                }}
              >
                <Typography
                  variant="h6"
                  color="white"
                  fontWeight="bold"
                  mb={3}
                  sx={{
                    background: "linear-gradient(45deg, #4f46e5, #7c3aed)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Overall Scores
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
                    gap: 3,
                  }}
                >
                  {Object.entries(data.final_evaluation.overall_score).map(([category, score]) => {
                    const numericScore = Number(score) || 0;
                    return (
                      <Box
                        key={category}
                        component={motion.div}
                        whileHover={{ scale: 1.05 }}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 1.5,
                          p: 2,
                          borderRadius: 2,
                          bgcolor: "rgba(255,255,255,0.03)",
                          transition: "all 0.3s ease",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            display: "inline-flex",
                          }}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={100}
                            size={90}
                            thickness={5}
                            sx={{
                              color: "rgba(255,255,255,0.1)",
                              position: "absolute",
                            }}
                          />
                          <CircularProgress
                            variant="determinate"
                            value={numericScore}
                            size={90}
                            thickness={5}
                            sx={{
                              color: getColor(numericScore),
                              "& .MuiCircularProgress-circle": {
                                strokeLinecap: "round",
                                transition: "stroke-dashoffset 0.5s ease-in-out",
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="div"
                              color="white"
                              sx={{ fontWeight: "bold" }}
                            >
                              {numericScore.toFixed(1)}%
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          color="white"
                          sx={{
                            textAlign: "center",
                            fontSize: "1rem",
                            fontWeight: "medium",
                            textTransform: "capitalize",
                          }}
                        >
                          {category.replace(/([A-Z])/g, " $1").trim()}
                        </Typography>
                        <Box
                          sx={{
                            width: "50%",
                            height: 4,
                            bgcolor: "rgba(255,255,255,0.1)",
                            borderRadius: 2,
                            overflow: "hidden",
                          }}
                        >
                          <Box
                            sx={{
                              width: `${numericScore}%`,
                              height: "100%",
                              bgcolor: getColor(numericScore),
                              transition: "width 0.5s ease-in-out",
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Paper>
            ) : (
              <Typography color="white" sx={{ mb: 3 }}>
                No overall scores available.
              </Typography>
            )}
            
            
            {/* Enhanced Skill Performance Circles */}
            {Object.keys(data.skill_performance || {}).length > 0 ? (
              <Paper
                sx={{
                  ...styles.paper,
                  mb: 3,
                  p: 3,
                  bgcolor: "rgba(31,41,55,0.95)",
                  border: "1px solid rgba(79,70,229,0.2)",
                }}
              >
                <Typography
                  variant="h6"
                  color="white"
                  fontWeight="bold"
                  mb={3}
                  sx={{
                    background: "linear-gradient(45deg, #4f46e5, #7c3aed)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Skill Performance Breakdown
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
                    gap: 3,
                  }}
                >
                  {Object.entries(data.skill_performance).map(
                    ([skill, score]) => {
                      const numericScore = Number(score) || 0;
                      return (
                        <Box
                          key={skill}
                          component={motion.div}
                          whileHover={{ scale: 1.05 }}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 1.5,
                            p: 2,
                            borderRadius: 2,
                            bgcolor: "rgba(255,255,255,0.03)",
                            transition: "all 0.3s ease",
                            // "&:hover": {
                            //   bgcolor: "rgba(79,70,229,0.1)",
                            //   boxShadow: "0 4px 15px rgba(79,70,229,0.2)",
                            // },
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              display: "inline-flex",
                            }}
                          >
                            <CircularProgress
                              variant="determinate"
                              value={100}
                              size={90}
                              thickness={5}
                              sx={{
                                color: "rgba(255,255,255,0.1)",
                                position: "absolute",
                              }}
                            />
                            <CircularProgress
                              variant="determinate"
                              value={numericScore}
                              size={90}
                              thickness={5}
                              sx={{
                                color: getColor(numericScore),
                                "& .MuiCircularProgress-circle": {
                                  strokeLinecap: "round",
                                  transition:
                                    "stroke-dashoffset 0.5s ease-in-out",
                                },
                              }}
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                component="div"
                                color="white"
                                sx={{ fontWeight: "bold" }}
                              >
                                {numericScore.toFixed(1)}%
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            color="white"
                            sx={{
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "medium",
                              textTransform: "capitalize",
                            }}
                          >
                            {skill.replace(/([A-Z])/g, " $1").trim()}
                          </Typography>
                          <Box
                            sx={{
                              width: "50%",
                              height: 4,
                              bgcolor: "rgba(255,255,255,0.1)",
                              borderRadius: 2,
                              overflow: "hidden",
                            }}
                          >
                            <Box
                              sx={{
                                width: `${numericScore}%`,
                                height: "100%",
                                bgcolor: getColor(numericScore),
                                transition: "width 0.5s ease-in-out",
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Paper>
            ) : (
              <Typography color="white" sx={{ mb: 3 }}>
                No skill performance data available.
              </Typography>
            )}{" "}
            {/* Authenticity Report Section */}
            {(authenticityScore > 0 || plagiarismScore > 0) && (
              <Paper
              component={motion.div}
              whileHover={{ y: -2 }}
              sx={{
                ...styles.paper,
                bgcolor: "rgba(31,41,55,0.95)",
                border: "1px solid rgba(79,70,229,0.2)",
                mb: 3,
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1.5} mb={2}>
                <VerifiedUser sx={{ color: "#7c3aed" }} />
                <Typography
                  variant="h6"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    background: "linear-gradient(45deg, #4f46e5, #7c3aed)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Authenticity Report
                  <Tooltip title="This report is for reference only and does not affect the final decision.">
                    <InfoOutlined
                      sx={{
                        ml: 1,
                        fontSize: 18,
                        color: "#7c3aed",
                        verticalAlign: "text-bottom",
                      }}
                    />
                  </Tooltip>
                </Typography>
              </Stack>

              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(255,255,255,0.03)",
                  borderRadius: 2,
                  mb: 2,
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography color="white">
                    Authenticity Confidence Score:
                  </Typography>
                  <Typography
                    color={getColor(authenticityScore)}
                    fontWeight="bold"
                  >
                    {authenticityScore.toFixed(1)}%
                  </Typography>
                </Stack>
                <LinearProgress
                  variant="determinate"
                  value={authenticityScore}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    bgcolor: "rgba(255,255,255,0.1)",
                    mb: 1,
                    "& .MuiLinearProgress-bar": {
                      borderRadius: 5,
                      bgcolor: getColor(authenticityScore),
                    },
                  }}
                />
                <Typography
                  variant="caption"
                  color="rgba(255,255,255,0.7)"
                  sx={{ display: "block", mb: 3 }}
                >
                  Higher score indicates higher confidence in the authenticity
                  of responses
                </Typography>
                
                {plagiarismScore > 0 && (
                <>
                  <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography color="white">
                    Plagiarism Detection Score:
                  </Typography>
                  <Typography
                    color={getPlagiarismColor(plagiarismScore)}
                    fontWeight="bold"
                  >
                    {plagiarismScore.toFixed(1)}%
                  </Typography>
                </Stack>
                <LinearProgress
                  variant="determinate"
                  value={plagiarismScore}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    bgcolor: "rgba(255,255,255,0.1)",
                    "& .MuiLinearProgress-bar": {
                      borderRadius: 5,
                      bgcolor: getPlagiarismColor(plagiarismScore),
                    },
                  }}
                />
                <Typography
                  variant="caption"
                  color="rgba(255,255,255,0.7)"
                  sx={{ display: "block" }}
                >
                  Lower score is better - indicates less potential plagiarism
                  detected
                </Typography>
                </>
                )}
                
              </Box>

              <Box
                sx={{
                  bgcolor: "rgba(79,70,229,0.08)",
                  p: 2,
                  borderRadius: 2,
                  borderLeft: "3px solid #7c3aed",
                }}
              >
                <Typography variant="body2" color="rgba(255,255,255,0.9)">
                  <i>
                    This report is for reference only and does not directly
                    affect the final recommendation. Please review the full
                    evaluation if authenticity is a priority for your hiring
                    process.
                  </i>
                </Typography>
              </Box>
            </Paper>
            )}
            <Stack spacing={2}>
              {[
                {
                  title: "Top Skills",
                  icon: <CheckCircleOutline sx={{ color: "#22c55e" }} />,
                  content: (
                    <Stack direction="row" flexWrap="wrap" gap={1}>
                      {data.final_evaluation.top_skills.length ? (
                        data.final_evaluation.top_skills.map((skill, i) => (
                          <Chip
                            key={i}
                            label={skill}
                            sx={{
                              bgcolor: "rgba(34,197,94,0.1)",
                              color: "#22c55e",
                              border: "1px solid rgba(34,197,94,0.3)",
                              fontWeight: "medium",
                              "&:hover": { bgcolor: "rgba(34,197,94,0.2)" },
                            }}
                          />
                        ))
                      ) : (
                        <Typography color="rgba(255,255,255,0.7)">
                          No strengths identified
                        </Typography>
                      )}
                    </Stack>
                  ),
                },
                {
                  title: "Improvement Areas",
                  icon: <WarningAmber sx={{ color: "#f59e0b" }} />,
                  content: (
                    <Stack direction="row" flexWrap="wrap" gap={1}>
                      {data.final_evaluation.improvement_areas.length ? (
                        data.final_evaluation.improvement_areas.map(
                          (area, i) => (
                            <Chip
                              key={i}
                              label={area}
                              sx={{
                                bgcolor: "rgba(245,158,11,0.1)",
                                color: "#f59e0b",
                                border: "1px solid rgba(245,158,11,0.3)",
                                fontWeight: "medium",
                                "&:hover": { bgcolor: "rgba(245,158,11,0.2)" },
                              }}
                            />
                          )
                        )
                      ) : (
                        <Typography color="rgba(255,255,255,0.7)">
                          No areas identified
                        </Typography>
                      )}
                    </Stack>
                  ),
                },
                {
                  title: "Future Potential",
                  icon: <StarOutline sx={{ color: "#4f46e5" }} />,
                  content: (
                    <Typography
                      sx={{
                        color: "white",
                        p: 2,
                        bgcolor: "rgba(79,70,229,0.08)",
                        borderRadius: 2,
                        borderLeft: "4px solid #4f46e5",
                        fontSize: "1rem",
                        lineHeight: 1.5,
                      }}
                    >
                      {data.final_evaluation.future_potential ||
                        "No potential assessment available"}
                    </Typography>
                  ),
                },
                {
                  title: "Hidden Insights",
                  icon: <LightbulbOutlined sx={{ color: "#7c3aed" }} />,
                  content: (
                    <Typography
                      sx={{
                        color: "white",
                        p: 2,
                        bgcolor: "rgba(124,58,237,0.08)",
                        borderRadius: 2,
                        borderLeft: "4px solid #7c3aed",
                        fontSize: "1rem",
                        lineHeight: 1.5,
                      }}
                    >
                      {data.final_evaluation.hidden_insights ||
                        "No additional insights available"}
                    </Typography>
                  ),
                },
                {
                  title: "Genie Recommendation",
                  icon: (
                    <ThumbUpOutlined
                      sx={{
                        color:
                          data.final_evaluation.recommendation === "Selected"
                            ? "#22c55e"
                            : "#ef4444",
                      }}
                    />
                  ),
                  content: (
                    <Box
                      sx={{
                        p: 2,
                        bgcolor:
                          data.final_evaluation.recommendation === "Selected"
                            ? "rgba(34,197,94,0.08)"
                            : "rgba(239,68,68,0.08)",
                        borderRadius: 2,
                        borderLeft: `4px solid ${
                          data.final_evaluation.recommendation === "Selected"
                            ? "#22c55e"
                            : "#ef4444"
                        }`,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontWeight: "medium",
                          mb: 1,
                          fontSize: "1rem",
                        }}
                      >
                        {data.final_evaluation.recommendation ||
                          "No recommendation provided"}
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(255,255,255,0.8)",
                          fontSize: "0.95rem",
                          lineHeight: 1.5,
                        }}
                      >
                        {data.final_evaluation.recommendation_reason ||
                          "No reasoning provided"}
                      </Typography>
                    </Box>
                  ),
                },
              ].map(({ title, icon, content }) => (
                <Paper
                  key={title}
                  component={motion.div}
                  whileHover={{ y: -2 }}
                  sx={{
                    ...styles.paper,
                    bgcolor: "rgba(31,41,55,0.95)",
                    border: "1px solid rgba(255,255,255,0.05)",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      borderColor: "rgba(79,70,229,0.2)",
                      boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1.5}
                    mb={2}
                  >
                    {icon}
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        background: "linear-gradient(45deg, #4f46e5, #7c3aed)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      {title}
                    </Typography>
                  </Stack>
                  {content}
                </Paper>
              ))}
            </Stack>
          </motion.div>
        </TabPanel>
      </Card>

      <Modal
        open={videoOpen}
        onClose={handleVideoClose}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <Box sx={styles.videoModal}>
          <Typography
            id="video-modal-title"
            variant="h6"
            color="white"
            sx={{ mb: 2 }}
          >
            Video Response
          </Typography>
          {selectedVideoUrl ? (
            <video width="100%" controls autoPlay>
              <source src={selectedVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Typography color="white">No video available.</Typography>
          )}
        </Box>
      </Modal>

      {/* <Fab
        onClick={() => setActiveTab(activeTab === 0 ? 1 : 0)}
        sx={styles.fab}
      >
        {activeTab === 0 ? (
          <Assessment sx={{ fontSize: 28 }} />
        ) : (
          <QuestionAnswer sx={{ fontSize: 28 }} />
        )}
      </Fab> */}
    </Box>
  );
};

export default InterviewEvaluationReport;
