import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import host from "../../global";
import { Trash, ChevronDown, Timer, Save, Plus, X, GripVertical, Play, Pause, Turtle, Percent, ArrowLeft } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
const AssessmentConfig = () => {
  const [jobPositions, setJobPositions] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [selectedID, setSelectedID] = useState("");
  const [MCQTimeLimit, setMCQTimeLimit] = useState(5);
  const [MCQPercentThreshold, setMCQPercentThreshold] = useState();
  const [CodingTimeLimit, setCodingTimeLimit] = useState(10);
  const [isMCQIncluded, setIsMCQIncluded] = useState(false);
  const [isCodingIncluded, setIsCodingIncluded] = useState(false);
  const [isBehavioralIncluded, setIsBehavioralIncluded] = useState(false);
  const [selectedBehavioralSet, setSelectedBehavioralSet] = useState("NA");
  const [behavioralTimeLimit, setBehavioralTimeLimit] = useState(5);
  const [behavioralSets, setBehavioralSets] = useState([]);
  const [codingSets, setCodingSets] = useState([]);
  const [codingJudgingCriteria, setCodingJudgingCriteria] = useState("NA");
  const [selectedCodingSet, setSelectedCodingSet] = useState("NA");
  const [generalConfig, setGeneralConfig] = useState(false);
  const [resumeScoreThreshold, setResumeScoreThreshold] = useState();
  const [interview_timelimit, setInterviewTimeLimit] = useState();
  const [selectedSet, setSelectedSet] = useState("NA");
  const [sets, setSets] = useState([]);
  const [jobdesc, setJobdesc] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [isCustomQuestionsIncluded, setIsCustomQuestionsIncluded] = useState(false);
  const [customQuestions, setCustomQuestions] = useState("");
  const [editedQuestions, setEditedQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assessmentSequence, setAssessmentSequence] = useState(["interview"]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
  const AWS_REGION_NAME = process.env.REACT_APP_AWS_REGION_NAME;
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
  const [jobData, setJobData] = useState("");
  const location = useLocation();
  const [editedJobdesc, setEditedJobdesc] = useState(null);
  const [isEditingJD, setIsEditingJD] = useState(true);
  const [jobRole, setJobRole] = useState("");
  const [isVideoIncluded, setIsVideoIncluded] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    if (selectedSet) {
      const jobDescriptionSet = jobData?.mcq_set;
      const jobDescriptionTimeLimit = jobData?.mcq_time_limit;
  
      if (selectedSet === jobDescriptionSet && jobDescriptionTimeLimit) {
        setMCQTimeLimit(jobDescriptionTimeLimit);
      } else {
        const selectedSetInfo = sets.find(set => set.name === selectedSet);
        if (selectedSetInfo?.timelimit) {
          setMCQTimeLimit(selectedSetInfo.timelimit.toString());
        } else {
          setMCQTimeLimit(5);
        }
      }
    }
  }, [selectedSet, sets, jobdesc]);

  useEffect(() => {
    if (selectedBehavioralSet) {
      const jobDescriptionBehavioralSet = jobData?.selected_behavioral_set;
      const jobDescriptionBehavioralTimeLimit = jobData?.behavioral_time_limit;
  
      if (selectedBehavioralSet === jobDescriptionBehavioralSet && jobDescriptionBehavioralTimeLimit) {
        setBehavioralTimeLimit(jobDescriptionBehavioralTimeLimit);
      } else {
        const selectedBehavioralSetInfo = behavioralSets.find(set => set.name === selectedBehavioralSet);
        if (selectedBehavioralSetInfo?.timelimit) {
          setBehavioralTimeLimit(parseInt(selectedBehavioralSetInfo.timelimit));
        } else {
          setBehavioralTimeLimit(5);
        }
      }
    }
  }, [selectedBehavioralSet, behavioralSets, jobdesc]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (location.state?.jobId) {
        setSelectedID(location.state.jobId);
        await handleJobPositionChange({ target: { value: location.state.jobId } });
      }
      setExpanded(false); 
      setGeneralConfig(false); 
      setIsEditingJD(true); 
    };
    fetchInitialData();
  }, [location]);

  console.log(location.state);
  const handleLanguageChange = async (language) => {
    setSelectedLanguage(language);
    setSelectedVoice(null);
    try {
      const response = await axios.get(`${host}/voices/${language}`);
      setVoices(response.data);
    } catch (error) {
      console.error("Error fetching voices:", error);
      setVoices([]);
    }
  };
  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [audio]);
  useEffect(() => {
    axios
      .get(`${host}/get_configurations`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        handleLanguageChange(response.data.language);
        setLanguages(response.data.available_languages);
        setSelectedLanguage(response.data.language);
        setVoices([{ name: response.data.voice_name, type: response.data.voice_type }]);
        setSelectedVoice({ name: response.data.voice_name, type: response.data.voice_type });
        setResumeScoreThreshold(response.data.resume_score_threshold);
        setIsVideoIncluded(response.data.video_included);
      })
      .catch((error) => console.error("Error fetching configurations:", error));
  }, []);
  useEffect(() => {
    let sequence = ["interview"];
    if (isMCQIncluded) sequence.unshift("mcq");
    if (isCodingIncluded) sequence.unshift("coding");
    if (isBehavioralIncluded) sequence.unshift("behavioral")
    setAssessmentSequence(sequence);
  }, [isMCQIncluded, isCodingIncluded, isBehavioralIncluded]);
  const handleDragStart = (e, item) => {
    setDraggedItem(item);
    e.target.style.opacity = "0.5";
  };
  const handleDragEnd = (e) => {
    e.target.style.opacity = "1";
    setDraggedItem(null);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e, targetItem) => {
    e.preventDefault();
    if (draggedItem === targetItem) return;
    const newSequence = [...assessmentSequence];
    const draggedIndex = newSequence.indexOf(draggedItem);
    const targetIndex = newSequence.indexOf(targetItem);
    newSequence.splice(draggedIndex, 1);
    newSequence.splice(targetIndex, 0, draggedItem);
    setAssessmentSequence(newSequence);
  };
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        const response1 = await axios.get(`${host}/getjdpositions`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setJobPositions(response1.data);
        const response2 = await axios.get(`${host}/orgadmin/get_setnames`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setCodingSets(response2.data.coding_sets);
        setSets(response2.data.set_names);
        setBehavioralSets(response2.data.behavioral_sets);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchJobPositions();
  }, [accessToken]);
  const handleTextareaChange = (e, field, section, index) => {
    const newValue = e.target.value;
    const updatedJobdesc = { ...editedJobdesc };

    if (field === "minExp" || field === "maxExp") {
      updatedJobdesc[field] = newValue === "" ? 0 : parseInt(newValue, 10);
    } else if (section && index !== undefined) {
      updatedJobdesc[section][index] = newValue;
    } else if (section) {
      updatedJobdesc[section][field] = newValue;
    } else {
      updatedJobdesc[field] = newValue;
    }
    setEditedJobdesc(updatedJobdesc);
  }

  const handleAddListItem = (section, category) => {
    const updatedJobdesc = { ...editedJobdesc };
    
    if (section === 'Roles_and_Responsibilities') {
      if (!updatedJobdesc.Roles_and_Responsibilities) {
        updatedJobdesc.Roles_and_Responsibilities = [];
      }
      updatedJobdesc.Roles_and_Responsibilities.push("");
    } 
    else if (section === 'Required_Skills') {
      if (!updatedJobdesc.Required_Skills) {
        updatedJobdesc.Required_Skills = { Easy: [], Medium: [], Hard: [] };
      }
      if (!updatedJobdesc.Required_Skills[category]) {
        updatedJobdesc.Required_Skills[category] = [];
      }
      updatedJobdesc.Required_Skills[category].push("");
    }
    else if (section === 'Tools_and_Technologies') {
      if (!updatedJobdesc.Tools_and_Technologies) {
        updatedJobdesc.Tools_and_Technologies = [];
      }
      updatedJobdesc.Tools_and_Technologies.push("");
    }
    else if (section === 'GoodtoHave') {
      if (!updatedJobdesc.GoodtoHave) {
        updatedJobdesc.GoodtoHave = [];
      }
      updatedJobdesc.GoodtoHave.push("");
    }
    setEditedJobdesc(updatedJobdesc);
  };

  const handleRemoveListItem = (section, category, index) => {
    const updatedJobdesc = { ...editedJobdesc };
    
    if (section === 'Roles_and_Responsibilities') {
      updatedJobdesc.Roles_and_Responsibilities.splice(index, 1);
    } 
    else if (section === 'Required_Skills') {
      updatedJobdesc.Required_Skills[category].splice(index, 1);
    }
    else if (section === 'Tools_and_Technologies') {
      updatedJobdesc.Tools_and_Technologies.splice(index, 1);
    }
    else if (section === 'GoodtoHave') {
      updatedJobdesc.GoodtoHave.splice(index, 1);
    }
    
    setEditedJobdesc(updatedJobdesc);
  };

  const formatJobDescriptionForEditing = (jobRole) => {
    if (!editedJobdesc) return null;

    const experience = (editedJobdesc.minExp + editedJobdesc.maxExp) / 2;
    const isExperienceInvalid = editedJobdesc.minExp > editedJobdesc.maxExp;

    return (
      <div>
        {/* <div className="mb-5">
          <label className="block mb-2 font-semibold text-white">Position:</label>
          <input
            type="text"
            value={jobRole}
            disabled
            className="text-gray-300 w-full glass-effect focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-lg py-3 px-2"
          />
        </div> */}

        <div className="mb-5">
          <label className="block mb-2 font-semibold text-white">Experience Range:</label>
          <div className="flex gap-2">
            <input
              type="number"
              min={0}
              value={editedJobdesc.minExp}
              onChange={(e) => handleTextareaChange(e, 'minExp')}
              className="w-20 text-gray-300 glass-effect focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-lg py-3 px-2"
            />
            <span className="self-center text-gray-300">-</span>
            <input
              type="number"
              min={0}
              value={editedJobdesc.maxExp}
              onChange={(e) => handleTextareaChange(e, 'maxExp')}
              className="w-20 text-gray-300 glass-effect focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-lg py-3 px-2"
            />
            <span className="self-center text-white">years</span>
          </div>
          {isExperienceInvalid && (
          <p className="text-red-400 text-sm mt-2">
            Maximum experience must be greater than minimum experience.
          </p>
        )}
        </div>

        {/* Required Skills */}
        <div className="mb-5">
          <div className="flex justify-between items-center mb-3">
            <label className="font-semibold text-white">Required Skills:</label>
            <button
              onClick={() => handleAddListItem('Required_Skills', experience < 4 ? 'Medium' : 'Hard')}
              className="text-green-400 hover:text-green-300 flex items-center"
            >
              <Plus size={16} className="mr-1" />
              Add Skill
            </button>
          </div>
          <div>
            {experience < 4 ? (
              editedJobdesc.Required_Skills?.Medium?.map((skill, i) => (
                <div key={i} className="mb-2 text-gray-300 flex items-center">
                  
                    <span className="w-8 text-right pr-2">{i + 1}.</span>
                    <textarea
                      value={skill}
                      onChange={(e) => {
                        const newSkills = [...editedJobdesc.Required_Skills.Medium];
                        newSkills[i] = [e.target.value];
                        const updatedJobdesc = { ...editedJobdesc };
                        updatedJobdesc.Required_Skills.Medium = newSkills;
                        setEditedJobdesc(updatedJobdesc);
                      }}
                      className="flex-1 text-gray-300 px-2 glass-effect focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-lg py-3 min-h-[60px]"
                    />
                    <button
                      onClick={() => handleRemoveListItem('Required_Skills', 'Medium', i)}
                      className="ml-2 text-red-400 hover:text-red-300"
                    >
                      <X size={16} />
                    </button>
                 
                </div>
              ))
            ) : (
              editedJobdesc.Required_Skills?.Hard?.map((skill, i) => (
                <div key={i} className="mb-2 text-gray-300 flex items-center">
                  
                    <span className="w-8 text-right pr-2">{i + 1}.</span>
                    <textarea
                      value={skill}
                      onChange={(e) => {
                        const newSkills = [...editedJobdesc.Required_Skills.Hard];
                        newSkills[i] = [e.target.value];
                        const updatedJobdesc = { ...editedJobdesc };
                        updatedJobdesc.Required_Skills.Hard = newSkills;
                        setEditedJobdesc(updatedJobdesc);
                      }}
                      className="flex-1 text-gray-300 px-2 glass-effect focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-lg py-3 min-h-[60px]"
                    />
                    <button
                      onClick={() => handleRemoveListItem('Required_Skills', 'Hard', i)}
                      className="ml-2 text-red-400 hover:text-red-300"
                    >
                      <X size={16} />
                    </button>
                  
                </div>
              ))
            )}
          </div>
        </div>

        {/* Roles & Responsibilities */}
        <div className="mb-5">
          <div className="flex justify-between items-center mb-3">
            <label className="font-semibold text-white">Roles & Responsibilities:</label>
            <button
              onClick={() => handleAddListItem('Roles_and_Responsibilities')}
              className="text-green-400 hover:text-green-300 flex items-center"
            >
              <Plus size={16} className="mr-1" />
              Add Role
            </button>
          </div>
          {editedJobdesc.Roles_and_Responsibilities?.map((role, i) => (
            <div key={i} className="mb-2 text-gray-300 flex items-center">
                <span className="w-8 text-right pr-2">{i + 1}.</span>
                <textarea
                  value={role}
                  onChange={(e) => {
                    const newRoles = [...editedJobdesc.Roles_and_Responsibilities];
                    newRoles[i] = e.target.value;
                    const updatedJobdesc = { ...editedJobdesc };
                    updatedJobdesc.Roles_and_Responsibilities = newRoles;
                    setEditedJobdesc(updatedJobdesc);
                  }}
                  className="flex-1 text-gray-300 glass-effect focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-lg py-3 px-2 min-h-[60px]"
                />
                <button
                  onClick={() => handleRemoveListItem('Roles_and_Responsibilities', null, i)}
                  className="ml-2 text-red-400 hover:text-red-300"
                >
                  <X size={16} />
                </button>
            </div>
          ))}
        </div>

        {/* Tools & Technologies */}
        <div className="mb-5">
          <div className="flex justify-between items-center mb-3">
            <label className="font-semibold text-white">Tools & Technologies:</label>
            <button
              onClick={() => handleAddListItem('Tools_and_Technologies')}
              className="text-green-400 hover:text-green-300 flex items-center"
            >
              <Plus size={16} className="mr-1" />
              Add Tool
            </button>
          </div>
          {editedJobdesc.Tools_and_Technologies?.map((tool, i) => (
            <div key={i} className="mb-2 text-gray-300 flex items-center">
                <span className="w-8 text-right pr-2">{i + 1}.</span>
                <input
                  type="text"
                  value={tool}
                  onChange={(e) => {
                    const newTools = [...editedJobdesc.Tools_and_Technologies];
                    newTools[i] = e.target.value;
                    const updatedJobdesc = { ...editedJobdesc };
                    updatedJobdesc.Tools_and_Technologies = newTools;
                    setEditedJobdesc(updatedJobdesc);
                  }}
                  className="flex-1 text-gray-300 glass-effect focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-lg py-3 px-2"
                />
                <button
                  onClick={() => handleRemoveListItem('Tools_and_Technologies', null, i)}
                  className="ml-2 text-red-400 hover:text-red-300"
                >
                  <X size={16} />
                </button>
            </div>
          ))}
        </div>

        {/* Good to Have */}
        <div className="mb-5">
          <div className="flex justify-between items-center mb-3">
            <label className="font-semibold text-white">Good to Have:</label>
            <button
              onClick={() => handleAddListItem('GoodtoHave')}
              className="text-green-400 hover:text-green-300 flex items-center"
            >
              <Plus size={16} className="mr-1" />
              Add Item
            </button>
          </div>
          {editedJobdesc.GoodtoHave?.map((item, i) => (
            <div key={i} className="mb-2 text-gray-300 flex items-center">
                <span className="w-8 text-right pr-2">{i + 1}.</span>
                <input
                  type="text"
                  value={item}
                  onChange={(e) => {
                    const newItems = [...editedJobdesc.GoodtoHave];
                    newItems[i] = e.target.value;
                    const updatedJobdesc = { ...editedJobdesc };
                    updatedJobdesc.GoodtoHave = newItems;
                    setEditedJobdesc(updatedJobdesc);
                  }}
                  className="flex-1 text-gray-300 glass-effect focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-lg py-3 px-2"
                />
                <button
                  onClick={() => handleRemoveListItem('GoodtoHave', null, i)}
                  className="ml-2 text-red-400 hover:text-red-300"
                >
                  <X size={16} />
                </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleSaveJDEdits = async () => {
    setIsLoading(true);

    if (editedJobdesc.minExp > editedJobdesc.maxExp) {
      toast.error("Maximum experience must be greater than minimum experience!");
      setIsLoading(false);
      return;
    }

    try {
      const updatedData = {
        jd_id: selectedID,
        description: editedJobdesc
      };

      const response = await axios.put(`${host}/orgadmin/updatejd`,updatedData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      if(response.status === 200) {
        toast.success("Job description updated successfully!");
        setJobdesc(editedJobdesc);
        setIsEditingJD(false);
      }
    } catch(error) {
      if(error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else {
          toast.error(`Error: ${detail || "Failed to update job description"}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleSaveCustomQuestions = async () => {
    if (customQuestions.trim() === "") {
      toast.warn("Please enter Custom Questions!");
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${host}/upload-custom-questions/`,
        {
          questions: customQuestions,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setQuestions(response.data.questions);
      setEditedQuestions(response.data.questions);
    } catch (error) {
      console.error("Error saving custom questions:", error);
      toast.error("Failed to save custom questions. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  const handleJobPositionChange = async (e) => {
    const selectedId = e.target.value;
    setSelectedID(selectedId);
    if (!selectedId) return;
    const selectedPosition = jobPositions[selectedId];
    try {
      const response = await axios.get(`${host}/getjd`, {
        headers: {
          Authorization: `Bearer ${accessToken.toString()}`,
        },
        params: {
          jd_id: selectedId,
        },
      });
      if (response) {
        setJobData(response.data);
        setJobRole(response.data.jobrole);
        setJobdesc(response.data.description);
        setEditedJobdesc(JSON.parse(JSON.stringify(response.data.description)));
        setInterviewTimeLimit(response.data.interview_timelimit);
        setAssessmentSequence(response.data.assessment_sequence);
        if (Array.isArray(response.data.customQuestions) && response.data.customQuestions.length > 0) {
          setQuestions(response.data.customQuestions);
          setIsCustomQuestionsIncluded(true);
        }
        else {
          setQuestions([]);
          setIsCustomQuestionsIncluded(false);
        }
        if (response.data.mcq_set && response.data.mcq_set !== "NA") {
          setIsMCQIncluded(true);
          setSelectedSet(response.data.mcq_set);
          setMCQTimeLimit(response.data.mcq_time_limit);
          setMCQPercentThreshold(response.data.mcq_percent_threshold);
        } else {
          setIsMCQIncluded(false);
        }
        if (response.data.Coding_set && response.data.Coding_set !== "NA") {
          setIsCodingIncluded(true);
          setCodingTimeLimit(response.data.coding_time_limit);
          setCodingJudgingCriteria(response.data.Coding_Judge_Criteria);
          setSelectedCodingSet(response.data.Coding_set);
        } else {
          setIsCodingIncluded(false);
        }
        if(response.data.selected_behavioral_set && response.data.selected_behavioral_set !== "NA") {
          setIsBehavioralIncluded(true);
          setBehavioralTimeLimit(response.data.behavioral_time_limit);
          setSelectedBehavioralSet(response.data.selected_behavioral_set);
        } else {
          setIsBehavioralIncluded(false);
        }
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  const handleApiError = (error) => {
    if (error.response) {
      const status = error.response.status;
      const detail = error.response.data.detail;
      if (status === 401) {
        Cookies.remove("accessToken");
        toast.error(`Unauthorized: ${detail}`);
      } else if (status === 404) {
        toast.warning(`Not Found: ${detail}`);
      } else {
        toast.error(`Error: ${detail}`);
      }
    } else {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };
  const handleSaveGeneralConfig = async () => {
    if (resumeScoreThreshold <= 0 || resumeScoreThreshold >= 100 || !resumeScoreThreshold) {
      toast.warn("Provide a correct Resume Score Threshold!");
      return;
    }
    try {
      const data = await axios.post(`${host}/save-config`, {}, {
        params: {
          resume_score_threshold: resumeScoreThreshold,
          language: selectedLanguage,
          voice_name: selectedVoice.name,
          voice_type: selectedVoice.type,
          video_included: isVideoIncluded,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (data.status === 200) {
        toast.success("Configuration saved successfully!");
      }
    } catch (error) {
      toast.error("Failed to save configuration!");
      console.error("Error:", error);
    }
  };
  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (interview_timelimit < 5) {
      toast.error("Interview time limit should be greater than 5 minutes.");
      return;
    }
    if (isMCQIncluded && MCQTimeLimit < 5) {
      toast.error("MCQ time limit should be greater than 5 minutes.");
      return;
    }
    if (isBehavioralIncluded && behavioralTimeLimit < 5) {
      toast.error("Behavioral time limit should be greater than 5 minutes.");
      return;
    }
    if (isMCQIncluded && (MCQPercentThreshold <= 9 || MCQPercentThreshold >= 100)) {
      toast.error("Invalid MCQ Percent Threshold. The valid range is 10 to 99.");
      return;
    }
    if (isCodingIncluded && CodingTimeLimit < 10) {
      toast.error("Coding time limit should be greater than 10 minutes.");
      return;
    }
    if (isMCQIncluded && (selectedSet === "NA" || !selectedSet)) {
      toast.error("Please select an MCQ set.");
      return;
    }
    if (
      isCodingIncluded &&
      (selectedCodingSet === "NA" || !selectedCodingSet)
    ) {
      toast.error("Please select a Coding set.");
      return;
    }
    if (isBehavioralIncluded && (selectedBehavioralSet === "NA" || !selectedBehavioralSet)) {
      toast.error("Please select an MCQ set.");
      return;
    }
    if (isCodingIncluded && !codingJudgingCriteria) {
      toast.error("Please select Coding Judge Criteria.");
      return;
    }

    if (isCustomQuestionsIncluded && questions.length === 0) {
      toast.error("Please add at least one custom question.");
      return;
    }
    try {
      const dataToSend = {
        jd_id: selectedID,
        interview_timelimit: interview_timelimit,
        selectedSet: isMCQIncluded ? selectedSet : "NA",
        MCQTimeLimit: isMCQIncluded ? MCQTimeLimit : 0,
        MCQPercentThreshold: isMCQIncluded ? MCQPercentThreshold : 0,
        selectedCodingSet: isCodingIncluded ? selectedCodingSet : "NA",
        CodingTimeLimit: isCodingIncluded ? CodingTimeLimit : 0,
        codingJudgingCriteria: isCodingIncluded ? codingJudgingCriteria : "NA",
        selectedBehavioralSet: isBehavioralIncluded ? selectedBehavioralSet : "NA",
        behavioralTimeLimit: isBehavioralIncluded ? behavioralTimeLimit : 0,
        custom_questions: isCustomQuestionsIncluded ? questions : [],
        assessmentSequence: assessmentSequence,
      };
      console.log("Data being sent:", dataToSend);
      const response = await axios.post(
        `${host}/orgadmin/assessment-configurations`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response) {
        toast.success("JD Updated Successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  const handlePlayAudio = async (voice) => {
    if (selectedVoice?.name === voice.name) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        const newAudio = new Audio(
          `https://${BUCKET_NAME}.s3.${AWS_REGION_NAME}.amazonaws.com/Voices/${voice.name}.mp3`
        );
        await newAudio.play();
        setAudio(newAudio);
        await newAudio.play();
        setIsPlaying(true);
        newAudio.onended = () => {
          setIsPlaying(false);
        };
      }
    } else {
      setSelectedVoice(voice);
      if (audio) {
        audio.pause();
      }
      try {
        const newAudio = new Audio(
          `https://${BUCKET_NAME}.s3.${AWS_REGION_NAME}.amazonaws.com/Voices/${voice.name}.mp3`
        );
        await newAudio.play();
        setAudio(newAudio);
        setIsPlaying(true);
        newAudio.onended = () => {
          setIsPlaying(false);
        };
      } catch (error) {
        console.error("Error playing audio:", error);
        toast.error("Failed to play audio sample");
      }
    }
  };
  console.log('jobPositions:', jobPositions);
  console.log('sets:', sets);
  console.log('codingSets:', codingSets);
  console.log('behavioralSets:', behavioralSets);
  console.log('voices:', voices);
  console.log('languages:', languages);
  console.log('questions:', questions);
  console.log('assessmentSequence:', assessmentSequence);
  return (
    <div className="max-w-6xl p-4 md:p-8">
      {/* Add Back Button */}
 <div className="mb-6">
        <button
          onClick={() => navigate(-1)}
          className="glass-effect flex items-center gap-2 px-4 py-2 text-white rounded-lg hover:bg-blue-600/20 transition-colors border border-gray-100"
        >
          <ArrowLeft className="w-5 h-5 text-white" />
          <span>Back</span>
        </button>
      </div>
      <div className="glass-effect backdrop-blur-[50px] rounded-lg shadow-sm border border-gray-100 mb-4">
        <button
          onClick={() => setIsEditingJD(!isEditingJD)}
          className="w-full flex items-center justify-between p-6"
        >
          <div className="flex items-center gap-3">
            <code className="text-blue-600">{"</>"}</code>
            <h2 className="text-xl font-semibold text-white">Edit Job Description</h2>
          </div>
          <ChevronDown
            className={`w-5 h-5 text-white transition-transform ${isEditingJD ? "rotate-180" : ""}`}
          />
        </button>
        {isEditingJD && (
          <div className="p-6 border-t border-gray-100">
            <div className="max-w-5xl mx-auto space-y-8">
            <div className="relative">
                <label className="block text-sm font-medium text-white mb-2">
                  Select Job Position
                </label>
                <select
                  value={selectedID}
                  onChange={handleJobPositionChange}
                  className="glass-effect text-gray-300 w-full p-3 rounded-lg bg-white focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:border-transparent"
                >
                  <option
                    value=""
                    style={{
                      background: "rgba(34, 52, 97, 0.35)",
                      color: "black",
                    }}
                    disabled
                  >
                    Select Job Position
                  </option>
                  {Object.keys(jobPositions || {}).length > 0
                    ? Object.entries(jobPositions || {}).map(([id, position]) => (
                        <option
                          key={id}
                          value={id}
                          style={{
                            background: "rgba(34, 52, 97, 0.35)",
                            color: "black",
                          }}
                        >
                          {position.job_role}
                        </option>
                      ))
                    : Array.from({ length: 5 }).map((_, index) => (
                        <option key={index} disabled>
                          Loading...
                        </option>
                      ))}
                </select>
              </div>
              {/* Show content only when a job is selected */}
              {selectedID && jobdesc && formatJobDescriptionForEditing(jobRole)}
              {selectedID && jobdesc && (
                <div className="flex justify-end">
                  <button
                    onClick={handleSaveJDEdits}
                    disabled={editedJobdesc.minExp > editedJobdesc.maxExp}
                    className={`button px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors ${
                      editedJobdesc.minExp > editedJobdesc.maxExp ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    <span>Save Changes</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="glass-effect backdrop-blur-[50px] rounded-lg shadow-sm border border-gray-100 mb-4">
        <button
          onClick={handleAccordionChange}
          className="w-full flex items-center justify-between p-6"
        >
          <div className="flex items-center gap-3">
            <code className="text-blue-600">{"</>"}</code>
            <h2 className="text-xl font-semibold text-white">
              Assessment Configuration
            </h2>
          </div>
          <ChevronDown
            className={`w-5 h-5 text-white transition-transform ${expanded ? "rotate-180" : ""
              }`}
          />
        </button>
        {expanded && (
          <div className="p-6 border-t border-gray-100">
            <div className="max-w-5xl mx-auto space-y-8">
              <div className="mb-5">
                <label className="block mb-2 font-semibold text-white">Position:</label>
                <input
                  type="text"
                  value={jobRole}
                  disabled
                  className="text-gray-300 w-full glass-effect focus:ring-1 focus:ring-blue-500 focus:border-blue-500 rounded-lg py-3 px-2"
                />
              </div>
              {selectedID && jobdesc && (
                <div className="space-y-8">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="relative">
                      <h3 className="text-lg font-medium text-white mb-4">
                        Interview Time Limit(in mins)
                      </h3>
                      <div className="relative">
                        <Timer className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-300" />
                        <input
                          min="5"
                          max="90"
                          type="number"
                          value={interview_timelimit}
                          onChange={(e) =>
                            setInterviewTimeLimit(parseInt(e.target.value))
                          }
                          className="text-gray-300 glass-effect w-full pl-12 pr-4 py-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                          placeholder="Interview Time Limit (minutes)"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="text-lg font-medium text-white">
                          Custom Questions
                        </h3>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={isCustomQuestionsIncluded}
                            onChange={() => {
                              setIsCustomQuestionsIncluded(
                                !isCustomQuestionsIncluded
                              );
                              if (!isCustomQuestionsIncluded) setQuestions([]);
                            }}
                            className="sr-only peer"
                          />
                          <div className="w-11 h-6 bg-gray-400 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-400 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-pink-500"></div>
                        </label>
                      </div>
                      <p className="text-white">
                        (Custom Questions to be asked in AI Interview)
                      </p>
                    </div>
                  </div>
                  {/* Custom Questions Textarea */}
                  {isCustomQuestionsIncluded && (
                    <div className="space-y-4">
                      {questions.length === 0 ? (
                        <>
                          <textarea
                            value={customQuestions}
                            onChange={(e) => setCustomQuestions(e.target.value)}
                            className="text-gray-300 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 glass-effect w-full p-4 rounded-lg min-h-[120px]"
                            placeholder="Enter custom questions..."
                          />
                          <button
                            onClick={handleSaveCustomQuestions}
                            className="button px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                          >
                            <span>Save Questions</span>
                          </button>
                        </>
                      ) : (
                        <div className="space-y-4">
                          <div className="max-h-[300px] overflow-y-auto space-y-3">
                          {(questions || []).map((question, index) => (
                              <div
                                key={index}
                                className="flex items-center gap-2"
                              >
                                <input
                                  value={question}
                                  onChange={(e) => {
                                    const updatedQuestions = [...questions];
                                    updatedQuestions[index] = e.target.value;
                                    setQuestions(updatedQuestions);
                                  }}
                                  className="glass-effect text-gray-300 flex-1 p-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-transparent"
                                />
                                <button
                                  onClick={() =>
                                    setQuestions(
                                      questions.filter((_, i) => i !== index)
                                    )
                                  }
                                  className="p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                                >
                                  <X className="w-5 h-5" />
                                </button>
                              </div>
                            ))}
                          </div>
                          <button
                            onClick={() => setQuestions([...questions, ""])}
                            className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                          >
                            <Plus className="w-4 h-4" />
                            Add Question
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {/* MCQ and Coding Settings Grid */}
                  <div className="grid md:grid-cols-2 gap-8">
                    {/* MCQ Settings */}
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <h3 className="text-lg font-medium text-white">
                          Knowledge Test Settings
                        </h3>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={isMCQIncluded}
                            onChange={(e) => setIsMCQIncluded(e.target.checked)}
                            className="sr-only peer"
                          />
                          <div className="w-11 h-6 bg-gray-400 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-400 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-pink-500"></div>
                        </label>
                      </div>
                      {isMCQIncluded && (
                        <div className="space-y-4">
                          <select
                            value={selectedSet}
                            onChange={(e) => setSelectedSet(e.target.value)}
                            className="glass-effect text-gray-300 w-full p-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                          >
                            <option
                              value=""
                              style={{
                                background: "rgba(34, 52, 97, 0.35)",
                                color: "black",
                              }}
                            >
                              Select MCQ Set
                            </option>
                            {(sets || []).map((set, index) => (
                              <option
                                key={index}
                                value={set.name}
                                style={{
                                  background: "rgba(34, 52, 97, 0.35)",
                                  color: "black",
                                }}
                              >
                                {set.name}
                              </option>
                            ))}
                          </select>
                          <div className="relative">
                            <Timer className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-300" />
                            <input
                              type="number"
                              min="5"
                              value={MCQTimeLimit}
                              onChange={(e) =>
                                setMCQTimeLimit(parseInt(e.target.value))
                              }
                              className="glass-effect text-gray-300 w-full pl-12 pr-4 py-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="MCQ Time Limit (minutes)"
                            />
                          </div>
                          <div className="relative">
                            <Percent className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-300" />
                            <input
                              type="number"
                              min="10"
                              max="100"
                              value={MCQPercentThreshold}
                              onChange={(e) =>
                                setMCQPercentThreshold(parseInt(e.target.value))
                              }
                              className="glass-effect text-gray-300 w-full pl-12 pr-4 py-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="MCQ Percent Threshold (%)"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Coding Settings */}
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <h3 className="text-lg font-medium text-white">
                          Coding Settings
                        </h3>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={isCodingIncluded}
                            onChange={(e) =>
                              setIsCodingIncluded(e.target.checked)
                            }
                            className="sr-only peer"
                          />
                          <div className="w-11 h-6 bg-gray-400 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-400 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-pink-500"></div>
                        </label>
                      </div>
                      {isCodingIncluded && (
                        <div className="space-y-4">
                          <select
                            value={selectedCodingSet}
                            onChange={(e) =>
                              setSelectedCodingSet(e.target.value)
                            }
                            className="glass-effect text-gray-300 w-full p-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                          >
                            <option
                              value=""
                              style={{
                                background: "rgba(34, 52, 97, 0.35)",
                                color: "black",
                              }}
                            >
                              Select Coding Set
                            </option>
                            {(codingSets || []).map((set, index) => (
                              <option
                                key={index}
                                value={set}
                                style={{
                                  background: "rgba(34, 52, 97, 0.35)",
                                  color: "black",
                                }}
                              >
                                {set}
                              </option>
                            ))}
                          </select>
                          <div className="relative">
                            <Timer className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-300" />
                            <input
                              min="10"
                              type="number"
                              value={CodingTimeLimit}
                              onChange={(e) =>
                                setCodingTimeLimit(parseInt(e.target.value))
                              }
                              className="glass-effect text-gray-300 w-full pl-12 pr-4 py-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Coding Time Limit (minutes)"
                            />
                          </div>

                          <select value={codingJudgingCriteria} onChange={(e) => setCodingJudgingCriteria(e.target.value)} className="glass-effect text-gray-300 w-auto p-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 inline-block mr-2">
                            <option value="" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} disabled selected> Select Judge Criteria </option>
                            <option value="0" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}> Judge 0 </option>
                            <option value="1" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}> Judge 1 </option>
                            <option value="2" style={{ background: "rgba(34, 52, 97, 0.35)", color: "gray" }} disabled> Judge 2 </option>
                            <option value="3" style={{ background: "rgba(34, 52, 97, 0.35)", color: "gray" }} disabled> Judge 3 </option>
                            <option value="4" style={{ background: "rgba(34, 52, 97, 0.35)", color: "gray" }} disabled> Judge 4 </option>
                            <option value="5" style={{ background: "rgba(34, 52, 97, 0.35)", color: "gray" }} disabled> Master Judge </option>
                          </select>
                          <select value={codingJudgingCriteria} onChange={(e) => setCodingJudgingCriteria(e.target.value)} className="glass-effect text-gray-300 w-auto p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent inline-block mr-2">
                            <option value="" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} disabled selected> Select Judge Criteria </option>
                            <option value="0" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}> Judge 0 </option>
                            <option value="1" style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}> Judge 1 </option>
                            <option value="2" style={{ background: "rgba(34, 52, 97, 0.35)", color: "gray" }} disabled> Judge 2 </option>
                            <option value="3" style={{ background: "rgba(34, 52, 97, 0.35)", color: "gray" }} disabled> Judge 3 </option>
                            <option value="4" style={{ background: "rgba(34, 52, 97, 0.35)", color: "gray" }} disabled> Judge 4 </option>
                            <option value="5" style={{ background: "rgba(34, 52, 97, 0.35)", color: "gray" }} disabled> Master Judge </option>
                          </select>
                        </div>
                      )}
                    </div>
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <h3 className="text-lg font-medium text-white">
                          Behavioral Test Settings
                        </h3>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={isBehavioralIncluded}
                            onChange={(e) => setIsBehavioralIncluded(e.target.checked)}
                            className="sr-only peer"
                          />
                          <div className="w-11 h-6 bg-gray-400 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-400 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-pink-500"></div>
                        </label>
                      </div>
                      {isBehavioralIncluded && (
                        <div className="space-y-4">
                          <select
                            value={selectedBehavioralSet}
                            onChange={(e) => setSelectedBehavioralSet(e.target.value)}
                            className="glass-effect text-gray-300 w-full p-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                          >
                            <option
                              value=""
                              style={{
                                background: "rgba(34, 52, 97, 0.35)",
                                color: "black",
                              }}
                            >
                              Select Behavioral Set
                            </option>
                            {(behavioralSets || []).map((set, index) => (
                              <option
                                key={index}
                                value={set.name}
                                style={{
                                  background: "rgba(34, 52, 97, 0.35)",
                                  color: "black",
                                }}
                              >
                                {set.name}
                              </option>
                            ))}
                          </select>
                          <div className="relative">
                            <Timer className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-300" />
                            <input
                              type="number"
                              min="5"
                              max="90"
                              value={behavioralTimeLimit}
                              onChange={(e) =>
                                setBehavioralTimeLimit(parseInt(e.target.value))
                              }
                              className="glass-effect text-gray-300 w-full pl-12 pr-4 py-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Behavioral Time Limit (Mins)"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {(isMCQIncluded || isCodingIncluded || isBehavioralIncluded) && (
                    <div className="space-y-4">
                      <h3 className="text-lg font-medium text-white">
                        Assessment Sequence
                      </h3>
                      <p className="text-sm text-gray-500">
                        Drag and drop to reorder the assessments
                      </p>
                      <div className="space-y-2">
                      {(assessmentSequence || []).map((assessment) => (
                          <div
                            key={assessment}
                            draggable
                            onDragStart={(e) => handleDragStart(e, assessment)}
                            onDragEnd={handleDragEnd}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, assessment)}
                            className="glass-effect flex items-center gap-3 p-4 bg-white border border-gray-200 rounded-lg cursor-move transition-colors"
                          >
                            <GripVertical className="w-5 h-5 text-gray-300" />
                            <span className="text-gray-300 font-medium capitalize">
                              {assessment}
                            </span>
                            {assessment === "interview" && (
                              <span className="text-xs bg-pink-100 text-pink-700 px-2 py-1 rounded-full ml-auto">
                                Required
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="flex items-center gap-2 mt-2">
                        <div className="flex-1 h-px bg-gray-200"></div>
                        <span className="text-sm text-white">
                        Current Sequence: {assessmentSequence ? assessmentSequence.join(" → ") : ""}
                        </span>
                        <div className="flex-1 h-px bg-gray-200"></div>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-end pt-4">
                    <button
                      onClick={(e) => {
                        handleSaveChanges(e);
                      }}
                      className="button"
                    >
                      <span className="flex gap-2 items-center">
                        <Save className="w-4 h-4" />
                        Save Changes
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="glass-effect backdrop-blur-[50px] rounded-lg shadow-sm border border-gray-100 mb-4">
        <button
          onClick={() => setGeneralConfig(!generalConfig)}
          className="w-full flex items-center justify-between p-6"
        >
          <div className="flex items-center gap-3">
            <code className="text-blue-600">{"</>"}</code>
            <h2 className="text-xl font-semibold text-white">
              General Configuration
            </h2>
          </div>
          <ChevronDown
            className={`w-5 h-5 text-white transition-transform ${generalConfig ? "rotate-180" : ""
              }`}
          />
        </button>
        {generalConfig && (
          <div className="p-6 border-t border-gray-100">
            <div className="max-w-5xl mx-auto space-y-8">
              <div className="grid md:grid-cols-2 gap-8">
                <div className="relative">
                  <h3 className="text-lg font-medium text-white mb-4">
                    Resume Score Threshold
                  </h3>
                  <div className="relative">
                    <Percent className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-300" />
                    <input
                      type="number"
                      value={resumeScoreThreshold}
                      onChange={(e) =>
                        setResumeScoreThreshold(parseInt(e.target.value))
                      }
                      className="text-gray-300 glass-effect w-full pl-12 pr-4 py-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Enter Resume Score Threshold"
                    />
                  </div>
                </div>
                <div className="relative">
                  <h3 className="text-lg font-medium text-white mb-4">
                    Select Interview Language
                  </h3>
                  <select
                    value={selectedLanguage}
                    onChange={(e) => handleLanguageChange(e.target.value)}
                    className="glass-effect text-gray-300 w-full p-3 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-transparent"
                  >
                    {(languages || []).map((lang, index) => (
                      <option
                        key={index}
                        value={lang}
                        style={{
                          background: "rgba(34, 52, 97, 0.35)",
                          color: "black",
                        }}
                      >
                        {lang}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {selectedLanguage && (
                <div>
                  <h2 className="text-lg font-medium text-white mb-4">Available Voices</h2>
                  <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-1 sm:gap-4">
                  {voices && voices.length > 0 ? (
                    voices.map((voice, index) => (
                      <li key={index} className="relative">
                        <label className="block p-2 bg-slate-700 rounded-lg cursor-pointer hover:bg-slate-600 transition-colors">
                          <input
                            type="radio"
                            name="selectedVoice"
                            checked={selectedVoice?.name === voice.name}
                            onChange={() => {
                              handlePlayAudio(voice);
                            }}
                            className="absolute opacity-0"
                          />
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                              <div
                                className={`w-3 h-3 rounded-full ${selectedVoice?.name === voice.name
                                  ? "bg-teal-500 ring-2 ring-teal-300 ring-offset-2 ring-offset-slate-700"
                                  : "bg-slate-500"
                                  }`}
                              />
                              <div className="text-white">
                                <span className="font-medium block">{voice.name}</span>
                                <span className="text-sm text-slate-300">{voice.type}</span>
                              </div>
                            </div>
                            <div
                              className="flex items-center justify-center w-8 h-8 rounded-full bg-[#FB54AE] "
                            >
                              {(selectedVoice?.name === voice.name && isPlaying) ? (
                                <Pause className="w-4 h-4 text-white" />
                              ) : (
                                <Play className="w-4 h-4 text-white" />
                              )}
                            </div>
                          </div>
                        </label>
                      </li>
                    ))
                  ) : (
                    <li className="text-white">No voices available for this language.</li>
                  )}
                  </ul>
                </div>
              )}
              <div className="flex items-center gap-x-2">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={isVideoIncluded}
                  onChange={(e) => setIsVideoIncluded(e.target.checked)}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-400 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-400 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-pink-500"></div>
              </label>
              <h5 className="text-lg font-medium text-white">
                Record video with Interview Answers
              </h5>
            </div>
            <div className="p-3 border border-yellow-300 rounded-lg w-[40%] bg-yellow-300 bg-opacity-50">
              <p className="text-sm text-gray-100 font-medium italic">Note: Recorded videos will be deleted after 30 days.</p>
            </div>
              {/* Save Button */}
              <div className="flex justify-end pt-4">
                <button onClick={handleSaveGeneralConfig} className="button">
                  <span className="flex gap-2 items-center">
                    <Save className="w-4 h-4" />
                    Save Changes
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AssessmentConfig;
